import { WebMercatorViewport } from 'deck.gl/typed';
export function getViewportBoundsByViewState(viewState) {
    const webMercatorViewport = new WebMercatorViewport(viewState);
    const [left, top, right, bottom] = webMercatorViewport.getBounds();
    return [
        [left, top],
        [right, top],
        [right, bottom],
        [left, bottom],
        [left, top],
    ];
}
