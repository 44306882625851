import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { CamLocPipelineViewer } from './CamLocPipelineViewer';
import { API } from '../../services/ApiService';
import { fcFromFeatures } from '../../utils/geoJson.utils';
import { getCameraLocalization } from '../../utils/pipelineFileGetters/getCameraLocalization';
import { LoadingStatus } from '../../const/appConstants';
export const CamLocPipelineViewerContainer = ({ pipelineId }) => {
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.INITIAL);
    const [pipeline, setPipeline] = useState(null);
    const [camLocConfig, setCamLocConfig] = useState({ featureCollection: fcFromFeatures([]) });
    useEffect(() => {
        const effect = async () => {
            const getPipelineResult = await API.getPipelineItem({ uuid: pipelineId });
            setPipeline(getPipelineResult);
            const cameraLocalization = await getCameraLocalization(getPipelineResult);
            const newCamLocConfig = {
                featureCollection: cameraLocalization || fcFromFeatures([]),
            };
            setCamLocConfig(newCamLocConfig);
            setLoadingStatus(LoadingStatus.SUCCESS);
        };
        effect();
    }, []);
    if (loadingStatus !== LoadingStatus.SUCCESS) {
        return (_jsx("div", { children: "Loading" }));
    }
    const userViewportConfig = {
        latitude: 25.1192784,
        longitude: 55.2586057,
        zoom: 15.9,
    };
    return (_jsx(CamLocPipelineViewer, { userViewportConfig: userViewportConfig, camLocConfig: camLocConfig }));
};
