import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { asyncConfigManager } from '../../../utils/asyncConfigManager';
import styles from './AssetFeatureViewer.module.scss';
export const AssetFeatureViewer = ({ feature }) => {
    const rawUrl = feature.properties.image_name;
    // @ts-ignore Todo ts error + todo: use getFinalAssetImageUrl
    const { persistentBucket } = asyncConfigManager.config.bucketNames;
    const className = feature.properties.class_name;
    const finalUrl = `https://storage.googleapis.com/${persistentBucket}/${rawUrl}`;
    return (_jsxs("div", { className: styles.wrapper, children: [_jsx("div", { className: styles.header, children: className }), _jsx("div", { className: styles.imageWrapper, children: finalUrl
                    ? _jsx("img", { src: finalUrl, alt: className })
                    : _jsx("div", { className: styles.loadingWrapper, children: "Loading" }) })] }));
};
