import { getOutputList } from './getOutputList';
export const getExposedFileUrl = (pipeline, exposed_as) => {
    if (!pipeline) {
        return null;
    }
    const output = getOutputList(pipeline);
    const module = Object.values(output)
        .find((el) => el.exposed_as === exposed_as);
    // @ts-ignore todo
    return !module ? null : module === null || module === void 0 ? void 0 : module.url;
};
export function getCameraLocalization(pipeline) {
    const pciFileUrl = getExposedFileUrl(pipeline, 'CAMERA_LOCALIZATION');
    // Todo: do without worker
    return new Promise((resolve, reject) => {
        if (pciFileUrl) {
            const worker = new Worker(new URL('workers/camera_localization.js', import.meta.url));
            worker.postMessage({ url: pciFileUrl });
            worker.onmessage = ({ data: { data } }) => {
                resolve(data);
                worker.terminate();
            };
        }
        else {
            resolve(undefined);
        }
    });
}
