import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { RoadLyMap } from '../../RoadLyMap';
import { getViewportParamsFromFeatures } from '../../../utils/viewportUtilities/getViewportParamsFromFeatures';
import styles from './AssetsPipelineViewer.module.scss';
import { AssetsViewerPanel } from '../AssetsViewerPanel';
import { AssetFrameViewerModal } from '../AssetFrameViewer/AssetFrameViewerModal';
import { StoredViewportsListContainer } from '../../../components/StoredViewportsList';
import { fcFromFeatures } from '../../../utils/geoJson.utils';
import { getAssetGeoJsonRenderingConfig } from '../utils';
export const AssetsPipelineViewer = ({ isLoading, assetsPipelinePayloadWithMapProps, assetsSessionsPayload, activateSession, activatePipeline, onReloadDataRequested, }) => {
    const [dynamicMapProps, setDynamicMapProps] = useState(null);
    const [activeAsset, setActiveAsset] = useState(null);
    const activeSession = (assetsSessionsPayload === null || assetsSessionsPayload === void 0 ? void 0 : assetsSessionsPayload.activeSession) || null;
    // Todo: remove after moving this to context layer
    const handleNavigationEnd = (newViewportConfig) => {
        // if (!dynamicMapProps) {
        //   throw new Error('Dynamic map props are not assigned (2)');
        // }
        // const updatedDynamicProps = {
        //   ...dynamicMapProps,
        //   userViewportConfig: newViewportConfig,
        // };
        //
        // setDynamicMapProps(updatedDynamicProps);
    };
    const onClickMap = (info) => {
        // Click on session
        if (typeof info.object.properties.sessionId === 'number') {
            activateSession(info.object.properties.sessionId);
            return;
        }
        // Click on dot
        (async () => {
            if (assetsPipelinePayloadWithMapProps === null) {
                throw new Error('Unable to reach pipeline');
            }
            setActiveAsset({
                filename: info.object.properties.image_name,
                pipelineId: assetsPipelinePayloadWithMapProps.pipeline.uuid,
                sign: info.object.properties.sign,
            });
        })();
    };
    useEffect(() => {
        (() => {
            if (!assetsSessionsPayload) {
                return;
            }
            const pipelineFeatures = assetsPipelinePayloadWithMapProps ? [...assetsPipelinePayloadWithMapProps.assetsFeatureCollection.features] : [];
            const sessionFeatures = assetsPipelinePayloadWithMapProps ? [] : [...assetsSessionsPayload.sessionsFeatureCollection.features];
            const features = [...sessionFeatures, ...pipelineFeatures];
            // Focus on selected session
            const userViewportConfig = activeSession
                ? getViewportParamsFromFeatures([activeSession.track_line])
                : getViewportParamsFromFeatures(features); // Why both types of features here?
            setDynamicMapProps({
                userViewportConfig,
                assetsRegistryConfig: {
                    // Todo: use finalAssetsConfig here
                    finalAssetsFeatureCollection: fcFromFeatures(pipelineFeatures),
                },
                geoJsonConfig: {
                    featureCollection: fcFromFeatures(sessionFeatures),
                    renderingConfig: getAssetGeoJsonRenderingConfig(),
                },
            });
        })();
    }, [assetsSessionsPayload, assetsPipelinePayloadWithMapProps]);
    const handleCloseAssetViewerPanel = () => {
        setActiveAsset(null);
        activatePipeline(null);
        activateSession(null);
    };
    const handleCloseAssetViewerPipelineMode = () => {
        setActiveAsset(null);
        activatePipeline(null);
    };
    const handleCloseAssetFrameViewer = () => {
        setActiveAsset(null);
    };
    /**
     * Stored viewports props
     */
    const handleSelectViewport = (newViewport) => {
        // Has not to work once is not loaded
        if (!dynamicMapProps) {
            return;
        }
        setDynamicMapProps({
            ...dynamicMapProps,
            userViewportConfig: {
                ...dynamicMapProps.userViewportConfig,
                ...newViewport,
            },
        });
    };
    return (_jsxs("div", { className: styles.wrapper, children: [isLoading
                ? _jsx("div", { className: styles.loadingOverlay, children: "Loading" })
                : null, activeSession || assetsPipelinePayloadWithMapProps
                ? (_jsx(AssetsViewerPanel, { onCloseSession: handleCloseAssetViewerPanel, onClosePipeline: handleCloseAssetViewerPipelineMode, activeSession: activeSession, assetsPipelinePayloadWithMapProps: assetsPipelinePayloadWithMapProps, onClickOnPipelineListItem: activatePipeline, onReloadDataRequested: onReloadDataRequested }))
                : null, activeAsset
                ? (_jsx(AssetFrameViewerModal, { onClose: handleCloseAssetFrameViewer, ...activeAsset }))
                : null, dynamicMapProps === null
                ? null
                : (_jsxs(_Fragment, { children: [_jsx(StoredViewportsListContainer, { activateViewport: handleSelectViewport }), _jsx("div", { className: clsx(styles.mapWrapper, activeSession ? styles.reduced : null), children: _jsx(RoadLyMap
                            // @ts-ignore
                            , { 
                                // @ts-ignore
                                onNavigationEnd: handleNavigationEnd, onClickMap: onClickMap, ...dynamicMapProps }) })] }))] }));
};
