import { useContext } from 'react'
import DialogContext from 'contexts/dialogContext'
import { DIALOGS_TYPES } from 'const'

export function useDialog() {
  const [dialog, setDialog] = useContext(DialogContext)

  return {
    type: dialog.type,
    isOpen: dialog.isOpen,
    hide: () =>
      setDialog(draft => {
        draft.isOpen = false
      }),
    show: type => setDialog({ isOpen: true, type }),
    dialogsTypes: DIALOGS_TYPES,
  }
}
