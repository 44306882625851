import { Button, Alignment } from '@blueprintjs/core'
import { API } from '@roadar-pipeline-viewer/roadly-typescript/dist/services/ApiService'
import { useEffect, useState } from 'react'
import { Select2 } from '@blueprintjs/select'
import { MenuItem2 } from '@blueprintjs/popover2'

export function APIControlledSelect({ selectedItem, onItemSelect, apiMethodName, itemsTextMapping = {} }) {
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    API[apiMethodName]()
      .then(newItems => {
        setItems(newItems)
        onItemSelect(newItems[0])
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    <Select2
      fill
      disabled={isLoading || items.length === 0}
      items={items}
      activeItem={selectedItem}
      onItemSelect={onItemSelect}
      noResults={'No results'}
      itemRenderer={(name, { handleClick, handleFocus, modifiers }) => (
        <MenuItem2
          text={itemsTextMapping[name] || name}
          roleStructure="listoption"
          active={modifiers.active}
          key={name}
          onClick={handleClick}
          onFocus={handleFocus}
        />
      )}
    >
      <Button fill loading={isLoading} alignText={Alignment.LEFT} rightIcon={'chevron-down'}>
        {itemsTextMapping[selectedItem] || selectedItem}
      </Button>
    </Select2>
  )
}
