export const getCursor = ({ isDragging, isHovering }) => {
    switch (true) {
        case isDragging:
            return 'grabbing';
        case isHovering:
            return 'pointer';
        default:
            return 'grab';
    }
};
