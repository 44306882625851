import { createContext } from 'react';
export const THEME = {
    LIGHT: 'light',
    DARK: 'dark',
};
// Default state is temporary, for type checking sake
// Should work, but not checked:
const defaultTemporaryState = {
    theme: THEME.DARK,
    setTheme: () => { },
};
export const ThemeContext = createContext(defaultTemporaryState);
