import { ProgressBar as BlueprintProgressBar } from '@blueprintjs/core'
import style from './ProgressBar.module.css'

function ProgressBar() {
  return (
    <div className={style.progress}>
      <BlueprintProgressBar className={style.bar} value={1} />
    </div>
  )
}

export default ProgressBar
