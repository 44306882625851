const _pointCloudSemanticClasses = [
    'bird',
    'ground_animal',
    'curb',
    'fence',
    'guard_rail',
    'barrier',
    'wall',
    'bike_lane',
    'crosswalk_plain',
    'curb_cut',
    'parking',
    'pedestrian_area',
    'rail_track',
    'road',
    'service_lane',
    'sidewalk',
    'bridge',
    'building',
    'tunnel',
    'person',
    'bicyclist',
    'motorcyclist',
    'other_rider',
    'lane_marking_crosswalk',
    'lane_marking_general',
    'mountain',
    'sand',
    'sky',
    'snow',
    'terrain',
    'vegetation',
    'water',
    'banner',
    'bench',
    'bike_rack',
    'billboard',
    'catch_basin',
    'cctv_camera',
    'fire_hydrant',
    'junction_box',
    'mailbox',
    'manhole',
    'phone_booth',
    'pothole',
    'street_light',
    'pole',
    'traffic_sign_frame',
    'utility_pole',
    'traffic_light',
    'traffic_sign_back',
    'traffic_sign_front',
    'trash_can',
    'bicycle',
    'boat',
    'bus',
    'car',
    'caravan',
    'motorcycle',
    'on_rails',
    'other_vehicle',
    'trailer',
    'truck',
    'wheeled_slow',
    'car_mount',
    'ego_vehicle',
];
const switchedOffClasses = [
    'sky', 'ego_vehicle', 'car_mount'
];
export const pointCloudSemanticClasses = _pointCloudSemanticClasses.map(v => v.replaceAll('_', ' '));
export const switchedOffIdxs = switchedOffClasses.map(cls => _pointCloudSemanticClasses.indexOf(cls));
