export const getNextIndexAroundTheArray = (array, currentIndex, direction) => {
    const maxIndex = array.length - 1;
    if (direction === 1) {
        return currentIndex === maxIndex
            ? 0
            : currentIndex + 1;
    }
    return currentIndex === 0
        ? maxIndex
        : currentIndex - 1;
};
