import { jsx as _jsx } from "react/jsx-runtime";
import { AssetsPipelinePresets } from './RunSignsAssetsLocPipeline';
import { API } from '../../../services/ApiService';
export const AssetsPipelinePresetsContainer = ({ sessionId, className, onPipelineSubmitted }) => {
    const onRunSignsPipelineRequested = async () => {
        await API.runSignsAssetsPipeline(sessionId);
        onPipelineSubmitted();
    };
    return (_jsx(AssetsPipelinePresets, { onRunSignsPipelineRequested,
        className }));
};
