import { apiAsClass } from '@roadar-pipeline-viewer/roadly-typescript/dist/services/ApiService'
import React, { useEffect } from 'react'
import { useImmer } from 'use-immer'
import { WebMercatorViewport } from 'deck.gl'
import { Alert } from '@blueprintjs/core'
import Loader from 'components/Loader/Loader'
import TracksMap from 'components/Map/TracksMap'
import SessionsMapSidebar from 'components/SessionsMap/SessionsMapSidebar'
import { DEFAULT_USER_VIEWPORT_CONFIG, MAP_PADDING, PROGRESS } from 'const'

const WIDTH = window.innerWidth
const HEIGHT = window.innerHeight

const DEFAULT_STATE = {
  progress: PROGRESS.IDLE,
  tracks: [],
  currentTrack: null,
  viewport: DEFAULT_USER_VIEWPORT_CONFIG,
  filters: {},
  noDataAlertClosed: false,
}

const getViewportParams = bbox => {
  if (bbox.length) {
    const [topLng, topLat, bottomLng, bottomLat] = bbox
    const padding = WIDTH > MAP_PADDING.DESKTOP * 2 ? MAP_PADDING.DESKTOP : MAP_PADDING.MOBILE

    const viewport = new WebMercatorViewport({ width: WIDTH, height: HEIGHT }).fitBounds(
      [
        [topLng, topLat],
        [bottomLng, bottomLat],
      ],
      { padding }
    )
    const { longitude, latitude, zoom } = viewport

    return { longitude, latitude, zoom }
  } else {
    return DEFAULT_USER_VIEWPORT_CONFIG
  }
}

function SessionsMapPage() {
  const [state, setState] = useImmer({ ...DEFAULT_STATE })

  const handleProgressChange = progress => {
    setState(draft => {
      draft.progress = progress
    })
  }

  const getPageData = async filters => {
    handleProgressChange(PROGRESS.WORK)

    const sessionsResponse = await apiAsClass
      .getExtendedSessions(filters)
      .then(jsonData => {
        jsonData.items = jsonData.items.map(item => {
          item.track_line.properties = Object.keys(item).reduce((acc, k) => {
            if (!['track_line', 'geocoding'].includes(k)) acc[k] = item[k]
            return acc
          }, {})
          return item
        })
        return jsonData
      })
      .catch(() => handleProgressChange(PROGRESS.ERROR))
    const viewport = sessionsResponse.items.length > 0 ? getViewportParams(sessionsResponse.bbox) : state.viewport

    setState(draft => {
      draft.progress = PROGRESS.SUCCESS
      draft.tracks = sessionsResponse.items // sessionsResponse.items.map(item => item.track_line.geometry.coordinates)
      draft.viewport = viewport
      draft.noDataAlertClosed = false
    })
  }
  const { progress, currentTrack, tracks, viewport, filters, noDataAlertClosed } = state
  const { owner, created_from, created_to } = filters
  const applyFilters = filters => {
    setState(draft => {
      draft.filters = filters
      draft.currentTrack = null
    })

    filters = Object.keys(filters).reduce((acc, k) => {
      if (filters[k] === undefined) return acc
      acc[k] = filters[k]
      return acc
    }, {})
    const query = new URLSearchParams(filters).toString()
    const uri = `${window.location.pathname}?${query}`
    window.history.pushState(uri, '', uri)
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const owner = params.get('owner') || 'me'
    const created_from = params.get('created_from')
    const created_to = params.get('created_to')
    const filters = {
      owner,
      created_from,
      created_to,
    }
    setState({
      ...state,
      filters,
    })
  }, [])

  useEffect(() => {
    if (Object.keys(filters).length === 0) return
    getPageData(filters).catch(e => handleProgressChange(PROGRESS.ERROR))
  }, [filters])

  const setTrack = track =>
    setState(draft => {
      draft.currentTrack = track
    })
  const setViewport = viewport =>
    setState(draft => {
      draft.viewport = viewport
    })
  return (
    <>
      <SessionsMapSidebar
        currentTrack={currentTrack}
        owner={owner}
        createdFrom={created_from}
        createdTo={created_to}
        applyFilters={applyFilters}
      />
      {progress === PROGRESS.ERROR ? (
        <div className="error">
          <div>Some error happened.</div>
        </div>
      ) : (
        <TracksMap
          tracks={tracks}
          setTrack={setTrack}
          viewport={viewport}
          defaultViewport={state.defaultViewport}
          setViewport={setViewport}
        />
      )}
      {progress === PROGRESS.WORK ? <Loader /> : null}
      {tracks.length === 0 && progress === PROGRESS.SUCCESS ? (
        <Alert
          isOpen={!noDataAlertClosed}
          onClose={() =>
            setState(draft => {
              draft.noDataAlertClosed = true
            })
          }
        >
          No data found
        </Alert>
      ) : null}
    </>
  )
}

export default SessionsMapPage
