import { createContext } from 'react';
// type ProfileContextType = [
//   any,
//   () => {}
// ];
const defaultState = [
    null, // profile
    (user) => { }, // set profile
];
export const ProfileContext = createContext(defaultState);
