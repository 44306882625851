import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { PciViewerQaControls } from './PciViewerQaControls';
import { API } from '../../../../services/ApiService';
import { LoadingStatus } from '../../../../const/appConstants';
import { PciSimpleQaStatus } from '../../../../const/qaConstants';
import { FrameNavigationDirection } from '../../../../const/pciConstants';
import { downloadJsonAsFile } from '../../../../utils/download/donloadJsonAsFile';
const initialQaPayloadPlaceholder = {
    status: PciSimpleQaStatus.not_viewed,
    id: 'idPlaceholder',
};
export const PciViewerQaControlsContainer = ({ onNavigate, ...outerPciViewerQaProps }) => {
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.INITIAL);
    const [pciQaSessionStats, setPciQaSessionStats] = useState([]);
    const [pciFrameSegmentQaPayload, setPciFrameSegmentQaPayload] = useState(initialQaPayloadPlaceholder);
    useEffect(() => {
        const effect = async () => {
            setLoadingStatus(LoadingStatus.LOADING);
            // const acquiredPciQaSessionStats = await API.getPciQaSessionStats(richPciViewerQaProps.pipelineId);
            // const acquiredPciQaSessionFrameSegment = await API.getPciQaSessionFrameSegment(richPciViewerQaProps.frameSegmentId);
            const [acquiredPciQaSessionStats, acquiredPciFrameSegmentQaPayload,] = await Promise.all([
                API.getPciQaSessionStats(outerPciViewerQaProps.pipelineId),
                API.getPciQaSessionFrameSegment(outerPciViewerQaProps.frameSegmentId),
            ]);
            setPciFrameSegmentQaPayload(acquiredPciFrameSegmentQaPayload);
            setPciQaSessionStats(acquiredPciQaSessionStats);
            setLoadingStatus(LoadingStatus.SUCCESS);
        };
        effect();
    }, [outerPciViewerQaProps.frameSegmentId]);
    const updateQaStatus = async (newStatus) => {
        setLoadingStatus(LoadingStatus.LOADING);
        const updatingResult = await API.patchPciQaSessionStats({
            qaStatusId: pciFrameSegmentQaPayload.id,
            status: newStatus,
        });
        setLoadingStatus(LoadingStatus.SUCCESS);
        onNavigate(FrameNavigationDirection.FORWARD);
    };
    const [exportLoadingStatus, setExportLoadingStatus] = useState(LoadingStatus.INITIAL);
    const handleExport = async () => {
        setExportLoadingStatus(LoadingStatus.LOADING);
        const exportingResult = await API.exportPciQaSession(outerPciViewerQaProps.pipelineId);
        downloadJsonAsFile(exportingResult, `qa-session-${outerPciViewerQaProps.pipelineId}`);
        setExportLoadingStatus(LoadingStatus.SUCCESS);
    };
    return (_jsx(PciViewerQaControls, { ...outerPciViewerQaProps, exportLoadingStatus: exportLoadingStatus, onExportRequested: handleExport, pciQaSessionStats: pciQaSessionStats, pciFrameSegmentQaPayload: pciFrameSegmentQaPayload, loadingStatus: loadingStatus, onRequestChangeStatus: updateQaStatus }));
};
