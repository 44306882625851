import { PipelineFilesList } from 'features/PipelineList/PipelineFilesList'
import { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { useNavigate, Link } from 'react-router-dom'
import {
  Card,
  Elevation,
  Intent,
  Tag,
  Icon,
  Collapse,
  Button,
  ButtonGroup,
  Dialog,
  Alert,
  InputGroup,
} from '@blueprintjs/core'
import { Popover2 as Popover, Classes as PopoverClasses } from '@blueprintjs/popover2'
import { toast } from 'react-toastify'

import { ContentFor } from 'components/ContentFor/ContentFor'
import JsonViewer from 'components/JsonViewer/JsonViewer'

import { PATH } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/routingConstants'
import { PROGRESS, STATUS, ROLES, REQUEST_STATUS } from 'const'

import useRoles from 'hooks/useRoles'
import useProfile from 'hooks/useProfile'

import utils from 'utils/utils'
import API from 'utils/api'
import { getDistanceInWordsFromMilliseconds } from 'utils/getDistanceInWordsFromMs'

import style from 'features/PipelineList/PipelineListItem/PipelineListItem.module.scss'

const toastProps = {
  position: 'bottom-center',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  theme: 'dark',
}

const INTENT = {
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
}

const addToast = props => {
  switch (props.intent) {
    case INTENT.DANGER:
      toast.error(props.message, toastProps)
      break
    case INTENT.SUCCESS:
      toast.success(props.message, toastProps)
      break
    default:
      toast(props.message, toastProps)
      break
  }
}

function PipelineListItemActions({ item, updatePipelineList }) {
  const navigate = useNavigate()

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { status, uuid } = item

  const onDialogOpen = e => {
    e.stopPropagation()
    setIsDialogOpen(true)
  }

  const onDialogClose = e => {
    e.stopPropagation()
    setIsDialogOpen(false)
  }

  const onDelete = () => {
    setIsLoading(true)
    API.deletePipeline(uuid)
      .then(({ status }) => {
        setIsLoading(false)
        if (status === REQUEST_STATUS.OK) {
          addToast({ message: `${uuid} deleted!`, intent: INTENT.SUCCESS })
          updatePipelineList()
        } else {
          addToast({ message: `${uuid} deleting failed!`, intent: INTENT.DANGER })
        }
        setIsDialogOpen(false)
      })
      .catch(() => {
        setIsLoading(false)
        addToast({ message: `${uuid} deleting failed!`, intent: INTENT.DANGER })
      })
  }

  const onResume = () => {
    setIsLoading(true)
    API.resumePipeline(uuid)
      .then(({ status }) => {
        setIsLoading(false)
        if (status === REQUEST_STATUS.OK) {
          addToast({ message: `${uuid} started!`, intent: INTENT.SUCCESS })
          updatePipelineList()
        } else {
          addToast({ message: `${uuid} starting failed!`, intent: INTENT.DANGER })
        }
      })
      .catch(() => {
        setIsLoading(false)
        addToast({ message: `${uuid} starting failed!`, intent: 'danger' })
      })
  }

  const onCancel = () => {
    setIsLoading(true)
    API.cancelPipeline(uuid)
      .then(({ status }) => {
        setIsLoading(false)
        if (status === REQUEST_STATUS.OK) {
          addToast({ message: `${uuid} canceled!`, intent: 'success' })
          updatePipelineList()
        } else {
          addToast({ message: `${uuid} canceling failed!`, intent: 'danger' })
        }
      })
      .catch(() => {
        setIsLoading(false)
        addToast({ message: `${uuid} canceling failed!`, intent: 'danger' })
      })
  }

  const onRerun = () => {
    navigate(PATH.rerunPipeline.makePath(uuid))
  }

  return (
    <>
      <Popover
        popoverClassName={PopoverClasses.POPOVER2_CONTENT_SIZING}
        placement="right-end"
        content={
          <div>
            <ButtonGroup vertical large>
              {status === STATUS.CANCELED && (
                <Button disabled={isLoading} icon="play" onClick={onResume}>
                  Resume
                </Button>
              )}
              {(status === STATUS.ACTIVE || status === STATUS.FAILED) && (
                <Button disabled={isLoading} icon="pause" onClick={onCancel}>
                  Cancel
                </Button>
              )}
              {status !== STATUS.ACTIVE && (
                <ContentFor role={ROLES.ADMIN}>
                  <Button disabled={isLoading} icon="refresh" onClick={onRerun}>
                    Rerun
                  </Button>
                </ContentFor>
              )}
              <Button disabled={isLoading} icon="trash" onClick={onDialogOpen}>
                Delete
              </Button>
            </ButtonGroup>
          </div>
        }
        renderTarget={({ isOpen, ref, ...targetProps }) => {
          return (
            <Button {...targetProps} elementRef={ref}>
              <Icon icon="wrench" className="icon" />
            </Button>
          )
        }}
      />
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        icon="trash"
        intent={Intent.DANGER}
        isOpen={isDialogOpen}
        loading={isLoading}
        onCancel={onDialogClose}
        onConfirm={onDelete}
      >
        <p>
          Are you sure you want to delete <b>{uuid}</b>?
        </p>
      </Alert>
    </>
  )
}

function PipelineListItemModule({ uuid, name, status, log, start, end, args, runSection, commit_hash, git_branch }) {
  return (
    <Card elevation={Elevation.ONE} className={style.module}>
      <div className={clsx(style.row, 'bp4-text-small')}>
        <a target="_blank" rel="noopener noreferrer" className={style.href} href={log}>
          <Tag className={style.tag} minimal interactive intent={utils.moduleStatusToIntent(status)}>
            <Icon icon="link" size={12} className={style.icon} />
            {name}
          </Tag>
        </a>
      </div>
      <div className={clsx(style.row, 'bp4-text-small')}>
        {args.map((item, i) => (
          <Tag key={`${uuid}-${item.name}-${i}`} className={style.argument} round minimal>
            {item.name}: {item.value}
          </Tag>
        ))}
        {runSection ? (
          <Tag className={style.argument} round minimal intent={INTENT.WARNING}>
            {runSection}
          </Tag>
        ) : null}
      </div>
      <div className={clsx(style.row, 'bp4-text-small')}>{utils.formatDate(start)}</div>
      <div className={clsx(style.row, 'bp4-text-small')}>{utils.formatDate(end)}</div>
      <div className={clsx(style.row, 'bp4-text-small')}>{utils.formatDateDistance(start, end)}</div>
      <div className={clsx(style.row, 'bp4-text-small')}>
        <a target="_blank" rel="noreferrer" href={utils.formatGitUrl(name, commit_hash, git_branch)}>
          Open
        </a>
      </div>
    </Card>
  )
}

function PipelineListItemModuleList({ item }) {
  return (
    <>
      <Card elevation={Elevation.ONE} className={style.module}>
        <div className={clsx(style.row, 'bp4-text-small', 'bp4-text-muted')}>Name [Link to logs]</div>
        <div className={clsx(style.row, 'bp4-text-small', 'bp4-text-muted')}>Arguments</div>
        <div className={clsx(style.row, 'bp4-text-small', 'bp4-text-muted')}>Start</div>
        <div className={clsx(style.row, 'bp4-text-small', 'bp4-text-muted')}>End</div>
        <div className={clsx(style.row, 'bp4-text-small', 'bp4-text-muted')}>Duration</div>
        <div className={clsx(style.row, 'bp4-text-small', 'bp4-text-muted')}>Git</div>
      </Card>

      {item.state.map((state, index) => {
        const definition = item.definition.find(item => item.id === state.uuid)
        const args = definition.arguments.filter(item => item.source_type === 'USER_INPUT')
        const runSection = definition.run_section !== 'run' ? `run_section: ${definition.run_section}` : undefined
        return (
          <PipelineListItemModule
            key={`${item.uuid}-${state.module}-${index}`}
            uuid={item.uuid}
            name={state.module}
            log={state.logs[0]}
            status={state.status}
            args={args}
            start={state.ts_start}
            end={state.ts_end}
            runSection={runSection}
            commit_hash={state.commit_hash}
            git_branch={state.git_branch}
          />
        )
      })}
    </>
  )
}

function PipelineListItemLog({ item }) {
  const [progress, setProgress] = useState(PROGRESS.IDLE) // eslint-disable-line
  const [data, setData] = useState(undefined) // eslint-disable-line

  useEffect(() => {
    const getPageData = async () => {
      setProgress(PROGRESS.WORK)
      const state = item.state.find(item => item.status === 'failed')
      const [log] = state.inline_logs

      const result = await API.getPipelineOutputLog(log.url, log.body)
      setData(result)
    }

    getPageData().catch(console.error)

    return function () {
      setProgress(PROGRESS.IDLE)
      setData(undefined)
    }
  }, [item.state])

  return <div>123</div>
}

export function PipelineListItem({ item, updatePipelineList, updateItemPart }) {
  const [isOpen, setIsOpen] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isViewJson, setViewJson] = useState(false)

  const openJsonViewer = () => setViewJson(true)
  const closeJsonViewer = () => setViewJson(false)

  const roles = useRoles()
  const { profile } = useProfile()

  const yaDiskHref = utils.getYandexDiskLink(item)
  const outputs = utils.getAllOutput(item)

  const position = item?.session_data?.geocoding
    ? item.session_data.geocoding.features?.map(item => item.text).join(', ') || ''
    : ''
  const onOpenClick = e => {
    e.stopPropagation()
    setIsOpen(!isOpen)
  }

  const onDialogClose = () => {
    setIsDialogOpen(false)
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(item.uuid)
  }
  const uuid = item.uuid
  const showToastSuccessUpdate = () => {
    addToast({ message: `${uuid} update!`, intent: INTENT.SUCCESS })
  }
  const showToastFailureUpdate = () => {
    addToast({ message: `${uuid} update failure!`, intent: INTENT.DANGER })
  }

  const splitHref = yaDiskHref ? yaDiskHref.split('/') : []
  const tmpSessionFolderName = splitHref[splitHref.length - 1] ? splitHref[splitHref.length - 1] : ''
  const sessionFolderName = tmpSessionFolderName.split('?')[0]
  const [showEditName, setShowEditName] = useState(false)
  const [pipelineName, setPipelineName] = useState(item.hasOwnProperty('name') ? item.name : '')
  const inputGroupRef = useRef()
  useEffect(() => {
    if (inputGroupRef.current && showEditName) {
      inputGroupRef.current.focus()
    }
  }, [showEditName])
  const onClickShowEdit = () => {
    setShowEditName(true)
  }
  const onClickCancelEdit = () => {
    setPipelineName(item.name)
    setShowEditName(false)
  }
  const onInputPipelineName = e => setPipelineName(e.target.value)
  const onUpdatePipelineName = () => {
    updateItemPart(item.uuid, pipelineName, showToastSuccessUpdate, showToastFailureUpdate)
    setShowEditName(false)
  }

  return (
    <Card elevation={Elevation.FOUR} className={style.pipeline}>
      <div className={style.listItemGridContainer}>
        <div className={style.row}>
          {roles.includes(ROLES.ADMIN) ? (
            <>
              <div className={style.actionsButtonContainer}>
                <PipelineListItemActions item={item} updatePipelineList={updatePipelineList} />
              </div>
              <div>
                <Button className={style.openButton} onClick={onOpenClick}>
                  <Icon icon={isOpen ? 'chevron-down' : 'chevron-right'} />
                </Button>
              </div>
            </>
          ) : null}

          {roles.includes(ROLES.OPERATOR) && profile.email === item.user.email ? (
            <PipelineListItemActions item={item} updatePipelineList={updatePipelineList} />
          ) : null}
        </div>

        <div className={clsx(style.row)}>
          <div className={clsx(style.col, style.pipelineContainer)} style={{ display: !showEditName ? '' : 'none' }}>
            <Link to={`/pipeline/${item.uuid}`}>
              <h3 className="title">{item.uuid}</h3>
            </Link>
            <Button
              className={style.copy}
              minimal
              small
              onClick={copyToClipboard}
              icon={<Icon icon="duplicate" size={14} />}
            />
            <Button
              className={style.edit}
              minimal
              small
              onClick={onClickShowEdit}
              icon={<Icon icon="edit" size={14} />}
            />
          </div>
          <div className={style.colinputname} style={{ display: showEditName ? '' : 'none' }}>
            <InputGroup
              fill
              type="text"
              value={pipelineName ? pipelineName : ''}
              onInput={onInputPipelineName}
              onKeyPress={event => {
                if (event.key === 'Enter') onUpdatePipelineName()
              }}
              inputRef={inputGroupRef}
            />
            <Button
              className={style.cross}
              minimal
              small
              onClick={onClickCancelEdit}
              icon={<Icon icon="cross" size={14} />}
            />
            <Button
              className={style.smalltick}
              minimal
              small
              onClick={onUpdatePipelineName}
              icon={<Icon icon="small-tick" size={14} />}
            />
          </div>
          {item?.name && (
            <div className={style.col} style={{ display: !showEditName ? '' : 'none' }}>
              <h3 className="title">{item?.name}</h3>
            </div>
          )}
          <p className="bp4-text-muted">Organization: {item.organization.name}</p>
          <p className="bp4-text-muted">Pipeline type: {item.type}</p>
          <p className="bp4-text-muted">
            <PipelineFilesList outputs={outputs} isBrief />
          </p>
          {sessionFolderName && <p className="bp4-text-muted bp4-text-small">Session folder: {sessionFolderName}</p>}
          {position && <p className={clsx('bp4-text-muted bp4-text-small', style.geolocation)}>Place: {position}</p>}
        </div>

        <div className={clsx(style.row, style.statusCell)}>
          <Tag round intent={utils.pipelineStatusToIntent(item.status)} className={style.status}>
            {item.status}
          </Tag>
        </div>

        <div className={clsx(style.row, style.emailCell)}>
          <span alt={item.user.email} title={item.user.email}>
            {item.user.email.length > 20 ? `${item.user.email.slice(0, 19)}...` : item.user.email}
          </span>
        </div>

        <div className={clsx(style.row, style.createdCell)}>{utils.formatDate(item.created)}</div>

        <div className={clsx(style.row, style.updatedCell)}>{utils.formatDate(item.updated)}</div>
      </div>

      <div className={style.collapse}>
        <Collapse isOpen={isOpen}>
          <div className={style.block}>
            <div className={style.meta}>
              <div className={style.metaItem}>
                <div className={style.metaLabel}>Processing start at:</div>
                {utils.formatDate(item.pipelineStartDate)}
              </div>
              <div className={style.metaItem}>
                <div className={style.metaLabel}>Processing end at:</div>
                {utils.formatDate(item.pipelineEndDate)}
              </div>
              <div className={style.metaItem}>
                <div className={style.metaLabel}>Processing time:</div>
                {`≈ ${getDistanceInWordsFromMilliseconds(item.pipelineLengthMilliseconds)}`}
              </div>
            </div>

            <div className={style.meta}>
              {/*
              {yaDiskHref ? (
                <div className={style.tagWrap}>
                  <div className={style.tagLabel}>Session raw files:</div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className={clsx('blank', style.tag)}
                    href={yaDiskHref}
                    key={yaDiskHref}
                  >
                    <Tag intent={Intent.NONE}>
                      <Icon icon="folder-shared" size={12} className="icon" />
                      session files
                    </Tag>
                  </a>
                </div>
              ) : null}
              */}

              <div className={style.tagWrap}>
                <div className={style.tagLabel}>Pipeline config:</div>
                <a onClick={openJsonViewer}>
                  <Tag intent={Intent.NONE}>
                    <Icon icon="code" size={12} className="icon" />
                    payload.json
                  </Tag>
                </a>
              </div>
            </div>

            <div className={style.filesWrapper}>
              <div className={style.filesLabel}>All files:</div>
              <div className={style.filesList}>
                <PipelineFilesList outputs={outputs} isBrief={false} />
              </div>
            </div>
          </div>
          <JsonViewer json={item.definition} isOpen={isViewJson} onClose={closeJsonViewer} />
          <PipelineListItemModuleList item={item} />
        </Collapse>
      </div>

      <Dialog isOpen={isDialogOpen} onClose={onDialogClose} usePortal canOutsideClickClose>
        <PipelineListItemLog item={item} />
      </Dialog>
    </Card>
  )
}
