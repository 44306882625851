import { Icon, Intent, Tag } from '@blueprintjs/core'
import { useDownloader } from 'hooks/useDownloader'
import { handleDownload } from 'utils'
import style from './PipelineFilesList.module.css'

const filterOutputs = ({ outputs, isBrief }) => {
  let toReturn = outputs.filter(item => item.unix_type === 'file')
  if (isBrief) {
    toReturn = toReturn.filter(item => {
      if (!item.value) {
        return false
      }
      return (item.value && item.value.includes('source_video.mp4')) || item.value.includes('track.las')
    })
  }

  return toReturn
}

export const PipelineFilesList = ({ outputs, isBrief }) => {
  const { handleDownload } = useDownloader()

  // Return null for no outputs or empty list
  if (!outputs?.length) {
    return null
  }

  const filteredOutputs = filterOutputs({ outputs, isBrief })
  return (
    <>
      {filteredOutputs.map(item => {
        const urlParts = item.url.split('/')
        const name = urlParts[urlParts.length - 1]
        return (
          <a
            onClick={event => handleDownload({ event, url: item.url, filename: item.gui_name })}
            href={item.url}
            rel="noopener noreferrer"
            key={item.url}
            title={item.gui_name}
            className={style.output}
          >
            <Tag intent={Intent.PRIMARY}>
              <Icon icon="document" iconSize={12} className="icon" />
              {name}
            </Tag>
          </a>
        )
      })}
    </>
  )
}
