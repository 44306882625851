import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Popover2 as Popover, Classes as PopoverClasses } from '@blueprintjs/popover2';
import { Button, Menu, MenuItem, MenuDivider, Tag, Intent, } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';
import Avatar from 'boring-avatars';
import { EXTERNAL_PATH, PATH } from '../../../const/routingConstants';
import { ROLES } from '../../../const/userConstants';
import style from './UserDropdown.module.scss';
import { ContentFor } from '../../../components/ContentFor';
import { useProfile } from '../../../hooks/useProfile';
import { API } from '../../../services/ApiService';
import { ProfileSettingsModal } from '../../ProfileSettings';
export const UserDropdown = () => {
    const { profile, updateProfile } = useProfile();
    const navigate = useNavigate();
    /**
     * Authorized state
     */
    const onCreateInviteClick = () => {
        navigate(PATH.createInvite.path);
    };
    const [isProfileSettingVisible, setIsProfileSettingVisible] = useState(false);
    const showProfileSettingsModal = () => setIsProfileSettingVisible(true);
    const hideProfileSettingsModal = () => setIsProfileSettingVisible(false);
    const onSignOutClick = async () => {
        try {
            const data = await API.logout();
            updateProfile(data);
        }
        catch (error) {
            console.error(error);
        }
    };
    return (_jsxs(_Fragment, { children: [isProfileSettingVisible ? _jsx(ProfileSettingsModal, { onClose: hideProfileSettingsModal }) : null, _jsx(Popover, { placement: "right", popoverClassName: PopoverClasses.POPOVER2_CONTENT_SIZING, content: (_jsxs(Menu, { children: [_jsx(MenuDivider, { title: (_jsxs("div", { className: style.info, children: [_jsxs("div", { className: style.emailContainer, children: [_jsx("div", { className: style.email, children: profile.email }), _jsx(ContentFor, { role: ROLES.ADMIN, children: _jsx(Tag, { minimal: true, intent: Intent.PRIMARY, children: "PRO" }) })] }), _jsx("div", { className: style.organization_name, children: profile.organization_name })] })) }), _jsx(MenuDivider, {}), _jsx(ContentFor, { role: ROLES.ADMIN, children: _jsx(MenuItem, { onClick: onCreateInviteClick, text: "Invite team members" }) }), _jsx(MenuItem, { href: EXTERNAL_PATH.roadLyHelpRoot.path, text: "User guide", target: "_blank" }), _jsx(MenuDivider, {}), _jsx(MenuItem, { onClick: showProfileSettingsModal, text: "Profile settings" }), _jsx(MenuDivider, {}), _jsx(MenuItem, { onClick: onSignOutClick, text: "Sign out" })] })), renderTarget: ({ isOpen, ref, ...targetProps }) => (_jsx(Button, { ...targetProps, elementRef: ref, className: style.avatar, children: _jsx(Avatar
                    // className={style.avatarImg}
                    , { 
                        // className={style.avatarImg}
                        size: 30, name: profile.email, variant: "pixel", colors: ['#EB752F', '#F09737', '#ED752F', '#D4D4D4', '#C6A178'] }) })) })] }));
};
