import { MODULE_OUTPUT_NAMES, MODULE_TYPE, STATE_RUN_SECTION } from 'const'
import { getOutputList } from '../getOutputList'

const getPosesFileUrl = pipeline => {
  const output = getOutputList(pipeline)
  const posesItem = Object.values(output).find(item => item.exposed_as == 'POSES')
  return posesItem ? posesItem.url : null
}

export const getPoses = pipeline => {
  const posesFileUrl = getPosesFileUrl(pipeline)

  return new Promise((resolve, reject) => {
    if (posesFileUrl) {
      const worker = new Worker(new URL('workers/poses.js', import.meta.url))
      worker.postMessage({ url: posesFileUrl })
      worker.onmessage = ({ data: { poses } }) => {
        resolve(poses)
        worker.terminate()
      }
    } else {
      resolve(undefined)
    }
  })
}
