import { LOCAL_STORAGE_KEYS } from '../../const/localStorageConstants';
export const apiCacheKeys = {
    getSessions: 'getSessions',
    extendedSessions: 'extendedSessions',
    pciMapData: 'pciMapData',
    staticPciMapData: 'staticPciMapData',
    getPipelinesList: 'getPipelinesList',
};
const cacheRegistry = {
    getSessions: {},
    extendedSessions: {},
    pciMapData: {},
    staticPciMapData: {},
    getPipelinesList: {},
};
function serializeArgs(...args) {
    return args
        .map((arg) => arg.toString())
        .join(':');
}
export const apiCacheServiceControl = {
    switchCacheService(isOn) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.IS_API_CACHE_SERVICE_ON, String(isOn));
    },
    isOn() {
        return true;
        // return localStorage.getItem(LOCAL_STORAGE_KEYS.IS_API_CACHE_SERVICE_ON) === 'true';
    },
    invalidateByKey(key) {
        cacheRegistry[key] = {};
    },
};
// Decorator:
export function cacheApiMethod(key) {
    return function (target, propertyKey, descriptor) {
        const method = descriptor.value;
        descriptor.value = async function decoratedApiMethod() {
            const isApiCacheServiceOn = apiCacheServiceControl.isOn();
            if (!isApiCacheServiceOn) {
                // eslint-disable-next-line prefer-rest-params
                return await method.apply(this, arguments);
            }
            // eslint-disable-next-line prefer-rest-params
            const subKey = serializeArgs(...arguments);
            const cachedValue = cacheRegistry[key][subKey];
            if (typeof cachedValue !== 'undefined') {
                return cachedValue;
            }
            // eslint-disable-next-line prefer-rest-params
            const dynamicResult = await method.apply(this, arguments);
            cacheRegistry[key][subKey] = dynamicResult;
            return dynamicResult;
        };
    };
}
