import { ToastContainer } from 'react-toastify'
import { useSessionsList } from 'hooks/useSessionsList'
import SessionsList from 'features/SessionsList/SessionsList'

export function SessionsListPage() {
  const {
    progress,
    items,
    limit,
    skip,
    total,
    owner,
    start_time_from,
    start_time_to,
    organization_id,
    is_broken,
    is_invalid,
  } = useSessionsList()
  return (
    <>
      <SessionsList
        items={items}
        total={total}
        limit={limit}
        skip={skip}
        progress={progress}
        owner={owner}
        start_time_from={start_time_from}
        start_time_to={start_time_to}
        organization_id={organization_id}
        is_broken={is_broken}
        is_invalid={is_invalid}
      />

      <ToastContainer />
    </>
  )
}
