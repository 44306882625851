import { LoadingStatus } from '../../const/appConstants';
import { AbstractPreloader } from '../AbstractPreloader';
import { preloadImage } from '../imageUtils';
import { processMask } from '../imageUtils/processMask';
import { getImageDataUrl } from '../imageUtils/getImageDataUrl';
export class FrameSegmentImagesPreloader extends AbstractPreloader {
    constructor() {
        super();
        this.state = { loadingStatus: LoadingStatus.INITIAL, data: {} };
    }
    getResult(stateData) {
        return stateData;
    }
    async load({ frameSegmentImageUrlStructure }) {
        // First sign urls
        const frameSegmentSignedImageUrlStructure = await this.loadSignedUrls(frameSegmentImageUrlStructure);
        // Second - load images
        const frameSegmentImageBase64Structure = await this.loadProcessedImageBase64s(frameSegmentImageUrlStructure);
        return {
            frameSegmentImageUrlStructure: frameSegmentSignedImageUrlStructure,
            // frameSegmentImageElementStructure,
            frameSegmentImageBase64Structure,
        };
    }
    async loadSignedUrls({ img, roi }) {
        // Skip signing
        return { img, roi };
        // Commented out in favor of using token authorization,
        // but kept for case of stability risks
        // const [signedImg, signedRoi, signedMask] = await Promise.all([img, roi, mask]
        //   .map((url) => {
        //     const signedUrlParams = extractBucketAndPathFromGcsUrl(url);
        //     return API.getSignedUrl(signedUrlParams);
        //   }));
        //
        // return {
        //   img: signedImg,
        //   roi: signedRoi,
        //   mask: signedMask,
        // };
    }
    async loadImageElements({ img, roi }) {
        const [imgElement, roiElement] = await Promise
            .all([img, roi].map((url) => preloadImage(url)));
        return {
            img: imgElement,
            roi: roiElement,
        };
    }
    async loadProcessedImageBase64s({ img, roi }) {
        const imageElements = await this.loadImageElements({ img, roi });
        const processingRoi = imageElements.roi;
        const roiProcessedBase64 = processMask(processingRoi, { inputColor: [0, 0, 0], invert: true });
        const imageUrlAsData = getImageDataUrl(imageElements.img);
        return {
            img: imageUrlAsData,
            roi: roiProcessedBase64,
        };
    }
}
