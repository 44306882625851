export const calculatePipelineTimes = (state) => {
    const nonOverlapSegments = [];
    let totalComputeTime = 0;
    let pipelineWorkTime = 0;
    let clientWaitingTime = 0;
    let maxEnd = 0;
    let minStart = -1;
    for (const item of state) {
        const startDate = new Date(item.ts_start);
        const endDate = new Date(item.ts_end);
        const start = startDate.getTime();
        const end = endDate.getTime();
        if (end === 0) {
            continue;
        }
        totalComputeTime = totalComputeTime + end - start;
        if (minStart < 0 || start < minStart) {
            minStart = start;
        }
        if (maxEnd < end) {
            maxEnd = end;
        }
        let indexPair = -1;
        for (let i = 0; i < nonOverlapSegments.length; i += 1) {
            // @ts-ignore
            if (nonOverlapSegments[i].start <= start && nonOverlapSegments[i].end >= start) {
                indexPair = i;
            }
        }
        // @ts-ignore
        if (indexPair > -1 && nonOverlapSegments[indexPair].end < end) {
            // @ts-ignore
            nonOverlapSegments[indexPair] = { start: nonOverlapSegments[indexPair].start, end };
        }
        if (indexPair <= -1) {
            nonOverlapSegments.push({ start, end });
        }
    }
    for (const pair of nonOverlapSegments) {
        pipelineWorkTime = pipelineWorkTime + pair.end - pair.start;
    }
    clientWaitingTime = maxEnd - minStart;
    return { pipelineWorkTime, totalComputeTime, clientWaitingTime };
};
