import Supercluster from 'supercluster';
import { ScatterplotLayer, TextLayer } from '@deck.gl/layers/typed';
import { CompositeLayer } from '@deck.gl/core/typed';
import { fcFromFeatures } from '../../../../utils/geoJson.utils';
import { getAssetsRegistryLayerAsObject } from '../assetsRegistryLayer/getAssetsRegistryLayer';
// Todo: name better
const ZOOM_THRESHOLD = 20;
export class RoadLyClusterLayer extends CompositeLayer {
    constructor(props) {
        super(props);
        this.state = {
            clusterIndex: this.createSuperCluster(),
            zoom: 0,
            prevZoom: 0,
        };
    }
    // tmp() {
    //   this.state.clusterIndex.getClusters()
    // }
    createSuperCluster() {
        return new Supercluster({
            maxZoom: ZOOM_THRESHOLD,
            radius: 10,
            minPoints: 1,
            map: (properties) => ({ my_count: properties.is_my ? 1 : 0 }),
            reduce: (accumulated, properties) => {
                accumulated.my_count += properties.my_count;
            },
        });
    }
    updateState({ props, oldProps }) {
        // console.log('Update layers');
        if (props.featureCollection !== oldProps.featureCollection) {
            const clusterIndex = this.createSuperCluster();
            const { featureCollection } = this.props;
            const points = featureCollection.features;
            clusterIndex.load(points);
            this.setState({ clusterIndex });
        }
        const { viewState } = props;
        if (!viewState) {
            return;
        }
        this.setState({
            prevZoom: this.state.zoom,
            zoom: viewState.zoom,
        });
    }
    checkIfCanBeZoomed(clusterId) {
        return this.state.clusterIndex.getClusterExpansionZoom(clusterId) <= ZOOM_THRESHOLD;
    }
    getClustersAndPoints() {
        // Todo: set this depending on view or its fine
        //  to leave bb set to whole world like this?
        const selectedClustersList = this.state.clusterIndex.getClusters([-180, -85, 180, 85], this.state.zoom);
        const clusters = []; // Clusters having more than 1 point
        const singleFeatures = []; // Clusters with single point only
        selectedClustersList.forEach((aCluster) => {
            if (aCluster.properties.cluster) {
                clusters.push(aCluster);
            }
            else {
                singleFeatures.push(aCluster);
            }
        });
        return { singleFeatures, clusters };
    }
    onClick(info, event) {
        const clusterId = info.object.properties.cluster_id;
        const clusterParams = clusterId && !this.checkIfCanBeZoomed(clusterId)
            ? {
                feature: info.object,
                subFeatures: this.state.clusterIndex.getChildren(clusterId),
            }
            : null;
        this.props.onPickCluster(clusterParams);
        return typeof clusterId !== 'undefined'; // False lets click propagate to map level
    }
    // @ts-ignore
    renderLayers() {
        // const { zoom, prevZoom, clusterIndex } = this.state;
        // Todo:
        //  Let's not do this until we get into it.
        //  Any way it has not to be done like this, with passing null, zoom value has to be passed
        // if (prevZoom !== zoom) {
        //   this.props.onChangeZoom(null);
        // }
        const { singleFeatures, clusters } = this.getClustersAndPoints();
        // Todo: pass points to attached sublayer
        // console.log('Render layers');
        return [
            new ScatterplotLayer({
                data: clusters,
                getPosition: (f) => f.geometry.coordinates,
                getRadius: 20,
                radiusUnits: 'pixels',
                getFillColor: (p) => ((p.properties.cluster_id && this.checkIfCanBeZoomed(p.properties.cluster_id)) ? [255, 200, 100, 150] : [200, 200, 200, 150]),
                pickable: true,
                // onClick: this.handleClickClusterInternal,
                onClick: (pickingInfo) => console.log('On click INSIDE::', pickingInfo),
            }),
            new TextLayer({
                data: clusters,
                getPosition: (f) => f.geometry.coordinates,
                getSize: 20,
                getColor: [20, 0, 20, 255],
                sizeUnits: 'pixels',
                fontFamily: 'Lato',
                getText: (f) => `${f.properties && f.properties.cluster ? f.properties.point_count : 1}`,
            }),
            // @ts-ignore Todo: make dynamic with typing
            getAssetsRegistryLayerAsObject({ finalAssetsFeatureCollection: fcFromFeatures(singleFeatures) }),
        ];
    }
}
