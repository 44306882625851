import { jsx as _jsx } from "react/jsx-runtime";
import { hintPageRenderers } from './hintPageRenderers';
import { useIsHintVisible } from './useIsHintVisible';
import { GettingStartedHint } from './GettingStartedHint';
import { useGettingStartedHintPagination } from './useGettingStartedHintPagination';
export const GettingStartedHintContainer = ({ className }) => {
    const { showGettingStartedHint, handleCloseGettingStartedHint } = useIsHintVisible();
    const onClose = () => handleCloseGettingStartedHint();
    const { currentPageIndex, goToPage } = useGettingStartedHintPagination({
        totalPages: hintPageRenderers.length,
    });
    if (!showGettingStartedHint) {
        return null;
    }
    return (_jsx(GettingStartedHint
    // pageRenderer
    , { currentPageIndex,
        goToPage,
        hintPageRenderers,
        className,
        onClose }));
};
