import { LOCAL_STORAGE_KEYS } from '../../const/localStorageConstants';
export function saveStoredViewpointsList(input) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.STORED_VIEWPOINTS_LIST, JSON.stringify(input));
    return input;
}
export function loadStoredViewpointPayloadsList() {
    const storedValue = localStorage.getItem(LOCAL_STORAGE_KEYS.STORED_VIEWPOINTS_LIST);
    if (storedValue === null) {
        const initialValue = [];
        return saveStoredViewpointsList(initialValue);
    }
    return JSON.parse(storedValue);
}
