import { useState, useEffect, useRef } from 'react'
import { startGaussian, setCameras, snapToClosestCamera, setKeepPose as setKeepPoseExtern } from '3rdparty/splat'
import { 
  defaultPosesShiftVertical,
  defaultPosesColor,
  defaultVerticalAxis,
  defaultIsPosesLanded,
  setIsPosesLanded as setIsPosesLandedExtern,
  setVerticalAxis as setVerticalAxisExtern,
  setPosesShiftVertical,
  init as initCamerasPoses, 
  setCameras as setCamerasForPoses, 
  setPosesColor as setPosesColorExtern,
  setShowCamerasPoses as setShowCamerasPosesExtern, 
  setProjectionMatrix, 
  setViewMatrix } from './poses'
import { debounce } from 'lodash'
import {
  Slider
} from '@blueprintjs/core'
import style from './GaussianViewer.module.css'

const setPosesShiftVerticalD = debounce(setPosesShiftVertical, 50);

export const GaussianViewer = ({ modelUrl, camerasUrl }) => {
  let worker = null
  let windowListeners = null
  const canvasRef = useRef(null)
  const camerasCanvasRef = useRef(null)
  const fpsRef = useRef(null)
  const [isLoading, setLoading] = useState(false)
  const [isLoaded, setLoaded] = useState(false)
  const [isError, setError] = useState(false)
  const [keepPose, setKeepPose] = useState(false)
  const [showCamerasPoses, setShowCamerasPoses] = useState(false)
  const [isPosesLanded, setIsPosesLanded] = useState(defaultIsPosesLanded)
  const [camerasPosesColor, setCamerasPosesColor] = useState(defaultPosesColor)
  const [posesShift, setPosesShift] = useState(defaultPosesShiftVertical)
  const [verticalAxis, setVerticalAxis] = useState(defaultVerticalAxis)
  const axes = ['x', 'y', 'z']

  const updatePosesShift = (shift) => {
    setPosesShift(shift)
    setPosesShiftVerticalD(shift)
  }

  const switchKeepPose = () => {
    setKeepPose(!keepPose)
    setKeepPoseExtern(!keepPose)
  }

  const switchPosesLanded = () => {
    setIsPosesLanded(!isPosesLanded)
    setIsPosesLandedExtern(!isPosesLanded)
  }

  const switchShowCamerasPoses = () => {
    setShowCamerasPoses(!showCamerasPoses)
    setShowCamerasPosesExtern(!showCamerasPoses)
  }

  const setPosesColor = evt => {
    const color = evt.target.value;
    setCamerasPosesColor(color);
    setPosesColorExtern(color);
  }

  const setPosesVerticalAxis = evt => {
    const value = evt.target.value;
    setVerticalAxis(value);
    setVerticalAxisExtern(value);
  }

  const loadFile = () => {
    if (!modelUrl) return
    setLoading(true)
    setError(false)
    return fetch(modelUrl)
      .then(response => {
        if (response.status > 399) {
          throw `Invalid response status ${status}`
        }
        return response.arrayBuffer()
      })
      .then(buffer => {
        const splatData = new Uint8Array(buffer)
        const rowLength = 3 * 4 + 3 * 4 + 4 + 4 // np ideas
        setLoading(false)
        setLoaded(true)
        if (splatData[0] == 112 && splatData[1] == 108 && splatData[2] == 121 && splatData[3] == 10) {
          // ply file magic header means it should be handled differently
          worker.postMessage({ ply: splatData.buffer })
        } else {
          worker.postMessage({
            buffer: splatData.buffer,
            vertexCount: Math.floor(splatData.length / rowLength),
          })
        }
      })
      .catch(() => {
        setError(true)
        setLoading(false)
        setLoaded(false)
      })
  }

  const updateCameras = () => {
    console.log('Cameras loaded from', camerasUrl)
    return fetch(camerasUrl)
      .then(response => response.json())
      .then(cameras => {
        setCameras(cameras) 
        setCamerasForPoses(cameras)
      })
  }

  const removeEventListeners = () => {
    if (windowListeners)
      for (let listener in windowListeners) window.removeEventListener(listener.name, listener.handler)
  }

  const notifyPoses = (parameterName, parameterValue) => {
    if (parameterName === 'projectionMatrix') 
      return setProjectionMatrix(parameterValue)
    if (parameterName === 'viewMatrix')
      return setViewMatrix(parameterValue)
  }

  useEffect(() => {
    initCamerasPoses(camerasCanvasRef.current)
    ;({ worker, windowListeners } = startGaussian(canvasRef.current, fpsRef.current, progress => {}, notifyPoses))
    updateCameras().finally(loadFile())
    return () => removeEventListeners()
  }, [])

  return (
    <div>
      <div className={style.info}>
        <h3>
          <span>{!isError ? (isLoading ? 'Loading model' : isLoaded ? 'Model loaded' : '') : 'Error happeded!'}</span>{' '}
          <span ref={fpsRef}></span>
        </h3>
        <p>
          <small className={style.small}>
            Based on{' '}
            <a className={style.asmall} target="_blank" href="https://github.com/antimatter15/splat">
              WebGL 3D Gaussian Splat Viewer
            </a>
            .
          </small>
        </p>

        <details>
          <summary>Use mouse or arrow keys to navigate.</summary>
          <div className={style.instructions}>
            movement (arrow keys) - left/right arrow keys to strafe side to side - up/down arrow keys to move
            <br />
            forward/back - space to jump camera angle (wasd) - a/d to turn camera left/right - w/s to tilt camera
            <br />
            up/down - q/e to roll camera counterclockwise/clockwise - i/k and j/l to orbit trackpad - scroll
            <br />
            up/down/left/right to orbit - pinch to move forward/back - ctrl key + scroll to move forward/back - shift +
            <br />
            scroll to move up/down or strafe mouse - click and drag to orbit - right click (or ctrl/cmd key) and drag
            <br />
            up/down to move touch (mobile) - one finger to orbit - two finger pinch to move forward/back - two finger
            <br />
            rotate to rotate camera clockwise/counterclockwise - two finger pan to move side-to-side and up-down gamepad
            <br />
            - if you have a game controller connected it should work other - press 0-9 to switch to one of the
            <br />
            pre-loaded camera views - press p to resume default animation - drag and drop .ply file to convert to .splat
          </div>
        </details>
        <div className={style.controls}>

          <label className={style.keepPoseLabel}>
            <input type="checkbox" checked={showCamerasPoses} onChange={switchShowCamerasPoses} className={style.keepPoseCheckbox} />
            Show cameras poses
          </label>
            {(showCamerasPoses) ? (
              <>
              <div className={style.controlsRow}>
              <label>
                <input type="checkbox" checked={isPosesLanded} onChange={switchPosesLanded} /> Land all cameras to plane
              </label>
              <label>
                Plane level (logarithmic)
                <Slider
                  className={style.posesSlider}
                  showTrackFill={false}
                  min={-8}
                  max={8}
                  stepSize={0.1}
                  labelStepSize={2}
                  onChange={updatePosesShift}
                  value={posesShift}
                />
              </label>
              </div>
              <div className={style.controlsRow}>
              <label>
                Vertical axis is&nbsp;
                <select onChange={setPosesVerticalAxis} value={verticalAxis}>
                  {axes.map(name => (<option key={name}>{name}</option>))}
                </select>
              </label>
              </div>
              <div className={style.controlsRow}>
              <label>
                Poses color&nbsp;
                <input type="color" onChange={setPosesColor} value={camerasPosesColor}/><br/>
              </label>
              </div>
              </>
              ) : null}
          <br/>
          <button className={style.snapButton} onClick={snapToClosestCamera}>
            Snap to closest camera
          </button>
          <label className={style.keepPoseLabel}>
            <input type="checkbox" checked={keepPose} onChange={switchKeepPose} className={style.keepPoseCheckbox} />
            Keep user selected pose during camera-based navigation
          </label>
        </div>
      </div>
      <canvas style={{zIndex: 0}} className={style.canvas} ref={canvasRef}></canvas>
      <canvas style={{zIndex: 1, pointerEvents: 'none'}} className={style.canvas} ref={camerasCanvasRef}></canvas>
    </div>
  )
}
