import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Checkbox } from '@blueprintjs/core';
import styles from './AssetsClassFilters.module.scss';
export const AssetsClassFilters = ({ assetsVisibilityFiltersByClassNameMap, onSetAssetVisibilityFilterByClassNameMap, }) => {
    const [isSelectAllOn, setIsSelectAllOn] = useState(true);
    const onChangeSelectAll = () => {
        const newIsSelectAllOn = !isSelectAllOn;
        const newMap = { ...assetsVisibilityFiltersByClassNameMap };
        Object.entries(newMap).forEach(([key, value]) => {
            newMap[key] = {
                ...value,
                isOn: newIsSelectAllOn,
            };
        });
        onSetAssetVisibilityFilterByClassNameMap(newMap);
        setIsSelectAllOn(newIsSelectAllOn);
    };
    const handleSwitchSingleAssetClassVisibility = (assetClassKey) => {
        onSetAssetVisibilityFilterByClassNameMap({
            ...assetsVisibilityFiltersByClassNameMap,
            [assetClassKey]: {
                ...assetsVisibilityFiltersByClassNameMap[assetClassKey],
                isOn: !assetsVisibilityFiltersByClassNameMap[assetClassKey].isOn,
            },
        });
    };
    const assetsClassFiltersList = Object.values(assetsVisibilityFiltersByClassNameMap);
    return (_jsx("div", { children: assetsClassFiltersList.length === 0 ? (_jsx("div", { children: "No assets classes to filter" })) : (_jsxs("div", { className: styles.listContainer, children: [_jsxs("div", { className: styles.assetsClassFiltersRow, children: [_jsx("div", { className: styles.checkBoxColumn, children: _jsx(Checkbox, { checked: isSelectAllOn, onChange: onChangeSelectAll }) }), _jsx("div", { className: styles.nameColumn, children: "Select all" })] }), _jsx("div", { className: styles.line }), assetsClassFiltersList.map((el) => (_jsxs("div", { className: styles.assetsClassFiltersRow, children: [_jsx("div", { className: styles.checkBoxColumn, children: _jsx(Checkbox, { checked: el.isOn, onChange: () => handleSwitchSingleAssetClassVisibility(el.assetClassName) }) }), _jsx("div", { className: styles.iconColumn, children: el.url ? (_jsx("img", { alt: el.assetClassName, src: el.url || undefined })) : null }), _jsx("div", { className: styles.nameColumn, children: el.assetClassName })] }, el.assetClassName)))] })) }));
};
