import clsx from 'clsx'
import { Card, Elevation, Button, Icon, Alignment } from '@blueprintjs/core'
import style from './SessionsListItem.module.scss'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { SessionsListPipelineItem } from '../SessionsListPipelineItem/SessionsListPipelineItem'
import DeckGLContext from '../../../contexts/deckGLContext'
import { API } from '@roadar-pipeline-viewer/roadly-typescript/dist/services/ApiService'
import { VideoPlayer } from '@roadar-pipeline-viewer/roadly-typescript/dist/components/VideoPlayer'
import { asyncConfigManager } from '@roadar-pipeline-viewer/roadly-typescript/dist/utils/asyncConfigManager'
import { PlusIcon } from '@roadar-pipeline-viewer/roadly-typescript/dist/components/icons/PlusIcon'
import { PATH } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/routingConstants'
import { useNavigate } from 'react-router-dom'

export function SessionsListItem({ item }) {
  const navigate = useNavigate()
  const [isMiniPciLoading, setIsMiniPciLoading] = useState(false)
  const [isSignsLoading, setIsSignsLoading] = useState(false)
  const [isAssetsLoading, setIsAssetsLoading] = useState(false)
  const copyToClipboard = () => {
    navigator.clipboard.writeText(item.folder_name)
  }

  const [state, dispatch] = useContext(DeckGLContext)

  const onMiniPCISubmit = () => {
    setIsMiniPciLoading(true)
    API.runMiniPCIPipelineItem({ payload: { sessionId: item.id } })
      .then(res => setIsMiniPciLoading(false))
      .catch(err => setIsMiniPciLoading(false))
  }

  const onSignsSubmit = () => {
    API.runSignsAssetsPipeline(item.id)
      .then(res => setIsSignsLoading(false))
      .catch(err => setIsSignsLoading(false))
  }

  const [assetsText, setAssetsText] = useState('')
  const handleAssetsText = event => {
    setAssetsText(event.target.value)
  }

  const onAssetsSubmit = () => {
    const assetsParams = {
      sessionId: item.id,
      textPrompt: assetsText,
    }
    setAssetsText('')
    API.runAssetsPipeline(assetsParams)
      .then(res => setIsAssetsLoading(false))
      .catch(err => setIsAssetsLoading(false))
  }

  useEffect(() => {
    if (item.track_line && item.distance_calc_raw > 0) {
      dispatch({ type: 'ENQUEUE_SESSION', payload: item })
    }
  }, [item])

  const [isOpen, setIsOpen] = useState(false)

  let playerRef = useRef(undefined)
  let playerRef_2 = useRef(undefined)
  const { videosBucket } = asyncConfigManager.config.bucketNames
  const videoUrlRaw = `https://storage.googleapis.com/${videosBucket}/${item.folder_prefix}/${item.folder_name}/video`
  const videoUrlRaw_2 = `https://storage.googleapis.com/${videosBucket}/${item.folder_prefix}/${item.folder_name}/video_2`

  function buttonClick() {
    setIsOpen(!isOpen)
    playerRef.current = undefined
    playerRef_2.current = undefined
  }

  return (
    <Card elevation={Elevation.FOUR} className={style.session}>
      <div className={style.listItemGridContainer}>
        <div className={style.menuContainer}>
          <div>
            <Button className={style.openButton} onClick={buttonClick}>
              <Icon icon={isOpen ? 'chevron-down' : 'chevron-right'} />
            </Button>
          </div>
          <div className={style.row}>...</div>
        </div>
        <div className={clsx(style.row)}>
          <div className={clsx(style.col, style.pipelineContainer)}>
            <h3 className="title">{item.folder_name}</h3>
            <Button
              className={style.copy}
              minimal
              small
              onClick={copyToClipboard}
              icon={<Icon icon="duplicate" size={14} />}
            />
          </div>
          <p className="bp4-text-muted">Folder Prefix: {item.folder_prefix}</p>
          {item.folder_name ? (
            <>
              <Button
                className={clsx('bp4-minimal', style.button)}
                onClick={() =>
                  navigate({
                    pathname: PATH.runPipeline.path,
                    search: `?session=${item.id}`,
                  })
                }
              >
                <PlusIcon color="#F2994A" />
                Add new pipeline
              </Button>

              <div className={style.runButtons}>
                <button className={style.runButton} onClick={onMiniPCISubmit} disabled={isMiniPciLoading}>
                  Run PCI
                </button>
                <button className={style.runButton} onClick={onSignsSubmit} disabled={isSignsLoading}>
                  Run SIGNs
                </button>
                <button className={style.runButton} onClick={onAssetsSubmit} disabled={!assetsText || isAssetsLoading}>
                  Run ASSETs
                </button>
                <input
                  type="text"
                  className={style.assetsTextInput}
                  value={assetsText}
                  placeholder="Assets text prompt"
                  onChange={handleAssetsText}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        <div className={clsx(style.row, style.statusCell)}>...</div>

        <div className={clsx(style.row, style.emailCell)}>{item.user.email}</div>

        <div className={clsx(style.row, style.createdCell)}>...</div>

        <div className={clsx(style.row, style.distanceCell)}>{item.distance_calc_raw}</div>
      </div>
      {isOpen && (
        <>
          <div className={style.container}>
            {item.track_line && item.distance_calc_raw > 0 ? (
              <>
                {state.renderedSessions[item.id] ? (
                  <img className={style.img} src={state.renderedSessions[item.id]} />
                ) : (
                  'Loading...'
                )}
              </>
            ) : (
              ''
            )}
            <div className={clsx(style.videoContainer)}>
              <a href={videoUrlRaw} className={clsx(style.videoUrl)} download={videoUrlRaw}>
                Download video
              </a>
              <VideoPlayer
                url={videoUrlRaw}
                className={clsx(style.videoPlayerContainer)}
                playerRef={playerRef}
                onTimeUpdated={() => {}}
              />
            </div>
            <div className={clsx(style.videoContainer)}>
              <a href={videoUrlRaw_2} className={clsx(style.videoUrl)} download={videoUrlRaw_2}>
                Download video_2
              </a>
              <VideoPlayer url={videoUrlRaw_2} playerRef={playerRef_2} onTimeUpdated={() => {}} />
            </div>
          </div>
          <div className={style.pipelines}>
            <SessionsListPipelineItem pipelines={item.pipelines} />
          </div>
        </>
      )}
    </Card>
  )
}
