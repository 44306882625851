import uniq from 'lodash/uniq';
import { getNextIndexAroundTheArray } from '../getNextIndexAroundTheArray';
export function getDirectedIndexes(array, startingIndex, direction, amount) {
    if (startingIndex > array.length - 1 || startingIndex < 0) {
        throw new Error('Starting index is outside array indexes');
    }
    const toReturn = [startingIndex]; // Includes starting index
    const finalAmount = amount - 1;
    for (let i = 0, cursor = startingIndex; i < finalAmount; i += 1) {
        cursor = getNextIndexAroundTheArray(array, cursor, direction);
        toReturn.push(cursor);
    }
    return uniq(toReturn);
}
