import React, { useState } from 'react'
import { API } from '@roadar-pipeline-viewer/roadly-typescript/dist/services/ApiService'
import utils from '../../../utils/utils'
import { PipelineListItem } from '@roadar-pipeline-viewer/roadly-typescript/dist/features/PipelineList/PipelineListItem/PipelineListItem'
import { Link } from 'react-router-dom'
import { Tag } from '@blueprintjs/core'
import style from './SessionListPipelineItem.module.css'
import clsx from 'clsx'
import ProgressBar from '../../../components/ProgressBar/ProgressBar'

export function SessionsListPipelineItem({ pipelines }) {
  let [pipelinesFull, setPipelinesFull] = useState()
  if (pipelines.length === 0) {
    return <p className={clsx(style.noFoundPipeline)}>No pipelines found in this session</p>
  }
  let promises = []
  for (let [id, pipeline] of Object.entries(pipelines)) {
    const p = API.getPipelineItem({ uuid: pipeline.uuid })
    promises.push(p)
  }
  Promise.all(promises).then(res => setPipelinesFull(res))
  return (
    <>
      {pipelinesFull ? (
        pipelinesFull.map(pipeline => (
          <PipelineListItem
            key={pipeline.uuid}
            initialItem={pipeline}
            initialFullItem={pipeline}
            updatePipelineList={() => {}}
            updateItemPart={() => {}}
            initialOutputs={utils.getAllOutput(pipeline)}
          />
        ))
      ) : (
        <ProgressBar />
      )}
    </>
  )
}
