import { PageVideoWrapper } from '@roadar-pipeline-viewer/roadly-typescript/dist/components/PageVideoWrapper/PageVideoWrapper'
import { ConfirmCodePaper } from 'features/ConfirmCode'

export const ConfirmCodePage = () => {
  return (
    <PageVideoWrapper>
      <ConfirmCodePaper />
    </PageVideoWrapper>
  )
}
