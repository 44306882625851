import { getOutputList } from 'utils/getOutputList'
import { MODULE_OUTPUT_NAMES, MODULE_TYPE } from 'const'

const getGpsFileUrl = pipeline => {
  const output = getOutputList(pipeline)
  const module = Object.values(output).find(item => item.outputName === MODULE_OUTPUT_NAMES.GPS)

  return module && module.unix_type === MODULE_TYPE.FILE && module.url ? module.url : undefined
}

export async function getPipelineTrack(pipeline) {
  const hasTrackInMeta = pipeline?.session_data?.track_line?.geometry?.coordinates?.length
  const gpsFileUrl = getGpsFileUrl(pipeline)

  return new Promise((resolve, reject) => {
    if (hasTrackInMeta) {
      resolve(pipeline.session_data.track_line.geometry.coordinates)
    } else if (gpsFileUrl) {
      const worker = new Worker(new URL('workers/gps.js', import.meta.url))
      worker.postMessage({ url: gpsFileUrl })
      worker.onmessage = msg => {
        resolve(msg.data.track)
        worker.terminate()
      }
    } else {
      resolve(undefined)
    }
  })
}
