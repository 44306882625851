import { Footer } from 'components/Footer'
import NavigationBar from 'components/NavigationBar/NavigationBar'
import { Outlet } from 'react-router-dom'

export const AuthorizedLayout = () => {
  return (
    <>
      {/*<NavigationBar />*/}
      <Outlet />
      <Footer />
    </>
  )
}
