import { jsx as _jsx } from "react/jsx-runtime";
import { GeoJsonLayer } from '@deck.gl/layers/typed';
const defaultGeoJsonRenderingConfig = {
    getFillColor: (info) => [50, 200, 50, 255],
};
/*
 * This layer is for all basic usages of geo json
 */
export const getGeoJsonLayer = ({ featureCollection, renderingConfig = {} }) => {
    const { lineWidthMinPixels, getPointRadius, getFillColor } = {
        ...defaultGeoJsonRenderingConfig,
        ...renderingConfig,
    };
    // const featureCollectionOnTheGround = bringFeatureCollectionToTheGround(featureCollection);
    const featureCollectionOnTheGround = featureCollection;
    return (
    // @ts-ignore Todo
    _jsx(GeoJsonLayer
    /* Static */
    , { 
        /* Static */
        id: "geo-json-layer", pickable: true, lineWidthMinPixels: lineWidthMinPixels, getLineWidth: 1, lineWidthUnits: "pixels", onClick: (what) => console.log('what', what), 
        // @ts-ignore Todo
        getFillColor: getFillColor, 
        // @ts-ignore Todo
        getLineColor: getFillColor, pointType: "circle", getPointRadius: getPointRadius, pointRadiusUnits: "pixels", lineMiterLimit: 1, filled: true, stroked: false, 
        /* Dynamic */
        data: featureCollectionOnTheGround.features }));
};
