import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import { Button, FormGroup, Intent, Tag } from '@blueprintjs/core';
import { emailValidationPattern } from '../../../../const/validationConstants';
import { LoadingStatus } from '../../../../const/appConstants';
import styles from '../RestorePassword.module.scss';
const formOptions = {
    email: {
        required: 'Email is required',
        pattern: emailValidationPattern,
    },
};
export const RequestRestorePasswordForm = ({ progressStatus, onSubmit, exception }) => {
    const { register, handleSubmit, formState: { errors }, } = useForm();
    // const combinedErrorsMap = combineFormErrorDetails(errors, exception?.formErrorDetails);
    // console.log(combinedErrorsMap);
    return (_jsx("form", { onSubmit: handleSubmit(onSubmit), children: _jsxs("div", { children: [_jsxs(FormGroup, { label: "Email:", labelFor: "email", children: [_jsx("div", { className: "bp4-input-group", children: _jsx("input", { id: "email", className: "bp4-input", type: "text", placeholder: "some@email.com", ...register('email', formOptions.email) }) }), errors.email && (_jsx(Tag, { multiline: true, fill: true, large: true, intent: Intent.DANGER, className: styles.error, children: combinedErrorsMap.email.message }))] }), _jsx("div", { className: styles.centeredBlock, children: _jsx(Button, { loading: progressStatus === LoadingStatus.LOADING, type: "submit", intent: Intent.PRIMARY, text: "Restore password" }) })] }) }));
};
