import { useState } from 'react';
import { fcFromFeatures } from '../../utils/geoJson.utils';
export function usePciData() {
    const [allSampleUnitsData, setAllSampleUnitsData] = useState(null);
    const [allViewpointsData, setAllViewpointsData] = useState(null);
    const supplementAllViewpointsData = (newFC) => {
        setAllViewpointsData((prevState) => fcFromFeatures([
            ...((prevState === null || prevState === void 0 ? void 0 : prevState.features) || []),
            ...newFC.features,
        ]));
    };
    const supplementAllSampleUnitsData = (newFC) => {
        setAllSampleUnitsData((prevState) => fcFromFeatures([
            ...((prevState === null || prevState === void 0 ? void 0 : prevState.features) || []),
            ...newFC.features,
        ]));
    };
    const [allCoverageData, setAllCoverageData] = useState(null);
    const isInitialDataState = allSampleUnitsData === null && allViewpointsData === null;
    return {
        allSampleUnitsData,
        allViewpointsData,
        allCoverageData,
        setAllSampleUnitsData,
        supplementAllSampleUnitsData,
        setAllViewpointsData,
        supplementAllViewpointsData,
        setAllCoverageData,
        isInitialDataState,
    };
}
