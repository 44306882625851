import { SORT } from 'const'
import { getQueryString } from 'utils/utils'
import clsx from 'clsx'
import style from './SortableDateColumn.module.css'
import { Icon } from '@blueprintjs/core'
import { Link } from 'react-router-dom'

const sortToIcon = {
  [SORT.DESC]: 'sort-desc',
  [SORT.ASC]: 'sort-asc',
}

export function SortableDateColumn({ url, searchParams, lastSortOrder, fieldName, label, className }) {
  const isActive = searchParams.sort_field === fieldName
  const newSortOrder = isActive ? (lastSortOrder === SORT.ASC ? SORT.DESC : SORT.ASC) : lastSortOrder
  const query = getQueryString(searchParams, { sort_order: newSortOrder, sort_field: fieldName })

  return (
    <div className={className}>
      {label}
      <Link
        tabIndex={0}
        to={`${url}?${query}`}
        className={clsx(style.sort, isActive ? style.activeSort : style.inactiveSort)}
      >
        <Icon icon={sortToIcon[lastSortOrder]} />
      </Link>
    </div>
  )
}
