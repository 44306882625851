export const pciFeatureProcessingStatuses = {
    valid: 'valid',
    invalid: 'invalid',
    hidden: 'hidden',
};
export const pciSeverityNames = {
    good: 'good',
    satisfactory: 'satisfactory',
    fair: 'fair',
    poor: 'poor',
    inferior: 'inferior',
    serious: 'serious',
    failed: 'failed',
};
/**
 * Properly sorted severity names for
 * filtering pci features using their indexes
 */
export const pciSeverityNamesSortedArray = [
    pciSeverityNames.good,
    pciSeverityNames.satisfactory,
    pciSeverityNames.fair,
    pciSeverityNames.poor,
    pciSeverityNames.inferior,
    pciSeverityNames.serious,
    pciSeverityNames.failed,
];
export const pciSeverityLabels = {
    [pciSeverityNames.good]: 'Good',
    [pciSeverityNames.satisfactory]: 'Satisfactory',
    [pciSeverityNames.fair]: 'Fair',
    [pciSeverityNames.poor]: 'Poor',
    [pciSeverityNames.inferior]: 'Very poor',
    [pciSeverityNames.serious]: 'Serious',
    [pciSeverityNames.failed]: 'Failed',
};
export const defaultPciSeverityFilters = {
    [pciSeverityNames.good]: true,
    [pciSeverityNames.satisfactory]: true,
    [pciSeverityNames.fair]: true,
    [pciSeverityNames.poor]: true,
    [pciSeverityNames.inferior]: true,
    [pciSeverityNames.serious]: true,
    [pciSeverityNames.failed]: true,
};
const severityRgbColors = {
    [pciSeverityNames.good]: [72, 151, 88],
    [pciSeverityNames.satisfactory]: [137, 196, 88],
    [pciSeverityNames.fair]: [235, 202, 98],
    [pciSeverityNames.poor]: [230, 157, 89],
    [pciSeverityNames.inferior]: [218, 97, 93],
    [pciSeverityNames.serious]: [173, 54, 49],
    [pciSeverityNames.failed]: [154, 25, 20],
};
export const PCI_PALETTE = [
    severityRgbColors[pciSeverityNames.good],
    severityRgbColors[pciSeverityNames.satisfactory],
    severityRgbColors[pciSeverityNames.fair],
    severityRgbColors[pciSeverityNames.poor],
    severityRgbColors[pciSeverityNames.inferior],
    severityRgbColors[pciSeverityNames.serious],
    severityRgbColors[pciSeverityNames.failed],
];
export var ImageViewType;
(function (ImageViewType) {
    ImageViewType["front"] = "front";
    ImageViewType["bev"] = "bev";
})(ImageViewType || (ImageViewType = {}));
export var FrameNavigationDirection;
(function (FrameNavigationDirection) {
    FrameNavigationDirection["FORWARD"] = "FORWARD";
    FrameNavigationDirection["BACK"] = "BACK";
})(FrameNavigationDirection || (FrameNavigationDirection = {}));
export var DistressSeverity;
(function (DistressSeverity) {
    DistressSeverity["HIGH"] = "HIGH";
    DistressSeverity["MODERATE"] = "MODERATE";
    DistressSeverity["LOW"] = "LOW";
})(DistressSeverity || (DistressSeverity = {}));
export const DistressColorBySeverity = {
    [DistressSeverity.HIGH]: '#da615d',
    [DistressSeverity.MODERATE]: '#ebca62',
    [DistressSeverity.LOW]: '#7bb663',
};
export var pciViewerHotKeyKey;
(function (pciViewerHotKeyKey) {
    pciViewerHotKeyKey["notViewed"] = "notViewed";
    pciViewerHotKeyKey["toAnnotate"] = "toAnnotate";
    pciViewerHotKeyKey["valid"] = "valid";
    pciViewerHotKeyKey["switchRoiMap"] = "switchRoiMap";
    pciViewerHotKeyKey["switchDistress"] = "switchDistress";
    pciViewerHotKeyKey["nextFrameSegment"] = "nextFrameSegment";
    pciViewerHotKeyKey["previousFrameSegment"] = "previousFrameSegment";
})(pciViewerHotKeyKey || (pciViewerHotKeyKey = {}));
export const pciViewerHotKeyMap = {
    [pciViewerHotKeyKey.notViewed]: 'q',
    [pciViewerHotKeyKey.toAnnotate]: 'w',
    [pciViewerHotKeyKey.valid]: 'e',
    [pciViewerHotKeyKey.switchRoiMap]: 'r',
    [pciViewerHotKeyKey.switchDistress]: 'd',
    [pciViewerHotKeyKey.nextFrameSegment]: ['ArrowUp', 'ArrowRight'],
    [pciViewerHotKeyKey.previousFrameSegment]: ['ArrowDown', 'ArrowLeft'],
};
export const pciDataTypes = {
    viewpoint: 'viewpoint',
    sampleUnit: 'sampleUnit',
};
export const pciViewModeKeys = {
    viewpoints: 'viewpoints',
    combined: 'combined',
    sampleUnits: 'sampleUnits',
};
export const initialPciFetchParams = {
    // Westborough
    zoom: 12,
    longitude: -71.6116708,
    latitude: 42.2660841,
    // Nashville
    // zoom: 10,
    // longitude: -86.7578045,
    // latitude: 36.1937511,
};
export const pciExportTypeFormats = {
    json: 'json',
    csv: 'csv',
    excel: 'excel',
    geoJson: 'geoJson',
    zip: 'zip',
};
export const pciExportTypeFormatsArray = [
    pciExportTypeFormats.json,
    pciExportTypeFormats.csv,
    pciExportTypeFormats.excel,
    pciExportTypeFormats.geoJson,
    pciExportTypeFormats.zip,
];
export const pciExportTypeHeadersByFormat = {
    json: 'application/json',
    csv: 'text/csv',
    excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    geoJson: 'application/geo+json',
    zip: 'application/zip', // Shapely
};
export const pciExportTypeExtensionsByFormat = {
    json: 'json',
    csv: 'csv',
    excel: 'xlsx',
    geoJson: 'geojson',
    zip: 'zip', // Shapely
};
