import { useNavigate } from 'react-router-dom'
import { PATH } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/routingConstants'
import { TabsWrap } from '@roadar-pipeline-viewer/roadly-typescript/dist/components/TabsWrap/TabsWrap'

export function SessionsListTabs({ searchParams }) {
  const navigate = useNavigate()

  return (
    <div>
      <TabsWrap
        handleAddNewPipelineClick={() => {
          navigate({
            pathname: PATH.runPipeline.path,
          })
        }}
      />
    </div>
  )
}
