import { WebMercatorViewport } from 'deck.gl/typed';
import { lineString as turfLineString } from '@turf/helpers';
import * as turf from '@turf/turf';
import { DEFAULT_USER_VIEWPORT_CONFIG, MAP_PADDING } from '../../const/mapConstants';
export const getViewportParams = (geometry) => {
    // Todo: This has to account map element width and height
    //  They exist in viewState in map context
    const WIDTH = window.innerWidth;
    const HEIGHT = window.innerHeight;
    try {
        if (geometry && geometry.length) {
            let geom = null;
            if (Array.isArray(geometry[0])) {
                console.log('Line string');
                geom = turfLineString(geometry);
            }
            else {
                console.log('Not line string');
                geom = turf.buffer(turf.point(geometry.slice(0, 2)), 1, { units: 'kilometers' });
            }
            const [topLat, topLng, bottomLat, bottomLng] = turf.bbox(geom);
            // Todo: utilize getViewportParamsByBBox ?
            const padding = WIDTH > MAP_PADDING.DESKTOP * 2 ? MAP_PADDING.DESKTOP : MAP_PADDING.MOBILE;
            const viewport = new WebMercatorViewport({ width: WIDTH, height: HEIGHT }).fitBounds([
                [topLat, topLng],
                [bottomLat, bottomLng],
            ], { padding });
            const { longitude, latitude, zoom } = viewport;
            return { longitude, latitude, zoom };
        }
        return DEFAULT_USER_VIEWPORT_CONFIG;
    }
    catch (e) {
        console.error('utils:getViewportParams:caught', e);
        return DEFAULT_USER_VIEWPORT_CONFIG;
    }
};
