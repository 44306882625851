import { IcoSphereGeometry, CylinderGeometry } from '@luma.gl/engine'

var projectionMatrix = null;
var viewMatrix = null;
var cameras = null;
var gl = null;
var cameraPosesProgram = null;
var cameraIndicesLength = null;
var isDrawEnabled = false;
export const defaultPosesShiftVertical = -1;
var posesShiftVertical = defaultPosesShiftVertical;
export const defaultIsPosesLanded = true;
var isPosesLanded = defaultIsPosesLanded; 
export const defaultPosesColor = '#dde8ee';
var posesColor = defaultPosesColor;
export const defaultVerticalAxis = 'z';
var verticalAxis = defaultVerticalAxis;


export function setShowCamerasPoses(_isDrawEnabled) {
    isDrawEnabled = _isDrawEnabled;
    if (!isDrawEnabled) {
        gl.clearColor(0, 0, 0, 0);
        gl.clear(gl.COLOR_BUFFER_BIT);
    }

}

const vertexShaderForCameraSource = `
#version 300 es
precision highp float;
precision highp int;

uniform mat4 projection, view;
uniform vec3 vertShift;
uniform int applyVertShift;
uniform vec3 uColor;

uniform vec3 uShapePositions[102]; // 102 is shape_position_count/3 

in vec3 position;
out vec4 vColor;
out float clipped;

void main () {
    // vec4 pos2d = transpose(projection) * transpose(view) * position;

    vec4 pos2d = vec4(position, 1.0);
    if (applyVertShift > 0) {
        vec3 shapePosition = uShapePositions[gl_VertexID % 102];
        for (int i=0; i<3; i++) {
            if (abs(vertShift[i]) > 0.0) {
                pos2d[i] = vertShift[i] + shapePosition[i];
            }
        }
    }
    pos2d = projection * view * pos2d;

    vec4 vCenter = pos2d / pos2d.w;

    float clip = 1.0;

    clipped = 0.0;
    if (vCenter.z < -clip || vCenter.z > clip || vCenter.x < -clip || vCenter.x > clip || vCenter.y < -clip || vCenter.y > clip) {
        clipped = 1.0;
    }

    gl_Position = vCenter;
    vColor = vec4(uColor, 1);
    // vColor.r = float(gl_VertexID % 255)/255.0; // just debug
    gl_PointSize = 3.0;
}
`.trim()

const fragmentShaderForCameraSource = `
#version 300 es
precision highp float;

in vec4 vColor;
in float clipped;
out vec4 fragColor;

void main () {
    if (clipped > 0.0) {
        discard;
    }
    fragColor = vColor;
}

`.trim()

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ] : null;
}

export function init(canvas) {
    gl = canvas.getContext('webgl2', {
        antialias: false,
      });

    canvas.width = innerWidth;
    canvas.height = innerHeight;

    gl.disable(gl.DEPTH_TEST);
    gl.viewport(0, 0, innerWidth, innerHeight);
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    const vertexShader = gl.createShader(gl.VERTEX_SHADER)
    gl.shaderSource(vertexShader, vertexShaderForCameraSource)
    gl.compileShader(vertexShader)
    if (!gl.getShaderParameter(vertexShader, gl.COMPILE_STATUS)) console.error(gl.getShaderInfoLog(vertexShader))

    const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER)
    gl.shaderSource(fragmentShader, fragmentShaderForCameraSource)
    gl.compileShader(fragmentShader)
    if (!gl.getShaderParameter(fragmentShader, gl.COMPILE_STATUS)) console.error(gl.getShaderInfoLog(fragmentShader))

    cameraPosesProgram = gl.createProgram()
    gl.attachShader(cameraPosesProgram, vertexShader)
    gl.attachShader(cameraPosesProgram, fragmentShader)
    gl.linkProgram(cameraPosesProgram)
    gl.useProgram(cameraPosesProgram)

}

export function setIsPosesLanded(_isPosesLanded) {
    isPosesLanded = _isPosesLanded;
    updatePosesShiftVertical();
}

export function setPosesShiftVertical(_posesShiftVertical) {
    posesShiftVertical = _posesShiftVertical;
    updatePosesShiftVertical();
}

function updatePosesShiftVertical() {
    let uShift = [0.0, 0.0, 0.0];
    let uApplyShift = 0;
    if (isPosesLanded) {
        let value = Math.sign(posesShiftVertical) * Math.pow(2, Math.abs(posesShiftVertical));
        if (verticalAxis === 'x')
            uShift[0] = value
        else if (verticalAxis === 'y')
            uShift[1] = value
        else if (verticalAxis === 'z')
            uShift[2] = value
        uApplyShift = 1;
    }
    console.log('>> uShift',uShift, 'uApplyShift',uApplyShift);
    const u_shift = gl.getUniformLocation(cameraPosesProgram, 'vertShift');
    const u_apply_shift = gl.getUniformLocation(cameraPosesProgram, 'applyVertShift');
    gl.uniform3fv(u_shift, uShift);
    gl.uniform1i(u_apply_shift, uApplyShift);
}

export function setPosesColor(_posesColor) {
    posesColor = _posesColor;
    updatePosesColor();
}

function updatePosesColor() {
    const rgb = hexToRgb(posesColor);
    const color = rgb.map(v => v/255);

    const u_color = gl.getUniformLocation(cameraPosesProgram, 'uColor');
    gl.uniform3fv(u_color, color);
}

export function setVerticalAxis(_verticalAxis) {
    verticalAxis = _verticalAxis;
    updatePosesShiftVertical();
}

const assign = (sumArr, arr, idx) => {
    for (let i=0; i<arr.length; i++) {
        sumArr[idx+i] = arr[i]
    }
}

export function setCameras(_cameras) {
    cameras = _cameras;

    const [x0, y0, z0] = cameras[0].position
    const [x1, y1, z1] = cameras[1].position
    const camDist = Math.sqrt(Math.pow(x1-x0, 2) + Math.pow(y1-y0, 2) + Math.pow(z1-z0, 2))
    const shapeSize = camDist/8;

    const shape = new IcoSphereGeometry({
      iterations: 2
    })
    
    const shapePosition = Array.from(shape.getAttributes()['POSITION'].value).map(el => el*shapeSize) //simple scaling for cube and sphere
    // const shapePosition = Array.from(shape.getAttributes()['POSITION'].value)
    const shapeIndices = Array.from(shape.getAttributes()['indices'].value)

    const u_shp = gl.getUniformLocation(cameraPosesProgram, 'uShapePositions');
    gl.uniform3fv(u_shp, shapePosition);

    var cameraVertices = new Float32Array(shapePosition.length * cameras.length) // []
    var cameraIndices = new Uint32Array(shapeIndices.length * cameras.length) // []
    let prevMaxIdx = 0
    let positionIdx = 0;
    let indIdx = 0;
    for (let i=0; i<cameras.length; i++) {
        const cam = cameras[i]
        const [x,y,z] = cam.position
        const newPositions = shapePosition.map((v, i) => {
          const coord_num = i % 3;
          if (coord_num === 0)
            return v + x;
          if (coord_num === 1)
            return v + y;
          if (coord_num === 2)
            return v + z;
        })

        assign(cameraVertices, newPositions, positionIdx)
        positionIdx += newPositions.length
        const newIdxs = shapeIndices.map(idx => idx + prevMaxIdx)
        // cameraIndices = [...cameraIndices, ...newIdxs]
        assign(cameraIndices, newIdxs, indIdx)
        indIdx += newIdxs.length
        prevMaxIdx = Math.max(...newIdxs) + 1
    }
    updatePosesShiftVertical();
    updatePosesColor();

    var cameraVertexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, cameraVertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, cameraVertices, gl.STATIC_DRAW);

    var cameraIndexBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, cameraIndexBuffer);
    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, cameraIndices, gl.STATIC_DRAW);

    cameraIndicesLength = cameraIndices.length;

    var attribCamPosition = gl.getAttribLocation(cameraPosesProgram, "position");
    gl.enableVertexAttribArray(attribCamPosition);
    gl.bindBuffer(gl.ARRAY_BUFFER, cameraVertexBuffer);
    gl.vertexAttribPointer(attribCamPosition, 3, gl.FLOAT, false, 0, 0);

    doRender();
}

export function setProjectionMatrix(_projectionMatrix) {
    projectionMatrix = [..._projectionMatrix];
    const u_projection_1 = gl.getUniformLocation(cameraPosesProgram, 'projection');
    gl.uniformMatrix4fv(u_projection_1, false, projectionMatrix);
    doRender();
}

export function setViewMatrix(_viewMatrix) {
    viewMatrix = [..._viewMatrix];
    const u_view_1 = gl.getUniformLocation(cameraPosesProgram, 'view');
    gl.uniformMatrix4fv(u_view_1, false, viewMatrix);
    doRender();
}

function doRender() {
    if (!isDrawEnabled)
        return;
    if (!projectionMatrix || !viewMatrix || !cameras)
        return;
    gl.drawElements(gl.TRIANGLES, cameraIndicesLength, gl.UNSIGNED_INT, 0);
}