import { Dialog, Classes, Button } from '@blueprintjs/core'
import ReactJson from 'react-json-view'
import styles from './JsonViewer.module.css'

function JsonViewer({ json, isOpen, onClose }) {
  return (
    <Dialog
      className={Classes.OVERLAY_SCROLL_CONTAINER}
      isOpen={isOpen}
      onClose={onClose}
      usePortal
      canOutsideClickClose
    >
      <div>
        <div className={Classes.DIALOG_HEADER}>
          <h5 className="bp4-heading">Payload.json</h5>
          <Button icon="cross" className={Classes.DIALOG_CLOSE_BUTTON} onClick={onClose} />
        </div>
        <div className={styles.viewer}>
          <ReactJson src={json} theme="brewer" displayObjectSize={false} displayDataTypes={false} indentWidth={2} />
        </div>
      </div>
    </Dialog>
  )
}

export default JsonViewer
