import { Button, Alignment } from '@blueprintjs/core'
import { API } from '@roadar-pipeline-viewer/roadly-typescript/dist/services/ApiService'
import { useEffect, useState } from 'react'
import { Select2 } from '@blueprintjs/select'
import { MenuItem2 } from '@blueprintjs/popover2'
import utils from 'utils/utils'

export function CamLocPipelineSelect({ uuid, onItemSelect }) {
  const [pipelines, setPipelines] = useState([{ uuid: null }])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    API.getPipelineListShort({
      types: 'CAM_LOC',
      status: 'success',
    })
      .then(response => {
        const items = response.items
        items.unshift({ uuid: null })
        setPipelines(items)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [])

  const activeItem = pipelines.find(p => p.uuid === uuid)
  const itemText = item => (!item.uuid ? '---' : `${item.uuid} - ${utils.formatDate(item.created)}`)

  const shortUUID = uuid => `${uuid.substr(0, 5)}..${uuid.substr(-5)}`
  const activeItemText = item => (!item.uuid ? '---' : `${shortUUID(item.uuid)} - ${utils.formatDate(item.created)}`)

  return (
    <Select2
      fill
      disabled={isLoading || pipelines.length === 0}
      items={pipelines}
      activeItem={activeItem}
      onItemSelect={value => onItemSelect(value)}
      noResults={'No results'}
      itemRenderer={(item, { handleClick, handleFocus, modifiers }) => (
        <MenuItem2
          text={itemText(item)}
          roleStructure="listoption"
          active={modifiers.active}
          key={item.uuid}
          onClick={handleClick}
          onFocus={handleFocus}
        />
      )}
    >
      <Button fill loading={isLoading} alignText={Alignment.LEFT} rightIcon={'chevron-down'}>
        {activeItemText(activeItem)}
      </Button>
    </Select2>
  )
}
