export function getSeverityIndexByPci(pci) {
    return pci > 85
        ? 0
        : pci > 70
            ? 1
            : pci > 55
                ? 2
                : pci > 40
                    ? 3
                    : pci > 25
                        ? 4
                        : pci > 10
                            ? 5
                            : 6;
}
export const rgbToHex = ([r, g, b, a]) => {
    return typeof a === 'undefined'
        ? `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`
        : `#${r.toString(16)}${g.toString(16)}${b.toString(16)}${a.toString(16)}`;
};
