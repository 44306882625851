import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, FormGroup, Intent } from '@blueprintjs/core';
import { LoadingStatus } from '../../../const/appConstants';
import { FormFieldError } from '../../../components/FormFieldError';
import settingsStyles from '../ProfileSettings/ProfileSettings.module.scss';
import { setServerErrorDetails } from '../../../utils/setServerErrorDetails';
import { passwordValidationProps } from '../../../const/validationConstants';
export const ManuallyChangePassword = ({ loadingStatus, onSubmitChangePassword, exception, onRestartFlowRequested }) => {
    const { register, handleSubmit, setError, reset, watch, formState: { errors }, } = useForm();
    useEffect(() => {
        setServerErrorDetails(setError, exception === null || exception === void 0 ? void 0 : exception.formErrorDetails);
    }, [exception]);
    const formOptions = {
        password: {
            required: 'Current password is required',
            ...passwordValidationProps,
        },
        newPassword: {
            required: 'New password is required',
            ...passwordValidationProps,
        },
        newPasswordConfirmation: {
            required: 'New password confirmation is required',
            validate: (value) => {
                if (watch('newPassword') !== value) {
                    return 'New passwords do no match';
                }
            },
        },
    };
    const handleRestartFlowRequested = () => {
        reset();
        onRestartFlowRequested();
    };
    if (loadingStatus === LoadingStatus.SUCCESS) {
        return (_jsxs("div", { children: ["Password successfully updated", _jsx("div", { children: _jsx(Button, { text: "Ok", onClick: handleRestartFlowRequested }) })] }));
    }
    return (_jsx("div", { className: settingsStyles.narrowFormContainer, children: _jsxs("form", { onSubmit: handleSubmit(onSubmitChangePassword), children: [_jsxs(FormGroup, { label: "Current password", labelFor: "current-password", children: [_jsx("div", { className: "bp4-input-group", children: _jsx("input", { id: "password", className: "bp4-input", type: "password", placeholder: "Current password", ...register('password', formOptions.password) }) }), _jsx(FormFieldError, { fieldError: errors.password })] }), _jsxs(FormGroup, { label: "New password", labelFor: "newPassword", children: [_jsx("div", { className: "bp4-input-group", children: _jsx("input", { id: "newPassword", className: "bp4-input", type: "password", placeholder: "New password", ...register('newPassword', formOptions.newPassword) }) }), _jsx(FormFieldError, { fieldError: errors.newPassword })] }), _jsxs(FormGroup, { label: "New password confirmation", labelFor: "newPasswordConfirmation", children: [_jsx("div", { className: "bp4-input-group", children: _jsx("input", { id: "newPasswordConfirmation", className: "bp4-input", type: "password", placeholder: "New password confirmation", ...register('newPasswordConfirmation', formOptions.newPasswordConfirmation) }) }), _jsx(FormFieldError, { fieldError: errors.newPasswordConfirmation })] }), _jsx(FormGroup, { children: _jsx(Button, { loading: loadingStatus === LoadingStatus.LOADING, type: "submit", intent: Intent.PRIMARY, text: "Submit" }) })] }) }));
};
