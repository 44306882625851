import { jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import { COMPLETE_VIEWPORT_CONFIG, MAPBOX_OVERLAY, MapboxOverlayKey, MapboxOverlayType, UnitsSystem, } from '../../const/mapConstants';
import { DeckGLMap } from './DeckGLMap';
import { getBasicMapLayer, getTerrainLayer } from './layers';
import { getGeoJsonLayer } from './layers/getGeoJsonLayer';
import { getMiniPciIndexLayerAsReactNode } from './layers/getMiniPciIndexLayer';
import { getCamLocLayer } from './layers/camLocLayer/getCamLocLayer';
import { getAssetsRegistryQaLayer } from './layers/assetsRegistryQaLayer/getAssetsRegistryQaLayer';
import { getRoadLyClusterLayer } from './layers/RoadLyClusterLayer';
import { RoadLyMapContext } from '../../contexts/roadLyMapContext';
import { getPolygonsBeingLoadedLayer } from './layers/getPolygonsBeingLoadedLayer';
export const RoadLyMap = ({ onClickRoadLyMapClusterSubFeature, roadLyMapPopupProps, onClickMap, onMouseMoveOverMap, onNavigationEnd, 
// Viewport config, depends on users location or preferences
userViewportConfig, 
// Browser settings passed down here, can be overridden with user's settings
unitsSystem = UnitsSystem.imperial, overlayKey = MapboxOverlayKey.CUSTOM_DARK, isSmall = false, className, dragEventsConfig, geoJsonConfig, touchRotate = true, polygonsBeingLoaded, screenShotProps, assetsRegistryConfig, finalAssetsQaConfig, miniPciIndexConfig = null, camLocConfig = null, }) => {
    const [roadLyMapContext, setRoadLyMapContext] = useContext(RoadLyMapContext);
    const onNavigationEndInMap = (viewState, boundingBox) => {
        if (typeof onNavigationEnd === 'function') {
            onNavigationEnd(viewState, boundingBox);
        }
    };
    // @ts-ignore Todo
    const initialViewState = { ...COMPLETE_VIEWPORT_CONFIG, ...userViewportConfig };
    const overlayConfig = MAPBOX_OVERLAY[overlayKey];
    const getTooltip = miniPciIndexConfig ? miniPciIndexConfig.getTooltip : null;
    const onClickDeckGL = useCallback((info, event) => {
        if (!info.object) {
            // Deselect cluster (hides cluster popup)
            setRoadLyMapContext({
                ...roadLyMapContext,
                activeCluster: null,
            });
        }
        if (typeof onClickMap === 'function') {
            return onClickMap(info);
        }
    }, [onClickMap]);
    // Tooltip has to say what to render
    return (_jsxs(DeckGLMap, { onClickRoadLyMapClusterSubFeature,
        roadLyMapPopupProps,
        isSmall,
        initialViewState,
        onClickDeckGL,
        onNavigationEnd: onNavigationEndInMap,
        touchRotate,
        dragEventsConfig,
        screenShotProps,
        getTooltip,
        onMouseMoveOverMap, children: [overlayConfig.type === MapboxOverlayType.FLAT && getBasicMapLayer({ overlayConfig, initialViewState, unitsSystem, screenShotProps }), overlayConfig.type === MapboxOverlayType.TERRAIN && getTerrainLayer(overlayConfig), geoJsonConfig && getGeoJsonLayer(geoJsonConfig), assetsRegistryConfig && getRoadLyClusterLayer({
                featureCollection: assetsRegistryConfig.finalAssetsFeatureCollection,
            }), finalAssetsQaConfig && getAssetsRegistryQaLayer(finalAssetsQaConfig), miniPciIndexConfig && getMiniPciIndexLayerAsReactNode(miniPciIndexConfig), camLocConfig && getCamLocLayer(camLocConfig), polygonsBeingLoaded ? getPolygonsBeingLoadedLayer(polygonsBeingLoaded) : null] }));
};
