import { getExposedFileUrl } from 'utils/getExposedFileUrl'

export const getCameraLocalization = pipeline => {
  const pciFileUrl = getExposedFileUrl(pipeline, 'CAMERA_LOCALIZATION')

  return new Promise((resolve, reject) => {
    if (pciFileUrl) {
      const worker = new Worker(new URL('workers/camera_localization.js', import.meta.url))
      worker.postMessage({ url: pciFileUrl })
      worker.onmessage = ({ data: { data } }) => {
        resolve(data)
        worker.terminate()
      }
    } else {
      resolve(undefined)
    }
  })
}
