import { jsx as _jsx } from "react/jsx-runtime";
import { appConfig } from '../../config';
import { appBrands } from '../../const/appConstants';
import { TmpUrbiMap } from './TmpUrbiMap';
import { RoadLyMap } from './RoadLyMap';
export const PreferredMap = (params) => {
    const { appBrand } = appConfig;
    return (appBrand === appBrands.URBI
        ? _jsx(TmpUrbiMap, { ...params })
        : _jsx(RoadLyMap, { ...params }));
};
