import { MAPBOX_ACCESS_TOKEN } from './mapConstants'

export const MAPBOX_OVERLAY_TYPE = {
  FLAT: 'flat',
  TERRAIN: 'terrain',
}

export const MAPBOX_OVERLAY_KEY = {
  CUSTOM_DARK: 'custom-dark',
  STREETS: 'streets-v11',
  LIGHT: 'light-v10',
  DARK: 'dark-v10',
  OUTDOORS: 'outdoors-v11',
  NAVIGATION_DAY: 'navigation-day',
  NAVIGATION_NIGHT: 'navigation-night',
  SATELLITE: 'satellite-v9',
  SATELLITE_STREETS: 'satellite-streets',
  TERRAIN_OSM: 'satellite-osm',
  TERRAIN_SATELLITE: 'satellite-streets',
}

export const MAPBOX_OVERLAY = {
  [MAPBOX_OVERLAY_KEY.STREETS]: {
    key: MAPBOX_OVERLAY_KEY.STREETS,
    type: MAPBOX_OVERLAY_TYPE.FLAT,
    title: 'Street',
    style: 'mapbox://styles/mapbox/streets-v11',
  },
  [MAPBOX_OVERLAY_KEY.LIGHT]: {
    key: MAPBOX_OVERLAY_KEY.LIGHT,
    type: MAPBOX_OVERLAY_TYPE.FLAT,
    title: 'Light',
    style: 'mapbox://styles/mapbox/light-v10',
  },
  [MAPBOX_OVERLAY_KEY.DARK]: {
    key: MAPBOX_OVERLAY_KEY.DARK,
    type: MAPBOX_OVERLAY_TYPE.FLAT,
    title: 'Dark',
    style: 'mapbox://styles/mapbox/dark-v10',
  },
  [MAPBOX_OVERLAY_KEY.CUSTOM_DARK]: {
    key: MAPBOX_OVERLAY_KEY.CUSTOM_DARK,
    type: MAPBOX_OVERLAY_TYPE.FLAT,
    title: 'Road.ly dark',

    // Todo: attach link from company's account
    style: 'mapbox://styles/andreyselin/cldu293b300ax01qqlswrde47',
  },
  [MAPBOX_OVERLAY_KEY.OUTDOORS]: {
    key: MAPBOX_OVERLAY_KEY.OUTDOORS,
    type: MAPBOX_OVERLAY_TYPE.FLAT,
    title: 'Outdoors',
    style: 'mapbox://styles/mapbox/outdoors-v11',
  },
  [MAPBOX_OVERLAY_KEY.NAVIGATION_DAY]: {
    key: MAPBOX_OVERLAY_KEY.NAVIGATION_DAY,
    type: MAPBOX_OVERLAY_TYPE.FLAT,
    title: 'Navigation day',
    style: 'mapbox://styles/mapbox/navigation-day-v1',
  },
  [MAPBOX_OVERLAY_KEY.NAVIGATION_NIGHT]: {
    key: MAPBOX_OVERLAY_KEY.NAVIGATION_NIGHT,
    type: MAPBOX_OVERLAY_TYPE.FLAT,
    title: 'Navigation night',
    style: 'mapbox://styles/mapbox/navigation-night-v1',
  },
  [MAPBOX_OVERLAY_KEY.SATELLITE]: {
    key: MAPBOX_OVERLAY_KEY.SATELLITE,
    type: MAPBOX_OVERLAY_TYPE.FLAT,
    title: 'Satellite',
    style: 'mapbox://styles/mapbox/satellite-v9',
  },
  [MAPBOX_OVERLAY_KEY.SATELLITE_STREETS]: {
    key: MAPBOX_OVERLAY_KEY.SATELLITE_STREETS,
    type: MAPBOX_OVERLAY_TYPE.FLAT,
    title: 'Satellite streets',
    style: 'mapbox://styles/mapbox/satellite-streets-v11',
  },
  [MAPBOX_OVERLAY_KEY.TERRAIN_OSM]: {
    key: MAPBOX_OVERLAY_KEY.TERRAIN_OSM,
    type: MAPBOX_OVERLAY_TYPE.TERRAIN,
    title: 'Terrain OSM (3d)',
    style: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
  },
  [MAPBOX_OVERLAY_KEY.TERRAIN_SATELLITE]: {
    key: MAPBOX_OVERLAY_KEY.TERRAIN_SATELLITE,
    type: MAPBOX_OVERLAY_TYPE.TERRAIN,
    title: 'Terrain satellite (3d)',
    style: `https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.png?access_token=${MAPBOX_ACCESS_TOKEN}`,
  },
}
