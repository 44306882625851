export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
export const metersToMiles = (meters) => meters * 0.000621371;
export const capitalizeString = (input) => `${input.charAt(0).toUpperCase()}${input.slice(1)}`;
export function getFromMapOrFail(map, key) {
    const result = map.get(key);
    if (typeof result === 'undefined') {
        throw new Error('Map value is undefined');
    }
    return result;
}
