export function filterByStepAndIndex({ element, array, stepSize }) {
    const result = [];
    const indexOfCurrentId = array.indexOf(element);
    if (indexOfCurrentId === -1) {
        throw new Error('Current frame segment is not in sequence of ids');
    }
    const offset = indexOfCurrentId % stepSize;
    for (let i = offset; i < array.length; i += stepSize) {
        result.push(array[i]);
    }
    return result;
}
