const MAPILLARY_PALETTE = [
[135, 206, 235],
[88, 19, 94],
[255, 255, 0],
[120, 120, 120],
[289, 182, 5],
[255, 165, 0],
[112, 128, 144],
[234, 156, 55],
[255, 254, 145],
[255, 248, 147],
[168, 193, 194],
[247, 233, 82],
[248, 64, 64],
[150, 150, 150],
[245, 254, 255],
[255, 224, 224],
[255, 0, 220],
[191, 74, 68],
[20, 20, 30],
[234, 105, 105],
[179, 80, 80],
[207, 93, 93],
[235, 105, 105],
[255, 255, 255],
[238, 233, 0],
[139, 137, 112],
[194, 178, 128],
[135, 206, 235],
[255, 250, 250],
[157, 80, 44],
[64, 181, 63],
[0, 0, 139],
[255, 0, 255],
[139, 115, 85],
[70, 130, 180],
[255, 255, 255],
[169, 169, 169],
[79, 18, 84],
[178, 34, 34],
[30, 144, 255],
[0, 0, 205],
[157, 227, 255],
[204, 0, 0],
[34, 34, 34],
[204, 204, 0],
[123, 73, 211],
[51, 195, 211],
[97, 57, 166],
[0, 128, 0],
[51, 195, 211],
[51, 195, 211],
[76, 99, 99],
[30, 144, 255],
[0, 0, 128],
[255, 217, 0],
[105, 64, 61],
[240, 230, 140],
[42, 42, 42],
[0, 0, 255],
[128, 128, 128],
[255, 255, 255],
[0, 128, 0],
[255, 255, 102],
[0, 0, 0],
[0, 0, 0]
];

export const MAPILLARY = Array.prototype.concat(
  MAPILLARY_PALETTE.map(v => v[0] / 255),
  MAPILLARY_PALETTE.map(v => v[1] / 255),
  MAPILLARY_PALETTE.map(v => v[2] / 255)
)

export const DEFAULT_PALETTE = Array(MAPILLARY.length*2).fill(0)

export const MAP_PALETTES_CONSTANTS = {
  DEFAULT_PALETTE,
  MAPILLARY,
  MAPILLARY_PALETTE
}
