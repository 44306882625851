import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Deck2gisLayer } from '@2gis/deck2gis-layer';
import { GeoJsonLayer } from '@deck.gl/layers/typed';
import { SimpleMeshLayer } from '@deck.gl/mesh-layers/typed';
import { memoizeByFirstArg } from '../../../../utils/memoize';
import { getCameraColorByVisibilityZone, getCameraPyramidGeometryByDistance, getCamLocPointData, } from '../../../../utils/meshes';
const getCamLocFillColor = (d) => {
    var _a;
    if (d.geometry.type === 'Point') {
        return [0, 0, 255, 255];
    }
    const vZone = ((_a = d.properties) === null || _a === void 0 ? void 0 : _a.visibility_zone) !== undefined ? d.properties.visibility_zone : -1;
    return getCameraColorByVisibilityZone(vZone, 180);
};
const getCamLocLineColor = () => [10, 10, 10, 255];
const getCamLocPointDataMemoized = memoizeByFirstArg(getCamLocPointData);
const geoJsonLayerStaticProps = {
    id: 'cam-loc',
    lineWidth: 1,
    lineWidthUnits: 'pixels',
    getFillColor: getCamLocFillColor,
    getLineColor: getCamLocLineColor,
    pointType: 'circle',
    getPointRadius: 3,
    pointRadiusUnits: 'pixels',
    filled: true,
};
// Todo: make constants
const distanceNames = [
    'recognition',
    'detection',
    'identification',
];
const getCamLocLayerProps = (camLocPointData, index) => ({
    id: `cam-loc-pyramid-${index}`,
    getPosition: (d) => d.position,
    getOrientation: (d) => d.angle,
    mesh: getCameraPyramidGeometryByDistance(`${distanceNames[index]}_distance`, camLocPointData),
    getColor: (d) => getCameraColorByVisibilityZone(distanceNames[index], 180),
    data: [camLocPointData],
});
export const getCamLocLayer = (camLocConfig) => {
    const { features: allFeatures } = camLocConfig.featureCollection;
    const camLocData = getCamLocPointDataMemoized(allFeatures);
    // @ts-ignore
    return (_jsxs(_Fragment, { children: [_jsx(GeoJsonLayer
            /* Static */
            , { ...geoJsonLayerStaticProps, 
                /* Dynamic */
                data: allFeatures }), camLocData ? (_jsxs(_Fragment, { children: [_jsx(SimpleMeshLayer, { ...getCamLocLayerProps(camLocData, 2) }), _jsx(SimpleMeshLayer, { ...getCamLocLayerProps(camLocData, 1) }), _jsx(SimpleMeshLayer, { ...getCamLocLayerProps(camLocData, 0) })] })) : null] }));
};
export const applyCamLocTo2GisMap = ({ deck, map }, camLocConfig) => {
    const { features: allFeatures } = camLocConfig.featureCollection;
    const camLocData = getCamLocPointDataMemoized(allFeatures);
    const geoJsonLayer = new Deck2gisLayer({
        // @ts-ignore
        deck,
        type: GeoJsonLayer,
        ...geoJsonLayerStaticProps,
        data: allFeatures,
    });
    const camLocLayers = camLocData ? [
        /* @ts-ignore Todo: find out way to expect exact deck type with 2gis map */
        new Deck2gisLayer({ deck, type: SimpleMeshLayer, ...getCamLocLayerProps(camLocData, 2) }),
        // @ts-ignore
        new Deck2gisLayer({ deck, type: SimpleMeshLayer, ...getCamLocLayerProps(camLocData, 1) }),
        // @ts-ignore
        new Deck2gisLayer({ deck, type: SimpleMeshLayer, ...getCamLocLayerProps(camLocData, 0) }),
    ] : [];
    const rerender = () => {
        try {
            map.removeLayer(geoJsonLayer.id);
            camLocLayers.forEach((layer) => map.removeLayer(layer.id));
        }
        catch (e) {
            console.warn('Removing layers error caught', e);
        }
        map.addLayer(geoJsonLayer);
        camLocLayers.forEach((layer) => map.addLayer(layer));
    };
    rerender();
};
