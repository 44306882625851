import { toast } from 'react-toastify'
import { parseErrorsDetialResponse } from 'utils/utils.js'
import { Button, Icon } from '@blueprintjs/core'
import { PATH } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/routingConstants'

const toastProps = {
  position: 'bottom-center',
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'dark',
}

const INTENT = {
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
}

const addToast = props => {
  switch (props.intent) {
    case INTENT.DANGER:
      toast.error(props.message, toastProps)
      break
    case INTENT.SUCCESS:
      toast.success(props.message, toastProps)
      break
    default:
      toast(props.message, toastProps)
      break
  }
}

export function HandleSubmitResponse(res, formOptions, navigate, setErrors, setRequestErrors, setLoading) {
  if (!res?.ok) {
    res
      .json()
      .then(data => {
        setLoading(false)
        parseErrorsDetialResponse(
          data,
          formOptions,
          (loc, msg) => {
            setErrors(prevState => ({ ...prevState, [loc]: msg }))
          },
          setRequestErrors
        )
      })
      .catch(() => {
        setLoading(false)
        setRequestErrors([{ loc: 'Request', msg: 'Something went wrong' }])
      })
  } else {
    setLoading(false)
    res
      .json()
      .then(data => {
        addToast({
          message: (
            <div>
              {data.pipeline_uuid} running!
              <Button
                minimal
                small
                onClick={() => navigator.clipboard.writeText(data.pipeline_uuid)}
                icon={<Icon icon="duplicate" size={14} />}
              />
            </div>
          ),
          intent: INTENT.SUCCESS,
        })
        navigate(PATH.pipelinesList.path)
      })
      .catch(() => {
        console.error('Unexpected response from server')
        navigate(PATH.pipelinesList.path)
      })
  }
}
