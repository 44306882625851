import { createDataUrlOfSvgCircleWithLabel } from '../../../../utils/createSvgCircleWithLabel/createSvgCircleWithLabel';
import { abbreviationFromClassName } from '../../../../utils/abbreviation.utilities/abbreviationFromClassName';
import { getFromMapOrFail } from '../../../../utils';
const qaStatusColors = {
    untouched: '#aaa',
    approved: '#3c3',
    declined: '#c33',
};
export function createAssetsQaIconsMap(features) {
    const result = new Map([
        [null, {}],
        [true, {}],
        [false, {}],
    ]);
    const nullFeatures = getFromMapOrFail(result, null);
    const trueFeatures = getFromMapOrFail(result, true);
    const falseFeatures = getFromMapOrFail(result, false);
    features.forEach((feature) => {
        if (nullFeatures[feature.properties.class_name]) {
            return;
        }
        const abbr = abbreviationFromClassName(feature.properties.class_name);
        nullFeatures[feature.properties.class_name] = createDataUrlOfSvgCircleWithLabel(128, qaStatusColors.untouched, abbr, 58);
        trueFeatures[feature.properties.class_name] = createDataUrlOfSvgCircleWithLabel(128, qaStatusColors.approved, abbr, 58);
        falseFeatures[feature.properties.class_name] = createDataUrlOfSvgCircleWithLabel(128, qaStatusColors.declined, abbr, 58);
    });
    return result;
}
