import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useRoles } from '../../hooks/useRoles';
import { ROLES } from '../../const/userConstants';
export const ContentFor = ({ role, children }) => {
    const roles = useRoles();
    if ((roles && roles.includes(role)) || roles.includes(ROLES.SUPERADMIN)) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return _jsx(_Fragment, { children: children });
    }
    return null;
};
