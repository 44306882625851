import { useEffect } from 'react';
// import mapData from './map_data_waltham.json';
import { API } from '../../services/ApiService';
export const GrabDemoFrameSegmentsPage = () => {
    // Get json
    // Set start and end position for grabbing
    // Get each of itom in segment and fill the result
    //
    // Tmp:
    const mapData = { features: [] };
    useEffect(() => {
        const effect = async () => {
            const features = (mapData
                .features
                .filter((el) => el.geometry.type === 'Point'));
            const chunk = 100;
            const result = [];
            const startStep = 0;
            const steps = 10;
            let stopped = false;
            function stop() {
                stopped = true;
            }
            console.log('Len', features.length);
            async function iterate(i) {
                const requestChunk = features.slice(i * chunk, (i + 1) * chunk);
                if (requestChunk.length === 0) {
                    console.log('DONE');
                    return;
                }
                const chunkResults = await Promise
                    .all(requestChunk
                    .map((feature) => API.getMiniPciFrameSegment(feature.properties.id)));
                if (stopped) {
                    return;
                }
                result.push(...chunkResults);
                console.log({ i, result, requestChunk, chunkResults });
                if (i === steps - 1) { // todo: count start step
                    return;
                }
                return iterate(i + 1);
            }
            // @ts-ignore
            window.iterate = iterate;
            // @ts-ignore
            window.stop = stop;
            // @ts-ignore
            window.result = result;
            // iterate(startStep).then(() => {
            //   console.log('\nDONE ------------------------------------------\n');
            //   console.log('--->', result);
            // });
            // for (let i = startStep; i < startStep + steps; i += 1) {
            //   const requestChunk = features.slice(i * chunk, (i + 1) * chunk);
            //   const chunkResults = await Promise
            //     .all(requestChunk
            //       .map((feature) => API.getMiniPciFrameSegment(feature.properties.id)));
            //
            //   result.push(...chunkResults);
            //   console.log({ requestChunk, chunkResults });
            // }
            // console.log({ features, result });
        };
        effect();
    }, []);
    return null;
};
