import sortBy from 'lodash/sortBy';
import { apiAsClass } from '../../../services/ApiService';
import { fcFromFeatures } from '../../../utils/geoJson.utils';
import { ROLES } from '../../../const/userConstants';
import { filterPciMapDataForDemoUser } from '../../../utils/filterPciMapDataForDemoUser/filterPciMapDataForDemoUser';
function isViewpointPciFeature(feature) {
    return feature.geometry.type === 'Point';
}
export const fetchAndApplyPciDataSideResults = {
    outOfZoom: 'outOfZoom',
};
export function isSideResult(input) {
    return Boolean(fetchAndApplyPciDataSideResults[input]);
}
export const fetchAndApplyPciData = async ({ zoom, pipelineId, // string or null
profile, setAllSampleUnitsData, setAllViewpointsData, boundingBox, }) => {
    if (!zoom) {
        throw new Error('Zoom is not passed');
    }
    const asSuperAdmin = profile.roles.includes(ROLES.SUPERADMIN);
    // const nonHardFilteredMixedFeatureCollection = await apiAsClass.getPciRegistryArea({ pipelineId, boundingBox });
    // eslint-disable-next-line no-nested-ternary
    const nonHardFilteredMixedFeatureCollection = profile.isStaticUser
        ? await apiAsClass.getStaticPciRegistryArea(profile.organization_id)
        : boundingBox
            ? await apiAsClass.getPciRegistryArea({ pipelineId, boundingBox, asSuperAdmin })
            : await apiAsClass.getWholePciRegistry({ pipelineId, boundingBox, asSuperAdmin });
    // Hard filter features by user name
    const mixedFeatureCollection = profile.isDemoUser
        ? filterPciMapDataForDemoUser(nonHardFilteredMixedFeatureCollection)
        : nonHardFilteredMixedFeatureCollection;
    console.log({
        isDemoUser: profile.isDemoUser,
        mixedFeatureCollection,
    });
    const viewpointFeatures = [];
    const sampleUnitFeatures = [];
    mixedFeatureCollection.features.forEach((feature) => {
        if (isViewpointPciFeature(feature)) {
            viewpointFeatures.push(feature);
        }
        else {
            sampleUnitFeatures.push(feature);
        }
    });
    const sortedViewpointFeatures = sortBy(viewpointFeatures, (el) => el.properties.id);
    // const finalViewpointFeatures = sortedViewpointFeatures.filter((el, index) => index % 4 === 0);
    const finalViewpointFeatures = sortedViewpointFeatures;
    const sampleUnitsFeatureCollection = fcFromFeatures(sampleUnitFeatures);
    const viewpointsFeatureCollection = fcFromFeatures(finalViewpointFeatures);
    setAllSampleUnitsData(sampleUnitsFeatureCollection);
    setAllViewpointsData(viewpointsFeatureCollection);
    return {
        viewpointsFeatureCollection,
        sampleUnitsFeatureCollection,
    };
};
