import { useState } from 'react'
import { PipelineListHeader } from './PipelineListHeader'
import { PipelineListContent } from './PipelineListContent'
import { PipelineListTabs } from './PipelineListTabs'
import { PipelineListContentHead } from './PipelineListContentHead'

import style from './PipelineList.module.css'

function PipelineList({
  progress,
  items,
  limit,
  skip,
  sort_field,
  sort_order,
  status,
  total,
  owner,
  session,
  modules,
  types,
  is_complete_view,
  pipeline_name,
  organization_id,
  updatePipelineList,
  updateItemPart,
}) {
  const [selectedSession, setSelectedSession] = useState()
  const [selectedModules, setSelectedModules] = useState([])
  const [selectedOrganization, setSelectedOrganization] = useState()
  const searchParams = {
    limit,
    skip,
    sort_field,
    sort_order,
    status,
    owner,
    session,
    modules,
    types,
    organization_id,
    name: pipeline_name,
    is_complete_view: +is_complete_view,
  }
  return (
    <div className={style.list}>
      <PipelineListTabs setSelectedSession={setSelectedSession} searchParams={searchParams} />
      <PipelineListHeader
        limit={limit}
        skip={skip}
        sort_order={sort_order}
        status={status}
        owner={owner}
        session={session}
        types={types}
        modules={modules}
        is_complete_view={is_complete_view}
        pipeline_name={pipeline_name}
        organization_id={organization_id}
        selectedOrganization={selectedOrganization}
        setSelectedOrganization={setSelectedOrganization}
        selectedSession={selectedSession}
        setSelectedSession={setSelectedSession}
        selectedModules={selectedModules}
        setSelectedModules={setSelectedModules}
      />
      <PipelineListContentHead searchParams={searchParams} />
      <PipelineListContent
        searchParams={searchParams}
        progress={progress}
        items={items}
        limit={limit}
        skip={skip}
        sort_order={sort_order}
        status={status}
        total={total}
        session={session}
        updatePipelineList={updatePipelineList}
        selectedSession={selectedSession}
        updateItemPart={updateItemPart}
      />
    </div>
  )
}

export default PipelineList
