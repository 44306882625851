import { useSearchParams } from 'react-router-dom'
import { asyncConfigManager } from '@roadar-pipeline-viewer/roadly-typescript/dist/utils/asyncConfigManager'
import { GaussianViewer } from 'components/GaussianViewer'

export const GaussianViewerPage = () => {
  const [searchParams] = useSearchParams()
  const modelPath = searchParams.get('modelPath')
  if (!modelPath) return <div>modelPath must be specified in get params</div>
  const { persistentBucket } = asyncConfigManager.config.bucketNames
  const modelUrl = `https://storage.googleapis.com/${persistentBucket}/${modelPath}`

  const camerasPath = searchParams.get('camerasPath')
  const camerasUrl = camerasPath
    ? `https://storage.googleapis.com/${persistentBucket}/${camerasPath}`
    : modelUrl.split('/').slice(0, -3).concat(['cameras.json']).join('/')
  return <GaussianViewer modelUrl={modelUrl} camerasUrl={camerasUrl} />
}
