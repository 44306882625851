import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EXTERNAL_PATH, PATH } from '../../const/routingConstants';
const HintPage1 = {
    header: () => _jsx(_Fragment, { children: "Download and install the Roadly PRO iPhone app" }),
    content: () => (_jsxs(_Fragment, { children: ["Right now Roadly supports iPhone 11 or later iPhones. You can install the Roadly PRO from", ' ', _jsx("strong", { children: _jsx("a", { href: "https://apps.apple.com/us/app/roadly-pro/id6476778274", target: "_blank", rel: "noreferrer", children: "here" }) }), "."] })),
};
const HintPage2 = {
    header: () => _jsx(_Fragment, { children: "Enter the Roadly PRO app" }),
    content: () => (_jsxs(_Fragment, { children: ["When launching for the first time, please, log in using the same email and password you use for the", ' ', _jsx("strong", { children: _jsx("a", { children: "https://hdm.road.ly" }) })] })),
};
const HintPage3 = {
    header: () => _jsx(_Fragment, { children: "Making your first recording" }),
    content: () => (_jsxs(_Fragment, { children: ["Before making your first recording, please, install your smartphone under your windshield like a dashcam. Use our", ' ', _jsx("strong", { children: _jsx("a", { href: EXTERNAL_PATH.roadLyHelpRoot.path, children: "Tutorial" }) }), ' ', "which will guide you through the app settings, video recording and session upload."] })),
};
const HintPage4 = {
    header: () => _jsx(_Fragment, { children: "Upload the data to the cloud" }),
    content: () => (_jsxs(_Fragment, { children: ["Find a place with good WIFI connection (or you may be the lucky one with a fast and unlimited cell internet). While in the Roadly PRO app, open the menu on the top right corner, you should see the list of completed recordings. Press the upload button, more info is in the", ' ', _jsx("strong", { children: _jsx("a", { href: EXTERNAL_PATH.roadLyHelpRoot.path, children: "Tutorial" }) }), "."] })),
};
const HintPage5 = {
    header: () => _jsx(_Fragment, { children: "Wait for the processing and see the\u00A0results" }),
    content: () => (_jsxs(_Fragment, { children: ["Uploaded sessions will be shown in the Pipeline viewer ", _jsx("strong", { children: _jsx("a", { href: PATH.pipelinesList.path, children: "here" }) }), ". Tracks of the uploaded sessions will be shown on the map - at first you will see the white lines representing your route. After the processing these lines will be colored according to the pavement condition."] })),
};
export const hintPageRenderers = [HintPage1, HintPage2, HintPage3, HintPage4, HintPage5];
