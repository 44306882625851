import { useState } from 'react'
import DeckGL from '@deck.gl/react'
import { COORDINATE_SYSTEM } from '@deck.gl/core'
import { Tile3DLayer, TerrainLayer } from '@deck.gl/geo-layers'
import { PointCloudLayer, PathLayer } from '@deck.gl/layers'
import { GeoJsonLayer } from '@deck.gl/layers'
import { SimpleMeshLayer } from '@deck.gl/mesh-layers'

import { Classes, Button, Dialog } from '@blueprintjs/core'
import ReactSlidy from 'react-slidy'
import 'react-slidy/lib/styles.css'

import { Tiles3DLoader } from '@loaders.gl/3d-tiles'

import { Map as StaticMap, ScaleControl } from 'react-map-gl'
import {
  POINT_CLOUD_TYPE,
  TILE_TYPES,
  TILES_MODULE_OUTPUT_TYPES,
  MAPBOX_OVERLAY_TYPE,
  COMPLETE_VIEWPORT_CONFIG,
  ELEVATION_DATA,
  ELEVATION_DECODER,
  PATH_OPTIONS,
  MAPBOX_ACCESS_TOKEN,
} from 'const'

import { getCursor } from '@roadar-pipeline-viewer/roadly-typescript/dist/utils'
import {
  getCameraPyramidGeometry,
  getCameraColorByVisibilityZone,
} from '@roadar-pipeline-viewer/roadly-typescript/dist/utils/meshes'
import PaletteTile3DLayer from 'components/Map/layers/ColoredTile3DLayer/PaletteTile3DLayer'

import styles from './PipelineItemMap.module.css'

import { getColorBySeverityClass, SAMPLE_UNIT_PALETTE } from 'utils/pci'

function CrackPopover({ info, onClose }) {
  const PCI_POPUP_IMG_WIDTH = window.innerWidth * 0.75
  const PCI_POPUP_MARGIN = 20

  const crack = info.object
  const crackImages = crack?.properties?.images || {}

  const crackId = crack?.properties?.id
  const title = [
    crackId ? crackId.split('_').pop() : '',
    crack?.properties?.estimation?.d_type,
    crack?.properties?.estimation?.severity,
  ].join(' / ')

  const slides = []

  Object.keys(crackImages).forEach(viewType => {
    const images = [...crackImages[viewType]].sort((a, b) => a.path.localeCompare(b.path))
    images.forEach(imgInfo => {
      const url = imgInfo.path.replace('/opt/roadar_data_out', '/data')
      const bbox = imgInfo.bbox
      const fullWidth = 1920
      const fullHeight = 1072

      const imgWidth = PCI_POPUP_IMG_WIDTH
      const imgHeight = PCI_POPUP_IMG_WIDTH * (fullHeight / fullWidth)
      const imgBbox = bbox.map(el => el * (imgWidth / fullWidth))

      slides.push({ viewType, url, bbox, imgBbox, imgWidth, imgHeight })
    })
  })

  const titleColor = getColorBySeverityClass(crack?.properties?.SEVERITY_CLASS, true)

  return (
    <Dialog
      className={`${Classes.OVERLAY_SCROLL_CONTAINER} ${styles.pciPopup}`}
      style={{ width: `${PCI_POPUP_IMG_WIDTH + PCI_POPUP_MARGIN}px` }}
      isOpen={true}
      onClose={onClose}
      usePortal
      canOutsideClickClose
    >
      <div>
        <div className={Classes.DIALOG_HEADER}>
          <h5 className="bp4-heading">
            <span style={{ backgroundColor: titleColor }} className={styles.circle} /> {title}
          </h5>
          <Button icon="cross" className={Classes.DIALOG_CLOSE_BUTTON} onClick={onClose} />
        </div>
        <div className={Classes.DIALOG_BODY}>
          <ReactSlidy>
            {slides.map(s => (
              <div key={s.url}>
                <svg
                  width={s.imgWidth}
                  height={s.imgHeight}
                  viewBox={`0 0 ${s.imgWidth} ${s.imgHeight}`}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <image href={s.url} width={s.imgWidth} height={s.imgHeight} />
                  <rect
                    x={s.imgBbox[0]}
                    y={s.imgBbox[1]}
                    width={s.imgBbox[2]}
                    height={s.imgBbox[3]}
                    stroke="#99ff99"
                    strokeWidth="2"
                    fillOpacity="0"
                  />
                  <text className={styles.svgOuterText} x={20} y={s.imgHeight - 20}>
                    {s.url}
                  </text>
                  <text className={styles.svgText} x={20} y={s.imgHeight - 20}>
                    {s.url}
                  </text>
                </svg>
              </div>
            ))}
          </ReactSlidy>
        </div>
      </div>
    </Dialog>
  )
}

function PipelineItemMap({
  viewport,
  overlay,

  unit,
  track,
  poses,
  pciData,
  isPCICrackVisible,
  selectedPCISeverityClasses,

  cameraLocalization,

  pointCloud,
  pointSize,
  posesStepSize,

  hasPath,

  isPathVisible,
  isOverlayShow,
  isPointCloudVisible,

  pathCurrent,
  tilesCurrent,
  semanticFilterClasses,

  onPosesClick,
  fps,

  isSmall,
  videoPoses,
}) {
  const [hoveredPose, setHoveredPose] = useState(null)
  const [pickedPose, setPickedPose] = useState(null)
  const [pickedPCICrackInfo, setPickedPCICrackInfo] = useState(null)
  const [hoveredPCISegmentInfo, setHoveredPCISegmentInfo] = useState(null)

  const onClickDeckGL = info => {
    if (!info.layer || (info.layer && info.layer.id !== 'track-poses-points')) {
      setPickedPose(null)
    }
  }

  const onHoverPoses = info => {
    if (info.layer && info.index && info.index !== -1) {
      const poses = info.layer.props.data[info.index]
      setHoveredPose(poses)
    } else if (hoveredPose) {
      setHoveredPose(null)
    }
  }

  const onClickPoses = info => {
    if (info.layer && info.index && info.index !== -1) {
      const poses = info.layer.props.data[info.index]
      const posesTime = poses.time / fps
      setPickedPose(poses)
      onPosesClick(posesTime)
    }
  }

  const onClickPCIPoly = info => {
    setPickedPCICrackInfo(info)
  }

  const onHoverPCISegment = info => {
    setHoveredPCISegmentInfo(info)
  }

  const pciIndexData = !pciData ? null : pciData.features.filter(f => f?.properties?.id.indexOf('sample_unit') === 0)
  const pciCracksData = !pciData
    ? null
    : pciData.features.filter(
        f => f?.properties?.id.indexOf('crack') === 0 && selectedPCISeverityClasses[f?.properties?.SEVERITY_CLASS]
      )

  const hasCameraLocalization = !!cameraLocalization
  const cameraLocalizationData = cameraLocalization?.features
  const camLocPoint = !cameraLocalizationData
    ? null
    : cameraLocalizationData.find(f => f.geometry.type === 'Point' && f.properties?.pitch)
  const camLocPointData = !camLocPoint
    ? null
    : {
        position: camLocPoint.geometry.coordinates,
        angle: [-camLocPoint.properties.pitch, -90 - camLocPoint.properties.yaw, 0],
        color: [255, 0, 255],
      }

  const getCamLocPyramidMesh = distanceName => {
    if (!camLocPoint) return null
    return getCameraPyramidGeometry(
      camLocPoint.properties.fov_x,
      camLocPoint.properties.fov_y,
      camLocPoint.properties[distanceName]
    )
  }

  const hasValidMesh = poses && poses[0] && !isNaN(poses[0]['euler'][0])

  let palette = tilesCurrent.palette;
  if (tilesCurrent.outputType === TILES_MODULE_OUTPUT_TYPES.SEMANTIC && semanticFilterClasses) {
    const semMask = Array(semanticFilterClasses.length*3).fill(-1).map(
        (v, i) => semanticFilterClasses[i%semanticFilterClasses.length]);
    palette = palette.map((v, i) => (semMask[i] === 0) ? -1 : v);
  }

  const getSemClassTooltip = obj => {
    if (!obj.color || (tilesCurrent.outputType !== TILES_MODULE_OUTPUT_TYPES.SEMANTIC))
      return null;
    const idx = obj.color[0]; // not real color but pickingColor from vertex shader
    return tilesCurrent.legend[idx];
  }

  return (
    <div className={isSmall ? styles.map : styles.smallMap}>
      <DeckGL
        initialViewState={{ ...COMPLETE_VIEWPORT_CONFIG, ...viewport }}
        controller={{ touchRotate: true }}
        pickingRadius={4}
        onClick={onClickDeckGL}
        getCursor={getCursor}
        getTooltip={getSemClassTooltip}
      >
        {isOverlayShow && overlay.type === MAPBOX_OVERLAY_TYPE.FLAT ? (
          <StaticMap
            initialViewState={{ ...COMPLETE_VIEWPORT_CONFIG, ...viewport }}
            mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
            mapStyle={overlay.style}
          >
            <ScaleControl unit={unit} position="bottom-right" />
          </StaticMap>
        ) : null}

        {isOverlayShow && overlay.type === MAPBOX_OVERLAY_TYPE.TERRAIN ? (
          <TerrainLayer
            id="terrain"
            minZoom={0}
            maxZoom={23}
            opacity={1}
            elevationData={ELEVATION_DATA}
            elevationDecoder={ELEVATION_DECODER}
            texture={overlay.style}
          />
        ) : null}

        {hasPath && isPathVisible && pathCurrent.value === PATH_OPTIONS.GPS.value ? (
          <PathLayer
            id="track-gps"
            data={[{ path: track.map(([lat, lng]) => [lat, lng, 0]) }]}
            pickable={false}
            getPath={d => d.path}
            getColor={[255, 0, 0]}
            getWidth={0.5}
            parameters={{
              depthTest: false,
            }}
          />
        ) : null}

        {hasPath && isPathVisible && pathCurrent.value === PATH_OPTIONS.POSES.value ? (
          <>
            <PointCloudLayer
              id="track-poses-points"
              data={poses}
              pointSize={0.8}
              coordinateSystem={COORDINATE_SYSTEM.LNGLAT}
              pickable
              material
              getPosition={({ position: [lat, lng, alt] }) => [lat, lng, alt]}
              getColor={[255, 255, 191]}
              onHover={onHoverPoses}
              onClick={onClickPoses}
            />
            {hasValidMesh ? (
              <SimpleMeshLayer
                id="track-poses-mesh"
                wireframe
                data={poses}
                mesh={getCameraPyramidGeometry(90, 90, 2)}
                material={false}
                getOrientation={d => d.euler}
                getPosition={({ position: [lat, lng, alt] }) => [lat, lng, alt]}
                getColor={d => {
                  if (hoveredPose && hoveredPose.position === d.position) {
                    return [215, 48, 39]
                  }
                  if (pickedPose && pickedPose.position === d.position) {
                    return [100, 0, 100]
                  }
                  if (videoPoses && videoPoses.position === d.position) {
                    return [0, 0, 100]
                  }
                  return [0, 100, 0]
                }}
                updateTriggers={{
                  getColor: [hoveredPose, pickedPose, videoPoses],
                }}
              />
            ) : null}
          </>
        ) : null}
        {hasCameraLocalization ? (
          <>
            <GeoJsonLayer
              id="cam-loc"
              data={cameraLocalizationData}
              lineWidth={1}
              lineWidthUnits="pixels"
              getFillColor={d => {
                if (d.geometry.type === 'Point') return [0, 0, 255, 255]
                const vZone = d.properties?.visibility_zone !== undefined ? d.properties.visibility_zone : -1
                return getCameraColorByVisibilityZone(vZone, 180)
              }}
              getLineColor={() => [10, 10, 10, 255]}
              pointType="circle"
              getPointRadius={3}
              pointRadiusUnits="pixels"
              filled={true}
            />
          </>
        ) : null}
        {camLocPointData ? (
          <>
            <SimpleMeshLayer
              id="cam-loc-pyramid-2"
              data={[camLocPointData]}
              mesh={getCamLocPyramidMesh('identification_distance')}
              getPosition={d => d.position}
              getColor={d => getCameraColorByVisibilityZone('identification', 180)}
              getOrientation={d => d.angle}
            />
            <SimpleMeshLayer
              id="cam-loc-pyramid-1"
              data={[camLocPointData]}
              mesh={getCamLocPyramidMesh('detection_distance')}
              getPosition={d => d.position}
              getColor={d => getCameraColorByVisibilityZone('detection', 180)}
              getOrientation={d => d.angle}
            />
            <SimpleMeshLayer
              id="cam-loc-pyramid-0"
              data={[camLocPointData]}
              mesh={getCamLocPyramidMesh('recognition_distance')}
              getPosition={d => d.position}
              getColor={d => getCameraColorByVisibilityZone('recognition', 180)}
              getOrientation={d => d.angle}
            />
          </>
        ) : null}
        {pciData ? (
          <>
            <GeoJsonLayer
              id="pci-index"
              data={pciIndexData}
              getLineColor={d => {
                const pciValue = d.properties?.pci
                if (pciValue === undefined) return [0, 0, 0, 255]
                const idx = Math.floor(pciValue / 10)
                return SAMPLE_UNIT_PALETTE[idx]
              }}
              lineWidth={3}
              lineWidthUnits="meters"
              pickable
              onHover={onHoverPCISegment}
            />
            {isPCICrackVisible ? (
              <>
                <GeoJsonLayer
                  id="pci-cracks"
                  data={pciCracksData}
                  getLineColor={d => getColorBySeverityClass(d?.properties?.SEVERITY_CLASS)}
                  getFillColor={d => {
                    const color = getColorBySeverityClass(d?.properties?.SEVERITY_CLASS)
                    color[3] = 125
                    return color
                  }}
                  lineWidthUnits="pixels"
                  pickable
                  onClick={onClickPCIPoly}
                />
                {pickedPCICrackInfo ? (
                  <CrackPopover info={pickedPCICrackInfo} onClose={() => setPickedPCICrackInfo(null)} />
                ) : null}
              </>
            ) : null}
            {hoveredPCISegmentInfo && hoveredPCISegmentInfo.object ? (
              <div
                className={styles.pciTooltip}
                style={{ left: hoveredPCISegmentInfo.x, top: hoveredPCISegmentInfo.y }}
              >
                PCI: {hoveredPCISegmentInfo.object.properties.pci.toFixed(2)} (
                {hoveredPCISegmentInfo.object.properties.rating})
              </div>
            ) : null}
            }
          </>
        ) : null}

        {isPointCloudVisible &&
        pointCloud.type === POINT_CLOUD_TYPE.TILES &&
        tilesCurrent.type === TILE_TYPES.PALETTE_TILE ? (
          <PaletteTile3DLayer
            id="semantic-tile-3d-layer"
            pointSize={pointSize}
            data={pointCloud.tiles[TILES_MODULE_OUTPUT_TYPES.SEMANTIC]}
            loader={Tiles3DLoader}
            palette={palette}
            pickable
            material={false}
          />
        ) : null}

        {isPointCloudVisible && pointCloud.type === POINT_CLOUD_TYPE.TILES && tilesCurrent.type === TILE_TYPES.TILE ? (
          <Tile3DLayer
            id="natural-tile-3d-layer"
            pointSize={pointSize}
            data={pointCloud.tiles[TILES_MODULE_OUTPUT_TYPES.RAW]}
            loader={Tiles3DLoader}
          />
        ) : null}

        {isPointCloudVisible && pointCloud.type === POINT_CLOUD_TYPE.XYZ ? (
          <PointCloudLayer
            id="point-cloud-xyz"
            data={pointCloud.points}
            pointSize={pointSize}
            coordinateSystem={COORDINATE_SYSTEM.LNGLAT}
            pickable={false}
            material={false}
            getPosition={({ position }) => position}
            getColor={({ color }) => color}
          />
        ) : null}
      </DeckGL>
    </div>
  )
}

export default PipelineItemMap
