import lodashMemoize from 'lodash/memoize';
/**
 * Configuration of lodash memoize here
 * For more hardcore caching implementations follow this link:
 * https://javascript.plainenglish.io/advanced-memoization-for-javascript-functions-with-lodash-memoize-12677b07e9bc
 */
/* Function to construct cache key */
const resolver = (...args) => JSON.stringify(args);
/**
 * Common memoization function. Uses stringified args as key.
 * May be quite unproductive for big object/array arguments
 */
export const memoize = (functionToMemoize) => lodashMemoize(functionToMemoize, resolver);
/**
 * Uses only first argument as key,
 * used for saving resources
 * Todo: autoClearOnRewrite
 */
export const memoizeByFirstArg = (functionToMemoize) => lodashMemoize(functionToMemoize);
