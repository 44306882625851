import { useState } from 'react';
import { API } from '../services/ApiService';
import { LoadingStatus } from '../const/appConstants';
import { useProfile } from './useProfile';
export const useLogin = () => {
    const { profile, updateProfile } = useProfile();
    const [loginLoadingStatus, setLoginLoadingStatus] = useState(LoadingStatus.INITIAL);
    const login = async (loginParams) => {
        setLoginLoadingStatus(LoadingStatus.LOADING);
        const data = await API.login(loginParams);
        updateProfile(data);
        setLoginLoadingStatus(LoadingStatus.SUCCESS);
    };
    const logout = async () => {
        const data = await API.logout();
        updateProfile(data);
    };
    return {
        profile,
        login,
        logout,
        loginLoadingStatus,
    };
};
