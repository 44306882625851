import { Button, Alignment } from '@blueprintjs/core'
import { API } from '@roadar-pipeline-viewer/roadly-typescript/dist/services/ApiService'
import { useEffect, useState } from 'react'
import { Select2 } from '@blueprintjs/select'
import { MenuItem2 } from '@blueprintjs/popover2'
// import style from './reconstructionselect.module.css'

export function ReconstructionSelect({ reconstructionName, onItemSelect }) {
  const [reconstructions, setReconstructions] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    API.getReconstructions()
      .then(items => {
        items.unshift('autodetect')
        setReconstructions(items)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [])

  const activeItem = reconstructionName ? reconstructionName : reconstructions[0]

  return (
    <Select2
      fill
      disabled={isLoading || reconstructions.length === 0}
      items={reconstructions}
      activeItem={activeItem}
      onItemSelect={value => onItemSelect(value === 'autodetect' ? null : value)}
      noResults={'No results'}
      itemRenderer={(name, { handleClick, handleFocus, modifiers }) => (
        <MenuItem2
          text={name}
          roleStructure="listoption"
          active={modifiers.active}
          key={name}
          onClick={handleClick}
          onFocus={handleFocus}
        />
      )}
    >
      <Button fill loading={isLoading} alignText={Alignment.LEFT} rightIcon={'chevron-down'}>
        {activeItem}
      </Button>
    </Select2>
  )
}
