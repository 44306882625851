import { Button, Alignment, FormGroup } from '@blueprintjs/core'
import { Select2 } from '@blueprintjs/select'
import { MenuItem2 } from '@blueprintjs/popover2'
import style from './FilterByUsers.module.scss'

export const FilterByUsers = ({ items, isLoading, value, onItemSelect, onItemPredicate }) => {
  const onItemRender = ({ email, description }, { handleClick, handleFocus, modifiers }) => (
    <MenuItem2
      text={email}
      label={description?.length >= 80 ? description.slice(0, 80) + '...' : description}
      roleStructure="menuitem"
      active={modifiers.active}
      key={email}
      onClick={handleClick}
      onFocus={handleFocus}
    />
  )

  return (
    <FormGroup label="Email" labelFor="users_email">
      <Select2
        fill
        disabled={isLoading}
        id="users_email"
        items={items}
        popoverContentProps={{
          className: style.selectorPopoverContent,
        }}
        activeItem={value}
        onItemSelect={onItemSelect}
        noResults={'No results'}
        itemPredicate={onItemPredicate}
        itemRenderer={onItemRender}
      >
        <Button className={style.select} loading={isLoading} alignText={Alignment.LEFT} rightIcon={'chevron-down'}>
          {value ? value.email : 'Select user'}
        </Button>
      </Select2>
    </FormGroup>
  )
}
