export const getPipelineProcessingTimings = (pipelineState, 
// Pipeline is for handling cancelled state when we know
// cancelling time on pipeline level (in update time property)
// but dont know on job level (having only start time for cancelled jobs)
{ updated: pipelineUpdated, status: pipelineStatus }) => {
    let pipelineStartDate;
    let pipelineEndDate;
    let pipelineLengthMilliseconds = 0;
    pipelineState.forEach(({ ts_start, ts_end, status }) => {
        if (!ts_start) {
            return;
        }
        let resultEndDate;
        if (ts_end !== null) {
            resultEndDate = ts_end; // In case of failed pipeline ts_end is date of failing
        }
        else if (status === 'running') { // In this case ts_end is null
            if (pipelineStatus === 'canceled') { // ! canceled - not cancelled with double l!
                resultEndDate = pipelineUpdated;
            }
            else {
                resultEndDate = new Date();
            }
        }
        else {
            throw new Error('Unexpected state during job timing calculation');
        }
        // Common part:
        const difference = ts_start.getTime() - resultEndDate.getTime();
        pipelineLengthMilliseconds += difference;
        if (!(pipelineStartDate < ts_start)) {
            pipelineStartDate = ts_start;
        }
        if (ts_end !== null && !(pipelineStartDate < ts_start)) {
            pipelineEndDate = ts_end;
        }
    });
    return {
        pipelineLengthMilliseconds,
        // @ts-ignore todo
        pipelineStartDate,
        // @ts-ignore todo
        pipelineEndDate,
    };
};
