import React, { memo } from 'react'
import { Card, Elevation, FormGroup, Switch } from '@blueprintjs/core'
import style from './SessionsListHeader.module.scss'
import { FilterByUsersContainer } from 'components/FilterByUsers'
import { DatePicker } from '@roadar-pipeline-viewer/roadly-typescript/dist/components/DatePicker/DatePicker'
import { FilterByOrganizationsContainer } from '../../../components/FilterByOrganizations'
import { SESSIONS_LIST_FILTERS } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/sessionsConstants'
import { getQueryString } from '@roadar-pipeline-viewer/roadly-typescript/dist/utils/utils'
import { useLocation, useNavigate } from 'react-router-dom'

function SessionsListHeaderComponent({
  owner,
  start_time_from,
  start_time_to,
  organization_id,
  is_broken,
  is_invalid,
  searchParams,
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const HandleDate = (date, time, isDateFrom) => {
    let dateAndTimeFrom = null
    if (date) {
      dateAndTimeFrom = `${date} `
    }
    const searchParamsWithSkip = { ...searchParams, skip: SESSIONS_LIST_FILTERS.DEFAULT_SKIP }
    if (dateAndTimeFrom) {
      if (time) {
        dateAndTimeFrom += `${time}:00`
      } else {
        dateAndTimeFrom += isDateFrom ? '00:00:00' : '23:59:59'
      }
    }
    let newParams
    newParams = isDateFrom ? { start_time_from: dateAndTimeFrom } : { start_time_to: dateAndTimeFrom }
    const query = getQueryString(searchParamsWithSkip, newParams)
    navigate({
      ...location,
      search: `?${query}`,
    })
  }
  const HandleDateFrom = (date, time) => {
    HandleDate(date, time, true)
  }

  const HandleDateTo = (date, time) => {
    HandleDate(date, time, false)
  }

  return (
    <div className={style.header}>
      <Card className={style.filters} elevation={Elevation.ZERO}>
        <div className={style.filterItems}>
          <div className={style.col}>
            <div className={style.formItem}>
              <FilterByOrganizationsContainer
                organization_id={organization_id}
                searchParams={searchParams}
              ></FilterByOrganizationsContainer>
            </div>
          </div>
          <div className={style.col}>
            <div className={style.formItem}>
              <FilterByUsersContainer owner={owner} searchParams={searchParams}></FilterByUsersContainer>
            </div>
          </div>
          <DatePicker
            titleDate={'Date from'}
            titleTime={'Time from (UTC)'}
            onHandle={HandleDateFrom}
            initialDate={start_time_from}
          />
          <DatePicker
            titleDate={'Date to'}
            titleTime={'Time to (UTC)'}
            onHandle={HandleDateTo}
            initialDate={start_time_to}
          />
          <div className={style.flag}>
            <FormGroup label="Is broken">
              <Switch
                checked={is_broken}
                onChange={() => {
                  searchParams.skip = 0
                  const query = getQueryString(searchParams, { is_broken: !is_broken })
                  navigate({
                    ...location,
                    search: `?${query}`,
                  })
                }}
              />
            </FormGroup>
          </div>
          <div className={style.flag}>
            <FormGroup label="Is invalid">
              <Switch
                checked={is_invalid}
                onChange={() => {
                  searchParams.skip = 0
                  const query = getQueryString(searchParams, { is_invalid: !is_invalid })
                  navigate({
                    ...location,
                    search: `?${query}`,
                  })
                }}
              />
            </FormGroup>
          </div>
        </div>
      </Card>
    </div>
  )
}

export const SessionsListHeader = memo(SessionsListHeaderComponent)
