import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import style from './DatePicker.module.css';
export const DatePicker = ({ titleDate, titleTime, onHandle, initialDate }) => {
    const [date, setDate] = useState(initialDate ? initialDate.slice(0, 10) : null);
    const [time, setTime] = useState(initialDate ? initialDate.slice(11, 19) : null);
    useEffect(() => {
        if (!date) {
            setTime('');
        }
        onHandle(date, time);
    }, [date, time]);
    return (_jsxs("div", { className: style.container, children: [_jsxs("div", { className: style.pickerContainer, children: [_jsx("p", { className: style.label, children: titleDate }), _jsx("input", { className: style.picker, type: "date", id: "date", value: date || '', onChange: (event) => setDate(event.target.value) })] }), _jsxs("div", { className: style.pickerContainer, children: [_jsx("p", { className: style.label, children: titleTime }), _jsx("input", { className: style.picker, type: "time", id: "time", value: time || '', onChange: (event) => setTime(event.target.value) })] })] }));
};
