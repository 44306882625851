export const getQueryString = (searchParams, newParams) => {
    const params = Object.entries(searchParams)
        .filter(([_key, value]) => {
        if (Array.isArray(value)) {
            return false;
        }
        return !!value;
    })
        .reduce((memo, [key, value]) => ({ ...memo, [key]: value }), {});
    const urlSearchParams = new URLSearchParams(params);
    Object.entries(newParams).forEach(([key, value]) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const [k, val] of Object.entries(searchParams)) {
            if (Array.isArray(val) && key !== k) {
                val.map((v) => urlSearchParams.append(k, v));
            }
        }
        if (Array.isArray(value)) {
            value.map((v) => urlSearchParams.append(key, v));
        }
        else {
            urlSearchParams.set(key, String(value));
        }
    });
    return urlSearchParams.toString();
};
