import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LoadingOverlay, StoredViewportsListContainer, GettingStartedHintContainer, } from '../../../components';
import { PciDashboardContainer } from '../PciDashboard';
import { PciDataTypeFilters } from '../PciDataTypeFilters';
import { PciSeverityFilters } from '../PciSeverityFilters';
import { PciMenuContainer } from '../../pciMapTools/PciMenu';
import { PciViewerModal } from '../FSViewer/PciViewerModal';
import { useProfile } from '../../../hooks/useProfile';
import { navigateRoundArray } from '../../../utils/navigateRoundArray';
import { fetchAndApplyPciData, isSideResult } from './fetchAndApplyPciData';
import styles from './PciRegistry.module.scss';
import { pciFeatureProcessingStatuses, } from '../../../const/pciConstants';
import { DEMO_VIEWPORT_CONFIG, WALTHAM_VIEWPORT_CONFIG, WORLD_VIEWPORT_CONFIG } from '../../../const/mapConstants';
import { PreferredMap } from '../../RoadLyMap/PreferredMap';
import { capitalizeString } from '../../../utils';
import { FloatingContainer } from '../../../components/FloatingContainer';
import { filterByStepAndIndex } from '../../../utils/array.utilities/filterByStepAndIndex/filterByStepAndIndex';
import { useLoadedViewports } from '../../../hooks/useLoadedViewports';
import { useOuterPciViewerQaProps } from '../hooks/useOuterPciViewerQaProps';
import { DemoUserPciPanelContainer } from '../../pciMapTools/DemoUserPciPanel';
import { StickyContainer } from '../../../components/StickyContainer/StickyContainer';
import { LOCAL_STORAGE_KEYS } from '../../../const/localStorageConstants';
import { stableOrganizationIds } from '../../../const/stableOrganizationIds';
import { isStableEnv } from '../../../utils/isStableEnv';
import { usePciFeatureFilters } from '../../../hooks/pci.hooks/usePciFeatureFilters';
import { usePciData } from '../../../hooks/pci.hooks/usePciData';
export const PciRegistryFeature = ({ initialSelectedFrameSegmentId }) => {
    const [searchParams] = useSearchParams();
    const pipelineId = searchParams.get('pipelineId');
    /* Page level logic above */
    const { profile } = useProfile();
    const { allSampleUnitsData, allViewpointsData, allCoverageData, setAllSampleUnitsData, supplementAllSampleUnitsData, setAllViewpointsData, supplementAllViewpointsData, setAllCoverageData, isInitialDataState, } = usePciData();
    // eslint-disable-next-line no-nested-ternary
    const initialViewportConfig = profile.isDemoUser
        ? DEMO_VIEWPORT_CONFIG
        : isStableEnv() && profile.organization_id === stableOrganizationIds.waltham
            ? WALTHAM_VIEWPORT_CONFIG
            : WORLD_VIEWPORT_CONFIG;
    /*
    const initialViewportConfig = useMemo(() => {
      if (isInitialDataState) {
        // This has to be never shown, since map is not shown until data is loaded
        return WORLD_VIEWPORT_CONFIG;
      }
  
      const viewportConfigBasedOnPciData = getViewportConfigFromFeaturesLists([
        allViewpointsData?.features || [],
        allSampleUnitsData?.features || [],
      ]);
  
      if (viewportConfigBasedOnPciData) {
        return viewportConfigBasedOnPciData;
      }
  
      // If there is no data retrieved
      // try to show default viewport of user taken from server
      if (!profile.defaultViewportConfig) {
        return profile.defaultViewportConfig;
      }
  
      // If user has no default viewport, return default viewport app-wise
      return WORLD_VIEWPORT_CONFIG;
    }, [
      // allSampleUnitsData?.features,
      // allViewpointsData?.features,
      isInitialDataState,
      profile.defaultViewportConfig,
    ]);
    */
    // Need to wait until first pci loading
    // What is order of assigning config
    // There is viewport config assigned automatically
    // - From PCI map data
    // - default config from profile (this is initial)
    // -
    // And there is one assigned in hand mode
    //
    const [lastActualViewport, setLastActualViewport] = useState(null);
    const userViewportConfig = lastActualViewport || initialViewportConfig;
    const [frameSegmentIdsSequence, setFrameSegmentIdsSequence] = useState([]);
    const [polygonsBeingLoaded, setPolygonsBeingLoaded] = useState({ type: 'Polygon', coordinates: [] });
    /**
     * Set up fetchers on start and
     * on panning/zooming action end
     */
    // isLoadedAfterNavigation === true means it is now NOT LOADING STATE
    // When a user did some navigation and loading is in progress then it is falsy
    const [isLoadedAfterNavigation, setIsLoadedAfterNavigation] = useState(true);
    const { updateAndGetPolygon } = useLoadedViewports();
    const fetchAndApplyEffect = async (zoom, boundingBox) => {
        var _a;
        if (zoom < 5) {
            return;
        }
        let finalBoundingBox = boundingBox;
        // What are cases when bounding box is not passed?
        if (boundingBox) {
            const multiPolygonToLoad = updateAndGetPolygon([boundingBox]);
            if (multiPolygonToLoad.length === 0) {
                // Skip loading if nothing to load
                return;
            }
            [[finalBoundingBox]] = multiPolygonToLoad;
            setPolygonsBeingLoaded({
                type: 'Polygon',
                coordinates: [finalBoundingBox],
            });
        }
        setIsLoadedAfterNavigation(false);
        const result = await fetchAndApplyPciData({
            zoom,
            pipelineId,
            profile,
            setAllViewpointsData: supplementAllViewpointsData,
            setAllSampleUnitsData: supplementAllSampleUnitsData,
            boundingBox: finalBoundingBox,
        });
        setIsLoadedAfterNavigation(true);
        if (isSideResult(result)) {
            return;
        }
        const { viewpointsFeatureCollection } = result;
        // Todo: later get sequence according to what street (or
        //  something else) the viewpoint belongs to:
        // Todo: also wipe out sequence from
        //  PciMenu / export when it is ready
        // @ts-ignore
        const newTmpAllViewpointsSequence = ((_a = viewpointsFeatureCollection.features) !== null && _a !== void 0 ? _a : []).map((el) => el.properties.id);
        const tmpAllViewpointsSequence = ([
            ...frameSegmentIdsSequence,
            ...newTmpAllViewpointsSequence,
        ]).sort();
        //   .filter((el, index) => index % 4 === 0);
        setFrameSegmentIdsSequence(tmpAllViewpointsSequence);
    };
    // Also works on start, same behavior as useEffect
    const onNavigationEnd = (newViewportParams, boundingBox) => {
        if (profile.isStaticUser && !isInitialDataState) {
            // Static data is fetched in the beginning
            // and has not to be supplemented during navigation
            return;
        }
        fetchAndApplyEffect(newViewportParams.zoom, boundingBox);
    };
    const { filteredSampleUnitsData, filteredViewpointsData, filteredCoverageData, viewpointsFilters, sampleUnitsFilters, isCoverageModeOn, pciViewModeKey, invalidPciVisibilityFilters, setInvalidPciVisibilityFilters, handleChangePciSeverityFilters, handleClickPciViewModeKey, handleClickCoverageMap, } = usePciFeatureFilters({ allSampleUnitsData, allViewpointsData, allCoverageData });
    /**
     * Pci viewer stuff
     */
    const [selectedFrameSegmentId, setSelectedFrameSegmentId] = useState(initialSelectedFrameSegmentId);
    // Todo: replace any with proper type:
    const onClickMap = (info) => {
        var _a, _b;
        if (((_b = (_a = info.object) === null || _a === void 0 ? void 0 : _a.geometry) === null || _b === void 0 ? void 0 : _b.type) !== 'Point') {
            return;
        }
        setSelectedFrameSegmentId(info.object.properties.id);
    };
    const handlePciViewerClose = () => setSelectedFrameSegmentId(null);
    const storedStepSize = localStorage.getItem(LOCAL_STORAGE_KEYS.PCI_NAVIGATION_STEP);
    const navigationStepSize = storedStepSize === null ? 4 : Number(storedStepSize);
    const handlePciViewerNavigate = (direction) => {
        if (!selectedFrameSegmentId) {
            throw new Error('There is no current viewpoint');
        }
        // Todo: this has to be done in single place (woriqw):
        const effectiveFrameSegmentIdsSequence = filterByStepAndIndex({
            array: frameSegmentIdsSequence,
            element: selectedFrameSegmentId,
            stepSize: navigationStepSize,
        });
        const currentIndex = effectiveFrameSegmentIdsSequence.indexOf(selectedFrameSegmentId);
        const indexOfNewSelectedFrameSegmentId = navigateRoundArray({
            arrayLength: effectiveFrameSegmentIdsSequence.length,
            currentIndex,
            direction,
        });
        const newFrameSegmentId = effectiveFrameSegmentIdsSequence[indexOfNewSelectedFrameSegmentId];
        setSelectedFrameSegmentId(newFrameSegmentId);
    };
    /**
     * Stored viewports props
     */
    const handleSelectViewport = (newViewport) => {
        setLastActualViewport({
            ...lastActualViewport,
            ...newViewport,
        });
    };
    /**
     * Common part
     */
    // const showBadPciAboveProps = useShowBadPciAbove();
    const showBadPciAbove = true;
    const miniPciIndexConfig = {
        // showBadPciAbove: showBadPciAboveProps.showBadPciAbove,
        showBadPciAbove,
        viewpointsData: filteredViewpointsData,
        sampleUnitsData: filteredSampleUnitsData,
        coverageData: filteredCoverageData,
        getTooltip: ({ object }) => {
            if (!object) {
                return null;
            }
            let text = '';
            if (object.properties.status === pciFeatureProcessingStatuses.invalid) {
                text = object.properties.invalid_desc || 'Description not passed';
            }
            else {
                text = `${capitalizeString(object.properties.stats.pciSeverityName)} / ${object.properties.stats.pci.toFixed(0)}`;
            }
            return {
                text,
                style: {
                    marginLeft: '10px',
                },
            };
        },
    };
    const mapConfig = {
        onClickMap,
        onNavigationEnd,
        userViewportConfig,
        miniPciIndexConfig,
    };
    /**
     * PCI QA stuff
     */
    const outerPciViewerQaProps = useOuterPciViewerQaProps({ pipelineId, selectedFrameSegmentId });
    return (_jsxs("div", { className: styles.miniPciContainer, children: [_jsxs(StickyContainer, { position: "topRight", stickContent: "topRight", isPadded: true, children: [profile.isDemoUser ? (_jsx(DemoUserPciPanelContainer, {})) : null, _jsx(StoredViewportsListContainer, { activateViewport: handleSelectViewport }), _jsx(PciMenuContainer, {})] }), _jsx(GettingStartedHintContainer, { className: styles.gettingStartedHintContainer }), selectedFrameSegmentId && (_jsx(PciViewerModal, { frameSegmentId: selectedFrameSegmentId, onClose: handlePciViewerClose, onNavigate: handlePciViewerNavigate, navigationStepSize: navigationStepSize, isFrameSegmentIdsSequenceLoaded: isLoadedAfterNavigation, frameSegmentIdsSequence: frameSegmentIdsSequence, outerPciViewerQaProps: outerPciViewerQaProps })), _jsx(PciDashboardContainer, { className: styles.pciDashboardContainer, tmpViewpointsData: allViewpointsData }), _jsx(PciSeverityFilters, { className: styles.pciSeverityFilters, onChangePciSeverityFilters: handleChangePciSeverityFilters, sampleUnitsFilters: sampleUnitsFilters, viewpointsFilters: viewpointsFilters, pciViewModeKey: pciViewModeKey, onChangeInvalidPciVisibilityFilters: setInvalidPciVisibilityFilters, invalidPciVisibilityFilters: invalidPciVisibilityFilters }), _jsx(PciDataTypeFilters, { onClickPciViewModeKey: handleClickPciViewModeKey, onClickCoverageMap: handleClickCoverageMap, className: styles.pciDataTypeFilters, isCoverageMapOn: isCoverageModeOn, pciViewModeKey: pciViewModeKey }), isLoadedAfterNavigation ? null : (_jsx(FloatingContainer, { className: styles.loadingContainer, children: _jsx(LoadingOverlay, {}) })), _jsx(PreferredMap, { className: styles.map, ...mapConfig })] }));
};
