import { SERVICE_WORKER_MESSAGE } from 'const/serviceWorkerConstants'

const wait = ms =>
  new Promise(resolve =>
    setTimeout(() => {
      console.log('Race timeout reached')
      resolve()
    }, ms)
  )

const waitForControllerChange = () =>
  new Promise(
    resolve =>
      (navigator.serviceWorker.oncontrollerchange = () => {
        console.log('Controller change resolved')
        resolve()
      })
  )

const unregisterAllServiceWorkers = () => {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister()
    }
  })
}

export const startServiceWorker = async ({ isCloudStorageContour, initialToken }) => {
  // Temporarily only unregister existing workers, do not
  // register new ones until HDMP-1179 is finished:
  unregisterAllServiceWorkers()
  return

  // Todo: make sure url length does not exceed max url string when long initial token is passed
  await navigator.serviceWorker.register(
    `/service-worker.js?isCloudStorageContour=${isCloudStorageContour}&initialToken=${initialToken}`
  )

  // Await for when new sw is ready in order
  // not to fetch further resources within old sw
  await Promise.race([wait(1000), waitForControllerChange()])

  const ready = await navigator.serviceWorker.ready

  /**
   * Assign fast controls to operate on service worker
   */
  window.swController = {
    getConfig: () => ready.active.postMessage({ type: SERVICE_WORKER_MESSAGE.GET_CONFIG }),
    updateToken: () => ready.active.postMessage({ type: SERVICE_WORKER_MESSAGE.UPDATE_TOKEN }),
    setToken: newToken => ready.active.postMessage({ type: SERVICE_WORKER_MESSAGE.SET_TOKEN, payload: { newToken } }),

    switchDebug: newIsOn =>
      ready.active.postMessage({
        type: SERVICE_WORKER_MESSAGE.SWITCH_DEBUG_MODE,
        payload: { newIsOn },
      }),

    switchInterceptor: newIsOn =>
      ready.active.postMessage({
        type: SERVICE_WORKER_MESSAGE.SWITCH_ON_OFF_MODE,
        payload: { newIsOn },
      }),

    setConfig: newConfig =>
      ready.active.postMessage({ type: SERVICE_WORKER_MESSAGE.SET_CONFIG, payload: { newConfig } }),
  }
}
