import { StorageType } from '../../const/appConstants';
const initialValuePlaceholder = {};
/**
 * Class to store and provide asynchronously assigned configuration.
 * Some code may ask for configurableNormalizer.getConfig and await
 * while configuration is ready, then it will resolve the config.
 */
export class AsyncConfigManager {
    constructor() {
        /**
         * This is config to store config values.
         * Do not modify it directly, only via configureBuckets-like methods
         * Also, do not read it directly, only via async getConfig method
         */
        this.config = {
            storageType: initialValuePlaceholder,
            bucketNames: initialValuePlaceholder,
        };
        this.isConfigured = false;
        const $this = this;
        this.configurationPromise = new Promise((resolve, reject) => {
            $this.resolve = resolve;
            $this.reject = reject;
        });
    }
    configureStorage({ bucketNames = {}, storageType }) {
        this.config.storageType = storageType;
        // @ts-ignore
        this.config.bucketNames = storageType === StorageType.GOOGLE_STORAGE ? bucketNames : null;
        this.checkIfIsConfigurationFinished();
    }
    /**
     * This function has to run after every config update
     * it checks if configuration is completed and resolves initial promise
     */
    checkIfIsConfigurationFinished() {
        const isNotYetConfigured = Object
            .values(this.config)
            .some((el) => el === initialValuePlaceholder);
        if (isNotYetConfigured) {
            return;
        }
        this.isConfigured = true;
        // @ts-ignore
        this.resolve(null);
    }
    async getConfig() {
        if (this.isConfigured) {
            return this.config;
        }
        await this.configurationPromise;
        return this.config;
    }
}
export const asyncConfigManager = new AsyncConfigManager();
