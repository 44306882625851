import { useState } from 'react'
import {
  Button,
  Checkbox,
  Drawer,
  Position,
  DrawerSize,
  Collapse,
  Tag,
  Tooltip,
  Intent,
  Icon,
  Classes,
  Slider,
  Switch,
  Callout,
} from '@blueprintjs/core'
import cx from 'classnames'

import { handleDownload } from 'utils'
import utils from 'utils/utils'
import JsonViewer from 'components/JsonViewer/JsonViewer'
import useWindow from 'hooks/useWindow'
import useProfile from 'hooks/useProfile'
import API from 'utils/api'
import { ROLES, PATH_OPTIONS } from 'const'
import { ContentFor } from 'components/ContentFor/ContentFor'
import styles from './PipelineItemSidebar.module.css'
import { SEVERITY_CLASS_OPTIONS } from 'utils/pci'
import { TILE_TYPES } from 'const/mapConstants'
import { PointCloudSemanticClassSelectorContainer } from '@roadar-pipeline-viewer/roadly-typescript/dist/features/PointCloud.feature/PointCloudSemanticClassSelector'

function PipelineItemSidebar({
  pipeline,

  hasPath,
  isShow,
  isPathVisible,
  onPathVisibleChange,

  pathOptions,
  pathCurrent,
  onPathChange,

  hasPCI,
  pciFileUrl,
  isPCICrackVisible,
  setPCICrackVisible,
  isPCIPotholeVisible,
  setPCIPotholeVisible,
  selectedPCISeverityClasses,
  setSelectedPCISeverityClasses,

  hasPointCloud,
  isPointCloudVisible,
  onPointCloudVisibleChange,
  hasTiles,
  tilesOptions,
  tilesCurrent,
  onTailsChange,
  onSemanticFilterClassesChange,
  pointSize,
  onPointSizeChange,
  posesStepSize,
  onPosesStepSizeChange,
}) {
  const { isDesktop } = useWindow()
  const [open, setOpen] = useState(isDesktop)
  const [isViewJson, setViewJson] = useState(false)
  const [isPublic, setIsPublic] = useState(pipeline.is_public)

  const openJsonViewer = () => setViewJson(true)
  const closeJsonViewer = () => setViewJson(false)

  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const { profile } = useProfile()
  const yaDiskHref = utils.getYandexDiskLink(pipeline)
  const output = utils.getAllOutput(pipeline)

  const onPublicChange = e => {
    setIsPublic(e.target.checked)

    API.changePipelineItemPublished({
      uuid: pipeline.uuid,
      data: { is_public: e.target.checked },
    })
  }

  const handlePathChange = e => {
    const value = pathOptions.find(item => item.value === e.target.value)
    onPathChange(value)
  }

  const handleTailsChange = e => {
    const value = tilesOptions.find(item => item.value === e.target.value)
    onTailsChange(value)
  }

  const handlePathSwitchChange = e => {
    onPathVisibleChange(e.target.checked)
  }

  const handlePointCloudSwitchChange = e => {
    onPointCloudVisibleChange(e.target.checked)
  }

  const handlePCICrackVisibilityChange = e => {
    setPCICrackVisible(e.target.checked)
  }

  const handlePCIPotholeVisibilityChange = e => {
    setPCIPotholeVisible(e.target.checked)
  }

  const togglePCISeverityClass = (e, severityClass) => {
    const newPCISeverityClasses = [...selectedPCISeverityClasses]
    newPCISeverityClasses[severityClass] = !newPCISeverityClasses[severityClass]
    setSelectedPCISeverityClasses(newPCISeverityClasses)
  }

  const position = pipeline?.session_data?.geocoding
    ? pipeline.session_data.geocoding.features?.map(item => item.text).join(', ') || ''
    : ''
  const splitHref = yaDiskHref ? yaDiskHref.split('/') : []
  const tmpSessionFolderName = splitHref[splitHref.length - 1] ? splitHref[splitHref.length - 1] : ''
  const sessionFolderName = tmpSessionFolderName.split('?')[0]

  if (!isShow) {
    return null
  }

  return (
    <>
      <div className={styles.buttonWrap}>
        <Button icon="menu" onClick={onOpen} />
      </div>
      <Drawer
        isOpen={open}
        onClose={onClose}
        hasBackdrop={false}
        position={Position.LEFT}
        size={DrawerSize.SMALL}
        title="Pipeline Info"
        canOutsideClickClose={false}
        canEscapeKeyClose={false}
        className={styles.drawer}
      >
        <div className={Classes.DRAWER_BODY}>
          <div className={Classes.DIALOG_BODY}>
            <div>
              <h4 className="map-sidebar-title">Pipeline:</h4>
              <p className="text bp4-text-small bp4-text-muted">
                <b>Uuid:</b> {pipeline.uuid}
              </p>
              <p className="text bp4-text-small bp4-text-muted">
                <b>Email:</b> {pipeline.user.email}
              </p>
              <p className="text bp4-text-small bp4-text-muted">
                <b>Status: </b>
                <Tag minimal round intent={utils.pipelineStatusToIntent(pipeline.status)}>
                  {pipeline.status}
                </Tag>
              </p>
              <p className="text bp4-text-small bp4-text-muted">
                <b>Create date:</b> {utils.formatDate(pipeline.created)}
              </p>
              <p className="text bp4-text-small bp4-text-muted">
                <b>Session folder:</b> {sessionFolderName}
              </p>
              <p className="text bp4-text-small bp4-text-muted">
                <b>Place:</b> {position}
              </p>
            </div>

            {profile.isAuthorized ? (
              <div>
                <div className={styles.title}>
                  <div className={styles.titleText}>Share:</div>
                  <Switch checked={isPublic} onChange={onPublicChange} />
                </div>
                {isPublic ? (
                  <Callout intent={Intent.PRIMARY}>When pipeline is public, it will be available for everyone.</Callout>
                ) : null}
              </div>
            ) : null}

            {/*
             Todo: utilize files list component from pipelines list item
              instead of following code block
            */}

            {output.length ? (
              <>
                <h4 className="map-sidebar-title">Files:</h4>
                {output
                  .filter(item => item.unix_type === 'file')
                  .filter(item => {
                    if (!item.value) {
                      return false
                    }
                    return (
                      item.value.includes('source_video.mp4') ||
                      item.value.includes('track.las') ||
                      ['CAMERA_LOCALIZATION'].includes(item.exposed_as) // proper way of filtering - TODO extend array
                    )
                  })
                  .map(item => {
                    const urlParts = item.url.split('/')
                    const name = urlParts[urlParts.length - 1]
                    return (
                      <a
                        onClick={event => handleDownload(event, item.url, item.gui_name)}
                        href={item.url}
                        rel="noopener noreferrer"
                        key={item.url}
                        title={item.gui_name}
                        className="map-output-tag"
                      >
                        <Tag intent={Intent.PRIMARY}>
                          <Icon icon="document" iconSize={12} className="icon" />
                          {name}
                        </Tag>
                      </a>
                    )
                  })}
              </>
            ) : null}

            {output.length ? (
              <>
                {output
                  .filter(item => item.unix_type === 'file')
                  .filter(item => {
                    if (!item.value) {
                      return false
                    }
                    return item.exposed_as === 'UPLOADED_FILE'
                  })
                  .map(item => {
                    const url = item.url
                    const urlTokens = url.split('.')
                    const ext = urlTokens.pop()
                    urlTokens.push('noext')
                    const imgUrl = urlTokens.join('.')
                    const name = url.split('/').pop()
                    const isVideo = ['mp4', 'mov', 'avi'].includes(ext)
                    const isImage = ['jpeg', 'png'].includes(ext)
                    if (!isImage && !isVideo) {
                      console.log('Unexpected uploaded file format')
                      return null
                    }
                    if (isImage)
                      return (
                        <div key={url}>
                          <img className={styles.inputImage} src={imgUrl} key={url} />
                          <a className={styles.inputLink} download={name} title={name} href={imgUrl}>
                            <Tag intent={Intent.PRIMARY}>Download image</Tag>
                          </a>
                        </div>
                      )
                    if (isVideo)
                      return (
                        <div key={url}>
                          <video className={styles.inputVideo} controls key={url}>
                            <source src={url} />
                          </video>
                          <a className={styles.inputLink} download={name} title={name} href={imgUrl}>
                            <Tag intent={Intent.PRIMARY}>Download video</Tag>
                          </a>
                        </div>
                      )
                  })}
              </>
            ) : null}

            <ContentFor role={ROLES.ADMIN}>
              {/*
              {yaDiskHref ? (
                <div>
                  <h4 className="map-sidebar-title">Session raw files:</h4>
                  <a target="_blank" rel="noopener noreferrer" className="blank" href={yaDiskHref} key={yaDiskHref}>
                    <Tag intent={Intent.NONE}>
                      <Icon icon="folder-shared" size={12} className="icon" />
                      session files
                    </Tag>
                  </a>
                </div>
              ) : null}
              */}

              <div>
                <h4 className="map-sidebar-title">Payload json:</h4>
                <a onClick={openJsonViewer}>
                  <Tag intent={Intent.PRIMARY}>
                    <Icon icon="link" size={12} className="icon" />
                    payload.json
                  </Tag>
                </a>
                <JsonViewer json={pipeline.definition} isOpen={isViewJson} onClose={closeJsonViewer} />
              </div>
            </ContentFor>
            {hasPointCloud ? (
              <div>
                <div className={styles.title}>
                  <div className={styles.titleText}>Point cloud:</div>
                  <Switch checked={isPointCloudVisible} onChange={handlePointCloudSwitchChange} />
                </div>

                <Collapse isOpen={isPointCloudVisible}>
                  {hasTiles ? (
                    <>
                      <div className="bp4-html-select">
                        <select value={tilesCurrent.value} onChange={handleTailsChange}>
                          {tilesOptions.map(item => (
                            <option value={item.value} key={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                        <span className="bp4-icon bp4-icon-double-caret-vertical"></span>
                      </div>
                      <div className={cx({[styles.disabled]: tilesCurrent.type === TILE_TYPES.TILE})}>
                        <PointCloudSemanticClassSelectorContainer
                          onSwitch={onSemanticFilterClassesChange}
                        />
                      </div>
                    </>
                  ) : null}

                  <div className="fld">
                    <Slider
                      showTrackFill={false}
                      min={0.1}
                      max={2.1}
                      stepSize={0.05}
                      labelStepSize={2}
                      onChange={onPointSizeChange}
                      value={pointSize}
                    />
                  </div>
                </Collapse>
              </div>
            ) : null}
            {hasPath ? (
              <div>
                <div className={styles.title}>
                  <div className={styles.titleText}>Path:</div>
                  <Switch checked={isPathVisible} onChange={handlePathSwitchChange} />
                </div>
                {pathOptions.length > 1 ? (
                  <Collapse isOpen={isPathVisible}>
                    <div className="bp4-html-select">
                      <select value={pathCurrent.value} onChange={handlePathChange}>
                        {pathOptions.map(item => (
                          <option value={item.value} key={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                      <span className="bp4-icon bp4-icon-double-caret-vertical"></span>
                    </div>
                    {pathCurrent.value === PATH_OPTIONS.POSES.value ? (
                      <div className="fld">
                        Poses step size:
                        <Slider
                          showTrackFill={false}
                          min={1}
                          max={20}
                          stepSize={1}
                          labelStepSize={5}
                          onChange={onPosesStepSizeChange}
                          value={posesStepSize}
                        />
                      </div>
                    ) : null}
                  </Collapse>
                ) : null}
              </div>
            ) : null}
            {hasPCI ? (
              <div>
                <div className={styles.title}>
                  <div className={styles.titleText}>PCI</div>
                </div>
                <div className={styles.subtitle}>
                  <div className={styles.titleText}>Cracks</div>
                  <Switch checked={isPCICrackVisible} onChange={handlePCICrackVisibilityChange} />
                </div>
                <div className={styles.subtitle}>
                  <div className={styles.titleText}>Potholes</div>
                  <Switch checked={isPCIPotholeVisible} onChange={handlePCIPotholeVisibilityChange} />
                </div>
                <div className={styles.subtitle}>
                  <div className={styles.titleText}>Severity classes</div>
                  <div className={styles.severityCheckboxesWrap}>
                    {SEVERITY_CLASS_OPTIONS.map(({ severityClass, color, name }) => (
                      <div
                        key={severityClass}
                        className={styles.severityCheckboxWrap}
                        style={{ '--severity-color': color }}
                      >
                        <Tooltip content={name}>
                          <Checkbox
                            checked={selectedPCISeverityClasses[severityClass]}
                            onChange={evt => togglePCISeverityClass(evt, severityClass)}
                          />
                        </Tooltip>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.subtitle}>
                  <a href={pciFileUrl} className={styles.exportPCILink}>
                    <Tag intent={Intent.PRIMARY}>Export</Tag>
                  </a>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default PipelineItemSidebar
