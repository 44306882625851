import { MAP_PALETTES_CONSTANTS } from './mapPalettesConstants'
import { TILES_MODULE_OUTPUT_TYPES } from './pipelineConstants'
import {
  pointCloudSemanticClasses
} from '@roadar-pipeline-viewer/roadly-typescript/dist/const/pointCloud.constants'

export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

export const ELEVATION_DATA = `https://api.mapbox.com/v4/mapbox.terrain-rgb/{z}/{x}/{y}.png?access_token=${MAPBOX_ACCESS_TOKEN}`

export const ELEVATION_DECODER = {
  rScaler: 6553.6,
  gScaler: 25.6,
  bScaler: 0.1,
  offset: -10000,
}

export const MAP_PADDING = {
  MOBILE: 100,
  DESKTOP: 300,
}

export const POINT_CLOUD_TYPE = { XYZ: 'xyz', TILES: 'tiles' }

/**
 * Layer type to show in map depending
 * on POINT_CLOUD_TYPE and TILE_TYPES
 */
export const POINT_CLOUD_FORMAT = {
  SEMANTIC: 'SEMANTIC',
  NATURAL: 'NATURAL',
  XYZ: 'XYZ',
}

/**
 * Deep viewport config including props,
 * not available for changing by user
 */
export const COMPLETE_VIEWPORT_CONFIG = {
  latitude: 52.35389348750071,
  longitude: 4.934368124949788,
  zoom: 15.9,
  pitch: 0,
  bearing: 0,
  maxPitch: 85,
  maxZoom: 28,
}

/**
 * Viewport props, available on user level
 */
export const DEFAULT_USER_VIEWPORT_CONFIG = {
  // London
  latitude: 51.5142077,
  longitude: -0.1214403,
  zoom: 12,

  // Amsterdam
  // latitude: 52.35389348750071,
  // longitude: 4.934368124949788,
  // zoom: 15.9,
}

export const DEFAULT_POINT_SIZE = 0.25
export const DEFAULT_POSES_STEP_SIZE = 10

export const UNITS = ['metric', 'imperial']

export const TILE_TYPES = {
  TILE: 'TILE',
  PALETTE_TILE: 'PALETTE_TILE',
}

export const TILE_OPTIONS = {
  NATURAL_COLORS: {
    type: TILE_TYPES.TILE,
    outputType: TILES_MODULE_OUTPUT_TYPES.RAW,
    label: 'Natural colors',
    value: 'natural_colors',
  },
  MAPILLARY: {
    type: TILE_TYPES.PALETTE_TILE,
    outputType: TILES_MODULE_OUTPUT_TYPES.SEMANTIC,
    label: 'Semantic colors',
    value: 'Mapillary',
    palette: MAP_PALETTES_CONSTANTS.MAPILLARY,
    legend: pointCloudSemanticClasses
  }
}

export const TILE_OPTIONS_LIST = Object.values(TILE_OPTIONS)

export const PATH_OPTIONS = {
  DISABLE: {
    label: 'off',
    value: 'disable',
  },
  GPS: {
    label: 'gps',
    value: 'gps',
  },
  POSES: {
    label: 'detailed',
    value: 'poses',
  },
}

export const TERRAIN_TEXTURES = {
  osm: {
    title: 'Open Street Map',
    style: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
  },
  satellite: {
    title: 'Satellite',
    style: `https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.png?access_token=${MAPBOX_ACCESS_TOKEN}`,
  },
}
