import { useState } from 'react'
import PipelineItemMap from 'components/PipelineItemMap/PipelineItemMap'
import PipelineItemMapSettings from 'components/PipelineItemMapSettings/PipelineItemMapSettings'
import { MAPBOX_OVERLAY_KEY, MAPBOX_OVERLAY, UNITS } from 'const'
import pipelineItem from '../PipelineItem/PipelineItem'

function PipelineItemContent({
  pipeline,
  viewport,
  hasPath,
  isPathVisible,
  pathCurrent,
  track,
  poses,
  pciData,
  isPCICrackVisible,
  selectedPCISeverityClasses,
  cameraLocalization,
  isPointCloudVisible,
  pointCloud,
  pointSize,
  posesStepSize,
  tilesCurrent,
  semanticFilterClasses,
  onPosesClick,
  fps,
  isSettingsActive,
  videoPoses,
}) {
  const [unit, setUnit] = useState(UNITS[0])
  const [isOverlayShow, setIsOverlayShow] = useState(true)

  const [overlay, setOverlay] = useState(
    // Temporary, for citix demo
    pipeline.uuid === '477563f5-1af5-4b12-a780-c907b9856713'
      ? MAPBOX_OVERLAY[MAPBOX_OVERLAY_KEY.TERRAIN_OSM]
      : MAPBOX_OVERLAY[MAPBOX_OVERLAY_KEY.STREETS]
  )

  return (
    <>
      <PipelineItemMap
        viewport={viewport}
        overlay={overlay}
        unit={unit}
        track={track}
        poses={poses}
        pciData={pciData}
        cameraLocalization={cameraLocalization}
        isPCICrackVisible={isPCICrackVisible}
        selectedPCISeverityClasses={selectedPCISeverityClasses}
        pointCloud={pointCloud}
        pointSize={pointSize}
        isOverlayShow={isOverlayShow}
        isPathVisible={isPathVisible}
        isPointCloudVisible={isPointCloudVisible}
        hasPath={hasPath}
        pathCurrent={pathCurrent}
        tilesCurrent={tilesCurrent}
        semanticFilterClasses={semanticFilterClasses}
        onPosesClick={onPosesClick}
        fps={fps}
        isSmall={isSettingsActive}
        videoPoses={videoPoses}
      />
      {isSettingsActive ? (
        <PipelineItemMapSettings
          unit={unit}
          overlay={overlay}
          isOverlayShow={isOverlayShow}
          onUnitChange={setUnit}
          onOverlayChange={setOverlay}
          onIsOverlayShowChange={setIsOverlayShow}
        />
      ) : null}
    </>
  )
}

export default PipelineItemContent
