export function getOutputList(pipeline) {
  return pipeline.state.reduce((memo, state) => {
    const urls = Object.values(state.outputs).reduce((memo, item) => {
      const [moduleId, outputName] = item.url.split('/').slice(-2)
      const key = `${moduleId}_${outputName}`

      memo[key] = { moduleName: state.module, moduleId, outputName, ...item }

      return memo
    }, {})
    return { ...memo, ...urls }
  }, {})
}
