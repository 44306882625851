import { useCallback, useState } from 'react';
import { LOCAL_STORAGE_KEYS } from '../../const/localStorageConstants';
const getInitialValue = () => {
    const storedValue = localStorage.getItem(LOCAL_STORAGE_KEYS.IS_GETTING_STARTED_HINT_VISIBLE);
    return storedValue === null ? true : storedValue === 'true';
};
export const useIsHintVisible = () => {
    const [showGettingStartedHint, setShowGettingStartedHint] = useState(getInitialValue());
    const handleCloseGettingStartedHint = useCallback(() => {
        setShowGettingStartedHint(false);
        localStorage.setItem(LOCAL_STORAGE_KEYS.IS_GETTING_STARTED_HINT_VISIBLE, String(false));
    }, []);
    return { showGettingStartedHint, handleCloseGettingStartedHint };
};
