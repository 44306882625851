import { pciFeatureProcessingStatuses } from '../../const/pciConstants';
/**
 * Filters out !_normalized_! features having pciSeverityName
 * which is key with truthy value in viewpointsFilters
 */
export function filterPciFeatures(allPciFeatures, pciFilters, showInvalid) {
    return allPciFeatures
        .filter((feature) => {
        if (feature.properties.status === pciFeatureProcessingStatuses.invalid) {
            return showInvalid;
        }
        return pciFilters[feature.properties.stats.pciSeverityName];
    });
}
