import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { LoadingStatus } from '../../../const/appConstants';
import { pointCloudSemanticClasses, switchedOffIdxs } from '../../../const/pointCloud.constants';
import { preparePointCloudSemanticClassRefsArray } from '../../../utils/pointCloud.utilities/preparePointCloudSemanticClassStructuresArray';
import { PointCloudSemanticClassSelector } from './PointCloudSemanticClassSelector';
export const PointCloudSemanticClassSelectorContainer = ({ onSwitch, }) => {
    const [refsArray, setRefsArray,] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.INITIAL);
    useEffect(() => {
        const effect = async () => {
            const updatedRefArray = preparePointCloudSemanticClassRefsArray(pointCloudSemanticClasses, switchedOffIdxs);
            setRefsArray(updatedRefArray);
            setLoadingStatus(LoadingStatus.SUCCESS);
            const booleanArray = updatedRefArray.map((el) => el.value);
            onSwitch(booleanArray);
        };
        effect();
    }, []);
    function handleSwitchInside(theRef) {
        const updatedRefArray = refsArray.map((aRef) => (aRef.index === theRef.index
            ? { ...aRef, value: aRef.value === 1 ? 0 : 1 }
            : aRef));
        setRefsArray(updatedRefArray);
        const booleanArray = updatedRefArray.map((el) => el.value);
        onSwitch(booleanArray);
    }
    if (loadingStatus !== LoadingStatus.SUCCESS) {
        return null;
    }
    return (_jsx(PointCloudSemanticClassSelector, { onSwitchInside: handleSwitchInside,
        refsArray }));
};
