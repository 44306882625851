import { jsx as _jsx } from "react/jsx-runtime";
import GL from '@luma.gl/constants';
import { IconLayer, } from '@deck.gl/layers/typed';
import { Deck2gisLayer } from '@2gis/deck2gis-layer';
import { bringFeatureCollectionToTheGround } from '../../../../utils/bringFeatureCollectionToTheGround';
const missingImg = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMzciIHZpZXdCb3g9IjAgMCAzNyAzNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTguNSIgY3k9IjE4LjUiIHI9IjExIiBmaWxsPSIjODM5NDlCIiBzdHJva2U9IiNFQ0VDRUMiIHN0cm9rZS13aWR0aD0iMyIvPgo8L3N2Zz4K';
const getIcon = (feature) => {
    return {
        // url: 'https://avatars.githubusercontent.com/u/2059298?s=80&v=4',
        // url: '/favicon_urbi.png',
        url: feature.properties.icon_url || missingImg,
        // url: feature.properties.icon_url || 'https://storage.googleapis.com/roadly-dev-frontend-assets/sign_icons/4.2.3_Russian_road_sign.svg',
        width: 100,
        height: 100,
    };
};
const assetsRegistryStaticProps = {
    id: 'aggregated-assets-layer',
    pickable: true,
    getIcon,
    getSize: 30,
    sizeMinPixels: 15,
    sizeMaxPixels: 50,
    getPosition: (feature) => feature.geometry.coordinates,
    textureParameters: {
        [GL.TEXTURE_MIN_FILTER]: GL.LINEAR_MIPMAP_LINEAR,
        [GL.TEXTURE_MAG_FILTER]: GL.LINEAR,
        [GL.TEXTURE_WRAP_S]: GL.REPEAT,
        [GL.TEXTURE_WRAP_T]: GL.REPEAT,
    },
};
// const defaultLayerProps = {
//   id: 'assets-registry-layer-default',
//   pickable: true,
//   stroked: false,
//   filled: true,
//   radiusMinPixels: 3,
//   radiusMaxPixels: 7,
//   getPosition: (feature: FinalAssetFeature) => feature.geometry.coordinates,
//   getColor: [255, 186, 0],
// };
export const getAssetsRegistryLayerAsObject = ({ finalAssetsFeatureCollection }) => {
    const { features } = bringFeatureCollectionToTheGround(finalAssetsFeatureCollection);
    return new IconLayer({
        ...assetsRegistryStaticProps,
        data: features,
    });
};
export const getAssetsRegistryLayerAsReactNode = ({ finalAssetsFeatureCollection }) => {
    const { features } = bringFeatureCollectionToTheGround(finalAssetsFeatureCollection);
    return (
    // @ts-ignore Todo
    _jsx(IconLayer, { ...assetsRegistryStaticProps, data: features }));
};
export const applyAssetsRegistryLayerTo2GisMap = ({ deck, map }, { finalAssetsFeatureCollection }) => {
    const { features } = bringFeatureCollectionToTheGround(finalAssetsFeatureCollection);
    // const emptyFeatures: FinalAssetFeature[] = [];
    // const completeFeatures: FinalAssetFeature[] = [];
    //
    // (allFeatures as FinalAssetFeature[]).forEach((feature) => {
    //   if (feature.properties.icon_url === null) {
    //     emptyFeatures.push(feature);
    //   } else {
    //     completeFeatures.push(feature);
    //   }
    // });
    //
    // const emptyUrlLayer = new Deck2gisLayer({
    //   /* @ts-ignore Todo: find out way to expect exact deck type with 2gis map */
    //   deck,
    //   type: ScatterplotLayer,
    //   ...defaultLayerProps,
    //   data: emptyFeatures,
    // });
    const assetsRegistryLayer = new Deck2gisLayer({
        /* @ts-ignore Todo: find out way to expect exact deck type with 2gis map */
        deck,
        type: IconLayer,
        ...assetsRegistryStaticProps,
        data: features,
    });
    /* Rendering */
    const rerender = () => {
        try {
            map.removeLayer(assetsRegistryLayer.id);
            // map.removeLayer(emptyUrlLayer.id);
        }
        catch (e) {
            console.warn('Removing layers error caught', e);
        }
        map.addLayer(assetsRegistryLayer);
        // map.addLayer(emptyUrlLayer);
    };
    rerender();
};
