import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import YoutubeBackground from 'react-youtube-background';
import { ThemeContext, THEME } from '../../contexts/themeContext';
import style from './PageVideoWrapper.module.css';
const DARK_OVERLAY = 'rgba(0,0,0,0.5)';
const LIGHT_OVERLAY = 'rgba(255,255,255,0.5)';
export const PageVideoWrapper = ({ children }) => {
    const { theme, setTheme } = useContext(ThemeContext);
    const videoId = 'oKlzXhTuBzs';
    return (_jsx(YoutubeBackground, { videoId: videoId, overlay: theme === THEME.DARK ? DARK_OVERLAY : LIGHT_OVERLAY, className: style.root, children: children }));
};
