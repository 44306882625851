export function getImageDataUrl(image) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
        throw new Error('getImageDataUrl: context is null');
    }
    canvas.height = image.naturalHeight;
    canvas.width = image.naturalWidth;
    context.drawImage(image, 0, 0);
    return canvas.toDataURL();
}
