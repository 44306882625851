import { useImmerReducer } from 'use-immer'
import { useEffect, useMemo, useState } from 'react'
import jsyaml from 'js-yaml'
import yamlSrc, { stringifiedDefaultMonoYamlAsJson } from 'const/mono.yaml'
import { ACTIONS as ACTIONS_FORM, reducer as reducerForm } from 'reducers/form'
import { addYamlToDsoppModules, removeYamlFromDsoppModules } from 'utils/payloadJsonUtils/payloadJsonUtils'
import API from 'utils/api'

const defaultYaml = yamlSrc

const getPrettyJsonString = json => JSON.stringify(json, null, 2)

export function usePipelineForm({
  // Yaml is content of yaml editor
  initialYaml,
  // Json is what came from server in case of using in rerun.
  // What is it in case of running custom pipeline?
  json,
  initialFormData = {},
}) {
  const yaml = initialYaml || defaultYaml
  const [formData, dispatchForm] = useImmerReducer(reducerForm, initialFormData)
  const [yamlText, setYamlText] = useState(getPrettyJsonString(jsyaml.load(yaml)))
  const [isYamlValid, setIsYamlValid] = useState(true)
  const [payloadJson, setPayload] = useState()
  const [isJsonValid, setIsJsonValid] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  const initialYamlText = useMemo(() => getPrettyJsonString(jsyaml.load(initialYaml)), [initialYaml])

  useEffect(() => {
    if (json) {
      setPayload(getPrettyJsonString(json))
      setIsLoading(false)
    } else {
      API.getPayloadJson().then(res => {
        updatePayload(getPrettyJsonString(res))
        setIsLoading(false)
      })
    }
  }, []) //eslint-disable-line

  const updatePayload = text => {
    try {
      setPayload(text)
      setIsJsonValid(true)
    } catch (e) {
      setPayload(text)
      setIsJsonValid(false)
    }
  }
  const addYamlToPayload = (takeInitialYaml = false) => {
    if (isJsonValid && !isLoading) {
      try {
        let json = JSON.parse(payloadJson)
        const newJson = addYamlToDsoppModules(json, takeInitialYaml ? initialYamlText : yamlText)
        setIsYamlValid(true)
        setPayload(getPrettyJsonString(newJson))
      } catch (e) {
        setIsYamlValid(false)
        removeYamlFromPayload()
      }
    }
  }
  const removeYamlFromPayload = () => {
    if (isJsonValid && !isLoading) {
      try {
        let json = JSON.parse(payloadJson)
        const newJson = removeYamlFromDsoppModules(json)
        setPayload(getPrettyJsonString(newJson))
      } catch (e) {
        setIsJsonValid(false)
      }
    }
  }

  const onInput = ({ key, value }) =>
    dispatchForm({
      type: ACTIONS_FORM.INPUT,
      key: key,
      value: value,
    })

  const onChangeYaml = newYaml => {
    setYamlText(newYaml)
  }

  const checkIfIsNotDefaultYaml = () => {
    if (!yamlText) {
      return true // Undefined is not default yaml
    }

    try {
      const stringifiedJson = JSON.stringify(JSON.parse(yamlText))
      return stringifiedJson !== stringifiedDefaultMonoYamlAsJson
    } catch (e) {
      return true // If json is invalid, it is not default
    }
  }

  useEffect(() => {
    if (yamlText && yamlText.length > 0 && checkIfIsNotDefaultYaml()) {
      addYamlToPayload()
    } else {
      removeYamlFromPayload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yamlText])

  const onChangePayload = jsonText => {
    updatePayload(jsonText)
  }

  return {
    //form
    formData,
    onInput,
    //yaml
    onChangeYaml,
    isYamlValid,
    yamlText,
    addYamlToPayload,
    removeYamlFromPayload,
    //payload
    onChangePayload,
    payloadJson,
    isJsonValid,
    isLoading,
  }
}
