import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '@blueprintjs/core';
import { useLogin } from '../../hooks/useLogin';
import { PATH } from '../../const/routingConstants';
import styles from './DemoAutoLogin.module.scss';
import { LoadingOverlay } from '../../components';
import { demoUserLoginParams } from '../../const/userConstants';
export const DemoAutoLoginFeature = () => {
    const navigate = useNavigate();
    const { login, profile } = useLogin();
    useEffect(() => {
        const effect = async () => {
            console.log('--profile--', profile);
            if (profile.isAuthorized) {
                toast.warn('User is already authorized');
                return;
            }
            await login(demoUserLoginParams);
            // toast.info('You have successfully logged in as demo user');
            navigate(PATH.index.path);
        };
        effect();
    }, []);
    const handleClickGoHome = () => {
        navigate(PATH.index.path);
    };
    return (_jsx("div", { className: styles.demoAutoLoginContainer, children: profile.isAuthorized ? (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.label, children: "You are already logged in" }), _jsx(Button, { onClick: handleClickGoHome, children: "To main page" })] })) : (_jsx(LoadingOverlay, { children: _jsx("div", { children: "Logging in as demo user" }) })) }));
};
