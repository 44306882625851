// Moved to ts
import { getBrowserLocale } from './getBrowserLocale'

/**
 * Helper for rendering date in user's default format
 * Intl used instead of date-fns/locale in order
 * not to depend on quite big list of locales objects in it
 */

class LocaleDateManager {
  constructor(localeCode) {
    this.setFormat(localeCode)
  }

  setFormat(localeCode) {
    this.dateTimeFormat = new Intl.DateTimeFormat(localeCode, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      second: 'numeric',
      minute: 'numeric',
    })
  }

  formatDate(date) {
    try {
      return this.dateTimeFormat.format(date)
    } catch (e) {
      console.error('LocaleDateManager failed date', {
        date,
        typeOfDate: typeof date,
      })
      throw e
    }
  }
}

export const localeDateManager = new LocaleDateManager(getBrowserLocale())
