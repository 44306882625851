import { lineString as turfLineString } from '@turf/helpers'
import * as turf from '@turf/turf'
import { WebMercatorViewport } from 'deck.gl'
import { DEFAULT_USER_VIEWPORT_CONFIG, MAP_PADDING } from 'const'

// Todo: figure out how to use these better:
const WIDTH = window.innerWidth
const HEIGHT = window.innerHeight

export const getViewportParams = geometry => {
  try {
    if (geometry && geometry.length) {
      let geom = null
      if (Array.isArray(geometry[0])) {
        geom = turfLineString(geometry)
      } else {
        geom = turf.buffer(turf.point(geometry.slice(0, 2)), 1, { units: 'kilometers' })
      }
      const [topLat, topLng, bottomLat, bottomLng] = turf.bbox(geom)
      const padding = WIDTH > MAP_PADDING.DESKTOP * 2 ? MAP_PADDING.DESKTOP : MAP_PADDING.MOBILE

      const viewport = new WebMercatorViewport({ width: WIDTH, height: HEIGHT }).fitBounds(
        [
          [topLat, topLng],
          [bottomLat, bottomLng],
        ],
        { padding }
      )
      const { longitude, latitude, zoom } = viewport

      return { longitude, latitude, zoom }
    } else {
      return DEFAULT_USER_VIEWPORT_CONFIG
    }
  } catch (e) {
    console.error('utils:getViewportParams:caught', e)
    return DEFAULT_USER_VIEWPORT_CONFIG
  }
}
