export const FILTER = {
  ALL: 'all',
  ACTIVE: 'active',
  CANCELED: 'canceled',
  FAILED: 'failed',
  SUCCESS: 'success',
}

export const SORT = {
  ASC: 'asc',
  DESC: 'desc',
}
