import { DocumentWrapper } from 'components/DocumentWrapper'
import styles from 'components/DocumentWrapper/DocumentStyles.module.scss'

export const DataRequirementsPage = () => {
  return (
    <DocumentWrapper>
      <div className={styles.textBlock}>
        <h2>DATA REQUIREMENTS</h2>
        <p>
          All the requirements stated below are explicitly specified for sessions recorded in strict accordance with the
          data collection rules outlined in Appendix C. Any deviation from these rules may result in a reduction in data
          quality and render some sessions unprocessable.
        </p>
        <p>
          <strong>Consistency of data</strong> - the road data is computed along the centerline of the session track
          without any interruptions.
        </p>
        <p>
          Relevance of data - the road data is computed based on pertinent sessions recorded within a period of one
          month prior to the current date, if available. In the absence of any recorded sessions during the specified
          period, the data from the most recent session available is utilized to represent pavement.
        </p>
      </div>

      <div className={styles.textBlock}>
        <h2>Data Collection Requirements:</h2>

        <h3>Equipment</h3>
        <p>The data collection process should utilize the following smartphones:</p>
        <ul>
          <li>iPhone 11/pro</li>
          <li>iPhone 12/pro</li>
          <li>iPhone 13/pro</li>
          <li>iPhone 14/pro</li>
        </ul>
        <p>
          The smartphone must be securely affixed under the windshield to provide an unobstructed view of the road. The
          device must be stabilized to minimize shaking.
        </p>

        <h3>Weather conditions:</h3>
        <ul>
          <li>
            Video recordings should be conducted at the same time of day, with similar weather conditions. Only daylight
            data collection is allowed.
          </li>
          <li>
            Cloudy weather conditions are ideal for video recording, as the level of illumination remains consistent,
            and there are no glares on objects. Suitable recording times are between 12 PM and 3 PM if the day is
            expected to be sunny. This restriction is due to minimal shadows on objects. When recording on a cloudy day,
            data collection can occur at any time during daylight hours.
          </li>
          <li>It is not recommended to record video during rain or fog.</li>
          <li>
            Sunset is not a suitable time for video recording. The rapid changes in lighting conditions can result in
            inaccurate object positioning within the frame due to incorrect depth calculation.
          </li>
          <li>
            Nighttime video recording is not allowed as natural light is insufficient, and data quality will be
            compromised.
          </li>
        </ul>
      </div>
    </DocumentWrapper>
  )
}
