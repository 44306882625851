import { normalizeUser } from './normalizeUser';
import { apiHelpers } from './api.helpers';
export class ApiClient {
    /**
     * Handling errors in fetch is covered here:
     * https://www.tjvantoll.com/2015/09/13/fetch-and-errors/
     * Todo: make generic and remove unknown
     */
    async fetch(path, init) {
        try {
            const response = await fetch(path, init);
            if (response.ok) {
                return response;
            }
            if (response.status === 401) {
                apiHelpers.setProfile(normalizeUser());
            }
            // Todo: handle 404 and other error types
            console.error('Unhandled fetch error response', { path, init, response });
            return response;
        }
        catch (e) {
            // Todo: use custom exceptions
            console.error('Failed fetching: Network is down', { path, init });
            console.error('Caught error:', e);
            throw e;
        }
    }
}
export const apiClient = new ApiClient();
