import uniqBy from 'lodash/uniqBy';
export function getAssetClassVisibilityMap({ features }) {
    const allAssetsClassFiltersList = features.map((el) => ({
        assetClassName: el.properties.class_name,
        isOn: true,
        url: el.properties.icon_url,
    }));
    const uniqueAssetsClassFiltersList = uniqBy(allAssetsClassFiltersList, (el) => el.assetClassName);
    return uniqueAssetsClassFiltersList.reduce((acc, curr) => {
        acc[curr.assetClassName] = curr;
        return acc;
    }, {});
}
