import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { clsx } from 'clsx';
import { Tooltip } from '@blueprintjs/core/lib/esm/components/tooltip/tooltip';
import { Position } from '@blueprintjs/core/lib/esm/common';
import { useNavigate } from 'react-router';
import { AssetsIcon, AssetsRegistryIcon, PciMapIcon } from '../../components/icons';
import { PATH } from '../../const/routingConstants';
import { PreferredSignLogo } from '../../components/Logo';
import styles from './AppSidebar.module.scss';
import { UserDropdown } from './UserDropdown';
export const AppSidebar = ({ className, buttonVisibilityMap }) => {
    const { pciRegistry, assetsPipelines, assetsRegistry } = buttonVisibilityMap;
    const navigate = useNavigate();
    const handleGoToMiniPci = () => {
        navigate(PATH.pciRegistry.path);
    };
    const handleGoToAssets = () => {
        navigate(PATH.assetsPipelineViewer.path);
    };
    const handleGoToAssetsRegistry = () => {
        navigate(PATH.aggregatedAssetsViewer.path);
    };
    return (_jsxs("div", { className: clsx(styles.appSidebar, className), children: [_jsx("div", { className: styles.logo, children: _jsx("a", { href: "/", children: _jsx(PreferredSignLogo, {}) }) }), _jsxs("div", { className: styles.appSidebarActionsContainer, children: [_jsxs("div", { className: styles.appSidebarGroup, children: [pciRegistry ? (_jsx(_Fragment, { children: _jsx("div", { className: styles.appSidebarGroupSection, onClick: handleGoToMiniPci, children: _jsx(Tooltip, { targetClassName: styles.tooltipTarget, content: "PCI Inspection", position: Position.RIGHT, children: _jsx(PciMapIcon, {}) }) }) })) : null, assetsPipelines ? (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.separator }), _jsx("div", { className: styles.appSidebarGroupSection, onClick: handleGoToAssets, children: _jsx(Tooltip, { targetClassName: styles.tooltipTarget, position: Position.RIGHT, content: "Assets Inspection", children: _jsx(AssetsIcon, {}) }) })] })) : null, assetsRegistry ? (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.separator }), _jsx("div", { className: styles.appSidebarGroupSection, onClick: handleGoToAssetsRegistry, children: _jsx(Tooltip, { targetClassName: styles.tooltipTarget, position: Position.RIGHT, content: "Assets Registry", children: _jsx(AssetsRegistryIcon, {}) }) })] })) : null] }), _jsx(UserDropdown, {})] })] }));
};
