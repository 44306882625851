import { useMemo, useState } from 'react';
import { defaultFinalAssetsQaVisibilityFilters } from '../../../const/finalAssetsQa.constants';
import { getFromMapOrFail } from '../../../utils';
import { fcFromFeatures } from '../../../utils/geoJson.utils';
import { getAssetClassVisibilityMap } from '../../../utils/assets.utilities/getAssetClassVisibilityMap';
const finalAssetsQaStatusByIsApproved = new Map([
    [true, 'approved'],
    [false, 'declined'],
    [null, 'unhandled'],
]);
export const useFinalAssetsQaVisibilityFilters = ({ finalAssetsFeatureCollection, }) => {
    const [finalAssetsQaVisibilityFilters, setFinalAssetsQaVisibilityFilters,] = useState(defaultFinalAssetsQaVisibilityFilters);
    const [assetsVisibilityFiltersByClassNameMap, onSetAssetVisibilityFilterByClassNameMap,] = useState(getAssetClassVisibilityMap(finalAssetsFeatureCollection));
    const handleSwitchVisibilityByQaStatus = (finalAssetsQaStatus) => {
        setFinalAssetsQaVisibilityFilters({
            ...finalAssetsQaVisibilityFilters,
            [finalAssetsQaStatus]: !finalAssetsQaVisibilityFilters[finalAssetsQaStatus],
        });
    };
    const visibleFinalAssetsFeatureCollection = useMemo(() => {
        // Filter by status
        const filteredByStatus = finalAssetsFeatureCollection
            .features
            .filter((el) => finalAssetsQaVisibilityFilters[getFromMapOrFail(finalAssetsQaStatusByIsApproved, el.properties.is_approved)]);
        // Filter by asset type
        const filteredFeatures = filteredByStatus
            .filter((feature) => assetsVisibilityFiltersByClassNameMap[feature.properties.class_name].isOn);
        return fcFromFeatures(filteredFeatures);
    }, [
        finalAssetsFeatureCollection,
        finalAssetsQaVisibilityFilters,
        assetsVisibilityFiltersByClassNameMap,
    ]);
    const assetsVisibilityFiltersByClassNameProps = {
        assetsVisibilityFiltersByClassNameMap,
        onSetAssetVisibilityFilterByClassNameMap,
    };
    return {
        finalAssetsQaVisibilityFilters,
        visibleFinalAssetsFeatureCollection,
        handleSwitchVisibilityByQaStatus,
        assetsVisibilityFiltersByClassNameProps,
    };
};
