import { jsx as _jsx } from "react/jsx-runtime";
import { PciSimpleQaStatus } from '../../../../const/qaConstants';
import styles from './PciViewerQaStatsBar.module.scss';
function generateBarPicture({ inputData, getColor }) {
    const colors = inputData.map(getColor);
    const canvas = document.createElement('canvas');
    canvas.width = colors.length;
    canvas.height = 1;
    const context = canvas.getContext('2d');
    if (context === null) {
        throw new Error('Canvas context is null');
    }
    const imageData = context.createImageData(colors.length, 1);
    const { data } = imageData;
    const imageDataLength = data.length;
    for (let i = 0, u = 0; i < imageDataLength; i += 4, u += 1) {
        data[i] = colors[u][0];
        data[i + 1] = colors[u][1];
        data[i + 2] = colors[u][2];
        data[i + 3] = 255;
    }
    context.putImageData(imageData, 0, 0);
    return canvas.toDataURL();
}
function getPciQaMarkColor(pciQaStatsItem) {
    if (pciQaStatsItem.status === PciSimpleQaStatus.valid) {
        return [20, 230, 20];
    }
    if (pciQaStatsItem.status === PciSimpleQaStatus.to_annotate) {
        return [230, 20, 20];
    }
    return [150, 150, 150];
}
const inputData = [
    PciSimpleQaStatus.not_viewed, PciSimpleQaStatus.not_viewed, PciSimpleQaStatus.not_viewed,
    PciSimpleQaStatus.not_viewed, PciSimpleQaStatus.not_viewed, PciSimpleQaStatus.not_viewed,
    PciSimpleQaStatus.not_viewed, PciSimpleQaStatus.not_viewed, PciSimpleQaStatus.not_viewed,
    PciSimpleQaStatus.valid, PciSimpleQaStatus.valid, PciSimpleQaStatus.valid,
    PciSimpleQaStatus.valid, PciSimpleQaStatus.valid, PciSimpleQaStatus.valid,
    PciSimpleQaStatus.valid, PciSimpleQaStatus.valid, PciSimpleQaStatus.valid,
    PciSimpleQaStatus.to_annotate, PciSimpleQaStatus.to_annotate, PciSimpleQaStatus.to_annotate,
    PciSimpleQaStatus.to_annotate, PciSimpleQaStatus.to_annotate, PciSimpleQaStatus.to_annotate,
    PciSimpleQaStatus.to_annotate, PciSimpleQaStatus.to_annotate, PciSimpleQaStatus.to_annotate,
];
export const PciViewerQaStatsBar = ({ pciQaSessionStats }) => {
    const imageDataUrl = generateBarPicture({ getColor: getPciQaMarkColor, inputData: pciQaSessionStats });
    // const imageDataUrl = useMemo(() => generateBarPicture({ getColor: getPciQaMarkColor, inputData: pciQaSessionStats }), [inputData]);
    return (_jsx("img", { src: imageDataUrl, alt: "QA mark bar", className: styles.image }));
};
