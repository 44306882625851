import { jsx as _jsx } from "react/jsx-runtime";
import { RoadLyMap } from '../RoadLyMap';
import { getViewportParamsFromFeatures } from '../../utils/viewportUtilities/getViewportParamsFromFeatures';
export const MiniSessionViewerContainer = ({ session, onAfterRender, deckRef, staticMapRef, onLoadStaticMap }) => {
    const features = [session.track_line];
    const geoJsonConfig = {
        featureCollection: {
            type: 'FeatureCollection',
            features,
        },
    };
    const mapProps = {
        geoJsonConfig,
        touchRotate: true,
        userViewportConfig: getViewportParamsFromFeatures(features),
        screenShotProps: {
            onAfterRender,
            deckRef,
            staticMapRef,
            onLoadStaticMap,
        },
    };
    return (mapProps === null
        ? _jsx("div", { children: "Loading" })
        : _jsx(RoadLyMap, { ...mapProps }));
};
