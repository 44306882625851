import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { FloatingContainer } from '../../../components/FloatingContainer';
import { AssetsClassFilters } from '../AssetsClassFilters';
import { AssetFeatureViewerModal } from '../AssetFeatureViewer';
import { PreferredMap } from '../../RoadLyMap/PreferredMap';
import styles from './AggregatedAssetsViewer.module.scss';
import { DEMO_VIEWPORT_CONFIG, MapboxOverlayKey, WALTHAM_VIEWPORT_CONFIG, WORLD_VIEWPORT_CONFIG } from '../../../const/mapConstants';
import { isStableEnv } from '../../../utils/isStableEnv';
import { stableOrganizationIds } from '../../../const/stableOrganizationIds';
import { useProfile } from '../../../hooks/useProfile';
import { StickyContainer } from '../../../components/StickyContainer/StickyContainer';
import { ToggleMapOverlayKey } from '../../../components/ToggleMapOverlayKey';
import { StickyContainerPanel } from '../../../components/StickyContainer/StickyContainerPanel';
export const AggregatedAssetsViewer = ({ activeAsset, finalAssetsFeatureCollection, assetsVisibilityFiltersByClassNameProps, onClickAsset, onCloseAssetFeatureViewerModal, }) => {
    const { profile } = useProfile();
    // eslint-disable-next-line no-nested-ternary
    const userViewportConfig = profile.isDemoUser
        ? DEMO_VIEWPORT_CONFIG
        : isStableEnv() && profile.organization_id === stableOrganizationIds.waltham
            ? WALTHAM_VIEWPORT_CONFIG
            : WORLD_VIEWPORT_CONFIG;
    const handleClickOnMap = (info) => {
        if (info.object) {
            onClickAsset(info.object);
        }
    };
    const handleClickRoadLyMapClusterSubFeature = (feature) => {
        onClickAsset(feature);
    };
    const [overlayKey, setOverlayKey] = useState(MapboxOverlayKey.CUSTOM_DARK);
    const handleSetMapOverlayKey = (newKey) => {
        setOverlayKey(newKey);
    };
    return (_jsxs(_Fragment, { children: [activeAsset
                ? (_jsx(AssetFeatureViewerModal, { feature: activeAsset, onClose: onCloseAssetFeatureViewerModal }))
                : null, _jsxs(StickyContainer, { position: "fullLeft", stickContent: "fullLeft", isPadded: true, children: [_jsx(FloatingContainer, { className: styles.assetsClassFiltersContainer, children: _jsx(AssetsClassFilters, { ...assetsVisibilityFiltersByClassNameProps }) }), _jsxs(StickyContainerPanel, { className: styles.leftControlsContainer, children: [_jsx("div", { children: "\u00A0" }), _jsx("div", { children: "\u00A0" }), _jsxs(FloatingContainer, { children: ["Map mode:\u00A0", _jsx(ToggleMapOverlayKey, { onSetMapOverlayKey: handleSetMapOverlayKey })] })] })] }), _jsx(PreferredMap, { overlayKey: overlayKey, userViewportConfig: userViewportConfig, onClickMap: handleClickOnMap, onClickRoadLyMapClusterSubFeature: handleClickRoadLyMapClusterSubFeature, assetsRegistryConfig: { finalAssetsFeatureCollection } })] }));
};
