import { OWNER } from './userConstants'
import { FILTER, SORT } from './listingConstants'

export const PIPELINE_TYPES = ['GENERAL', 'SPARSE_CLOUD', 'DENSE_CLOUD', 'CAM_LOC']

export const MODULE_TYPE = {
  FILE: 'file',
}

export const MODULE_OUTPUT_NAMES = {
  RAW: 'image-colors-tiles',
  SEMANTIC: 'semantic-tiles',
  XYZ: 'track.xyz',
  GPS: 'gps.csv',
  POSES_FULL: 'poses_full.txt',
  POSES: 'poses.txt',
}

export const STATUS = {
  ACTIVE: 'active',
  FAILED: 'failed',
  SUCCESS: 'success',
  CANCELED: 'canceled',
}

export const PIPELINE_LIST_FILTERS = {
  DEFAULT_LIMIT: 20,
  DEFAULT_SKIP: 0,
  DEFAULT_SORT: SORT.DESC,
  DEFAULT_STATUS: FILTER.ALL,
  DEFAULT_OWNER: OWNER.ALL,
  DEFAULT_WITH_SYSTEM_PIPELINES: false,
  DEFAULT_SESSION: 'all',
  DEFAULT_ORGANIZATION: 'all',
}

export const ROOT_FOLDER = '/IkeotU88aB9tOQ'

export const STATE_RUN_SECTION = {
  RUN_CHUNKED: 'run_chunked',
}

export const TILES_MODULE_OUTPUT_TYPES = {
  RAW: 'raw',
  SEMANTIC: 'semantic',
}

export const DEFAULT_PARAMS = {
  PIPELINE_LIST: {
    LIMIT: 100,
    SKIP: 0,
    SORT: SORT.ASC,
  },
}
