import clsx from 'clsx'
import { ContentFor } from 'components/ContentFor/ContentFor'
import { PATH } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/routingConstants'
import { SORT, ROLES, LOCAL_STORAGE_KEYS } from 'const'
import { SortableDateColumn } from 'components/SortableDateColumn/SortableDateColumn'
import useWindow from 'hooks/useWindow'
import { useListSortMap } from 'hooks/useListSortMap'
import style from 'features/PipelineList/PipelineListContentHead/PipelineListContentHead.module.scss'

const defaultPipelineSortMap = {
  created: SORT.DESC,
  updated: SORT.DESC,
}

export function PipelineListContentHead({ searchParams }) {
  const { isDesktop } = useWindow()

  // Add accounting search params here:
  const sortMap = useListSortMap(searchParams, LOCAL_STORAGE_KEYS.PIPELINE_LIST_SORT_MAP, defaultPipelineSortMap)

  return (
    <>
      <div className={style.listHeaderGridContainer}>
        <ContentFor role={ROLES.ADMIN}>
          <div className={clsx(style.headCol, style.actionsCell)}>Actions</div>
        </ContentFor>
        <div className={clsx(style.headCol, style.pipelineCell)}>Pipeline UUID</div>
        <div className={clsx(style.headCol, style.statusCell)}>Status</div>
        <div className={clsx(style.headCol, style.emailCell)}>Email</div>
        <SortableDateColumn
          url={PATH.index.path}
          className={style.headCol}
          lastSortOrder={sortMap.created}
          searchParams={searchParams}
          fieldName="created"
          label="Created"
        />
        <SortableDateColumn
          url={PATH.index.path}
          lastSortOrder={sortMap.updated}
          searchParams={searchParams}
          fieldName="updated"
          label="Updated"
        />
      </div>
    </>
  )
}
