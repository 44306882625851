import * as turf from '@turf/turf';
import { fcFromFeatures } from '../geoJson.utils';
const demoPolygon = {
    type: 'MultiPolygon',
    coordinates: [
        [
            [
                [-122.1057836250049, 47.6521663369788],
                // Under the road
                [-122.12615970771509, 47.66663700502181],
                // Above th road
                [-122.12573053327861, 47.667953205573404],
                [-122.12487218440573, 47.67174110974255],
                [-122.12301242851449, 47.67231890146572],
                [-122.12272631222358, 47.67488678728236],
                [-122.12720880078193, 47.67629907061944],
                [-122.1446181833523, 47.68698698948603],
                [-122.16367556384851, 47.679827345413955],
                [-122.17676316250245, 47.680651527151305],
                [-122.1942132940411, 47.671636331804564],
                [-122.19030997514429, 47.65241588869087],
                [-122.168504901409, 47.61816648583305],
                [-122.11047954408107, 47.616494739111566],
            ],
        ],
    ],
};
function isSampleUnitFeature(feature) {
    return Array.isArray(feature.geometry.coordinates[0]);
}
export function filterPciMapDataForDemoUser(initialFeatureCollection) {
    const filterFn = (feature) => {
        try {
            return turf.booleanPointInPolygon(isSampleUnitFeature(feature)
                ? feature.geometry.coordinates[0]
                : feature.geometry.coordinates, demoPolygon);
        }
        catch (e) {
            console.error('ERROR:::', e);
            console.warn('FEATURE:::', feature);
        }
    };
    return fcFromFeatures(initialFeatureCollection.features.filter(filterFn));
}
