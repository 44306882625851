import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useImmer } from 'use-immer'
import Loader from 'components/Loader/Loader'
import PipelineItem from 'components/PipelineItem/PipelineItem'
import API from 'utils/api'
import { DEFAULT_USER_VIEWPORT_CONFIG, PROGRESS } from 'const'
import {
  getPipelineTrack,
  getPoses,
  getPointCloud,
  getExposedFileUrl,
  getPCIData,
  getViewportParams,
  getCameraLocalization,
} from 'utils'
import { useProfile } from '@roadar-pipeline-viewer/roadly-typescript/dist/hooks/useProfile'

const DEFAULT_STATE = {
  progress: PROGRESS.IDLE,
  pipeline: {},
  track: undefined,
  pointCloud: undefined,
  poses: undefined,
  pciData: undefined,
  cameraLocalization: undefined,
  viewport: DEFAULT_USER_VIEWPORT_CONFIG,
}

function PipelineItemPage() {
  const { uuid } = useParams()
  const [state, setState] = useImmer({ ...DEFAULT_STATE })

  const handleProgressChange = progress => {
    setState(draft => {
      draft.progress = progress
    })
  }

  const { profile } = useProfile()

  useEffect(() => {
    const getPageData = async () => {
      handleProgressChange(PROGRESS.WORK)

      const pipeline = await API.getPipelineItem({ uuid }).catch(() => handleProgressChange(PROGRESS.ERROR))

      if (pipeline) {
        const track = await getPipelineTrack(pipeline)
        const pointCloud = await getPointCloud(pipeline)
        const poses = await getPoses(pipeline)
        const pciData = await getPCIData(pipeline)
        const cameraLocalization = await getCameraLocalization(pipeline)
        const viewport = track
          ? getViewportParams(track)
          : cameraLocalization
          ? getViewportParams((cameraLocalization.features[0] || {})?.geometry?.coordinates)
          : profile.defaultViewportConfig || DEFAULT_USER_VIEWPORT_CONFIG

        setState(draft => {
          draft.progress = PROGRESS.SUCCESS
          draft.pipeline = pipeline
          draft.track = track
          draft.pointCloud = pointCloud
          draft.poses = poses
          draft.pciData = pciData
          draft.cameraLocalization = cameraLocalization

          if (pipeline.uuid === '477563f5-1af5-4b12-a780-c907b9856713') {
            // Todo: get height from organization
            draft.viewport = {
              ...viewport,
              zoom: 13,
              pitch: 35,
              // position: [0, 0, 0],
            }
          } else {
            draft.viewport = viewport
          }
        })
      } else {
        handleProgressChange(PROGRESS.ERROR)
      }
    }

    getPageData().catch(e => {
      console.error('Error', e)
      handleProgressChange(PROGRESS.ERROR)
    })

    return () => {
      setState({ ...DEFAULT_STATE })
    }
  }, []) //eslint-disable-line

  if (state.progress === PROGRESS.IDLE || state.progress === PROGRESS.WORK) {
    return <Loader />
  }

  if (state.progress === PROGRESS.ERROR) {
    return (
      <div className="error">
        <div>You don't have access to this pipeline or the pipeline doesn't exist.</div>
      </div>
    )
  }

  return (
    <PipelineItem
      pipeline={state.pipeline}
      viewport={state.viewport}
      track={state.track}
      poses={state.poses}
      pciData={state.pciData}
      cameraLocalization={state.cameraLocalization}
      pciFileUrl={getExposedFileUrl(state.pipeline, 'PCI_DATA')}
      pointCloud={state.pointCloud}
    />
  )
}

export default PipelineItemPage
