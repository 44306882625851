export const randomFeatureColors = [
    [91, 192, 235, 255],
    [253, 231, 76, 255],
    [155, 197, 61, 255],
    [195, 66, 63, 255],
    [97, 201, 168, 255],
    [191, 215, 234, 255],
    [82, 72, 156, 255],
    [255, 140, 66, 255],
    [194, 208, 118, 255],
    [255, 160, 253, 255],
    [179, 239, 178, 255],
    [82, 25, 69, 255],
    [218, 255, 230, 255],
    [158, 178, 93, 255],
    [227, 165, 135, 255],
];
