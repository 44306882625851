import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { toast } from 'react-toastify';
import { LoadingStatus } from '../../../../const/appConstants';
import { API } from '../../../../services/ApiService';
import { FrameSegmentStatusControls } from './FrameSegmentStatusControls';
export const FrameSegmentStatusControlsContainer = ({ onRequestReloadFrameSegment, frameSegment, }) => {
    const [changeStatusLoadingStatus, setChangeStatusLoadingStatus] = useState(LoadingStatus.INITIAL);
    const handleChangeFrameSegmentStatus = async (patchBody) => {
        setChangeStatusLoadingStatus(LoadingStatus.LOADING);
        const changeStatusResult = await API.changeFrameSegmentStatus(frameSegment.properties.id, patchBody);
        toast.success('FrameSegment updated');
        onRequestReloadFrameSegment();
        setChangeStatusLoadingStatus(LoadingStatus.SUCCESS);
    };
    return (_jsx(FrameSegmentStatusControls, { frameSegment: frameSegment, changeStatusLoadingStatus: changeStatusLoadingStatus, onChangeFrameSegmentStatus: handleChangeFrameSegmentStatus }));
};
