import { PATH } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/routingConstants'
import useProfile from 'hooks/useProfile'
import { Navigate, useLocation } from 'react-router-dom'

export const PublicPage = ({ children, onlyPublic = true }) => {
  const location = useLocation()
  const { profile } = useProfile()

  if (profile.isAuthorized && onlyPublic) {
    return <Navigate to={PATH.index.path} state={{ from: location }} />
  }

  return children
}
