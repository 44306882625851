import { AggregatedAssetsViewerPage } from '@roadar-pipeline-viewer/roadly-typescript/dist/pages/AggregatedAssetsViewerPage/AggregatedAssetsViewerPage'
import { AssetsPipelineViewerPage } from '@roadar-pipeline-viewer/roadly-typescript/dist/pages/AssetsPipelineViewer.page'
import {
  RequestRestorePasswordPage,
  SubmitRestorePasswordPage,
} from '@roadar-pipeline-viewer/roadly-typescript/dist/pages/RestorePasswordPages'
import { PipelineListPageTs } from '@roadar-pipeline-viewer/roadly-typescript/dist/pages/PipelineListPageTs'
// import { PipelineListPageTs } from './pages/PipelineListPageTS/PipelineListPageTs'
import { ToastContainer } from 'react-toastify'
import { useState, useEffect } from 'react'
import { useImmer } from 'use-immer'
import { createRoot } from 'react-dom/client'
import 'mapbox-gl/dist/mapbox-gl.css'
import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom'

import { AssetsRegistryQaPage } from '@roadar-pipeline-viewer/roadly-typescript/dist/pages/AssetsRegistryQaPage'
import { AuthorizedLayoutWithAppSidebar } from '@roadar-pipeline-viewer/roadly-typescript/dist/layouts/AuthorizedLayoutWithAppSidebar'
import { PciRegistryPage } from '@roadar-pipeline-viewer/roadly-typescript/dist/pages/PciRegistryPage'
import { CamLocPipelineViewerPage } from '@roadar-pipeline-viewer/roadly-typescript/dist/pages/CamLocPipelineViewerPage'
import { VideoTestPage } from '@roadar-pipeline-viewer/roadly-typescript/dist/pages/VideoTestPage/VideoTestPage'
import { DemoAutoLoginPage } from '@roadar-pipeline-viewer/roadly-typescript/dist/pages/DemoAutoLogin.page'

import { GlobalDialogs } from 'components/GlobalDialogs'
import { PublicOrAuthorizedLayout, PublicPage, PrivatePage } from 'layouts'
import { ConfirmCodePage } from 'pages/ConfirmCodePage'
import { DataRequirementsPage } from 'pages/DataRequirementsPage'
import { GaussianViewerPage } from 'pages/GaussianViewerPage'
import { UserGuidePage } from 'pages/UserGuidePage'
import { SignUpPage } from 'pages/SignUpPage'
import { initAppService } from 'services/initAppService'
import 'index.css'

import { PATH } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/routingConstants'
import { ProfileContext } from '@roadar-pipeline-viewer/roadly-typescript/dist/contexts/profileContext'
import DialogContext from 'contexts/dialogContext'
import { ThemeContext, THEME } from '@roadar-pipeline-viewer/roadly-typescript/dist/contexts/themeContext'
import { AppContext } from '@roadar-pipeline-viewer/roadly-typescript/dist/contexts/appContext'

import CreateInvitePage from 'pages/CreateInvitePage/CreateInvitePage'
import { PipelineListPage } from 'pages/PipelineListPage/PipelineListPage'
import SessionsMapPage from 'pages/SessionsMapPage/SessionsMapPage'
import { SessionsListPage } from 'pages/SessionsListPage/SessionsListPage'
import RunPipelinePage from 'pages/RunPipelinePage/RunPipelinePage'
import RerunPipelinePage from 'pages/RerunPipelinePage/RerunPipelinePage'
import AcceptInvitePage from 'pages/AcceptInvitePage/AcceptInvitePage'
import SignIn from 'pages/SignInPage/SignInPage'
import NoMatch from 'pages/NoMatch/NoMatch'

import { apiClient } from '@roadar-pipeline-viewer/roadly-typescript/dist/services/ApiService/ApiClient'
import {
  RoadLyMapContext,
  useInitRoadLyMapContext,
} from '@roadar-pipeline-viewer/roadly-typescript/dist/contexts/roadLyMapContext'
import { MapPickerPage } from '@roadar-pipeline-viewer/roadly-typescript/dist/pages/MapPickerPage'
import PipelineItemPage from 'pages/PipelineItemPage/PipelineItemPage'
import { GrabDemoFrameSegmentsPage } from '@roadar-pipeline-viewer/roadly-typescript/dist/pages/GrabDemoFrameSegments.page/GrabDemoFrameSegments.page'
import { apiHelpers } from '@roadar-pipeline-viewer/roadly-typescript/dist/services/ApiService/api.helpers'

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={PATH.index.path} element={<AuthorizedLayoutWithAppSidebar />}>
          {/*<Route path={PATH.mapPicker.path} element={1} />*/}
          <Route path={PATH.mapPicker.path} element={<PrivatePage children={<MapPickerPage />} />} />
          <Route index element={<PrivatePage children={<PciRegistryPage />} />} />
          <Route path={PATH.miniPci.path} element={<Navigate to={PATH.pciRegistry.path} replace={true} />} />
          <Route path={PATH.pciRegistry.path} element={<PrivatePage children={<PciRegistryPage />} />} />
          <Route
            path={PATH.assetsPipelineViewer.path}
            element={<PrivatePage children={<AssetsPipelineViewerPage />} />}
          />

          <Route path={PATH.pipelinesList.path} element={<PrivatePage children={<PipelineListPage />} />} />

          <Route path={PATH.sessionsList.path} element={<PrivatePage children={<SessionsListPage />} />} />

          <Route path={PATH.pipelineItem.path} element={<PipelineItemPage />} />
          <Route path={PATH.gaussianViewer.path} element={<GaussianViewerPage />} />

          <Route path={PATH.runPipeline.path} element={<PrivatePage children={<RunPipelinePage />} />} />
          <Route path={PATH.rerunPipeline.path} element={<PrivatePage children={<RerunPipelinePage />} />} />
          <Route path={PATH.createInvite.path} element={<PrivatePage children={<CreateInvitePage />} />} />
          <Route path={PATH.sessionsMap.path} element={<PrivatePage children={<SessionsMapPage />} />} />
          <Route
            path={PATH.camLocPipelineViewer.path}
            element={<PrivatePage children={<CamLocPipelineViewerPage />} />}
          />
          <Route
            path={PATH.aggregatedAssetsViewer.path}
            element={<PrivatePage children={<AggregatedAssetsViewerPage />} />}
          />
          <Route path={PATH.assetsRegistryQa.path} element={<PrivatePage children={<AssetsRegistryQaPage />} />} />

          <Route path={'/pipelines-ts'} element={<PrivatePage children={<PipelineListPageTs />} />} />
          <Route path={'/test-page'} element={<PrivatePage children={<VideoTestPage />} />} />
          <Route path={'/grab-demo-data'} element={<PrivatePage children={<GrabDemoFrameSegmentsPage />} />} />
        </Route>
        <Route element={<Outlet />}>
          <Route path={PATH.signIn.path} element={<PublicPage children={<SignIn />} />} />
          <Route path={PATH.signUp.path} element={<PublicPage children={<SignUpPage />} />} />
          <Route path={PATH.confirmCode.path} element={<PublicPage children={<ConfirmCodePage />} />} />
          <Route
            path={PATH.requestRestorePassword.path}
            element={<PublicPage children={<RequestRestorePasswordPage />} />}
          />
          <Route
            path={PATH.restorePassword2stage.path}
            element={<PublicPage children={<SubmitRestorePasswordPage />} />}
          />
          <Route path={PATH.acceptInvite.path} element={<PublicPage children={<AcceptInvitePage />} />} />
        </Route>
        {/* Pages available both publicly and authorized below */}
        <Route element={<PublicOrAuthorizedLayout />}>
          <Route path={PATH.demoAutoLogin.path} element={<DemoAutoLoginPage />} />
          <Route
            path={PATH.dataRequirements.path}
            element={<PublicPage onlyPublic={false} children={<DataRequirementsPage />} />}
          />
          <Route path={PATH.userGuide.path} element={<PublicPage onlyPublic={false} children={<UserGuidePage />} />} />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </BrowserRouter>
  )
}

function Root({ user, initialAppState }) {
  // Think of combining appState and profile
  const [appState, setAppState] = useState(initialAppState)
  const [profile, setProfile] = useState(user)
  const [roadLyMapContextState, setRoadLyMapContextState] = useInitRoadLyMapContext()

  const [dialog, setDialog] = useImmer({ isOpen: false, type: null })
  const [theme, setTheme] = useState(THEME.DARK)

  useEffect(() => {
    apiHelpers.initProfileGetterAndSetter({
      setProfile,
      getProfile: () => profile,
    })
  }, [profile, setProfile])

  useEffect(() => {
    document.getElementById('body').classList.add('bp4-dark')
  }, [theme])

  return (
    <AppContext.Provider value={{ appState, setAppState }}>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <DialogContext.Provider value={[dialog, setDialog]}>
          <ProfileContext.Provider value={[profile, setProfile]}>
            <RoadLyMapContext.Provider value={[roadLyMapContextState, setRoadLyMapContextState]}>
              <AppRouter />
              {/* <GlobalDialogs /> */}
              <ToastContainer />
            </RoadLyMapContext.Provider>
          </ProfileContext.Provider>
        </DialogContext.Provider>
      </ThemeContext.Provider>
    </AppContext.Provider>
  )
}

initAppService().then(({ user, initialAppState }) => {
  const container = document.getElementById('root')
  const root = createRoot(container)
  root.render(<Root {...{ user, initialAppState }} />)
})
