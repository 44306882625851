import { useState, useEffect } from 'react'
import { Button, Drawer, Position, DrawerSize, Tabs, Tab } from '@blueprintjs/core'
import styles from './SessionsMapSidebar.module.css'
import TrackProps from './TrackProps'
import SessionsFilters from 'components/SessionsMap/SessionsFilters'

const DEFAULT_STATE = {
  isOpen: false,
  selectedTabId: 'sf',
}

export default function SessionsMapSidebar({ currentTrack, owner, createdFrom, createdTo, applyFilters }) {
  const [state, setState] = useState({ ...DEFAULT_STATE })
  const { isOpen, selectedTabId } = state
  const onOpen = () => setState({ ...state, isOpen: true })
  const onClose = () => setState({ ...state, isOpen: false })
  const setSelectedTabId = tabId => setState({ ...state, selectedTabId: tabId })

  useEffect(() => {
    if (!currentTrack) return
    setState({
      isOpen: true,
      selectedTabId: 'ct',
    })
  }, [currentTrack])

  return (
    <>
      <div className={styles.buttonWrap}>
        <Button icon="menu" onClick={onOpen} />
      </div>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        hasBackdrop={false}
        position={Position.LEFT}
        size={DrawerSize.SMALL}
        title="Sessions Info"
        canOutsideClickClose={false}
        canEscapeKeyClose={false}
      >
        <div className={styles.sidebarContent}>
          <Tabs
            animate={true}
            key={'vertical'}
            vertical={false}
            selectedTabId={selectedTabId}
            onChange={setSelectedTabId}
          >
            <Tab
              id="sf"
              title="Sessions filter"
              panel={
                <SessionsFilters
                  owner={owner}
                  createdFrom={createdFrom}
                  createdTo={createdTo}
                  applyFilters={applyFilters}
                />
              }
            />
            <Tab
              id="ct"
              title="Current track"
              panel={<div>{currentTrack && <TrackProps properties={currentTrack.properties} />}</div>}
            />
          </Tabs>
        </div>
      </Drawer>
    </>
  )
}
