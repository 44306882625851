const namespaceURI = 'http://www.w3.org/2000/svg';
export function createSvgCircleWithLabel(diameter, fillColor, text, fontSize) {
    // Root element
    const svg = document.createElementNS(namespaceURI, 'svg');
    svg.setAttribute('width', String(diameter));
    svg.setAttribute('height', String(diameter));
    const radius = diameter / 2;
    const radiusString = String(radius);
    // Circle
    const circle = document.createElementNS(namespaceURI, 'circle');
    circle.setAttribute('cx', radiusString);
    circle.setAttribute('cy', radiusString);
    circle.setAttribute('r', radiusString);
    circle.setAttribute('fill', fillColor);
    // Label
    const textElement = document.createElementNS(namespaceURI, 'text');
    textElement.setAttribute('x', radiusString);
    textElement.setAttribute('y', `${radius + 4}`); // Todo: discover text vertical alignment;
    textElement.setAttribute('font-size', String(fontSize));
    textElement.setAttribute('font-family', '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif');
    textElement.setAttribute('text-anchor', 'middle');
    textElement.setAttribute('alignment-baseline', 'middle');
    textElement.textContent = text;
    // Append
    svg.appendChild(circle);
    svg.appendChild(textElement);
    // Prepare and return data url
    return svg;
}
export function createDataUrlOfSvgCircleWithLabel(diameter, fillColor, text, fontSize) {
    // Root element
    const svg = createSvgCircleWithLabel(diameter, fillColor, text, fontSize);
    // Prepare and return data url
    const svgString = new XMLSerializer().serializeToString(svg);
    const base64 = btoa(svgString);
    return `data:image/svg+xml;base64,${base64}`;
}
/* - - - - */
/*
export function createSvgCircleWithLabel(diameter, fillColor, text, fontSize) {
  // Root element
  const svg = document.createElementNS(namespaceURI, 'svg');
  svg.setAttribute('width', String(diameter));
  svg.setAttribute('height', String(diameter));

  const radius = diameter / 2;
  const radiusString = String(radius);

  // Circle
  const circle = document.createElementNS(namespaceURI, 'circle');
  circle.setAttribute('cx', radiusString);
  circle.setAttribute('cy', radiusString);
  circle.setAttribute('r', radiusString);
  circle.setAttribute('fill', fillColor);

  // Label
  const textElement = document.createElementNS(namespaceURI, 'text');
  textElement.setAttribute('x', radiusString);
  textElement.setAttribute('y', `${radius + 4}`); // Todo: discover text vertical alignment;
  textElement.setAttribute('font-size', String(fontSize));
  textElement.setAttribute('text-anchor', 'middle');
  textElement.setAttribute('alignment-baseline', 'middle');
  textElement.textContent = text;

  // Append
  svg.appendChild(circle);
  svg.appendChild(textElement);

  // Prepare and return data url
  const svgString = new XMLSerializer().serializeToString(svg);
  const base64 = btoa(svgString);
  return `data:image/svg+xml;base64,${base64}`;
}
*/
