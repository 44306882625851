import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import clsx from 'clsx';
import { Sort } from '../../const/listingConstants';
import { getQueryString } from '../../utils/utils';
import style from './SortableDateColumn.module.css';
const sortToIcon = (s) => {
    if (s === Sort.DESC) {
        return 'sort-desc';
    }
    return 'sort-asc';
};
export const SortableDateColumn = ({ url, searchParams, lastSortOrder, fieldName, label, className }) => {
    const isActive = searchParams.sort_field === fieldName;
    let newSortOrder;
    if (isActive) {
        if (lastSortOrder === Sort.ASC) {
            newSortOrder = Sort.DESC;
        }
        else {
            newSortOrder = Sort.ASC;
        }
    }
    else {
        newSortOrder = lastSortOrder;
    }
    const query = getQueryString(searchParams, { sort_order: newSortOrder, sort_field: fieldName });
    return (_jsxs("div", { className: className, children: [label, _jsx(Link, { tabIndex: 0, to: `${url}?${query}`, className: clsx(style.sort, isActive ? style.activeSort : style.inactiveSort), children: _jsx(Icon, { icon: sortToIcon(lastSortOrder) }) })] }));
};
