import { useState } from 'react';
import { useProfile } from '../../../hooks/useProfile';
import { LoadingStatus } from '../../../const/appConstants';
import { API } from '../../../services/ApiService';
export const useExportPciWithOptions = () => {
    const { profile } = useProfile();
    const [exportLoadingStatus, setExportLoadingStatus] = useState(LoadingStatus.INITIAL);
    const onClickExport = async (format) => {
        setExportLoadingStatus(LoadingStatus.LOADING);
        const result = await API.simpleExportPciData(profile.organization_id, format);
        // downloadString(`pci.${pciExportTypeExtensionsByFormat[format]}`, result);
        setExportLoadingStatus(LoadingStatus.SUCCESS);
    };
    return {
        exportLoadingStatus,
        onClickExport,
    };
};
