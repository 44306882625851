import { useContext } from 'react';
import { ProfileContext } from '../contexts/profileContext';
export function useProfile() {
    const [profile, setProfile] = useContext(ProfileContext);
    const updateProfile = (user) => {
        setProfile(user);
    };
    return {
        profile,
        updateProfile,
    };
}
