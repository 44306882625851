import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { FrameNavigationDirection, pciViewerHotKeyKey, pciViewerHotKeyMap } from '../../../const/pciConstants';
export function usePciFSControls({ onNavigate }) {
    const [isRoiMaskVisible, setIsRoiMaskVisible] = useState(false);
    const toggleRoiMask = () => setIsRoiMaskVisible(!isRoiMaskVisible);
    const [isDistressVisible, setIsDistressVisible] = useState(true);
    const toggleDistress = () => setIsDistressVisible(!isDistressVisible);
    const refs = [
        useHotkeys(pciViewerHotKeyMap[pciViewerHotKeyKey.switchRoiMap], toggleRoiMask),
        useHotkeys(pciViewerHotKeyMap[pciViewerHotKeyKey.switchDistress], toggleDistress),
        useHotkeys(pciViewerHotKeyMap[pciViewerHotKeyKey.nextFrameSegment], () => onNavigate(FrameNavigationDirection.FORWARD)),
        useHotkeys(pciViewerHotKeyMap[pciViewerHotKeyKey.previousFrameSegment], () => onNavigate(FrameNavigationDirection.BACK)),
    ];
    return {
        isDistressVisible,
        isRoiMaskVisible,
        toggleDistress,
        toggleRoiMask,
        refs,
    };
}
