import { useContext } from 'react'
import { ProfileContext } from '@roadar-pipeline-viewer/roadly-typescript/dist/contexts/profileContext'

function useProfile() {
  const [profile, setProfile] = useContext(ProfileContext)

  const updateProfile = user => {
    setProfile(user)
  }

  return {
    profile,
    updateProfile,
  }
}

export default useProfile
