import { WelcomeDialog } from 'components/WelcomeDialog'
import { DIALOGS_TYPES } from 'const'
import { useDialog } from 'hooks/useDialog'

const DIALOGS_COMPONENTS = {
  [DIALOGS_TYPES.WELCOME]: WelcomeDialog,
}

/*
 * Usage of this component commented out 18.04.2024
 * Take time to make sure and remove it completely
 */

export const GlobalDialogs = () => {
  const { isOpen, type, hide } = useDialog()
  const DialogComponent = DIALOGS_COMPONENTS[type]

  return DialogComponent ? <DialogComponent isOpen={isOpen} onClose={() => hide()} /> : null
}
