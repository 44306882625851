import { ProgressBar } from '@blueprintjs/core'
import style from './Loader.module.css'

function Load() {
  return (
    <div className={style.loader}>
      {/* <img src={logoSrc} className={style.logo} alt="RoadAR" /> */}
      <ProgressBar className={style.progress} value={1} />
    </div>
  )
}

export default Load
