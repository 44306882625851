import { getDirectedIndexes } from './getDirectedIndexes';
export function getNeighbourElements(element, array, neighboursToInclude = 2) {
    const indexOfElement = array.indexOf(element);
    if (indexOfElement === -1) {
        throw new Error('Element is not found in array');
    }
    const amountToIncludeOnTheLeft = indexOfElement > neighboursToInclude
        ? neighboursToInclude
        : indexOfElement;
    const neighboursOnTheRight = array.length - indexOfElement;
    const amountToIncludeOnTheRight = neighboursOnTheRight > neighboursToInclude
        ? neighboursToInclude
        : neighboursOnTheRight;
    return array.slice(indexOfElement - amountToIncludeOnTheLeft, indexOfElement + 1 + amountToIncludeOnTheRight);
}
export function getDirectedNeighbourElements({ element, array, direction, amount }) {
    // Todo checking for uniqueness is not yet required, but probably will
    const indexOfElement = array.indexOf(element);
    if (indexOfElement === -1) {
        throw new Error('Element is not found in array');
    }
    const indexesOfElementsToGet = getDirectedIndexes(array, indexOfElement, direction, amount);
    return indexesOfElementsToGet.map((index) => array[index]);
}
