import { jsx as _jsx } from "react/jsx-runtime";
import { DistressColorBySeverity, PCI_PALETTE } from '../../../../const/pciConstants';
import { getSeverityIndexByPci, rgbToHex } from '../../../../utils';
import { wktStringToFlatPolysArray } from '../../../../utils/geometry.utilities/wktStringToFlatPolysArray';
import styles from './PciViewerDistressPolygonBB.module.scss';
export const PciViewerDistressPolygonBB = ({ distressesArray, imageViewType, activeReprKey, commonPci, }) => {
    const commonSeverityIndex = getSeverityIndexByPci(commonPci);
    const commonColor = rgbToHex([...PCI_PALETTE[commonSeverityIndex], 150]);
    const displayedDistresses = distressesArray
        .filter((distress) => distress.type !== 'NONDEFECT')
        .map((distress) => {
        const originalValue = distress.reprs[imageViewType][activeReprKey];
        let originalPoly = [];
        // originalPoly = wktStringToFlatPolysArray(geometryCollectionString);
        if (typeof originalValue === 'string') {
            originalPoly = wktStringToFlatPolysArray(originalValue);
        }
        else {
            originalPoly = [originalValue];
        }
        return {
            id: distress.id,
            color: DistressColorBySeverity[distress.severity],
            polygonStrings: originalPoly
                .map((subPolygon) => subPolygon
                .map(([left, top]) => `${left * 100} ${top * 100}`).join(' ')),
        };
    });
    const handleClickDistress = (distress) => {
        console.log('distress', distress);
    };
    return (_jsx("svg", { width: "100%", height: "100%", viewBox: "0 0 100 100", preserveAspectRatio: "none", className: styles.svgContainer, fill: "none", stroke: "none", strokeWidth: "0.3", children: displayedDistresses.map((displayedDistress) => (displayedDistress.polygonStrings.map((polygonString, polygonIndex) => (_jsx("polygon", { points: polygonString, stroke: "none", onClick: () => handleClickDistress(displayedDistress), 
            // stroke={displayedDistress.color}
            fill: commonColor }, `${displayedDistress.id}.${polygonIndex}`))))) }));
};
