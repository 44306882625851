import { stringToBase64 } from 'utils/utils'

export const isNeedFolderUri = json => {
  const module = json.find(module => module.module.includes('video_preprocessing_module') && module.arguments)
  return Boolean(module)
}

export const getFolderUriFromJSON = json => {
  if (json && Array.isArray(json)) {
    const module = json.find(module => module.module.includes('video_preprocessing_module') && module.arguments)
    if (module) {
      const argument = module.arguments.find(({ name }) => name === 'video_folder_uri')
      if (argument) {
        return argument
      }
    }
  }
  return {}
}

// Todo: move these most recent versions to ts
export const getYaml = json => {
  const module = json.find(module => checkIfIsMonoYamlModule(module))
  if (module) {
    const yamlArgument = module.arguments.find(({ name }) => name === 'mono_yaml')
    if (yamlArgument && yamlArgument.value) {
      return atob(yamlArgument.value)
    }
  }
  return undefined
}

const isMonoYamlRunSectionsMap = {
  run: true,
  run_chunked: true,
  run_dmvio: true,
  run_dmvio_chunked: true,
}
export const checkIfIsMonoYamlModule = module => {
  if (module.module.includes('dsopp_module')) {
    if (isMonoYamlRunSectionsMap[module.run_section]) {
      return true
    }
  }
  return false
}

// Todo: move these most recent versions to ts
export const addYamlToDsoppModules = (json, yamlText) => {
  if (json && Array.isArray(json)) {
    const yamlBase64 = stringToBase64(yamlText)
    json.forEach(module => {
      if (checkIfIsMonoYamlModule(module)) {
        if (module.arguments.find(({ name }) => name === 'mono_yaml')) {
          const argument = module.arguments.find(({ name }) => name === 'mono_yaml')
          argument.value = yamlBase64
        } else {
          module.arguments.push({
            name: 'mono_yaml',
            source_type: 'USER_INPUT',
            value: yamlBase64,
          })
        }
      }
    })
  }
  return json
}

export const removeYamlFromDsoppModules = json => {
  if (json && Array.isArray(json)) {
    json.forEach(module => {
      if (checkIfIsMonoYamlModule(module)) {
        module.arguments = module.arguments.filter(({ name }) => name !== 'mono_yaml')
      }
    })
  }
  return json
}
