import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Card } from '@blueprintjs/core';
import clsx from 'clsx';
import { PATH } from '../../const/routingConstants';
import { PageVideoWrapper } from '../../components/PageVideoWrapper';
import { RoadlyLogoWithLabel } from '../../components/Logo/RoadlyLogo';
import { SubmitRestorePasswordForm } from '../../features/auth/RestorePassword/SubmitRestorePassword';
import { API } from '../../services/ApiService';
import style from './RestorePassword.module.css';
export const SubmitRestorePasswordPage = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const codeQueryParam = searchParams.get('code') || '';
    const onSubmit = async ({ code, password }) => {
        const isDone = await API.restorePassword2Stage(code, password);
        if (isDone) {
            setTimeout(() => navigate(PATH.signIn.path), 1500);
        }
        return isDone;
    };
    return (_jsx(PageVideoWrapper, { children: _jsx("div", { className: style.inner, children: _jsxs(Card, { className: style.formCt, children: [_jsx("div", { className: style.logoWrapper, children: _jsx(RoadlyLogoWithLabel, { className: style.logo }) }), _jsx("h3", { className: clsx(style.title, 'bp4-heading'), children: "Restore password" }), _jsx(SubmitRestorePasswordForm, { onSubmit: onSubmit, code: codeQueryParam })] }) }) }));
};
