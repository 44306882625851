import { API, apiAsClass } from '../ApiService';
import { PipelineStepExposition } from '../../const/pipelineConstants';
import { bringFeatureCollectionToTheGround } from '../../utils/bringFeatureCollectionToTheGround';
import { fcFromFeatures } from '../../utils/geoJson.utils';
import { enrichFinalAssetsWithIcons, } from '../../features/RoadLyMap/layers/assetsRegistryLayer/enrichFinalAssetsWithIcons';
export class AssetsService {
    constructor() {
        this.exceptions = {
            assetsModuleIsNotFound: 'assetsModuleIsNotFound',
            assetsModuleIsNotReady: 'assetsModuleIsNotReady',
            assetsOutputIsNotFound: 'assetsOutputIsNotFound',
        };
    }
    async supplementRawAssetsWithFinalAssetsData(pipelineId, rawAssetFeatureCollection) {
        const mapping = await apiAsClass.getRawToFinalAssetsMapping({ pipelineId });
        const supplementedFeatures = rawAssetFeatureCollection
            .features
            .map((feature) => ({
            ...feature,
            properties: {
                ...feature.properties,
                class_name: mapping[feature.properties.sign].name,
                icon_url: mapping[feature.properties.sign].url,
            },
        }));
        const fc = fcFromFeatures(supplementedFeatures);
        enrichFinalAssetsWithIcons(fc);
        return fc;
    }
    async getAssetsPipelinePayload(uuid) {
        if (uuid === null) {
            return null;
        }
        const pipeline = await API.getPipelineItem({ uuid });
        const rawAssetsFeatureCollection = await this.getAssetsFromPipeline(pipeline);
        const supplementedAssetsFeatureCollection = await this.supplementRawAssetsWithFinalAssetsData(uuid, rawAssetsFeatureCollection);
        return {
            pipeline,
            assetsFeatureCollection: bringFeatureCollectionToTheGround(supplementedAssetsFeatureCollection),
        };
    }
    // Todo: fix pipeline typing
    async getAssetsFromPipeline(pipeline) {
        const step = pipeline.state.find((aStep) => Object.values(aStep.outputs).find((o) => o.exposed_as === 'ASSETS_GEOJSON'));
        if (!step) {
            throw this.exceptions.assetsModuleIsNotFound;
        }
        if (step.status !== 'success') {
            throw this.exceptions.assetsModuleIsNotReady;
        }
        const assetsOutput = Object.values(step.outputs)
            // @ts-ignore Todo
            .find((el) => el.exposed_as === PipelineStepExposition.ASSETS_GEOJSON);
        if (!assetsOutput) {
            throw this.exceptions.assetsModuleIsNotReady;
        }
        // @ts-ignore Todo:
        const fetchResult = await fetch(assetsOutput.url);
        // Todo: make validator/mapper for making sure raw assets are returned here:
        const json = await fetchResult.json();
        return json;
    }
    async getAssetsSessionsPayload(sessionId) {
        const filters = sessionId ? { hdmp_session_id: sessionId } : {};
        const sessionsResponse = await apiAsClass.getExtendedSessions(filters);
        const features = sessionsResponse.items.map((session) => ({
            ...session.track_line,
            properties: {
                ...session.track_line.properties,
                sessionId: session.id,
            },
        }));
        const activeSession = sessionsResponse.items.find((el) => el.id === sessionId) || null;
        const sessionsFeatureCollection = {
            type: 'FeatureCollection',
            features,
        };
        return {
            // Active session is for sidebar only
            activeSession,
            sessions: sessionsResponse.items,
            sessionsFeatureCollection,
        };
    }
}
export const assetsService = new AssetsService();
