import { PreferredLogoWithLabel } from '@roadar-pipeline-viewer/roadly-typescript/dist/components/Logo/PreferredLogoWithLabel'
import { useState, useRef, useContext, useEffect, useCallback } from 'react'
import clsx from 'clsx'
import { useSearchParams, Link } from 'react-router-dom'
import { FormGroup, InputGroup, Button, Tag, Intent, Card } from '@blueprintjs/core'
import { PageVideoWrapper } from '@roadar-pipeline-viewer/roadly-typescript/dist/components/PageVideoWrapper'
import API from 'utils/api'
import { PROGRESS } from 'const'
import useProfile from 'hooks/useProfile'
import { ThemeContext, THEME } from '@roadar-pipeline-viewer/roadly-typescript/dist/contexts/themeContext'

import style from './AcceptInvite.module.css'

const ERROR_KEY = {
  USER_EMAIL_ALREADY_EXISTS: 'USER_EMAIL_ALREADY_EXISTS',
  PASSWORDS_DID_NOT_MATCH: 'PASSWORDS_DID_NOT_MATCH',
  INVALID_CODE: 'INVALID_CODE',
  REQUIRED_LOGIN: 'REQUIRED_LOGIN',
  REQUIRED_PASSWORD: 'REQUIRED_PASSWORD',
  PASSWORDS_TO_SHORT: 'PASSWORDS_TO_SHORT',
  DEFAULT: 'DEFAULT',
}

const ERROR = {
  USER_EMAIL_ALREADY_EXISTS: 'User with this email already exists',
  PASSWORDS_DID_NOT_MATCH: 'Passwords did not match',
  INVALID_CODE: 'Invalid code',
  REQUIRED_LOGIN: 'Required login',
  REQUIRED_PASSWORD: 'Required password',
  PASSWORDS_TO_SHORT: 'Passwords requires at least 8 characters',
  DEFAULT: 'Something went wrong',
}

function AcceptInvite() {
  const { updateProfile } = useProfile()
  const [searchParams] = useSearchParams()
  const [error, setError] = useState(undefined)

  const [email, setEmail] = useState(searchParams.get('email'))
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [isFormDirty, setIsDirty] = useState(false)

  const [progress, setProgress] = useState(PROGRESS.IDLE)
  const { theme } = useContext(ThemeContext)
  const passwordRef = useRef()
  const repeatPasswordRef = useRef()
  const code = searchParams.get('code')

  const validateForm = useCallback(() => {
    if (!email) {
      setError(ERROR_KEY.REQUIRED_LOGIN)
      return false
    } else if (!password || !repeatPassword) {
      setError(ERROR_KEY.REQUIRED_PASSWORD)
      return false
    } else if (password !== repeatPassword) {
      setError(ERROR_KEY.PASSWORDS_DID_NOT_MATCH)
      return false
    } else if (password.length < 8) {
      setError(ERROR_KEY.PASSWORDS_TO_SHORT)
      return false
    }
    setError(undefined)
    return true
  }, [email, password, repeatPassword])

  useEffect(() => {
    validateForm()
  }, [email, password, repeatPassword, validateForm])

  const onSubmit = async () => {
    if (validateForm()) {
      try {
        setProgress(PROGRESS.WORK)
        const response = await API.acceptInvite({ email, password, code })
        if (response.status === 'ok') {
          updateProfile(response.data)
        } else if (response.status === 'error') {
          setError(response.data.response.data.detail)
          setProgress(PROGRESS.ERROR)
        }
      } catch (error) {
        setProgress(PROGRESS.ERROR)
      }
    }
  }

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      passwordRef.current.focus()
    }
  }

  const onPasswordKeyDown = e => {
    if (e.keyCode === 13) {
      onSubmit()
    }
  }

  return (
    <PageVideoWrapper>
      <Card className={style.form}>
        <div className={style.logoWrapper}>
          <PreferredLogoWithLabel className={style.logo} />
        </div>
        <h3 className={clsx(style.title, 'bp4-heading')}>Accept invite</h3>
        <p className={style.text}>
          Welcome to Roadly! Please enter your email and password to complete the registration.
          <br />
          <br />
          Password must be at least 8 characters long.
          <br />
          <br />
          If you have any questions, please contact us at{' '}
          <a href="mailto:support@road.ly" className="href">
            support@road.ly
          </a>
        </p>
        <FormGroup labelFor="text-input">
          <InputGroup
            autoFocus
            id="email"
            placeholder="some@email.com"
            name="roadar-accept-invite-email"
            value={email}
            onChange={e => {
              setEmail(e.target.value)
              validateForm()
              setIsDirty(true)
            }}
            disabled={progress === PROGRESS.WORK}
            onKeyDown={onKeyDown}
          />
        </FormGroup>
        <FormGroup labelFor="text-input">
          <InputGroup
            inputRef={passwordRef}
            id="password"
            placeholder="password"
            name="roadar-accept-invite-password"
            type="password"
            value={password}
            onChange={e => {
              setPassword(e.target.value)
              validateForm()
              setIsDirty(true)
            }}
            disabled={progress === PROGRESS.WORK}
            onKeyDown={onPasswordKeyDown}
          />
        </FormGroup>
        <FormGroup labelFor="text-input">
          <InputGroup
            inputRef={repeatPasswordRef}
            id="repeat-password"
            placeholder="repeat password"
            name="roadar-accept-invite-repeat-password"
            type="password"
            value={repeatPassword}
            onChange={e => {
              setRepeatPassword(e.target.value)
              validateForm()
              setIsDirty(true)
            }}
            disabled={progress === PROGRESS.WORK}
            onKeyDown={onPasswordKeyDown}
          />
        </FormGroup>
        {error && isFormDirty && (
          <div className={style.error}>
            <Tag intent={Intent.DANGER}>{ERROR[error] ? ERROR[error] : ERROR.DEFAULT}</Tag>
          </div>
        )}
        <div className={style.footer}>
          <Button onClick={onSubmit} loading={progress === PROGRESS.WORK} disabled={!!error}>
            Accept
          </Button>
        </div>
        <div className={style.linkToSignIn}>
          <p>or</p>
          <Link to={'/signin'}>Sign in</Link>
        </div>
      </Card>
    </PageVideoWrapper>
  )
}

export default AcceptInvite
