export const setPlayerTimeByPose = (pose, fps, playerRef) => {
  if (fps) {
    const currentTime = pose.time / fps
    playerRef.currentTime(currentTime)
  }
}

export const setPoseByPlayerTime = (posesState, fps, playerRef) => {
  const { poses, setPickedPose } = posesState
  playerRef.on('timeupdate', () => {
    const currentTime = playerRef.currentTime() * fps
    const currentPose =
      poses &&
      poses.find(
        (pose, index, arr) =>
          Math.abs(currentTime - pose.time) < (arr[index + 1] ? Math.abs(currentTime - arr[index + 1].time) : Infinity)
      )
    setPickedPose(currentPose)
  })
}

const trackColorMapping = {}

export const getTrackColor = properties => {
  const { id, is_my, has_pipelines } = properties
  if (trackColorMapping[id]) return trackColorMapping[id]
  const idColor = (id % 128) + (id % 8) * 18
  const color = is_my
    ? [255, idColor, parseInt(128 * Math.random()), 255]
    : [idColor, Math.min(70 + parseInt(idColor * 1.2), 255), Math.min(70 + parseInt(idColor * 1.2), 255), 255]
  trackColorMapping[id] = color
  return color
}
