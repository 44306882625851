import { ConfirmCodeContainer } from 'features/ConfirmCode/ConfirmCodeContainer'
import styles from './ConfirmCodePaper.module.scss'

export const ConfirmCodePaper = () => {
  return (
    <div className={styles.confirmCodePaperContainer}>
      <ConfirmCodeContainer />
    </div>
  )
}
