import { SessionsListTabs } from './SessionsListTabs'
import { SessionsListHeader } from './SessionsListHeader'
import { SessionsListContentHead } from './SessionsListContentHead'
import { SessionsListContent } from './SessionsListContent'
import style from './SessionsList.module.css'

function SessionsList({
  progress,
  items,
  limit,
  skip,
  total,
  owner,
  start_time_from,
  start_time_to,
  organization_id,
  is_broken,
  is_invalid,
}) {
  const searchParams = {
    limit,
    skip,
    owner,
    start_time_from,
    start_time_to,
    organization_id,
    is_broken,
    is_invalid,
  }
  return (
    <div className={style.list}>
      <SessionsListTabs />
      <SessionsListHeader
        owner={owner}
        start_time_from={start_time_from}
        start_time_to={start_time_to}
        organization_id={organization_id}
        searchParams={searchParams}
        is_broken={is_broken}
        is_invalid={is_invalid}
      />
      <SessionsListContentHead searchParams={searchParams} />
      <SessionsListContent
        progress={progress}
        searchParams={searchParams}
        items={items}
        limit={limit}
        skip={skip}
        total={total}
      />
    </div>
  )
}

export default SessionsList
