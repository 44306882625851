import { PipelinePreloader } from './PipelinePreloader';
export class PipelineBatchPreloader {
    constructor({ pipelineIdsSequence }) {
        this.preLoadersMap = {};
        this.pipelineIdsSequence = [];
        this.pipelineIdsSequence = pipelineIdsSequence;
    }
    preloadSingle(id) {
        if (!this.preLoadersMap[id]) {
            this.preLoadersMap[id] = new PipelinePreloader();
        }
        return this.preLoadersMap[id].get({ id });
    }
    preloadAll() {
        const promisesArray = this.pipelineIdsSequence.map((id) => this.preloadSingle(id));
        return Promise.allSettled(promisesArray);
    }
}
