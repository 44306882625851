import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { WebMercatorViewport } from '@deck.gl/core/typed';
import { RoadLyMapContext } from '../../../contexts/roadLyMapContext';
import styles from './RoadLyMapPopup.module.scss';
export const RoadLyMapPopup = ({ coordinates, drawPopup }) => {
    const [{ viewState }] = useContext(RoadLyMapContext);
    const viewport = new WebMercatorViewport(viewState);
    const [left, top] = viewport.project(coordinates);
    const style = {
        top: `${top}px`,
        left: `${left}px`,
    };
    return (_jsx("div", { className: styles.popupContainer, style: style, children: drawPopup() }));
};
