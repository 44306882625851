import { Geometry } from '@luma.gl/engine';
import { tan } from './trigonometry.utilities';
export const getCameraColorByVisibilityZone = (vZone, opacity = 255) => {
    switch (vZone) {
        case 'recognition':
            return [33, 135, 33, opacity]; // green
        case 'detection':
            return [247, 189, 0, opacity]; // yellow
        case 'identification':
            return [255, 0, 0, opacity]; // red
        default:
            return [100, 100, 100, opacity]; // gray for undef behavior
    }
};
export const getCamLocPointData = (camLocData) => {
    if (camLocData === null || camLocData.length === 0) {
        return null;
    }
    const camLocPoint = camLocData.find((f) => { var _a; return f.geometry.type === 'Point' && ((_a = f.properties) === null || _a === void 0 ? void 0 : _a.pitch); });
    if (typeof camLocPoint === 'undefined') {
        throw new Error('Cam loc data not found');
    }
    return {
        properties: camLocPoint.properties,
        position: camLocPoint.geometry.coordinates,
        angle: [-camLocPoint.properties.pitch, -90 - camLocPoint.properties.yaw, 0],
        color: [255, 0, 255],
    };
};
export const getCameraPyramidGeometry = (fov_x, fov_y, distance) => {
    console.log({ fov_x, fov_y, distance });
    // Todo: adjust naming of cw, ch
    const cw = distance * tan(fov_x / 2);
    const ch = distance * tan(fov_y / 2);
    const d = distance;
    const pyramidVertices = [
        0.0,
        0.0,
        0.0,
        -d,
        cw,
        ch,
        -d,
        cw,
        -ch,
        -d,
        cw,
        -ch,
        -d,
        -cw,
        -ch,
        0.0,
        0.0,
        0.0,
        -d,
        -cw,
        -ch,
        0.0,
        0.0,
        0.0,
        -d,
        -cw,
        ch,
        -d,
        -cw,
        ch,
        -d,
        cw,
        ch,
        0.0,
        0.0,
        0.0,
    ];
    return new Geometry({
        drawMode: 5, // TRIANGLE_STRIP
        attributes: {
            positions: new Float32Array(pyramidVertices),
        },
    });
};
export const getCameraPyramidGeometryByDistance = (distanceName, camLocPointData) => {
    if (!camLocPointData) {
        return () => {
            throw new Error('Unexpected use of getCamLocPyramidMesh');
        };
    }
    const mesh = getCameraPyramidGeometry(camLocPointData.properties.fov_x, camLocPointData.properties.fov_y, camLocPointData.properties[distanceName]);
    console.log('mesh', mesh);
    return mesh;
};
