import { useState } from 'react'
import { Tab, Tabs, Button } from '@blueprintjs/core'
import { useNavigate } from 'react-router-dom'
import { getQueryString } from 'utils/utils'
import { PATH } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/routingConstants'
import useWindow from 'hooks/useWindow'
import styles from './PipelineListTabs.module.css'

export const DEFAULT_SESSION = 'all'

export function PipelineListTabs({ searchParams, selectedSession }) {
  const { isDesktop } = useWindow()
  const navigate = useNavigate()

  const [tab, setTab] = useState('all')

  const handleTabChange = tab => {
    const query = getQueryString(searchParams, { status: tab, skip: '0' })
    setTab(tab)
    navigate(`/?${query}`)
  }

  const ButtonComponent = () => (
    <div className={styles.button}>
      <Button
        intent="warning"
        outlined
        text={'Sessions on map'}
        onClick={() =>
          navigate({
            pathname: PATH.sessionsMap.path,
          })
        }
      />
      &nbsp;
      <Button
        intent="success"
        outlined
        text={'+ Add new pipeline'}
        onClick={() =>
          navigate({
            pathname: PATH.runPipeline.path,
            search: selectedSession && selectedSession.id !== DEFAULT_SESSION ? `?session=${selectedSession.id}` : '',
          })
        }
      />
    </div>
  )

  return (
    <div className={styles.tabsWrap}>
      {!isDesktop && <ButtonComponent />}
      <div className={styles.tabs}>
        <Tabs id="TabsExample" selectedTabId={tab} onChange={handleTabChange}>
          <Tab id="all" title="All" />
          <Tab id="active" title="Active" />
          <Tab id="canceled" title="Canceled" />
          <Tab id="failed" title="Failed" />
          <Tab id="success" title="Success" />
        </Tabs>
        {isDesktop && <ButtonComponent />}
      </div>
    </div>
  )
}
