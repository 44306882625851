import { useEffect, useState } from 'react'
import style from './CreateInvitePage.module.css'
import { Elevation, Card } from '@blueprintjs/core'
import CreateInviteForm from 'components/CreateInviteForm/CreateInviteForm'
import InvitesList from 'components/InvitesList/InvitesList'
import API from 'utils/api'
import { OWNER } from 'const'

const invitesFilters = {
  owner: OWNER.ME,
}

function CreateInvitePage() {
  const [invites, setInvites] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const updateInvites = () => {
    setIsLoading(true)
    API.getInvites(invitesFilters)
      .then(({ items }) => {
        setInvites(items)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    updateInvites()
  }, [])

  return (
    <div className={style.page}>
      <h2 className="bp4-heading">Create invite</h2>
      <Card className={style.content} elevation={Elevation.THREE}>
        <div className={style.form}>
          <CreateInviteForm updateInvites={updateInvites} />
        </div>
        <div className={style.invitesList}>
          <InvitesList invites={invites} isLoading={isLoading} />
        </div>
      </Card>
    </div>
  )
}

export default CreateInvitePage
