import API from 'utils/api'
import { extractBucketAndPathFromGcsUrl } from './extractBucketAndPathFromGcsUrl'

export const prepareStorageUrl = async ({ url, filename = 'unnamed', isCloudStorageContour }) => {
  if (isCloudStorageContour) {
    const signedUrlParams = extractBucketAndPathFromGcsUrl(url)
    return await API.getSignedUrl(signedUrlParams)
  }
  return url
}
