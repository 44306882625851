import { useState } from 'react'
import { Button } from '@blueprintjs/core'
import style from './PipelineItem.module.css'

function FullScreenWrapper({ text, children, fullscreen, initialOpen = true, toggleFullscreen = () => {} }) {
  const [show, setShow] = useState(initialOpen)

  const onHide = () => {
    setShow(false)
  }

  const onShow = () => {
    setShow(true)
  }

  return (
    <>
      <div
        className={fullscreen ? style.fullPlayer : style.miniPlayer}
        style={{ display: !fullscreen && !show && 'none' }}
      >
        <div className={style.player}>
          {children}
          {!fullscreen && (
            <>
              <Button
                small
                className={style.fullscreenButton}
                icon={fullscreen ? 'minimize' : 'maximize'}
                onClick={toggleFullscreen}
              />
              <Button small className={style.hideButton} icon="cross" onClick={onHide} />
            </>
          )}
        </div>
      </div>
      {!fullscreen && !show && <Button className={style.showButton} icon="maximize" text={text} onClick={onShow} />}
    </>
  )
}

export default FullScreenWrapper
