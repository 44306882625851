import { jsx as _jsx } from "react/jsx-runtime";
import { Intent } from '@blueprintjs/core/lib/esm/common';
import { Tag } from '@blueprintjs/core/lib/esm/components/tag/tag';
import styles from './FormFieldError.module.scss';
export const FormFieldError = ({ fieldError }) => {
    console.log('fieldError', fieldError);
    if (!fieldError) {
        return null;
    }
    return (_jsx(Tag, { multiline: true, fill: true, large: true, intent: Intent.DANGER, className: styles.container, children: fieldError.message }));
};
