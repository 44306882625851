import { getAreaClass, getSeverityClass } from '../pci'
import { getExposedFileUrl } from 'utils/getExposedFileUrl'
import { make2d } from 'utils/make2d'

export const getPCIData = pipeline => {
  const pciFileUrl = getExposedFileUrl(pipeline, 'PCI_DATA')

  return new Promise((resolve, reject) => {
    if (pciFileUrl) {
      const worker = new Worker(new URL('workers/pci.js', import.meta.url))
      worker.postMessage({ url: pciFileUrl })
      worker.onmessage = ({ data: { data } }) => {
        data.features.forEach(f => {
          // flatten data
          f.geometry.coordinates = make2d(f.geometry.coordinates)
          // add derived props
          if (f?.properties?.estimation?.measurements?.AREA) {
            f.properties.AREA_CLASS = getAreaClass(f.properties.estimation.measurements.AREA)
            if (f.properties.estimation.severity)
              f.properties.SEVERITY_CLASS = getSeverityClass(f.properties.estimation.severity, f.properties.AREA_CLASS)
          }
        })
        resolve(data)
        worker.terminate()
      }
    } else {
      resolve(undefined)
    }
  })
}
