import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { API } from '../../../services/ApiService';
import { AggregatedAssetsViewer } from './AggregatedAssetsViewer';
import { enrichFinalAssetsWithIcons } from '../../RoadLyMap/layers/assetsRegistryLayer/enrichFinalAssetsWithIcons';
import { getAssetClassVisibilityMap } from '../../../utils/assets.utilities/getAssetClassVisibilityMap';
const EMPTY_FEATURES_COLLECTION = {
    type: 'FeatureCollection',
    features: [],
};
export const AggregatedAssetsViewerContainer = () => {
    const [allAggregatedAssets, setAllAggregatedAssets] = useState(EMPTY_FEATURES_COLLECTION);
    const [assetsVisibilityFiltersByClassNameMap, setAssetVisibilityFilterByClassNameMap] = useState({});
    const filteredAggregatedAssets = useMemo(() => {
        const filteredFeatures = allAggregatedAssets.features.filter((el) => assetsVisibilityFiltersByClassNameMap[el.properties.class_name].isOn);
        const result = {
            type: 'FeatureCollection',
            features: filteredFeatures,
        };
        return result;
    }, [assetsVisibilityFiltersByClassNameMap, allAggregatedAssets]);
    useEffect(() => {
        const effect = async () => {
            const getAssetsRegistryResult = await API.getFinalAssets();
            // const getAssetsRegistryResult = fcFromFeatures(mock as any);
            enrichFinalAssetsWithIcons(getAssetsRegistryResult);
            setAllAggregatedAssets(getAssetsRegistryResult);
            setAssetVisibilityFilterByClassNameMap(getAssetClassVisibilityMap(getAssetsRegistryResult));
        };
        effect();
    }, []);
    const [activeAsset, setActiveAsset] = useState(null);
    const handleClickAsset = (newActiveAsset) => {
        setActiveAsset(newActiveAsset);
    };
    const handleCloseAssetFeatureViewerModal = () => {
        setActiveAsset(null);
    };
    return (_jsx(AggregatedAssetsViewer, { onCloseAssetFeatureViewerModal: handleCloseAssetFeatureViewerModal, activeAsset: activeAsset, onClickAsset: handleClickAsset, finalAssetsFeatureCollection: filteredAggregatedAssets, assetsVisibilityFiltersByClassNameProps: {
            assetsVisibilityFiltersByClassNameMap,
            onSetAssetVisibilityFilterByClassNameMap: setAssetVisibilityFilterByClassNameMap,
        } }));
};
