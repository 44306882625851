import { useEffect, useState } from 'react'
import RerunPipelineForm from 'components/RerunPipelineForm/RerunPipelineForm'
import API from 'utils/api'
import { Link, useParams } from 'react-router-dom'
import { getYaml } from 'utils/payloadJsonUtils/payloadJsonUtils'
import utils from 'utils/utils'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import style from './RerunPipelinePage.module.css'

function RerunPipelinePage() {
  const { pipeline_uuid, module_uuid } = useParams()
  const [isLoading, setLoading] = useState(true)
  const [isError, setError] = useState(false)
  const [json, setJson] = useState()
  const [initialYaml, setInitialYaml] = useState()

  useEffect(() => {
    API.getPipelineItem({ uuid: pipeline_uuid })
      .then(({ definition }) => {
        if (module_uuid) {
          setJson(definition.filter(({ id }) => id === module_uuid))
        } else {
          setJson(definition)
        }
        const getYamlResult = getYaml(definition)
        setInitialYaml(getYamlResult)
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        setError(true)
      })
  }, [pipeline_uuid, module_uuid])

  const onSubmit = payload =>
    API.rerunPipelineItem({
      pipeline_uuid,
      module_uuid,
      payload,
    })

  if (isLoading) {
    return <ProgressBar />
  }

  if (isError) {
    return (
      <div>
        <h2>Something went wrong. Please, try again later.</h2>
        <p>
          <Link to="/">Go to the home page</Link>
        </p>
      </div>
    )
  }

  return (
    <div className={style.wrap}>
      <div className={style.form}>
        <RerunPipelineForm onSubmit={onSubmit} json={json} initialYaml={initialYaml} />
      </div>
    </div>
  )
}

export default RerunPipelinePage
