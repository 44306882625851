export const getTimeInWordsFromMilliseconds = (time) => {
    if (time < 0) {
        time *= (-1);
    }
    time /= 1000; // milliseconds to seconds
    const seconds = time % 60;
    time = (time - seconds) / 60;
    const minutes = time % 60;
    time = (time - minutes) / 60;
    const hours = time % 24;
    time = (time - hours) / 24;
    const days = time;
    // use only 1-2 the biggest dimensions
    if (days !== 0) {
        if (hours !== 0) {
            return `${time} days ${hours} hours`;
        }
        return `${time} days`;
    }
    if (hours !== 0) {
        if (minutes !== 0) {
            return `${hours} hours ${minutes} minutes`;
        }
        return `${hours} hours`;
    }
    if (minutes !== 0) {
        if (seconds !== 0) {
            return `${minutes} minutes ${seconds} seconds`;
        }
        return `${minutes} minutes`;
    }
    return `${seconds} seconds`;
};
