/**
 * This normalizer is supposed to work only after
 * async app configuration is made and configuration
 * of this is made within it
 */
export class SessionNormalizer {
    normalizeSession(rawSessionListItem) {
        try {
            return {
                ...rawSessionListItem,
            };
        }
        catch (e) {
            console.error('normalizeSession error:', e);
        }
    }
    normalizeGetSessionListResponse(response) {
        return {
            ...response,
            items: response.items.map(this.normalizeSession.bind(this)),
        };
    }
    normalizeGetSessionItemResponse(response) {
        return this.normalizeSession(response);
    }
}
