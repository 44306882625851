import { useState } from 'react';
/**
 * Helper to apply exception details to state in single line
 */
export function useException(SpecificException) {
    const [exception, setFinalException] = useState(null);
    const setException = (input) => {
        if (input instanceof SpecificException) {
            setFinalException(input);
            return;
        }
        // Handle non-custom-exception errors
        console.error('Todo: add exception for this');
        throw input;
    };
    return { exception, setException };
}
