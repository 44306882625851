import { useMemo, useState } from 'react';
import { defaultPciSeverityFilters, pciViewModeKeys } from '../../const/pciConstants';
import { fcFromFeatures } from '../../utils/geoJson.utils';
import { filterPciFeatures } from '../../utils/filterPciData';
// Todo: later move some params down here
export function usePciFeatureFilters({ allSampleUnitsData, allViewpointsData, allCoverageData, }) {
    const [sampleUnitsFilters, setSampleUnitsFilters] = useState(defaultPciSeverityFilters);
    const [viewpointsFilters, setViewpointsFilters] = useState(defaultPciSeverityFilters);
    const [isCoverageModeOn, setIsCoverageModeOn] = useState(false);
    const [pciViewModeKey, setPciViewModeKey] = useState(pciViewModeKeys.combined);
    const [invalidPciVisibilityFilters, setInvalidPciVisibilityFilters] = useState({
        viewpoint: true,
        sampleUnit: true,
    });
    /*
     * Handlers for toggling
     * PCI types and severities
     */
    const handleChangePciSeverityFilters = ({ newViewpointSeverityFilters, newSampleUnitsSeverityFilters,
    // newInvalidPciVisibilityFilters,
     }) => {
        setViewpointsFilters(newViewpointSeverityFilters);
        setSampleUnitsFilters(newSampleUnitsSeverityFilters);
        // setInvalidPciVisibilityFilters(newInvalidPciVisibilityFilters);
    };
    const handleClickPciViewModeKey = (newPciViewModeKey) => setPciViewModeKey(newPciViewModeKey);
    const handleClickCoverageMap = () => setIsCoverageModeOn(!isCoverageModeOn);
    // Filtering
    const filteredSampleUnitsData = useMemo(() => {
        if (pciViewModeKey === pciViewModeKeys.viewpoints) {
            return fcFromFeatures([]);
        }
        const filteredFeatures = filterPciFeatures((allSampleUnitsData === null || allSampleUnitsData === void 0 ? void 0 : allSampleUnitsData.features) || [], sampleUnitsFilters, invalidPciVisibilityFilters.sampleUnit);
        return fcFromFeatures(filteredFeatures);
    }, [allSampleUnitsData, sampleUnitsFilters, pciViewModeKey, invalidPciVisibilityFilters.sampleUnit]);
    const filteredViewpointsData = useMemo(() => {
        if (pciViewModeKey === pciViewModeKeys.sampleUnits) {
            return fcFromFeatures([]);
        }
        const filteredFeatures = filterPciFeatures((allViewpointsData === null || allViewpointsData === void 0 ? void 0 : allViewpointsData.features) || [], viewpointsFilters, invalidPciVisibilityFilters.viewpoint);
        return fcFromFeatures(filteredFeatures);
    }, [allViewpointsData, viewpointsFilters, pciViewModeKey, invalidPciVisibilityFilters.viewpoint]);
    const filteredCoverageData = useMemo(() => {
        if (!isCoverageModeOn) {
            return fcFromFeatures([]);
        }
        const filteredFeatures = (allCoverageData === null || allCoverageData === void 0 ? void 0 : allCoverageData.features) || [];
        return fcFromFeatures(filteredFeatures);
    }, [allCoverageData, isCoverageModeOn]);
    return {
        sampleUnitsFilters,
        // setSampleUnitsFilters,
        viewpointsFilters,
        // setViewpointsFilters,
        isCoverageModeOn,
        // setIsCoverageModeOn,
        pciViewModeKey,
        // setPciViewModeKey,
        invalidPciVisibilityFilters,
        setInvalidPciVisibilityFilters,
        handleChangePciSeverityFilters,
        handleClickPciViewModeKey,
        handleClickCoverageMap,
        filteredSampleUnitsData,
        filteredViewpointsData,
        filteredCoverageData,
    };
}
