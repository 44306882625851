import { Outlet } from 'react-router-dom'

import useProfile from 'hooks/useProfile'
import { AuthorizedLayout } from 'layouts/AuthorizedLayout'

export const PublicOrAuthorizedLayout = () => {
  const { profile } = useProfile()

  if (profile.isAuthorized) {
    return <AuthorizedLayout />
  }

  return <Outlet />
}
