import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { IconLayer } from '@deck.gl/layers/typed';
import GL from '@luma.gl/constants';
import { getFromMapOrFail } from '../../../../utils';
import { createAssetsQaIconsMap } from './createAssetsQaIconsMap';
const staticProps = {
    id: 'assets-registry-qa-layer',
    pickable: true,
    getSize: 4,
    sizeUnits: 'meters',
    sizeMinPixels: 12,
    sizeMaxPixels: 30,
    getPosition: (feature) => feature.geometry.coordinates,
    textureParameters: {
        [GL.TEXTURE_MIN_FILTER]: GL.LINEAR_MIPMAP_LINEAR,
        [GL.TEXTURE_MAG_FILTER]: GL.LINEAR,
        [GL.TEXTURE_WRAP_S]: GL.REPEAT,
        [GL.TEXTURE_WRAP_T]: GL.REPEAT,
    },
};
export const getAssetsRegistryQaLayer = ({ finalAssets }) => {
    const assetsQaIconsMap = useMemo(() => createAssetsQaIconsMap(finalAssets.features), [finalAssets.features]);
    const getIcon = (feature) => {
        return {
            width: 128,
            height: 128,
            url: getFromMapOrFail(assetsQaIconsMap, feature.properties.is_approved)[feature.properties.class_name],
        };
    };
    return (
    // @ts-ignore Todo
    _jsx(IconLayer, { ...staticProps, getIcon: getIcon, data: finalAssets.features }));
};
