export const OWNER = {
  ME: 'me',
  ALL: 'all',
}

export const INVITE_STATUS = {
  CREATED: 'created',
  ACTIVATED: 'activated',
  EXPIRED: 'expired',
}

// Todo: This is moved to typescript package, use it from there.
export const ROLES = {
  SUPERADMIN: 'superadmin',
  ADMIN: 'admin',
  OPERATOR: 'operator',
}
