import { Button, Alignment } from '@blueprintjs/core'
// import { useEffect, useState } from 'react'
import { Select2 } from '@blueprintjs/select'
import { MenuItem2 } from '@blueprintjs/popover2'

export function SimpleSelect({ items, selectedItem, onItemSelect, itemsTextMapping = {} }) {
  return (
    <Select2
      fill
      items={items}
      activeItem={selectedItem}
      onItemSelect={onItemSelect}
      noResults={'No results'}
      itemRenderer={(name, { handleClick, handleFocus, modifiers }) => (
        <MenuItem2
          text={itemsTextMapping[name] || name}
          roleStructure="listoption"
          active={modifiers.active}
          key={name}
          onClick={handleClick}
          onFocus={handleFocus}
        />
      )}
    >
      <Button fill alignText={Alignment.LEFT} rightIcon={'chevron-down'}>
        {itemsTextMapping[selectedItem] || selectedItem}
      </Button>
    </Select2>
  )
}
