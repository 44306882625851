import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { RunAssetsPipelineForm } from './RunAssetsPipelineForm';
import { API } from '../../../services/ApiService';
import { LoadingStatus } from '../../../const/appConstants';
export const RunAssetsPipelineFormContainer = ({ sessionId, className, onPipelineSubmitted }) => {
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.INITIAL);
    const handleRequestRunAssetsPipeline = async (params) => {
        setLoadingStatus(LoadingStatus.LOADING);
        const p = await API.runAssetsPipeline({
            sessionId,
            ...params,
        });
        const response = p.json();
        onPipelineSubmitted();
        setLoadingStatus(LoadingStatus.SUCCESS);
    };
    return (_jsx(RunAssetsPipelineForm, { onRequestRunAssetsPipeline: handleRequestRunAssetsPipeline, loadingStatus: loadingStatus, className: className }));
};
