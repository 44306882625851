import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonGroup } from '@blueprintjs/core/lib/esm/components/button/buttonGroup';
import { Button } from '@blueprintjs/core/lib/esm/components/button/buttons';
import { Intent } from '@blueprintjs/core/lib/esm/common';
import { LoadingStatus } from '../../../../const/appConstants';
import { LoadingOverlay } from '../../../../components';
import styles from './FrameSegmentStatusControls.module.scss';
import { pciFeatureProcessingStatuses } from '../../../../const/pciConstants';
export const FrameSegmentStatusControls = ({ changeStatusLoadingStatus, onChangeFrameSegmentStatus, frameSegment, }) => {
    // Todo:
    const handleChangeFsStatus = (newStatus) => {
        const patchBody = newStatus === 'invalid'
            ? { status: newStatus, description: 'default' }
            : { status: newStatus };
        onChangeFrameSegmentStatus(patchBody);
    };
    return (_jsxs("div", { className: styles.changeStatusContainer, children: [_jsxs(ButtonGroup, { children: [_jsx(Button, { onClick: () => handleChangeFsStatus(pciFeatureProcessingStatuses.valid), text: "Valid", intent: frameSegment.properties.status === pciFeatureProcessingStatuses.valid ? Intent.PRIMARY : undefined }), _jsx(Button, { onClick: () => handleChangeFsStatus('hidden'), text: "Hidden", intent: frameSegment.properties.status === 'hidden' ? Intent.PRIMARY : undefined }), _jsx(Button, { onClick: () => handleChangeFsStatus('invalid'), text: "Invalid", intent: frameSegment.properties.status === 'invalid' ? Intent.PRIMARY : undefined })] }), _jsx("span", { children: changeStatusLoadingStatus === LoadingStatus.LOADING
                    ? _jsx(LoadingOverlay, {})
                    : null })] }));
};
