import { createContext, useState } from 'react';
const defaultValue = {
    viewState: {
        altitude: 0,
        bearing: 0,
        latitude: 0,
        longitude: 0,
        maxPitch: 0,
        maxZoom: 0,
        minPitch: 0,
        minZoom: 0,
        normalize: true,
        pitch: 0,
        position: [0, 0, 0],
        width: 1000,
        height: 1000,
        zoom: 10,
    },
    activeCluster: null,
};
const initialContextPlaceholder = [defaultValue, (newValue) => { }];
export const RoadLyMapContext = createContext(initialContextPlaceholder);
export const useInitRoadLyMapContext = () => {
    return useState(defaultValue);
};
