import { DocumentWrapper } from 'components/DocumentWrapper'
import { UserGuideDocument } from 'components/UserGuideDocument/UserGuideDocument'

export const UserGuidePage = () => {
  return (
    <DocumentWrapper>
      <UserGuideDocument isWide />
    </DocumentWrapper>
  )
}
