import { useMemo, useRef } from 'react';
/**
 * Gives last saved or default map
 */
function getBasicSortMap(localStorageKey, defaultSortMap) {
    const storedString = localStorage.getItem(localStorageKey);
    if (storedString) {
        try {
            return JSON.parse(storedString);
        }
        catch (e) {
            console.error('Sort map parsing error', { localStorageKey, storedString });
            console.error(e);
            return defaultSortMap;
        }
    }
    return defaultSortMap;
}
/**
 * Reduces given state with updated sorting value
 */
function reduceListSortMap(previousState, searchParams) {
    return {
        ...previousState,
        [searchParams.sort_field]: searchParams.sort_order,
    };
}
/**
 * Hook for storing and reusing sorting state
 * of all fields to make sorting user-friendly
 */
export function useListSortMap(searchParams, localStorageKey, defaultSortMap) {
    const ref = useRef();
    return useMemo(() => {
        ref.current = reduceListSortMap(ref.current || getBasicSortMap(localStorageKey, defaultSortMap), searchParams);
        localStorage.setItem(localStorageKey, JSON.stringify(ref.current));
        return ref.current;
    }, [searchParams, ref, localStorageKey, defaultSortMap]);
}
