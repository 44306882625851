import { apiAsClass } from '@roadar-pipeline-viewer/roadly-typescript/dist/services/ApiService'
import { useEffect, useCallback } from 'react'
import { useImmer } from 'use-immer'
import { useSearchParams } from 'react-router-dom'
import { Progress } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/appConstants'
import { PipelineListFilters } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/pipelineConstants'
import API from 'utils/api'

const {
  DEFAULT_LIMIT,
  DEFAULT_SKIP,
  DEFAULT_SORT_ORDER,
  DEFAULT_SORT_FIELD,
  DEFAULT_STATUS,
  DEFAULT_OWNER,
  DEFAULT_SESSION,
  DEFAULT_IS_COMPLETE_VIEW,
  DEFAULT_ORGANIZATION,
} = PipelineListFilters

const updatePipelineName = async (uuid, payload) => {
  await API.updatePipelineName(uuid, payload)
}

export const usePipelineList = () => {
  const [searchParams] = useSearchParams()

  const limit = searchParams.get('limit') || DEFAULT_LIMIT
  const skip = searchParams.get('skip') || DEFAULT_SKIP
  const sort_field = searchParams.get('sort_field') || DEFAULT_SORT_FIELD
  const sort_order = searchParams.get('sort_order') || DEFAULT_SORT_ORDER
  const status = searchParams.get('status') || DEFAULT_STATUS
  const owner = searchParams.get('owner') || DEFAULT_OWNER
  const session = searchParams.get('session') || DEFAULT_SESSION
  const organization_id = searchParams.get('organization_id') || DEFAULT_ORGANIZATION
  const modules = searchParams.getAll('modules')
  const modules_str = modules.sort().join(',')
  const types = searchParams.getAll('types')
  const types_str = types.sort().join(',')
  const is_complete_view = !!Number(searchParams.get('is_complete_view')) || DEFAULT_IS_COMPLETE_VIEW
  const pipeline_name = searchParams.get('name')

  const [state, updateState] = useImmer({
    items: [],
    total: 0,
    progress: Progress.IDLE,
  })

  const updateItemPart = (uuid, name, showToastSuccessUpdate, showToastFailureUpdate) => {
    updateState(draft => {
      const item = draft.items.find(item => item.uuid === uuid)
      if (!item) return
      item.name = name
    })
    updatePipelineName(uuid, {
      name: name,
    })
      .then(showToastSuccessUpdate)
      .catch(showToastFailureUpdate)
  }

  const onRequestDataFailed = useCallback(() => {
    updateState(draft => {
      draft.progress = Progress.ERROR
    })
  }, [updateState])

  const fetchAndUpdatePipelineListState = useCallback(
    params => {
      updateState(draft => {
        draft.progress = Progress.WORK
        draft.items = []
      })

      apiAsClass
        .getPipelineList(params)
        .then(req => {
          updateState(draft => {
            draft.progress = Progress.SUCCESS
            draft.items = req.items
            draft.total = req.meta.total
          })
        })
        .catch(onRequestDataFailed)
    },
    [updateState, onRequestDataFailed]
  )

  const updatePipelineList = useCallback(() => {
    const modules_or_null = modules.length > 0 ? modules : null
    const types_or_null = types.length > 0 ? types : null
    fetchAndUpdatePipelineListState({
      limit,
      skip,
      sort_field,
      sort_order,
      status,
      owner,
      organization_id,
      is_complete_view,
      name: pipeline_name,
      modules: modules_or_null,
      types: types_or_null,
      hdmp_session_id: session,
    })
  }, [fetchAndUpdatePipelineListState, modules])

  useEffect(() => {
    updatePipelineList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    limit,
    skip,
    sort_field,
    sort_order,
    status,
    owner,
    session,
    modules_str,
    is_complete_view,
    types_str,
    pipeline_name,
    organization_id,
  ])

  return {
    progress: state.progress,
    items: state.items,
    total: state.total,
    limit: limit,
    skip: skip,
    sort_field,
    sort_order: sort_order,
    status: status,
    owner: owner,
    session: session,
    modules: modules,
    is_complete_view: is_complete_view,
    types: types,
    pipeline_name: pipeline_name,
    organization_id: organization_id,
    updatePipelineList,
    updateItemPart: updateItemPart,
  }
}
