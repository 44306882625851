import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox } from '@blueprintjs/core';
import { Divider } from '@blueprintjs/core/lib/esm/components';
import { getTypedRecordEntries } from '../../../utils/array.utilities/typedArray.utilities';
import { capitalize } from '../../../utils';
import styles from './FinalAssetsQaVisibilityFilters.module.scss';
export const FinalAssetsQaVisibilityFilters = ({ finalAssetsQaVisibilityFilters, onClickVisibilityFilter, }) => {
    const entries = getTypedRecordEntries(finalAssetsQaVisibilityFilters);
    return (_jsxs("div", { children: [_jsx("div", { className: styles.row, children: "QA status:" }), _jsx(Divider, {}), entries.map(([key, value]) => (_jsxs("div", { className: styles.row, children: [_jsx(Checkbox, { checked: value, onChange: () => onClickVisibilityFilter(key) }), capitalize(key)] }, key)))] }));
};
