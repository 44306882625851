import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Icon, Intent, Tag } from '@blueprintjs/core';
import { useDownloader } from '../../../hooks/useDownloader';
import style from './PipelineFilesList.module.css';
const filterOutputs = ({ outputs, isBrief }) => {
    let toReturn = outputs.filter((item) => item.unix_type === 'file');
    if (isBrief) {
        toReturn = toReturn.filter((item) => {
            if (!item.value) {
                return false;
            }
            return (item.value && item.value.includes('source_video.mp4')) || item.value.includes('track.las');
        });
    }
    return toReturn;
};
export const PipelineFilesList = ({ outputs, isBrief }) => {
    const { handleDownload } = useDownloader();
    // Return null for no outputs or empty list
    if (!(outputs === null || outputs === void 0 ? void 0 : outputs.length)) {
        return null;
    }
    const filteredOutputs = filterOutputs({ outputs, isBrief });
    return (_jsx(_Fragment, { children: filteredOutputs.map((item) => {
            const urlParts = item.url.split('/');
            const name = urlParts[urlParts.length - 1];
            return (_jsx("a", { onClick: (event) => handleDownload({ event, url: item.url, filename: item.gui_name }), href: item.url, rel: "noopener noreferrer", title: item.gui_name, className: style.output, children: _jsxs(Tag, { intent: Intent.PRIMARY, children: [_jsx(Icon, { icon: "document", iconSize: 12, className: "icon" }), name] }) }, item.url));
        }) }));
};
