import { ButtonGroup, Button } from '@blueprintjs/core'
import { PATH } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/routingConstants'
import { Link, useNavigate } from 'react-router-dom'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import { PROGRESS, PIPELINE_LIST_FILTERS } from 'const'
import { getQueryString } from 'utils/utils'

import { PipelineListItem } from '../PipelineListItem'

import style from './PipelineListContent.module.css'

const { DEFAULT_SESSION } = PIPELINE_LIST_FILTERS

function PipelineListEmpty({ status }) {
  return (
    <div className={style.empty}>
      We don't have any pipelines with status:&nbsp;<b>{status}</b>
    </div>
  )
}

function PipelineListWithSessionEmpty({ session }) {
  const navigate = useNavigate()

  return (
    <div className={style.empty}>
      We don't have any pipelines with name:&nbsp;<b>{session.folder_name}</b>
      <Button
        intent="success"
        className={style.emptyButton}
        outlined
        text={`Run pipeline with session ${session.folder_name}`}
        onClick={() =>
          navigate({
            pathname: PATH.runPipeline.path,
            search: `?session=${session.id}`,
          })
        }
      />
    </div>
  )
}

function PipelineListError() {
  return <div className={style.empty}>Something went wrong. Please try again later.</div>
}

function PaginationHref({ label, disabled, skip, searchParams }) {
  const query = getQueryString(searchParams, { skip })
  if (disabled) {
    return <Button disabled={disabled}>{label}</Button>
  }
  return (
    <Link role="button" tabIndex={0} to={`/pipelines/?${query}`} className={'bp4-button'}>
      {label}
    </Link>
  )
}

function PipelineListPagination({ limit, skip, total, searchParams }) {
  const _limit = parseInt(limit, 10)
  const _skip = parseInt(skip, 10)
  const _total = parseInt(total, 10)

  const hasNextPage = _skip + _limit < _total
  const hasPreviousPage = _skip > 0

  return (
    <div className={style.pagination}>
      <ButtonGroup>
        <PaginationHref
          label={'Prev page'}
          disabled={!hasPreviousPage}
          skip={hasPreviousPage ? _skip - _limit : 0}
          searchParams={searchParams}
        />
        <PaginationHref
          label={'Next page'}
          disabled={!hasNextPage}
          skip={hasNextPage ? _skip + _limit : _skip}
          searchParams={searchParams}
        />
      </ButtonGroup>
    </div>
  )
}

export function PipelineListContent({
  progress,
  items,
  limit,
  skip,
  status,
  session,
  total,
  updatePipelineList,
  selectedSession,
  searchParams,
  updateItemPart,
}) {
  if (progress === PROGRESS.WORK) {
    return <ProgressBar />
  }

  if (items.length === 0 && session !== DEFAULT_SESSION && selectedSession) {
    return <PipelineListWithSessionEmpty session={selectedSession} />
  }

  if (items.length === 0 && progress === PROGRESS.SUCCESS) {
    return <PipelineListEmpty status={status} />
  }

  if (items.length === 0 && progress === PROGRESS.ERROR) {
    return <PipelineListError />
  }
  return (
    <>
      {items.map(item => (
        <PipelineListItem
          key={item.uuid}
          item={item}
          updatePipelineList={updatePipelineList}
          updateItemPart={updateItemPart}
        />
      ))}
      <PipelineListPagination limit={limit} skip={skip} total={total} searchParams={searchParams} />
    </>
  )
}
