import { metersToMiles } from '../miscUtils';
class OrganizationSessionsDistanceNormalizer {
    async normalize(response) {
        try {
            const data = await response.json();
            const distanceAsMiles = metersToMiles(data.distance);
            return {
                distance: data.distance,
                distanceAsMiles,
                organizationId: data.organizationId,
            };
        }
        catch (e) {
            console.error('Following error occurred while normalizing organization distance response', e);
            throw e;
        }
    }
}
export const organizationSessionsDistanceNormalizer = new OrganizationSessionsDistanceNormalizer();
