class PciQaNormalizer {
    async normalizeGetPciQaSessionStatsResponse(response) {
        const json = await response.json();
        return json.features;
    }
    async normalizeGetFrameSegmentResponse(response) {
        const json = await response.json();
        return json.status;
    }
    async normalizePatchFrameSegmentResponse(response) {
        await response.json();
        return null;
    }
    async normalizeExportPciQaSessionResponse(response) {
        return response.json();
    }
}
export const pciQaNormalizer = new PciQaNormalizer();
