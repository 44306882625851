import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Card } from '@blueprintjs/core';
import clsx from 'clsx';
import { PreferredLogoWithLabel } from '../../../../components/Logo/PreferredLogoWithLabel';
import { RequestRestorePasswordFormContainer } from './RequestRestorePasswordFormContainer';
import styles from '../RestorePassword.module.scss';
export const RequestRestorePasswordPaper = () => {
    const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);
    const handleSuccessfulRequest = useCallback(() => setIsRequestSuccessful(true), []);
    return (_jsx(Card, { children: _jsxs("div", { className: styles.paperContainer, children: [_jsx("div", { className: styles.centeredBlock, children: _jsx(PreferredLogoWithLabel, { className: styles.logo }) }), isRequestSuccessful ? (_jsx("div", { children: _jsxs("div", { className: clsx(styles.centeredBlock, styles.extraMarginBottom), children: ["Check your email for", _jsx("br", {}), "further instructions"] }) })) : (_jsxs("div", { children: [_jsx("div", { className: clsx(styles.centeredBlock, styles.extraMarginBottom), children: "Type in your email to reset password" }), _jsx(RequestRestorePasswordFormContainer, { onSuccess: handleSuccessfulRequest })] }))] }) }));
};
