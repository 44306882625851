const vs = `\
#define SHADER_NAME point-cloud-layer-vertex-shader

attribute vec3 positions;
attribute vec3 instanceNormals;
attribute vec4 instanceColors;
attribute vec3 instancePositions;
attribute vec3 instancePositions64Low;
attribute vec3 instancePickingColors;

uniform float opacity;
uniform float radiusPixels;
uniform int sizeUnits;
uniform float palette[65*3];

varying vec4 vColor;
varying vec3 paletteColor;
varying vec2 unitPosition;
varying vec3 adjustedInstancePositions;

int idx;

void main(void) {
  adjustedInstancePositions = vec3(instancePositions.x, instancePositions.y, instancePositions.z + 10.0);
  geometry.worldPosition = adjustedInstancePositions;
  geometry.normal = project_normal(instanceNormals);

  // position on the containing square in [-1, 1] space
  unitPosition = positions.xy;
  geometry.uv = unitPosition;
  // geometry.pickingColor = instancePickingColors;

  // Find the center of the point and add the current vertex
  vec3 offset = vec3(positions.xy * project_size_to_pixel(radiusPixels, sizeUnits), 0.0);
  DECKGL_FILTER_SIZE(offset, geometry);

  gl_Position = project_position_to_clipspace(adjustedInstancePositions, instancePositions64Low, vec3(0.), geometry.position);
  gl_Position.xy += project_pixel_size_to_clipspace(offset.xy);
  DECKGL_FILTER_GL_POSITION(gl_Position, geometry);

  idx = int(floor(0.5 + 255.0 * instanceColors.r));
  paletteColor.r = palette[idx];
  paletteColor.g = palette[65 + idx];
  paletteColor.b = palette[65*2 + idx];

  if (paletteColor.r < 0.0) {
    vColor = vec4(0.0, 0.0, 0.0, 0.0);
    DECKGL_FILTER_COLOR(vColor, geometry);
    return;
  }

  // Apply lighting
  // vec3 lightColor = lighting_getLightColor(paletteColor, project_uCameraPosition, geometry.position.xyz, geometry.normal);

  // Apply opacity to instance color, or return instance picking color
  // vColor = vec4(lightColor, instanceColors.a * opacity);
  vColor = vec4(paletteColor, 1.0);
  geometry.pickingColor = vec3(idx, 0.0, 0.0);
  DECKGL_FILTER_COLOR(vColor, geometry);
}
`

export default vs
