import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useState, useEffect } from 'react';
import { ButtonGroup, Button, Alignment, Card, Elevation, Switch, InputGroup, FormGroup, Icon } from '@blueprintjs/core';
import { Select2, MultiSelect2 } from '@blueprintjs/select';
import { Popover2, MenuItem2 } from '@blueprintjs/popover2';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { getQueryString, reshapeModules, appendDistance } from '../../../utils/utils';
import { formatDate } from '../../../utils';
import { TYPES_DISTANCE_FOR_RENDER } from '../../../const/distanceConstants';
import { API, apiAsClass } from '../../../services/ApiService';
import { ContentFor } from '../../../components/ContentFor';
import { Owner, ROLES } from '../../../const/userConstants';
import { PipelineListFilters } from '../../../const/pipelineConstants';
import styles from './PipelineListHeader.module.scss';
const { DEFAULT_SESSION, DEFAULT_SKIP, DEFAULT_ORGANIZATION } = PipelineListFilters;
const TypeHref = ({ value, searchParams }) => {
    const searchParamsWithSkip = { ...searchParams, skip: DEFAULT_SKIP };
    const valueIsIncludeSearchParams = searchParams.types.includes(value);
    const searchTypesParamModify = valueIsIncludeSearchParams
        ? searchParams.types.filter((item) => item !== value)
        : [...searchParams.types, value];
    const query = getQueryString(searchParamsWithSkip, { types: searchTypesParamModify });
    return (_jsxs(Link, { role: "button", tabIndex: 0, to: `/pipelines-ts/?${query}`, className: clsx('bp4-button', { 'bp4-active': valueIsIncludeSearchParams }), children: [_jsx("span", { className: styles.value_type_popover, children: value }), valueIsIncludeSearchParams ? (_jsx(Icon, { icon: "small-tick", size: 24, className: styles.icon_type_popover })) : (_jsx("div", { className: styles.nbsp_type_popover, children: "\u00A0" }))] }));
};
const PipelineListHeaderComponent = ({ limit, skip, sort_order, sort_field, status, owner, session, modules, types, is_complete_view, pipeline_name, uuid, organization, selectedOrganization, setSelectedOrganization, selectedSession, setSelectedSession, selectedModules, setSelectedModules, }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = {
        limit,
        skip,
        sort_order,
        sort_field,
        status,
        owner,
        session,
        modules,
        types,
        organization,
        name: pipeline_name,
        uuid,
        is_complete_view,
    };
    const [sessions, setSessions] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [isSessionLoading, setIsSessionLoading] = useState(true);
    const [isOrganizationLoading, setIsOrganizationLoading] = useState(true);
    const [modulesConstants, setModulesConstants] = useState([]);
    const [isModulesLoading, setIsModulesLoading] = useState(true);
    const [emailsConstants, setEmailsConstants] = useState([]);
    const [isEmailsLoading, setIsEmailsLoading] = useState(true);
    const [pipelineNameInput, setPipelineNameInput] = useState(pipeline_name || '');
    const [emailInput, setEmailInput] = useState();
    const [uuidInput, setUuidInput] = useState(uuid || '');
    const [pipelineTypes, setPipelineTypes] = useState([]);
    const commonPartEmailsList = [
        { email: Owner.ALL, description: 'Select all users' },
        { email: Owner.ME, description: 'Select only you' },
    ];
    const handleInputPipelineName = () => {
        const searchParamsWithSkip = { ...searchParams, skip: DEFAULT_SKIP };
        const query = getQueryString(searchParamsWithSkip, { name: pipelineNameInput || null });
        navigate({
            ...location,
            search: `?${query}`,
        });
    };
    const onInputEmail = (emailInput) => {
        setEmailInput(emailInput);
        const searchParamsWithSkip = { ...searchParams, skip: DEFAULT_SKIP };
        const query = getQueryString(searchParamsWithSkip, { owner: emailInput.email });
        navigate({
            ...location,
            search: `?${query}`,
        });
    };
    const handleUuidChange = () => {
        const searchParamsWithSkip = { ...searchParams, skip: DEFAULT_SKIP };
        const query = getQueryString(searchParamsWithSkip, { uuid: uuidInput });
        navigate({
            ...location,
            search: `?${query}`,
        });
    };
    const onItemRemove = (tag, index) => {
        const newSelectedModules = selectedModules.filter((module) => module !== tag);
        setSelectedModules(newSelectedModules);
        const query = getQueryString(searchParams, { modules: newSelectedModules });
        navigate({
            ...location,
            search: `?${query}`,
        });
    };
    const onItemMultiSelect = (item) => {
        if (selectedModules.indexOf(item.id) === -1) {
            selectedModules.push(item.id);
            const query = getQueryString(searchParams, { modules: selectedModules });
            navigate({
                ...location,
                search: `?${query}`,
            });
        }
    };
    const clearAllModulesTags = () => {
        setSelectedModules([]);
        const query = getQueryString(searchParams, { modules: null });
        navigate({
            ...location,
            search: `?${query}`,
        });
    };
    const onItemSelect = (item) => {
        setSelectedSession(item);
        const searchParamsWithSkip = { ...searchParams, skip: DEFAULT_SKIP };
        const query = getQueryString(searchParamsWithSkip, { session: item.id });
        navigate({
            ...location,
            search: `?${query}`,
        });
    };
    const onOrganizationSelect = (organization) => {
        setSelectedOrganization(organization);
        const searchParamsWithSkip = { ...searchParams, skip: DEFAULT_SKIP };
        const query = getQueryString(searchParamsWithSkip, { organization: organization.id });
        navigate({
            ...location,
            search: `?${query}`,
        });
    };
    useEffect(() => {
        setIsModulesLoading(true);
        API.getModules()
            .then((all_modules) => {
            const reformat_modules = reshapeModules(all_modules);
            setSelectedModules([...modules]);
            setModulesConstants(reformat_modules);
            setIsModulesLoading(false);
        })
            .catch(() => {
            setIsModulesLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setIsEmailsLoading(true);
        API.getEmails(organization)
            .then((all_emails) => {
            let emailSelected;
            if (!Array.isArray(all_emails)) {
                emailSelected = [...commonPartEmailsList].find((user) => user.email === owner);
            }
            else {
                setEmailsConstants(all_emails);
                emailSelected = [...commonPartEmailsList, ...all_emails].find((user) => user.email === owner);
            }
            setEmailInput(emailSelected);
            setIsEmailsLoading(false);
        })
            .catch(() => {
            const emailSelected = [...commonPartEmailsList].find((user) => user.email === owner);
            setEmailInput(emailSelected);
            setIsEmailsLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization]);
    useEffect(() => {
        setIsSessionLoading(true);
        apiAsClass.getSessions(organization)
            .then(({ items }) => {
            setSessions(items);
            const defaultSession = items.find((item) => item.id === Number(session));
            setSelectedSession(defaultSession);
            setIsSessionLoading(false);
        })
            .catch(() => {
            setIsSessionLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization]);
    useEffect(() => {
        setIsOrganizationLoading(true);
        API.getOrganizations()
            .then((items) => {
            let defaultOrganization;
            if (Array.isArray(items)) {
                setOrganizations(items);
                defaultOrganization = items.find((item) => item.id === Number(organization));
            }
            setSelectedOrganization(defaultOrganization);
            setIsOrganizationLoading(false);
        })
            .catch((e) => {
            setIsOrganizationLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        API.getPipelineTypes().then((pipelineTypesConstants) => {
            setPipelineTypes(pipelineTypesConstants);
        });
    }, []);
    return (_jsx("div", { className: styles.header, children: _jsx(Card, { className: styles.filters, elevation: Elevation.ZERO, children: _jsxs("div", { className: styles.filterItems, children: [_jsx(ContentFor, { role: ROLES.SUPERADMIN, children: _jsx("div", { className: styles.col, children: _jsx("div", { className: styles.formItem, children: _jsx(FormGroup, { label: "Organizations", children: _jsx(Select2, { fill: true, resetOnSelect: true, disabled: isOrganizationLoading || organizations.length === 0, 
                                        // id="organization"
                                        items: [
                                            {
                                                id: DEFAULT_SESSION,
                                                name: 'All',
                                                description: 'All organizations',
                                                owner: { id: 0, email: '' },
                                            },
                                            ...organizations,
                                        ], menuProps: { className: styles.menu }, popoverContentProps: { className: styles.selectorPopoverContent }, activeItem: selectedOrganization, onItemSelect: onOrganizationSelect, noResults: "No results", itemPredicate: (query, item) => {
                                            var _a;
                                            return item.name.toLowerCase().includes(query.toLowerCase())
                                                || ((_a = item.description) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(query.toLowerCase()))
                                                || item.owner.email.toLowerCase().includes(query.toLowerCase());
                                        }, itemRenderer: ({ id, name, description, owner }, { handleClick, handleFocus, modifiers }) => (_jsx(MenuItem2, { text: `${name}(${owner.email})`, label: description, roleStructure: "listoption", active: modifiers.active, onClick: handleClick, onFocus: handleFocus }, id)), children: _jsxs(Button, { className: styles.select, loading: isOrganizationLoading, alignText: Alignment.LEFT, rightIcon: "chevron-down", children: [!isOrganizationLoading && organizations.length === 0 && 'No organizations', organizations.length > 0
                                                    && (selectedOrganization ? selectedOrganization.name : 'Select organization')] }) }) }) }) }) }), _jsx("div", { className: styles.col, children: _jsx("div", { className: styles.formItem, children: _jsx(FormGroup, { label: "Pipeline Type", children: _jsx(Popover2, { interactionKind: "click", placement: "bottom", content: (_jsx(ButtonGroup, { vertical: true, minimal: true, children: pipelineTypes.map((item) => (_jsx(TypeHref, { value: item, searchParams: searchParams }, item))) })), renderTarget: ({ isOpen, ref, ...targetProps }) => (_jsx(Button, { ...targetProps, elementRef: ref, text: types.length > 0 ? `${types.length} selected types` : 'All available types' })) }) }) }) }), _jsx("div", { className: styles.col, children: _jsx("div", { className: styles.formItem, children: _jsx(FormGroup, { label: "Session", children: _jsx(Select2, { fill: true, disabled: isSessionLoading || sessions.length === 0, 
                                    // id="session"
                                    items: [{ id: DEFAULT_SESSION, folder_name: 'All sessions' }, ...sessions], popoverContentProps: { className: styles.selectorPopoverContent }, activeItem: selectedSession, onItemSelect: onItemSelect, noResults: "No results", itemPredicate: (query, item) => item.folder_name.toLowerCase().includes(query.toLowerCase())
                                        || formatDate(item.create_ts).includes(query), itemRenderer: ({ folder_name, create_ts, distance_calc_raw }, { handleClick, handleFocus, modifiers }) => (_jsx(MenuItem2, { text: folder_name, label: create_ts
                                            ? appendDistance(formatDate(create_ts), distance_calc_raw, TYPES_DISTANCE_FOR_RENDER.METERS)
                                            : '', roleStructure: "listoption", active: modifiers.active, onClick: handleClick, onFocus: handleFocus }, folder_name)), children: _jsxs(Button, { className: styles.select, loading: isSessionLoading, alignText: Alignment.LEFT, rightIcon: "chevron-down", children: [!isSessionLoading && sessions.length === 0 && 'No sessions', sessions.length > 0 && (selectedSession ? selectedSession.folder_name : 'Select session')] }) }) }) }) }), _jsx("div", { className: styles.col, children: _jsx(FormGroup, { label: "Email", labelFor: "users_email", children: _jsx(Select2, { fill: true, disabled: isEmailsLoading, 
                                // id="users_email"
                                items: [...commonPartEmailsList, ...emailsConstants], popoverContentProps: {
                                    className: styles.selectorPopoverContent,
                                }, activeItem: emailInput, onItemSelect: onInputEmail, noResults: "No results", itemPredicate: (query, item) => {
                                    var _a, _b;
                                    return (((_a = item.email) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(query.toLowerCase()))
                                        || ((_b = item.description) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(query)));
                                }, itemRenderer: ({ email, description }, { handleClick, handleFocus, modifiers }) => (_jsx(MenuItem2, { text: email, label: (description === null || description === void 0 ? void 0 : description.length) >= 80 ? `${description.slice(0, 80)}...` : description, roleStructure: "menuitem", active: modifiers.active, onClick: handleClick, onFocus: handleFocus }, email)), children: _jsx(Button, { className: styles.select, loading: isEmailsLoading, alignText: Alignment.LEFT, rightIcon: "chevron-down", children: emailInput ? emailInput.email : 'Select user' }) }) }) }), _jsx("div", { className: styles.col, children: _jsx(FormGroup, { label: "Is complete view", children: _jsx(Switch, { className: styles.themeSwitch, checked: is_complete_view, onChange: () => {
                                    searchParams.skip = 0;
                                    const query = getQueryString(searchParams, { is_complete_view: !is_complete_view });
                                    navigate({
                                        ...location,
                                        search: `?${query}`,
                                    });
                                } }) }) }), _jsx("div", { className: styles.col, children: _jsx("div", { className: styles.moduleItem, children: _jsx(FormGroup, { label: "Module Name", children: _jsx(MultiSelect2, { resetOnSelect: true, resetOnQuery: true, onClear: clearAllModulesTags, disabled: isModulesLoading || modulesConstants.length === 0, itemRenderer: ({ id, name_module, section }, { handleClick, handleFocus, modifiers }) => (_jsx(MenuItem2, { text: name_module, label: section, roleStructure: "listoption", active: modifiers.active, onClick: handleClick, onFocus: handleFocus }, id)), items: modulesConstants, popoverProps: {
                                        popoverClassName: styles.popover_multiselect,
                                    }, menuProps: { className: styles.menu }, noResults: "No modules", onItemSelect: onItemMultiSelect, tagRenderer: (item) => item, itemPredicate: (query, module, _index, exactMatch) => {
                                        const normalizedTitle = module.id.toLowerCase();
                                        const normalizedQuery = query.toLowerCase();
                                        if (exactMatch) {
                                            return normalizedQuery === normalizedTitle;
                                        }
                                        return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
                                    }, onRemove: onItemRemove, selectedItems: selectedModules }) }) }) }), _jsx("div", { className: styles.col, children: _jsx(FormGroup, { label: "Pipeline Name", labelFor: "name", children: _jsx(InputGroup, { fill: true, id: "name", type: "text", value: pipelineNameInput, onChange: (e) => {
                                    setPipelineNameInput(e.target.value);
                                }, onKeyPress: (event) => {
                                    if (event.key === 'Enter') {
                                        handleInputPipelineName();
                                    }
                                } }) }) }), _jsx("div", { className: styles.col, children: _jsx(FormGroup, { label: "Pipeline uuid", labelFor: "uuid", children: _jsx(InputGroup, { fill: true, id: "uuid", type: "text", value: uuidInput, onChange: (e) => {
                                    setUuidInput(e.target.value);
                                }, onKeyPress: (event) => {
                                    if (event.key === 'Enter') {
                                        handleUuidChange();
                                    }
                                } }) }) })] }) }) }));
};
export const PipelineListHeader = memo(PipelineListHeaderComponent);
