import { StorageType } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/appConstants'
import API from 'utils/api'
import { asyncConfigManager } from '@roadar-pipeline-viewer/roadly-typescript/dist/utils/asyncConfigManager'
import {
  normalizers,
  PipelineNormalizer,
  PciFrameSegmentNormalizer,
  SessionNormalizer,
} from '@roadar-pipeline-viewer/roadly-typescript/dist/utils/normalizers'
import { startServiceWorker } from 'workers/serviceWorker/startServiceWorker'

const getAppConfig = async () => {
  try {
    return await API.getAppConfiguration()
  } catch (e) {
    // Handle testing contour where endpoint is not deployed by apr 19
    console.warn('getAppConfig error', e)
    return {
      storageType: StorageType.FILE_SYSTEM,
      bucketNames: {},
    }
  }
}

const initApp = async () => {
  const appConfig = await getAppConfig()

  asyncConfigManager.configureStorage(appConfig)
  const { storageType, bucketNames } = await asyncConfigManager.getConfig()
  console.log({ storageType })

  /**
   * Configure normalizers, depending on config,
   * asynchronously taken from backend
   */

  const pipelineNormalizer = new PipelineNormalizer()
  pipelineNormalizer.configureStorage({ storageType, bucketNames })
  normalizers.pipelineNormalizer = pipelineNormalizer

  const pciFrameSegmentNormalizer = new PciFrameSegmentNormalizer()
  pciFrameSegmentNormalizer.configure({ pciBucketName: bucketNames.rdaBucket })
  normalizers.pciFrameSegmentNormalizer = pciFrameSegmentNormalizer

  const sessionNormalizer = new SessionNormalizer()
  normalizers.sessionNormalizer = sessionNormalizer

  /**
   * Run (or dont run for file system environment)
   * service worker for intercepting cloud storage responses
   */

  const isCloudStorageContour = appConfig.storageType === StorageType.GOOGLE_STORAGE
  await startServiceWorker({ isCloudStorageContour, initialToken: '' })

  return { isCloudStorageContour }
}

export const initAppService = async () => {
  try {
    /* Service worker and normalizers */
    const initialAppState = await initApp()

    /* Old stuff */
    const user = await API.whoami()
    console.log('user', user)
    return { user, initialAppState }
  } catch (e) {
    console.error('initAppService error', e)
  }
}
