import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonGroup } from '@blueprintjs/core';
import clsx from 'clsx';
import { CombinedPciDataIcon, SegmentsIcon, ViewpointsIcon, } from '../../../components/icons';
import { pciViewModeKeys } from '../../../const/pciConstants';
import styles from './PciDataTypeFilters.module.scss';
export const PciDataTypeFilters = ({ className, onClickPciViewModeKey, onClickCoverageMap, 
// onClickPotholes,
isCoverageMapOn, pciViewModeKey, }) => {
    return (_jsx("div", { className: clsx(styles.container, className), children: _jsxs(ButtonGroup, { className: styles.buttonGroup, minimal: true, children: [_jsx("div", { className: pciViewModeKey === pciViewModeKeys.sampleUnits ? styles.activeButton : '', children: _jsxs(Button, { onClick: () => onClickPciViewModeKey(pciViewModeKeys.sampleUnits), children: [_jsx(SegmentsIcon, {}), "Sections"] }) }), _jsx("div", { className: pciViewModeKey === pciViewModeKeys.viewpoints ? styles.activeButton : '', children: _jsxs(Button, { onClick: () => onClickPciViewModeKey(pciViewModeKeys.viewpoints), children: [_jsx(ViewpointsIcon, {}), "Points"] }) }), _jsx("div", { className: pciViewModeKey === pciViewModeKeys.combined ? styles.activeButton : '', children: _jsxs(Button, { onClick: () => onClickPciViewModeKey(pciViewModeKeys.combined), children: [_jsx(CombinedPciDataIcon, {}), "Combined"] }) })] }) }));
};
