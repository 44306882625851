import { useContext } from 'react';
import { ProfileContext } from '../contexts/profileContext';
export const useRoles = () => {
    const [profile] = useContext(ProfileContext);
    if (profile === null) {
        throw new Error('Not authorized app state');
    }
    // @ts-ignore Todo:
    return profile.roles;
};
