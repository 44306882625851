import { SignUpPaper } from 'features/auth/SignUp/SignUpPaper'
import { useState } from 'react'

import { PageVideoWrapper } from '@roadar-pipeline-viewer/roadly-typescript/dist/components/PageVideoWrapper/PageVideoWrapper'

export const SignUpPage = () => {
  return (
    <PageVideoWrapper>
      <SignUpPaper />
    </PageVideoWrapper>
  )
}
