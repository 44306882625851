import clsx from 'clsx'
import { PATH } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/routingConstants'
import { useDialog } from 'hooks/useDialog'
import useWindow from 'hooks/useWindow'
import style from './Footer.module.css'

export function Footer() {
  const { isDesktop } = useWindow()
  const { show, dialogsTypes } = useDialog()

  const showUserGuideDialog = event => {
    event.preventDefault()
    show(dialogsTypes.WELCOME)
  }

  return (
    <div className={clsx(style.root, 'bp4-navbar')}>
      <div className={clsx(style.content)}>
        <div className={style.item}>
          © Roadly (<a href="mailto:support@road.ly">support@road.ly</a>)
        </div>
        <div className={style.footerRightBar}>
          {isDesktop ? (
            <>
              <a className={clsx(style.help, style.extraRightMargin)} onClick={showUserGuideDialog}>
                Short user guide
              </a>
              <span className={style.extraRightMargin}>|</span>
              <a className={style.help} href={PATH.userGuide.path}>
                Extended user guide
              </a>
            </>
          ) : (
            <a href={PATH.userGuide.path} className={style.help}>
              User guide
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
