/*
 * Hook can be found here:
 * https://stackoverflow.com/questions/42615556/how-to-preload-images-in-react-js
 */
export function preloadImage(src) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'anonymous'; // To allow operations in canvas
        img.onload = () => resolve(img);
        const rejectHandler = () => reject(src);
        img.onerror = rejectHandler;
        img.onabort = rejectHandler;
        img.src = src;
    });
}
