export const bringCoordinatesArrayToGround = (array) => {
    var _a;
    const newArray = [];
    for (let i = 0, u = 0; i < array.length; i += 3) {
        newArray.push(array[i]);
        newArray.push((_a = array[i + 1]) !== null && _a !== void 0 ? _a : 0);
        newArray.push(0);
    }
    return newArray;
};
export function bringFeatureCollectionToTheGround(featureCollection) {
    try {
        return {
            ...featureCollection,
            // Todo: handle different formats of coordinates
            features: featureCollection.features.map((feature) => {
                const oldCoordinates = feature.geometry.coordinates;
                return {
                    ...feature,
                    geometry: {
                        ...feature.geometry,
                        coordinates: bringCoordinatesArrayToGround(oldCoordinates),
                    },
                };
            }),
        };
    }
    catch (e) {
        console.error('Probably wrong feature collection');
        throw e;
    }
}
