export const hexToRgba = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16), 255] : null
}
// green to red
export const SEVERITY_PALETTE_HEX = ['#69B34C', '#ACB334', '#FAB733', '#FF8E15', '#FF4E11', '#FF0D0D']
export const SEVERITY_PALETTE = SEVERITY_PALETTE_HEX.map(v => hexToRgba(v))

export const SAMPLE_UNIT_PALETTE_HEX = [
  '#A50026',
  '#D73027',
  '#F46D43',
  '#FDAE61',
  '#FEE08B',
  '#FFFFBF',
  '#D9EF8B',
  '#A6D96A',
  '#66BD63',
  '#1A9850',
  '#006837',
]
export const SAMPLE_UNIT_PALETTE = SAMPLE_UNIT_PALETTE_HEX.map(v => hexToRgba(v))

export const getColorBySeverityClass = (severityClass, isHex = false) => {
  if (severityClass === undefined) return isHex ? '#c0c0c0' : [192, 192, 192, 255]
  const palette = isHex ? SEVERITY_PALETTE_HEX : SEVERITY_PALETTE
  return palette[severityClass]
}

export const SEVERITY_MAPPING = {
  LOW: 0,
  MODERATE: 1,
  HIGH: 2,
}

export const AREA_CLASSES_MAPPING = {
  0: 'SMALL',
  1: 'BIG',
}
const AREA_CLASSES = Object.keys(AREA_CLASSES_MAPPING).map(k => parseInt(k))

export const getAreaClass = area => (area < 0.1 ? AREA_CLASSES[0] : AREA_CLASSES[1])
export const getSeverityClass = (severity, areaClass) =>
  SEVERITY_MAPPING[severity] * AREA_CLASSES.length + parseInt(areaClass)

const getSeverityClassOptions = () => {
  const result = new Array(Object.keys(SEVERITY_MAPPING).length * AREA_CLASSES.length).fill(null)
  for (let severity of Object.keys(SEVERITY_MAPPING)) {
    for (let areaClass of AREA_CLASSES) {
      const severityClass = getSeverityClass(severity, areaClass)
      const color = getColorBySeverityClass(severityClass, true)
      const name = `${severity}, ${AREA_CLASSES_MAPPING[areaClass]}`.toLowerCase()
      const v = {
        severityClass,
        color,
        name,
      }
      result[severityClass] = v
    }
  }
  return result
}

export const SEVERITY_CLASS_OPTIONS = getSeverityClassOptions()

export const getPciIndex = pciData =>
  !pciData ? null : pciData.features.filter(f => f?.properties?.id.indexOf('sample_unit') === 0)

export const getPciCracks = (pciData, selectedPCISeverityClasses) =>
  !pciData
    ? null
    : pciData.features.filter(
        f => f?.properties?.id.indexOf('crack') === 0 && selectedPCISeverityClasses[f?.properties?.SEVERITY_CLASS]
      )

/**
 * Helper for finding pci level according to 6-level scale
 * Used for colorizing pci viewpoints and sample units
 * and filtering them
 */
export const getSeverityIndexByPci = pci => Math.floor((pci / 100) * 5.99)

export const rgbToHex = ([r, g, b]) => `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`
