import { Button, FormGroup, Intent, Tag } from '@blueprintjs/core'
import clsx from 'clsx'
import { emailValidationPattern } from 'const'
import { useForm } from 'react-hook-form'
import styles from './SignUpForm.module.scss'

const formOptions = {
  email: {
    pattern: emailValidationPattern,
  },
  name: {},
}

export const SignUpForm = ({ onSubmit, requestErrors = [], isLoading, className }) => {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm()

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={clsx(styles.formContainer, className)}>
          <div className={styles.formHeader}>Sign up</div>
          <div className={styles.formSubheader}>
            5 miles of PCI inspection <span className={styles.noWrap}>is included</span> into{' '}
            <span className={styles.noWrap}>the FREE trial</span>
          </div>
          <FormGroup label="Email:" labelFor="email">
            <div className="bp4-input-group">
              <input
                id="email"
                className={styles.formInput}
                type="text"
                placeholder="some@email.com"
                {...register('email', formOptions['email'])}
              />
            </div>
            {errors.email && (
              <Tag multiline fill large intent={Intent.DANGER} className={styles.error}>
                {errors.email.message}
              </Tag>
            )}
          </FormGroup>

          <FormGroup label="Organization name:" labelFor="organization">
            <div className="bp4-input-group">
              <input
                id="organization"
                className={styles.formInput}
                type="text"
                placeholder=""
                {...register('organization_name', formOptions['organization_name'])}
              />
            </div>
            {errors.organization && (
              <Tag multiline fill large intent={Intent.DANGER} className={styles.error}>
                {errors.name.message}
              </Tag>
            )}
          </FormGroup>

          <Button
            className={styles.signUpButton}
            loading={isLoading}
            type="submit"
            intent={Intent.PRIMARY}
            text="Sign up"
          />

          {requestErrors.map(({ loc, msg }) => (
            <Tag key={msg} multiline fill large intent={Intent.DANGER} className={styles.error}>
              {`${loc}: ${msg}`}
            </Tag>
          ))}
        </div>
      </form>
    </>
  )
}
