import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useRef } from 'react';
import clsx from 'clsx';
import { Button, Classes } from '@blueprintjs/core';
import { asyncConfigManager } from '../../../utils/asyncConfigManager';
import styles from './AssetsViewerPanel.module.scss';
import { VideoPlayer } from '../../../components/VideoPlayer';
import { AssetsClassFilters } from '../AssetsClassFilters';
export const AssetsViewerPanelPipelineContent = ({ assetsPipelinePayloadWithMapProps, onClosePipeline, }) => {
    // @ts-ignore Todo
    const { persistentBucket } = asyncConfigManager.config.bucketNames;
    const playerRef = useRef(undefined);
    const videoUrlRaw = `https://storage.googleapis.com/${persistentBucket}/pipeline-${assetsPipelinePayloadWithMapProps.pipeline.uuid}/uuid450/asset_localization_results/masks.mp4`;
    const handleTimeUpdated = useCallback(() => { }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.header, children: ["Pipeline result", _jsx(Button, { icon: "cross", className: clsx(Classes.DIALOG_CLOSE_BUTTON, styles.closePanelButton), onClick: onClosePipeline })] }), _jsx(VideoPlayer, { className: styles.videoWrapper, url: videoUrlRaw, playerRef: playerRef, onTimeUpdated: handleTimeUpdated }), _jsx("div", { className: styles.assetsLegendWrapper, children: _jsxs("div", { children: [_jsx("div", { className: styles.assetsLegendHeader, children: "Found assets" }), _jsx("div", { className: styles.assetsLegend, children: _jsx(AssetsClassFilters, { ...assetsPipelinePayloadWithMapProps.assetsVisibilityFiltersByClassNameProps }) })] }) })] }));
};
