import { Checkbox, Button } from '@blueprintjs/core'
import style from './RunPipelineForm.module.css'
import cx from 'classnames'

export const TripleCheckbox = ({ value, setter, label }) => (
  <Checkbox
    className={cx({
      [style.tripleCheckbox]: true,
      [style.tripleCheckboxUnchanged]: value === null,
    })}
    checked={!!value}
    onChange={({ target: { checked } }) => setter(checked)}
  >
    {label} <Button className={style.tripleCheckoxReset} onClick={() => setter(null)} icon="reset" small={true} />
  </Checkbox>
)
