export var Owner;
(function (Owner) {
    Owner["ME"] = "me";
    Owner["ALL"] = "all";
})(Owner || (Owner = {}));
export const PASSWORD_MIN_LEN = 8;
export var ROLES;
(function (ROLES) {
    ROLES["SUPERADMIN"] = "superadmin";
    ROLES["ADMIN"] = "admin";
    ROLES["OPERATOR"] = "operator";
})(ROLES || (ROLES = {}));
export const demoUserLoginParams = {
    email: 'dimitri@seas.family',
    password: '3bk9guxa',
};
