import parse from 'date-fns/parse';
/**
 * Parses given string as UTC date
 */
export function parseDate(dateString, showAsLocalDate = false) {
    if (showAsLocalDate) {
        return parse(dateString, 'yyyy-MM-dd HH:mm:ss', new Date());
    }
    return parse(`${dateString} Z`, 'yyyy-MM-dd HH:mm:ss X', new Date());
}
