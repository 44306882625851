import { PATH } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/routingConstants'
import { PROGRESS } from 'const'
import styles from './ConfirmCode.module.scss'

export const ConfirmCode = ({ status }) => {
  if (status === PROGRESS.WORK) {
    return <div>Loading</div>
  }

  if (status === PROGRESS.ERROR) {
    return <div>No confirmation found</div>
  }

  return (
    <div className={styles.centered}>
      Thank you for joining Roadly PRO.
      <div className={styles.break}></div>
      Your credentials are sent to your e-mail.
      <br />
      Please check your inbox and then <a href={PATH.signIn.path}>Sign in</a>
      <div className={styles.break}></div>
      Need help? Contact us:
      <br />
      <a href="mailto:support@road.ly">support@road.ly</a>
    </div>
  )
}
