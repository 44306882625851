import React, { createContext, useReducer } from 'react'
import DeckGLMapContainer from './DeckGlMapContainer'
import DeckGLContext from './deckGLContext'

const initialState = {
  sessionsQueue: [],
  renderedSessions: {},
  currentSession: null,
}

function deckGLReducer(state, action) {
  switch (action.type) {
    case 'ENQUEUE_SESSION':
      return { ...state, sessionsQueue: [...state.sessionsQueue, action.payload] }
    case 'SET_CURRENT_SESSION':
      return { ...state, currentSession: state.sessionsQueue[0] }
    case 'DEQUEUE_SESSION':
      return { ...state, sessionsQueue: state.sessionsQueue.slice(1), currentSession: null }
    case 'ADD_RENDERED_SESSION':
      return { ...state, renderedSessions: { ...state.renderedSessions, [action.payload.id]: action.payload.dataUrl } }
    default:
      return state
  }
}

export default function DeckGLContextProvider({ children }) {
  const [state, dispatch] = useReducer(deckGLReducer, initialState)

  return (
    <DeckGLContext.Provider value={[state, dispatch]}>
      <DeckGLMapContainer />
      {children}
    </DeckGLContext.Provider>
  )
}
