import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { PciDashboard } from './PciDashboard';
import { API } from '../../../services/ApiService';
const defaultValues = { networkScore: 0, centerLineMiles: 0 };
export const PciDashboardContainer = ({ className, tmpViewpointsData }) => {
    const [dashboardData, setDashboardData] = useState({ ...defaultValues });
    useEffect(() => {
        const effect = async () => {
            if (tmpViewpointsData === null) {
                setDashboardData({ ...defaultValues });
                return;
            }
            const networkScore = tmpViewpointsData.features.length
                ? tmpViewpointsData.features
                    .reduce((acc, feature) => acc + feature.properties.stats.pci, 0) / tmpViewpointsData.features.length
                : 0;
            const { distanceAsMiles } = await API.getOrganizationSessionsDistance();
            setDashboardData({ networkScore, centerLineMiles: distanceAsMiles });
        };
        effect();
    }, [tmpViewpointsData]);
    return _jsx(PciDashboard, { ...dashboardData, className });
};
