import { jsx as _jsx } from "react/jsx-runtime";
import { useSearchParams } from 'react-router-dom';
import { AssetsPipelineViewerContainer } from '../../features/AssetsViewer';
export const AssetsPipelineViewerPage = () => {
    const [searchParams] = useSearchParams();
    const activeSessionId = searchParams.get('activeSessionId');
    const activePipelineId = searchParams.get('activePipelineId');
    return (_jsx(AssetsPipelineViewerContainer, { activeSessionId: typeof activeSessionId === 'string'
            ? Number(activeSessionId)
            : null,
        activePipelineId }));
};
