import { OWNER } from './userConstants'

export const SESSIONS_LIST_FILTERS = {
  DEFAULT_LIMIT: 20,
  DEFAULT_SKIP: 0,
  DEFAULT_OWNER: OWNER.ME,
  DEFAULT_START_TIME_FROM: null,
  DEFAULT_START_TIME_TO: null,
  DEFAULT_ORGANIZATION_ID: 0,
  DEFAULT_IS_BROKEN: false,
  DEFAULT_IS_INVALID: false,
}
