import { apiAsClass } from '@roadar-pipeline-viewer/roadly-typescript/dist/services/ApiService'
import { TYPES_DISTANCE_FOR_RENDER } from 'const/distanceConstants'
import { memo, useState, useEffect, useCallback } from 'react'
import { ButtonGroup, Button, Alignment, Card, Elevation, Switch, InputGroup, FormGroup, Icon } from '@blueprintjs/core'
import { Select2 } from '@blueprintjs/select'
import { MultiSelect2 } from '@blueprintjs/select'
import { Popover2, MenuItem2 } from '@blueprintjs/popover2'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import API from 'utils/api'
import debounce from 'lodash.debounce'
import { ContentFor } from 'components/ContentFor/ContentFor'

import { OWNER, PIPELINE_TYPES, ROLES, PIPELINE_LIST_FILTERS } from 'const'
import { getQueryString, reshapeModules, appendDistance } from 'utils/utils'
import { formatDate } from 'utils/formatDate'
import style from 'features/PipelineList/PipelineListHeader/PipelineListHeader.module.scss'

const { DEFAULT_SESSION, DEFAULT_SKIP, DEFAULT_ORGANIZATION } = PIPELINE_LIST_FILTERS

function TypeHref({ value, searchParams }) {
  const searchParamsWithSkip = { ...searchParams, skip: DEFAULT_SKIP }
  const valueIsIncludeSearchParams = searchParams.types.includes(value)
  const searchTypesParamModify = valueIsIncludeSearchParams
    ? searchParams.types.filter(item => item !== value)
    : [...searchParams.types, value]
  const query = getQueryString(searchParamsWithSkip, { types: searchTypesParamModify })
  return (
    <Link
      role="button"
      tabIndex={0}
      to={`/pipelines/?${query}`}
      className={clsx('bp4-button', { 'bp4-active': valueIsIncludeSearchParams })}
    >
      <span className={style.value_type_popover}>{value}</span>

      {valueIsIncludeSearchParams ? (
        <Icon icon="small-tick" size={24} className={style.icon_type_popover} />
      ) : (
        <div className={style.nbsp_type_popover}>&nbsp;</div>
      )}
    </Link>
  )
}

function PipelineListHeaderComponent({
  limit,
  skip,
  sort_order,
  status,
  owner,
  session,
  modules,
  types,
  is_complete_view,
  pipeline_name,
  organization_id,
  selectedOrganization,
  setSelectedOrganization,
  selectedSession,
  setSelectedSession,
  selectedModules,
  setSelectedModules,
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = {
    limit,
    skip,
    sort_order,
    status,
    owner,
    session,
    modules,
    types,
    organization_id,
    name: pipeline_name,
    is_complete_view: +is_complete_view,
  }
  const [sessions, setSessions] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [isSessionLoading, setIsSessionLoading] = useState(true)
  const [isOrganizationLoading, setIsOrganizationLoading] = useState(true)
  const [modulesConstants, setModulesConstants] = useState([])
  const [isModulesLoading, setIsModulesLoading] = useState(true)
  const [emailsConstants, setEmailsConstants] = useState([])
  const [isEmailsLoading, setIsEmailsLoading] = useState(true)
  const [pipelineNameInput, setPipelineNameInput] = useState(pipeline_name ? pipeline_name : '')
  const [emailInput, setEmailInput] = useState()

  const commonPartEmailsList = [
    { email: OWNER.ALL, description: 'Select all users' },
    { email: OWNER.ME, description: 'Select only you' },
  ]

  const handleInputPipelineName = pipelineNameInput => {
    const searchParamsWithSkip = { ...searchParams, skip: DEFAULT_SKIP }
    const query = getQueryString(searchParamsWithSkip, { name: pipelineNameInput })
    navigate({
      ...location,
      search: `?${query}`,
    })
  }

  const onInputEmail = emailInput => {
    setEmailInput(emailInput)
    const searchParamsWithSkip = { ...searchParams, skip: DEFAULT_SKIP }
    const query = getQueryString(searchParamsWithSkip, { owner: emailInput.email })
    navigate({
      ...location,
      search: `?${query}`,
    })
  }

  const debonceHandlePipelineNameInput = useCallback(debounce(handleInputPipelineName, 500), [])

  const onItemRemove = (tag, index) => {
    setSelectedModules(items => items.filter(elm => elm !== tag))
    const updateSelectedModules = selectedModules.filter(elm => elm !== tag)
    const query = getQueryString(searchParams, { modules: updateSelectedModules })
    navigate({
      ...location,
      search: `?${query}`,
    })
  }

  const onItemMultiSelect = item => {
    if (selectedModules.indexOf(item.id) === -1) {
      selectedModules.push(item.id)
      const query = getQueryString(searchParams, { modules: selectedModules })
      navigate({
        ...location,
        search: `?${query}`,
      })
    }
  }

  const onItemSelect = session => {
    setSelectedSession(session)
    const searchParamsWithSkip = { ...searchParams, skip: DEFAULT_SKIP }
    const query = getQueryString(searchParamsWithSkip, { session: session.id })
    navigate({
      ...location,
      search: `?${query}`,
    })
  }

  const onOrganizationSelect = organization => {
    setSelectedOrganization(organization)
    const searchParamsWithSkip = { ...searchParams, skip: DEFAULT_SKIP }
    const query = getQueryString(searchParamsWithSkip, { organization_id: organization.id })
    navigate({
      ...location,
      search: `?${query}`,
    })
  }

  useEffect(() => {
    setIsModulesLoading(true)
    API.getModules()
      .then(all_modules => {
        const reformat_modules = reshapeModules(all_modules)
        setSelectedModules([...modules])
        setModulesConstants(reformat_modules)
        setIsModulesLoading(false)
      })
      .catch(() => {
        setIsModulesLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsEmailsLoading(true)
    API.getEmails(organization_id)
      .then(all_emails => {
        let emailSelected = undefined
        if (!Array.isArray(all_emails)) {
          emailSelected = [...commonPartEmailsList].find(user => user.email === owner)
        } else {
          setEmailsConstants(all_emails)
          emailSelected = [...commonPartEmailsList, ...all_emails].find(user => user.email === owner)
        }
        setEmailInput(emailSelected)
        setIsEmailsLoading(false)
      })
      .catch(() => {
        const emailSelected = [...commonPartEmailsList].find(user => user.email === owner)
        setEmailInput(emailSelected)
        setIsEmailsLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization_id])

  useEffect(() => {
    setIsSessionLoading(true)
    apiAsClass
      .getSessions(organization_id)
      .then(({ items }) => {
        setSessions(items)
        const defaultSession = items.find(item => item.id === Number(session))
        setSelectedSession(defaultSession)
        setIsSessionLoading(false)
      })
      .catch(() => {
        setIsSessionLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization_id])

  useEffect(() => {
    setIsOrganizationLoading(true)
    API.getOrganizations()
      .then(items => {
        let defaultOrganization = undefined
        if (Array.isArray(items)) {
          setOrganizations(items)
          defaultOrganization = items.find(item => item.id === Number(organization_id))
        }
        setSelectedOrganization(defaultOrganization)
        setIsOrganizationLoading(false)
      })
      .catch(e => {
        console.log(e)
        setIsOrganizationLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={style.header}>
      <Card className={style.filters} elevation={Elevation.ZERO}>
        <div className={style.filterItems}>
          <ContentFor role={ROLES.SUPERADMIN}>
            <div className={style.col}>
              <div className={style.formItem}>
                <FormGroup label="Organizations">
                  <Select2
                    fill
                    disabled={isOrganizationLoading || organizations.length === 0}
                    id="organization"
                    items={[
                      {
                        id: DEFAULT_SESSION,
                        name: 'All',
                        description: 'All organizations',
                        owner: { id: 0, email: '' },
                      },
                      ...organizations,
                    ]}
                    menuProps={{ className: style.menu }}
                    popoverContentProps={{ className: style.selectorPopoverContent }}
                    activeItem={selectedOrganization}
                    onItemSelect={onOrganizationSelect}
                    noResults={'No results'}
                    itemPredicate={(query, item) =>
                      item.name.toLowerCase().includes(query.toLowerCase()) ||
                      item.description?.toLowerCase().includes(query.toLowerCase()) ||
                      item.owner.email.toLowerCase().includes(query.toLowerCase())
                    }
                    itemRenderer={({ id, name, description, owner }, { handleClick, handleFocus, modifiers }) => (
                      <MenuItem2
                        text={name + '(' + owner.email + ')'}
                        label={description}
                        roleStructure="listoption"
                        active={modifiers.active}
                        key={id}
                        onClick={handleClick}
                        onFocus={handleFocus}
                      />
                    )}
                  >
                    <Button
                      className={style.select}
                      loading={isOrganizationLoading}
                      alignText={Alignment.LEFT}
                      rightIcon={'chevron-down'}
                    >
                      {!isOrganizationLoading && organizations.length === 0 && 'No organizations'}
                      {organizations.length > 0 &&
                        (selectedOrganization ? selectedOrganization.name : 'Select organization')}
                    </Button>
                  </Select2>
                </FormGroup>
              </div>
            </div>
          </ContentFor>
          <div className={style.col}>
            <div className={style.formItem}>
              <FormGroup label="Pipeline Type">
                <Popover2
                  interactionKind="click"
                  placement="bottom"
                  content={
                    <ButtonGroup vertical={true} minimal={true}>
                      {PIPELINE_TYPES.map(item => (
                        <TypeHref key={item} value={item} searchParams={searchParams} />
                      ))}
                    </ButtonGroup>
                  }
                  renderTarget={({ isOpen, ref, ...targetProps }) => (
                    <Button
                      {...targetProps}
                      elementRef={ref}
                      text={types.length > 0 ? types.length + ' selected types' : 'All available types'}
                    />
                  )}
                />
              </FormGroup>
            </div>
          </div>
          <div className={style.col}>
            <div className={style.formItem}>
              <FormGroup label="Session">
                <Select2
                  fill
                  disabled={isSessionLoading || sessions.length === 0}
                  id="session"
                  items={[{ id: DEFAULT_SESSION, folder_name: 'All sessions' }, ...sessions]}
                  popoverContentProps={{ className: style.selectorPopoverContent }}
                  activeItem={selectedSession}
                  onItemSelect={onItemSelect}
                  noResults={'No results'}
                  itemPredicate={(query, item) =>
                    item.folder_name.toLowerCase().includes(query.toLowerCase()) ||
                    formatDate(item.create_ts).includes(query)
                  }
                  itemRenderer={(
                    { folder_name, create_ts, distance_calc_raw },
                    { handleClick, handleFocus, modifiers }
                  ) => (
                    <MenuItem2
                      text={folder_name}
                      label={
                        create_ts
                          ? appendDistance(formatDate(create_ts), distance_calc_raw, TYPES_DISTANCE_FOR_RENDER.METERS)
                          : ''
                      }
                      roleStructure="listoption"
                      active={modifiers.active}
                      key={folder_name}
                      onClick={handleClick}
                      onFocus={handleFocus}
                    />
                  )}
                >
                  <Button
                    className={style.select}
                    loading={isSessionLoading}
                    alignText={Alignment.LEFT}
                    rightIcon={'chevron-down'}
                  >
                    {!isSessionLoading && sessions.length === 0 && 'No sessions'}
                    {sessions.length > 0 && (selectedSession ? selectedSession.folder_name : 'Select session')}
                  </Button>
                </Select2>
              </FormGroup>
            </div>
          </div>
          <div className={style.col}>
            <FormGroup label="Email" labelFor="users_email">
              <Select2
                fill
                disabled={isEmailsLoading}
                id="users_email"
                items={[...commonPartEmailsList, ...emailsConstants]}
                popoverContentProps={{
                  className: style.selectorPopoverContent,
                }}
                activeItem={emailInput}
                onItemSelect={onInputEmail}
                noResults={'No results'}
                itemPredicate={(query, item) => {
                  return (
                    item.email?.toLowerCase().includes(query.toLowerCase()) ||
                    item.description?.toLowerCase().includes(query)
                  )
                }}
                itemRenderer={({ email, description }, { handleClick, handleFocus, modifiers }) => (
                  <MenuItem2
                    text={email}
                    label={description?.length >= 80 ? description.slice(0, 80) + '...' : description}
                    roleStructure="menuitem"
                    active={modifiers.active}
                    key={email}
                    onClick={handleClick}
                    onFocus={handleFocus}
                  />
                )}
              >
                <Button
                  className={style.select}
                  loading={isEmailsLoading}
                  alignText={Alignment.LEFT}
                  rightIcon={'chevron-down'}
                >
                  {emailInput ? emailInput.email : 'Select user'}
                </Button>
              </Select2>
            </FormGroup>
          </div>
          <div className={style.col}>
            <FormGroup label="Is complete view">
              <Switch
                className={style.themeSwitch}
                checked={is_complete_view}
                onChange={() => {
                  searchParams.skip = 0
                  const query = getQueryString(searchParams, { is_complete_view: +!is_complete_view })
                  navigate({
                    ...location,
                    search: `?${query}`,
                  })
                }}
              />
            </FormGroup>
          </div>

          <div className={style.col}>
            <div className={style.formItem}>
              <FormGroup label="Module Name">
                <MultiSelect2
                  disabled={isModulesLoading || modulesConstants.length === 0}
                  itemRenderer={({ id, name_module, section }, { handleClick, handleFocus, modifiers }) => (
                    <MenuItem2
                      text={name_module}
                      label={section}
                      roleStructure="listoption"
                      active={modifiers.active}
                      key={id}
                      onClick={handleClick}
                      onFocus={handleFocus}
                    />
                  )}
                  items={modulesConstants}
                  popoverProps={{
                    popoverClassName: style.popover_multiselect,
                  }}
                  menuProps={{ className: style.menu }}
                  noResults={'No modules'}
                  onItemSelect={onItemMultiSelect}
                  tagRenderer={item => item}
                  itemPredicate={(query, module, _index, exactMatch) => {
                    const normalizedTitle = module.id.toLowerCase()
                    const normalizedQuery = query.toLowerCase()
                    if (exactMatch) {
                      return normalizedQuery === normalizedTitle
                    } else {
                      return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0
                    }
                  }}
                  onRemove={onItemRemove}
                  selectedItems={selectedModules}
                />
              </FormGroup>
            </div>
          </div>
          <div className={style.col}>
            <FormGroup label="Pipeline Name" labelFor="name">
              <InputGroup
                fill
                id="name"
                type="text"
                value={pipelineNameInput}
                onChange={e => {
                  setPipelineNameInput(e.target.value)
                  debonceHandlePipelineNameInput(e.target.value)
                }}
              />
            </FormGroup>
          </div>
        </div>
      </Card>
    </div>
  )
}

export const PipelineListHeader = memo(PipelineListHeaderComponent)
