import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GlobeIcon, ClockIcon, AreaIcon } from '../../../../components/icons';
import { formatDate } from '../../../../utils';
import styles from './PciViewerDetails.module.scss';
import { Button, Icon } from '@blueprintjs/core';
/**
 * Helper just for local usage in this component
 */
function formatCoordinatesArray(viewpointCoordinates) {
    return viewpointCoordinates.map((el) => Number(el).toFixed(6)).join(' / ');
}
export const PciViewerDetails = ({ viewpoint }) => {
    // const tmpAddress: string = useMemo(() => {
    //   const storedValue = localStorage.getItem(LOCAL_STORAGE_KEYS.TMP_ADDRESS_LABEL);
    //   if (storedValue) {
    //     return storedValue;
    //   }
    //   return 'Westborough, MA 01581, USA';
    // }, []);
    const copyIdToClipboard = () => {
        navigator.clipboard.writeText(viewpoint.properties.id);
    };
    return (_jsxs("div", { className: styles.detailsContainer, children: [_jsxs("div", { className: styles.detailsItem, children: [_jsx(GlobeIcon, { className: styles.icon }), " ", formatCoordinatesArray(viewpoint.geometry.coordinates)] }), _jsx("div", { className: styles.space, children: "\u00A0" }), _jsxs("div", { className: styles.detailsItem, children: [_jsx(ClockIcon, { className: styles.icon }), " ", formatDate(viewpoint.properties.source_time, true)] }), _jsx("div", { className: styles.space, children: "\u00A0" }), _jsxs("div", { className: styles.detailsItem, children: [_jsx(AreaIcon, { className: styles.icon }), " ", viewpoint.properties.stats.area.toFixed(2)] }), _jsx("div", { className: styles.space, children: "\u00A0" }), _jsxs("div", { className: styles.detailsItem, onClick: copyIdToClipboard, children: [_jsx(Button, { minimal: true, small: true, icon: _jsx(Icon, { icon: "duplicate" }) }), viewpoint.properties.id] })] }));
};
