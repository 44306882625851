import { PATH } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/routingConstants'
import SignUpMapBackground from 'images/signup-map.jpg'
import { SignUpFormContainer } from 'features/auth/SignUp/SignUpForm'
import { useState } from 'react'

import styles from './SignUpPaper.module.scss'

const mapBlockExtraStyles = {
  backgroundImage: `url(${SignUpMapBackground})`,
  backgroundSize: 'cover',
}

export const SignUpPaper = () => {
  const [isSignUpSuccessful, setIsSignUpSuccessful] = useState(false)

  const onSuccessfulSignUp = () => {
    setIsSignUpSuccessful(true)
  }

  return (
    <div className={styles.signUpPaper}>
      <div className={styles.signUpFormBlock}>
        {isSignUpSuccessful ? (
          <div className={styles.successMessage}>
            <div className={styles.thankYou}>
              <span className={styles.noWrap}>Thank you</span> <span className={styles.noWrap}>for joining us!</span>
            </div>
            Please check your inbox
            <br />
            and confirm your e-mail address.
            <div className={styles.break}></div>
            Need help? Contact us: <a href="mailto:support@road.ly">support@road.ly</a>
          </div>
        ) : (
          <>
            <div className={styles.signInHint}>
              Already a member? <a href={PATH.signIn.path}>Sign in</a>
            </div>
            <SignUpFormContainer onSuccess={onSuccessfulSignUp} />
            {/*
            <div className={styles.passwordRecoveryHint}>
              <a href={PATH.restorePassword2stage.path}>Password recovery</a>
            </div>
            */}
          </>
        )}
      </div>

      <div className={styles.mapBlock} style={mapBlockExtraStyles}>
        &nbsp;
      </div>
    </div>
  )
}
