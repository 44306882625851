import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useRef, useState } from 'react';
import { Button, Classes, Tab, Tabs } from '@blueprintjs/core';
import clsx from 'clsx';
import { asyncConfigManager } from '../../../utils/asyncConfigManager';
import styles from './AssetsViewerPanel.module.scss';
import { VideoPlayer } from '../../../components/VideoPlayer';
import { RunAssetsPipelineFormContainer } from '../RunAssetsPipelineForm/RunAssetsPipelineFormContainer';
import { AssetsPipelinesList } from '../AssetsPipelinesList';
import { AssetPipelineTypes } from '../../../const/pipelineConstants';
import { AssetsPipelinePresetsContainer } from '../AssetsPipelinePreset';
// This is for handling sessions with no geocoding?
const getPlaceName = (session) => {
    try {
        return session.geocoding.features[0].place_name;
    }
    catch (e) {
        return 'Unknown place';
    }
};
var TabKey;
(function (TabKey) {
    TabKey["ASSETS_FORM"] = "ASSETS_FORM";
    TabKey["PRESETS"] = "PRESETS";
})(TabKey || (TabKey = {}));
export const AssetsViewerPanelSessionContent = ({ session, onClickOnPipelineListItem, onCloseSession, onPipelineSubmitted, }) => {
    const playerRef = useRef(undefined);
    // @ts-ignore Todo
    const { videosBucket } = asyncConfigManager.config.bucketNames;
    const videoUrlRaw = `https://storage.googleapis.com/${videosBucket}/${session.folder_prefix}/${session.folder_name}/video`;
    const [selectedFormsTab, setSelectedFormsTab] = useState(TabKey.ASSETS_FORM);
    const handleTimeUpdated = useCallback(() => { }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.sessionWrapper, children: [_jsxs("div", { className: styles.header, children: ["Session ID: ", session.id, _jsx(Button, { icon: "cross", className: clsx(Classes.DIALOG_CLOSE_BUTTON, styles.closePanelButton), onClick: onCloseSession })] }), _jsx("div", { className: styles.videoWrapper, children: _jsx(VideoPlayer, { url: videoUrlRaw, playerRef: playerRef, onTimeUpdated: handleTimeUpdated }) })] }), _jsxs("div", { className: styles.pipelinesWrapper, children: [_jsxs("div", { className: styles.formsContainer, children: [_jsx("div", { className: styles.tabsContainer, children: _jsxs(Tabs, { onChange: (newTabId) => setSelectedFormsTab(newTabId), selectedTabId: selectedFormsTab, children: [_jsx(Tab, { id: TabKey.ASSETS_FORM, title: "Assets" }), _jsx(Tab, { id: TabKey.PRESETS, title: "Presets" })] }) }), selectedFormsTab === TabKey.ASSETS_FORM
                                ? _jsx(RunAssetsPipelineFormContainer, { onPipelineSubmitted: onPipelineSubmitted, sessionId: session.id })
                                : null, selectedFormsTab === TabKey.PRESETS
                                ? _jsx(AssetsPipelinePresetsContainer, { onPipelineSubmitted: onPipelineSubmitted, sessionId: session.id })
                                : null] }), _jsxs("div", { className: styles.sessionDetails, children: [_jsx("div", { children: getPlaceName(session) }), _jsxs("div", { children: ["Length:", _jsx("br", {}), session.distance_calc_raw, " m"] })] }), _jsx(AssetsPipelinesList, { onClickOnPipelineListItem: onClickOnPipelineListItem, assetsPipelines: session.pipelines.filter((el) => AssetPipelineTypes[el.pipeline_type]) })] })] }));
};
