import { createDataUrlOfSvgCircleWithLabel, } from '../../../../utils/createSvgCircleWithLabel/createSvgCircleWithLabel';
import { abbreviationFromClassName } from '../../../../utils/abbreviation.utilities/abbreviationFromClassName';
import { randomFeatureColors } from '../../../../const/mapConstants/randomFeatureColors';
import { rgbToHex } from '../../../../utils';
// Mutates input
export function enrichFinalAssetsWithIcons(finalAssetFeatureCollection) {
    const { features } = finalAssetFeatureCollection;
    const map = {};
    //
    features.forEach((feature) => {
        if (feature.properties.icon_url) {
            return;
        }
        if (!map[feature.properties.class_name]) {
            const abbr = abbreviationFromClassName(feature.properties.class_name);
            // Get random color:
            const colorIndex = Object.keys(map).length - 1;
            const color = randomFeatureColors[colorIndex] || randomFeatureColors[randomFeatureColors.length - 1];
            map[feature.properties.class_name] = createDataUrlOfSvgCircleWithLabel(128, rgbToHex(color), abbr, 58);
        }
        feature.properties.icon_url = map[feature.properties.class_name];
    });
}
