export var ExceptionType;
(function (ExceptionType) {
    ExceptionType["NOT_FOUND"] = "NOT_FOUND";
    ExceptionType["NOT_VALID"] = "NOT_VALID";
    ExceptionType["BAD_REQUEST"] = "BAD_REQUEST";
    ExceptionType["OTHER"] = "OTHER";
    ExceptionType["CUSTOM"] = "CUSTOM";
})(ExceptionType || (ExceptionType = {}));
/*
 * Custom exception is used to throw everywhere we can
 * to put as much custom logging and logic on it as we can
 *
 * Going this way we can tell for sure if the error is expected (ours) or not
 * Also it is required for typed handling of errors
 */
export class Exception {
    constructor({ exceptionPath, error, formErrorDetails }) {
        this.exceptionPath = exceptionPath;
        this.formErrorDetails = formErrorDetails;
        if (error) {
            // Todo: what else should be done here?
            console.error(error);
        }
    }
    static isException(input) {
        return input instanceof Exception;
    }
}
