import { PreferredLogoWithLabel } from '@roadar-pipeline-viewer/roadly-typescript/dist/components/Logo/PreferredLogoWithLabel'
import { useState, useRef, useContext } from 'react'
import clsx from 'clsx'
import { FormGroup, InputGroup, Button, Tag, Intent, Card } from '@blueprintjs/core'
import { PROGRESS } from 'const'
import { PageVideoWrapper } from '@roadar-pipeline-viewer/roadly-typescript/dist/components/PageVideoWrapper/PageVideoWrapper'
import API from 'utils/api'
import { PATH } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/routingConstants'
import { ThemeContext, THEME } from '@roadar-pipeline-viewer/roadly-typescript/dist/contexts/themeContext'
import useProfile from 'hooks/useProfile'
import style from './SignInPage.module.css'
import { PASS_MIN_LEN } from 'const'

function SignIn() {
  const { updateProfile } = useProfile()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [progress, setProgress] = useState(PROGRESS.IDLE)
  const passwordRef = useRef()
  const { theme } = useContext(ThemeContext)

  const onClick = async () => {
    try {
      setProgress(PROGRESS.WORK)
      // Todo: use useLogin hook:
      const data = await API.login({ email, password })
      updateProfile(data)
      if (data.isAuthorized) {
        setProgress(PROGRESS.SUCCESS)
      } else {
        setProgress(PROGRESS.ERROR)
      }
    } catch (error) {
      setProgress(PROGRESS.ERROR)
    }
  }

  const onLoginKeyDown = e => {
    if (e.keyCode === 13) passwordRef.current.focus()
  }

  const onPasswordKeyDown = e => {
    if (e.keyCode === 13) onClick()
  }

  const isValid = email && password && password.length >= PASS_MIN_LEN

  return (
    <PageVideoWrapper>
      <Card className={style.form}>
        <div className={style.logoWrapper}>
          <PreferredLogoWithLabel className={style.urbiLogo} />
        </div>

        <h3 className={clsx(style.title, 'bp4-heading')}>Sign in</h3>
        <FormGroup labelFor="email" label="Email:">
          <InputGroup
            autoFocus
            id="email"
            placeholder="some@email.com"
            name="roadar-email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            disabled={progress === PROGRESS.WORK}
            onKeyDown={onLoginKeyDown}
          />
        </FormGroup>
        <FormGroup labelFor="password" label="Password:">
          <InputGroup
            inputRef={passwordRef}
            id="password"
            placeholder="password"
            name="roadar-password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            disabled={progress === PROGRESS.WORK}
            onKeyDown={onPasswordKeyDown}
          />
        </FormGroup>
        {progress === PROGRESS.ERROR && (
          <div className={style.error}>
            <Tag intent={Intent.DANGER}>Invalid login or password</Tag>
          </div>
        )}

        <div className={style.footer}>
          <Button onClick={onClick} loading={progress === PROGRESS.WORK} disabled={!isValid}>
            Sign in
          </Button>
          <div className={style.forgetYourPassword}>
            <a href={PATH.requestRestorePassword.path}>Forgot your password?</a>
          </div>

          <div className={style.signUpInstead}>
            <a href={PATH.signUp.path}>Sign up instead</a>
          </div>
        </div>
      </Card>
    </PageVideoWrapper>
  )
}

export default SignIn
