import { useState } from 'react';
import { LOCAL_STORAGE_KEYS } from '../../const/localStorageConstants';
const LOCAL_STORAGE_KEY = LOCAL_STORAGE_KEYS.LAST_GETTING_STARTED_HINT_PAGE_NUMBER;
const getStoredValue = ({ totalPages, startFromBeginning }) => {
    if (startFromBeginning) {
        return 0;
    }
    const storedValueString = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedValueString === null) {
        return 0;
    }
    const storedValue = Number(storedValueString);
    // If number of pages has changed and became lower
    // than last saved current page, return first page
    return storedValue > totalPages ? 0 : storedValue;
};
export const useGettingStartedHintPagination = ({ startFromBeginning = false, totalPages }) => {
    const [lastViewedPageNumber, setLastViewedPageNumber] = useState(getStoredValue({ totalPages, startFromBeginning }));
    const [currentPageIndex, setCurrentPageIndex] = useState(lastViewedPageNumber);
    const goToPage = (newPageIndex) => {
        if (newPageIndex < 0) {
            newPageIndex = 0;
        }
        // Handle case when a user tries to go farther than there are total pages
        if (newPageIndex > totalPages - 1) {
            newPageIndex = totalPages - 1;
        }
        localStorage.setItem(LOCAL_STORAGE_KEY, String(newPageIndex));
        setLastViewedPageNumber(newPageIndex);
        setCurrentPageIndex(newPageIndex);
    };
    return { currentPageIndex, goToPage };
};
