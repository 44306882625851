import { useContext } from 'react'
import { ProfileContext } from '@roadar-pipeline-viewer/roadly-typescript/dist/contexts/profileContext'

function useRoles() {
  const [profile] = useContext(ProfileContext)

  return profile.roles
}

export default useRoles
