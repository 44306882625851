import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState, memo } from 'react';
import { load } from '@2gis/mapgl';
import { Deck } from '@deck.gl/core/typed';
import { initDeck2gisProps } from '@2gis/deck2gis-layer';
import { MapboxOverlayKey, UnitsSystem, } from '../../const/mapConstants';
import { applyMiniPciLayerTo2GisMap } from './layers/getMiniPciIndexLayer';
import { applyAssetsRegistryLayerTo2GisMap } from './layers/assetsRegistryLayer/getAssetsRegistryLayer';
import { applyCamLocTo2GisMap } from './layers/camLocLayer/getCamLocLayer';
export const urbiMapContainerId = 'urbi-map-container';
export const UrbiMapWrapper = memo(() => (_jsx("div", { id: urbiMapContainerId, style: { width: '100%', height: '100%' } })), () => true);
export const TmpUrbiMap = ({ onClickMap, onNavigationEnd, 
// Viewport config, depends on users location or preferences
userViewportConfig, 
// Browser settings passed down here, can be overridden with user's settings
unitsSystem = UnitsSystem.imperial, overlayKey = MapboxOverlayKey.CUSTOM_DARK, isSmall = false, className, touchRotate = true, screenShotProps, 
/* Assets config */
geoJsonConfig, assetsRegistryConfig, miniPciIndexConfig = null, camLocConfig = null, }) => {
    // @ts-ignore Todo
    // const initialViewState: RoadLyViewState = { ...COMPLETE_VIEWPORT_CONFIG, ...userViewportConfig };
    // const overlayConfig = MAPBOX_OVERLAY[overlayKey];
    const onClickDeckGL = useCallback((info /* todo */) => {
        if (typeof onClickMap === 'function') {
            return onClickMap(info);
        }
        console.log('Map clicked (U)', info);
    }, [onClickMap]);
    /*
     * Prepare layers
     */
    // Is null until map is initiated
    const [urbiMapEntities, setUrbiMapEntities] = useState(null);
    /**
     * Handling initiating map in the very
     * beginning of component lifecycle
     */
    useEffect(() => {
        const initMap = async () => {
            const mapGlAPI = await load();
            const map = new mapGlAPI.Map(urbiMapContainerId, {
                key: '7edde441-2ab4-4336-8069-32c19da34659',
                // ...get2GisViewportConfig(userViewportConfig),
                center: [46.7758369, 24.7152123],
                zoom: 10,
            });
            // Apply dark theme:
            await map.setStyleById('e05ac437-fcc2-4845-ad74-b1de9ce07555');
            // @ts-ignore Todo
            const deck = new Deck(initDeck2gisProps(map, { onClick: onClickDeckGL }));
            setUrbiMapEntities({ map, deck });
        };
        initMap();
    }, []);
    // useEffect(() => {
    //   if (urbiMapEntities === null) {
    //     return;
    //   }
    //
    //   setTimeout(() => {
    //     const { map } = urbiMapEntities;
    //     const { center, zoom } = get2GisViewportConfig(userViewportConfig);
    //     map.setCenter(center);
    //     map.setZoom(zoom);
    //   }, 200);
    // }, [urbiMapEntities, userViewportConfig]);
    /*
     * Handling layer separately,
     * since they may change
     */
    useEffect(() => {
        if (urbiMapEntities === null) {
            return;
        }
        if (miniPciIndexConfig) {
            applyMiniPciLayerTo2GisMap(urbiMapEntities, miniPciIndexConfig);
        }
        if (assetsRegistryConfig) {
            applyAssetsRegistryLayerTo2GisMap(urbiMapEntities, assetsRegistryConfig);
        }
        if (camLocConfig) {
            applyCamLocTo2GisMap(urbiMapEntities, camLocConfig);
        }
    }, [urbiMapEntities, miniPciIndexConfig, assetsRegistryConfig]);
    return (_jsx(UrbiMapWrapper, {}));
};
