const ACTIONS = {
  INPUT: 'INPUT',
}

function reducer(draft, action) {
  switch (action.type) {
    case ACTIONS.INPUT:
      return void (draft[action.key] = action.value)
    default:
      return undefined
  }
}

export { ACTIONS, reducer }
