import useRoles from 'hooks/useRoles'
import { ROLES } from 'const'

export function ContentFor({ role, children }) {
  const roles = useRoles()

  if ((roles && roles.includes(role)) || roles.includes(ROLES.SUPERADMIN)) {
    return <>{children}</>
  }

  return null
}
