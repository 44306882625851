/**
 * Utility for downloading files via fetch to make download calls from
 * app origin and therefore make them interceptable by service worker.
 *
 * Otherwise usual download calls are made directly to outer
 * resources, outside the service worker scope.
 *
 * Auth token is not assigned here since the only place responsible
 * for fetching and assigning it is service worker
 */
export const download = (url, filename = 'unnamed') =>
  fetch(url).then(t =>
    t.blob().then(b => {
      const a = document.createElement('a')
      a.href = URL.createObjectURL(b)
      a.setAttribute('download', filename)
      a.click()
    })
  )

/**
 * onClick handler to use instead of downloading <a> tag
 */
export const handleDownload = (event, url, filename = 'unnamed') => {
  event.preventDefault()
  download(url, filename)
}
