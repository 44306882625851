import { getGeoJsonLayer } from './getGeoJsonLayer';
export function getPolygonsBeingLoadedLayer(polygonsBeingLoaded) {
    return getGeoJsonLayer({
        featureCollection: {
            type: 'FeatureCollection',
            // @ts-ignore
            features: [polygonsBeingLoaded],
        },
    });
}
