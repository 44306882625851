import { useEffect, useCallback } from 'react'
import { useImmer } from 'use-immer'
import { useSearchParams } from 'react-router-dom'
import { PROGRESS, SESSIONS_LIST_FILTERS } from 'const'
import API from 'utils/api'

const {
  DEFAULT_LIMIT,
  DEFAULT_SKIP,
  DEFAULT_OWNER,
  DEFAULT_START_TIME_FROM,
  DEFAULT_START_TIME_TO,
  DEFAULT_ORGANIZATION_ID,
  DEFAULT_IS_BROKEN,
  DEFAULT_IS_INVALID,
} = SESSIONS_LIST_FILTERS

const stringToBool = value => {
  if (typeof str === 'boolean') {
    return value
  }
  if (value === 'true') {
    return true
  } else {
    return false
  }
}
export const useSessionsList = () => {
  const [searchParams] = useSearchParams()

  const limit = searchParams.get('limit') || DEFAULT_LIMIT
  const skip = searchParams.get('skip') || DEFAULT_SKIP
  const owner = searchParams.get('owner') || DEFAULT_OWNER
  const start_time_from = searchParams.get('start_time_from') || DEFAULT_START_TIME_FROM
  const start_time_to = searchParams.get('start_time_to') || DEFAULT_START_TIME_TO
  const organization_id = searchParams.get('organization_id') || DEFAULT_ORGANIZATION_ID
  const is_broken = stringToBool(searchParams.get('is_broken') || DEFAULT_IS_BROKEN)
  const is_invalid = stringToBool(searchParams.get('is_invalid') || DEFAULT_IS_INVALID)

  const [state, updateState] = useImmer({
    items: [],
    total: 0,
    progress: PROGRESS.IDLE,
  })

  const onRequestDataFailed = useCallback(() => {
    updateState(draft => {
      draft.progress = PROGRESS.ERROR
    })
  }, [updateState])

  const fetchAndUpdateSessionsListState = useCallback(
    params => {
      updateState(draft => {
        draft.progress = PROGRESS.WORK
        draft.items = []
      })
      API.getSessionList(params)
        .then(req => {
          updateState(draft => {
            draft.progress = PROGRESS.SUCCESS
            draft.items = req.items
            draft.total = req.meta.total
          })
        })
        .catch(onRequestDataFailed)
    },
    [updateState, onRequestDataFailed]
  )

  const updateSessionsList = useCallback(() => {
    fetchAndUpdateSessionsListState({
      limit,
      skip,
      owner,
      start_time_from,
      start_time_to,
      organization_id,
      is_broken,
      is_invalid,
    })
  }, [
    fetchAndUpdateSessionsListState,
    is_broken,
    is_invalid,
    organization_id,
    start_time_from,
    start_time_to,
    owner,
    limit,
    skip,
  ])

  useEffect(() => {
    updateSessionsList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, skip, owner, start_time_from, start_time_to, organization_id, is_broken, is_invalid])

  return {
    progress: state.progress,
    items: state.items,
    total: state.total,
    limit: limit,
    skip: skip,
    owner: owner,
    start_time_from: start_time_from,
    start_time_to: start_time_to,
    organization_id: organization_id,
    is_broken: is_broken,
    is_invalid: is_invalid,
  }
}
