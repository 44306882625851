import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import styles from './PciDashboard.module.scss';
const milesToKilometers = (miles) => miles * 1.60934;
function renderDashboardValue(input) {
    if (input < 100) {
        return input.toFixed(2);
    }
    if (input < 1000) {
        return input.toFixed(1);
    }
    return input.toFixed(0);
}
export const PciDashboard = ({ networkScore, centerLineMiles, className }) => {
    return (_jsxs("div", { className: clsx(styles.dashboardContainer, className), children: [_jsxs("div", { className: clsx(styles.dashboardPlane, styles.networkScore), children: [_jsx("div", { className: styles.value, children: renderDashboardValue(networkScore) }), _jsxs("div", { className: styles.label, children: ["NETWORK", _jsx("br", {}), "SCORE"] })] }), _jsxs("div", { className: clsx(styles.dashboardPlane, styles.centerLineMiles), children: [_jsx("div", { className: styles.value, children: renderDashboardValue(centerLineMiles) }), _jsxs("div", { className: styles.label, children: ["CENTERLINE", _jsx("br", {}), "MILES"] })] })] }));
};
