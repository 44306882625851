import { getOutputList } from 'utils/getOutputList'
import { MODULE_OUTPUT_NAMES, TILES_MODULE_OUTPUT_TYPES } from 'const'

export const get3dTilesFromPipeline = pipeline => {
  const output = getOutputList(pipeline)

  const MODULE_3D_TILES_NAME = '3dtiles_module'

  const tiles3dModules = Object.values(output)
    .filter(item => item.moduleName.includes(MODULE_3D_TILES_NAME))
    .reduce((memo, item) => {
      if (item.outputName === MODULE_OUTPUT_NAMES.RAW) {
        memo[TILES_MODULE_OUTPUT_TYPES.RAW] = `${item.url}/tileset.json`
      }
      if (item.outputName === MODULE_OUTPUT_NAMES.SEMANTIC) {
        memo[TILES_MODULE_OUTPUT_TYPES.SEMANTIC] = `${item.url}/tileset.json`
      }
      return memo
    }, {})

  if (tiles3dModules[TILES_MODULE_OUTPUT_TYPES.RAW] || tiles3dModules[TILES_MODULE_OUTPUT_TYPES.SEMANTIC]) {
    return tiles3dModules
  } else {
    return undefined
  }
}
