import { Button, Alignment } from '@blueprintjs/core'
import { apiAsClass } from '@roadar-pipeline-viewer/roadly-typescript/dist/services/ApiService'
import { useEffect, useState } from 'react'
import { Select2, MultiSelect2 } from '@blueprintjs/select'
import { MenuItem2 } from '@blueprintjs/popover2'
import style from './SessionSelect.module.css'
import { formatDate } from 'utils/formatDate'

const itemPredicate = (query, item) =>
  item.folder_name.toLowerCase().includes(query.toLowerCase()) ||
  formatDate(item.create_ts).includes(query) ||
  (item.id + '').indexOf(query) === 0

const itemRenderer = ({ id, folder_prefix, folder_name, create_ts }, { handleClick, handleFocus, modifiers }) => (
  <MenuItem2
    text={`${id} ${folder_prefix.substring(0, 5)}../${folder_name}`}
    label={formatDate(create_ts)}
    roleStructure="listoption"
    active={modifiers.active}
    key={id}
    onClick={handleClick}
    onFocus={handleFocus}
  />
)

export function SessionSelect({ sessionId, onItemSelect }) {
  const [sessions, setSessions] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    apiAsClass
      .getSessions()
      .then(({ items }) => {
        setSessions(items)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [])

  const currentSession = sessionId ? sessions.find(sess => sess.id === sessionId) : null

  return (
    <Select2
      fill
      disabled={isLoading || sessions.length === 0}
      id="session"
      items={sessions}
      menuProps={{ className: style.menu }}
      activeItem={currentSession}
      onItemSelect={onItemSelect}
      noResults={'No results'}
      itemPredicate={itemPredicate}
      itemRenderer={itemRenderer}
    >
      <Button fill loading={isLoading} alignText={Alignment.LEFT} rightIcon={'chevron-down'}>
        {!isLoading && sessions.length === 0 && 'No sessions'}
        {sessions.length > 0 && (currentSession ? currentSession.folder_name : 'Select session')}
      </Button>
    </Select2>
  )
}

export function SessionMultiSelect({ sessionIds, onItemSelect, onRemove, onReset }) {
  const [sessions, setSessions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isOpening, setIsOpening] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    apiAsClass
      .getSessions()
      .then(({ items }) => {
        setSessions(items)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [])

  const currentSession = sessionIds.length ? sessionIds[sessionIds.length - 1] : null
  const selectedItems = sessions.filter(el => sessionIds.includes(el.id))

  const _onReset = () => {
    setIsOpening(false)
    onReset()
  }

  return (
    <>
      <MultiSelect2
        fill
        disabled={isLoading || sessions.length === 0}
        tagInputProps={{
          inputProps: { onFocus: () => setIsOpening(true) },
          placeholder: isLoading
            ? 'Loading session list...'
            : isOpening
            ? 'Please wait a little...'
            : 'Select sessions',
        }}
        popoverProps={{
          onOpening: () => setIsOpening(false),
        }}
        id="sessions"
        items={sessions}
        selectedItems={selectedItems}
        menuProps={{ className: style.menu }}
        activeItem={currentSession}
        onItemSelect={onItemSelect}
        onRemove={onRemove}
        noResults={'No results'}
        itemPredicate={itemPredicate}
        itemRenderer={itemRenderer}
        tagRenderer={({ id, folder_name }) => `${id} ${folder_name.substring(0, 19)}`}
      ></MultiSelect2>
      <Button className={style.tripleCheckoxReset} icon="reset" small={true} onClick={_onReset} />
    </>
  )
}
