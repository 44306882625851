import distanceInWordsStrict from 'date-fns/formatDistanceStrict'
import { Intent } from '@blueprintjs/core'
import jsyaml from 'js-yaml'
import { checkIfIsMonoYamlModule } from 'utils/payloadJsonUtils/payloadJsonUtils'

import { formatDate } from './formatDate'
import { parseDate } from './parseDate'

const TARGET = process.env.REACT_APP_TARGET

export const getTarget = () => TARGET

export const formatDateDistance = (start, end) => {
  return start && end ? distanceInWordsStrict(end, start) : ''
}

export const getAllOutput = pipeline => {
  return pipeline.state.reduce(
    (memo, item) => [
      ...memo,
      ...Object.values(item.outputs).map(output =>
        output.url ? { ...output, url: output.url.replace(TARGET, '/') } : output
      ),
    ],
    []
  )
}

// This function is moved to typescript package:
export const getQueryString = (searchParams, newParams) => {
  const params = Object.entries(searchParams)
    .filter(([_key, value]) => {
      if (Array.isArray(value)) return false
      return !!value
    })
    .reduce((memo, [key, value]) => ({ ...memo, [key]: value }), {})

  const url_search_params = new URLSearchParams(params)

  Object.entries(newParams).forEach(([key, value]) => {
    for (const [k, val] of Object.entries(searchParams)) {
      if (Array.isArray(val) && key !== k) {
        val.map(v => url_search_params.append(k, v))
      }
    }

    if (Array.isArray(value)) {
      value.map(v => url_search_params.append(key, v))
    } else {
      url_search_params.set(key, value)
    }
  })

  return url_search_params.toString()
}

export const getLastOutputDate = pipeline => {
  const [last] = pipeline.state
    .map(item => item.ts_end)
    .filter(item => !!item)
    .slice(-1)

  return last
}

export const getYandexDiskLink = pipeline => {
  const module = pipeline.definition.find(item => item.module.includes('video_preprocessing_module'))

  if (!module) {
    return undefined
  }

  const folder = module.arguments.find(item => item.name === 'video_folder_uri')
  const absPath = module.arguments.find(item => item.name === 'abspath')

  if (!folder) {
    return undefined
  }

  return absPath
    ? `https://yadi.sk/d${folder.value[0] === '/' ? folder.value : `/${folder.value}`}`
    : `https://yadi.sk/d/IkeotU88aB9tOQ${folder.value}`
}

export const getVideoMetadata = pipeline => {
  try {
    return pipeline.session_data.video_metadata
  } catch (e) {
    return {}
  }
}
export const getPipelineVideoFps = pipeline => {
  try {
    return pipeline.session_data.video_metadata.fps
  } catch (e) {
    return undefined
  }
}

export const moduleStatusToIntent = status => {
  switch (status) {
    case 'success':
      return Intent.SUCCESS
    case 'failed':
      return Intent.DANGER
    case 'running':
      return Intent.PRIMARY
    case 'waiting':
      return Intent.WARNING
    default:
      return Intent.NONE
  }
}

export const pipelineStatusToIntent = status => {
  switch (status) {
    case 'success':
      return Intent.SUCCESS
    case 'failed':
      return Intent.DANGER
    case 'active':
      return Intent.WARNING
    default:
      return Intent.NONE
  }
}

export const get3Dtiles = pipeline => {
  const module3dTiles = pipeline.state.filter(({ module }) => {
    return module.includes('3dtiles_module') && module.status === 'success'
  })

  if (!module3dTiles.length) {
    return null
  }

  const output = pipeline.state
    .filter(({ module }) => module.includes('3dtiles_module'))
    .map(({ outputs: { output_dir } }) => output_dir)

  return {
    semantic: output.find(({ value }) => value.includes('/opt/roadar_data/semantic-tiles')),
    image_colors: output.find(({ value }) => value.includes('/opt/roadar_data/image-colors-tiles')),
  }
}

export const reshapeModules = modules => {
  let reshape_modules = [].concat(
    ...modules.map(function (module, i) {
      let module_concat_with_run_section = [
        { id: module.name, name_module: module.name, section: '*' },
        ...module.sections.map(function (section, i) {
          return {
            id: `${module.name}|${section}`,
            name_module: module.name,
            section: section,
          }
        }),
      ]
      return module_concat_with_run_section
    })
  )
  return reshape_modules
}

export const stringToBase64 = value => {
  return btoa(jsyaml.dump(JSON.parse(value)))
}

export const formatGitUrl = (name_module, commit_hash, git_branch) => {
  let urlGit = 'https://gitlab.netvision-internal.ru/rnd-roadar/' + name_module.split(':')[0]
  if (commit_hash) {
    urlGit += '/-/commit/' + commit_hash
  } else {
    if (git_branch) {
      urlGit += '/-/commits/' + git_branch
    } else {
      urlGit += '/-/commits/main'
    }
  }
  return urlGit
}

export const parseErrorsDetialResponse = (data, formOptions, setError, setRequestErrors) => {
  if (data && data.detail && Array.isArray(data.detail)) {
    data.detail.forEach(({ loc, msg }) => {
      const inputKey = loc[1]
      if (formOptions.includes(inputKey)) {
        setError(loc[1], { type: 'custom', message: msg })
      } else {
        setRequestErrors(prevState => [...prevState, { loc: loc[1], msg }])
      }
    })
  } else {
    if (data && data.detail) {
      setRequestErrors([{ loc: 'Request', msg: data.detail }])
    } else {
      setRequestErrors([{ loc: 'Request', msg: 'Something went wrong' }])
    }
  }
}

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

export const isNonEmptyString = input => typeof input === 'string' && input.length > 0

export const appendDistance = (sourceStr, distance, units) => {
  if (distance) {
    return `${sourceStr} (${distance} ${units})`
  }
  return sourceStr
}

const utils = {
  stringToBase64,
  getTarget,
  parseDate,
  formatDate,
  formatDateDistance,
  getAllOutput,
  getQueryString,
  getLastOutputDate,
  getYandexDiskLink,
  getVideoMetadata,
  pipelineStatusToIntent,
  moduleStatusToIntent,
  get3dTiles: get3Dtiles,
  getPipelineVideoFps,
  formatGitUrl,
  reshapeModules,
  parseErrorsDetialResponse,
  appendDistance,
}

export default utils
