import jsyaml from 'js-yaml'

export const monoyaml = `
sensors:
  - id: camera_1
    type: camera
    features_extractor:
      point_density_for_detector: 6000
    provider:
      type: video
      video_file: ""
      timestamps: ""
      start_frame: 0
    model:
      shutter_time_seconds: 0
      calibration: ""
      photometric_calibration: ""
      vignetting: ""
    transformations:
      resize_transformer:
        resize_ratio: 1
    segmentation:
      provider:
        type: video
        video_file: ""
        timestamps: ""
        start_frame: 0
      semantic_legend: ""
      objects_to_filter: "car car_mount ego_vehicle sky person"
      filter_by_semantic: on
      filter_type: exclude

  - id: gnss_1
    type: gnss
    provider:
      path: ""

  - id: imu_1
    type: imu
    use_vio: on
    provider:
      timestamps: ""
      path: ""
      vio_settings: ""
      camchain: ""
      orientation: "Right"

tracker:
  number_of_desired_points: 8000
  keyframe_strategy:
    factor: 1
  marginalization_strategy:
    minimum_size: 5
    maximum_size: 7
    maximum_percentage_of_marginalized_points_in_frame: 0.95

initializer:
  type: monocular
  initializer_type: calibrated
  sensor_id: camera_1
  essential_matrix_ransac_threshold: 0.5
  pnp_ransac_threshold: 0.5
  se3_inlier_ratio: 0.7
  reprojection_threshold: 1
  pnp_inlier_ratio: 0.6
  features_extractor:
    type: ORB
    number_of_features: 6000
  keyframe_strategy:
    strategy: wait_for_movement
    sliding_window_length: 4

gnss_alignmenter:
 mode: on
 optimize_time_offset: off
 optimize_gravity: on
`
export default monoyaml

export const defaultMonoYamlAsJson = jsyaml.load(monoyaml)
export const stringifiedDefaultMonoYamlAsJson = JSON.stringify(defaultMonoYamlAsJson)
