import { useEffect, useState } from 'react'
import { DESKTOP_BREAKPOINT } from 'const'

function getWindowDimensions() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
    isDesktop: window.innerWidth > DESKTOP_BREAKPOINT,
  }
}

function useWindow() {
  const [windowState, setWindow] = useState(getWindowDimensions())

  const handleResize = () => {
    setWindow(getWindowDimensions())
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    width: windowState.width,
    height: windowState.height,
    isDesktop: windowState.isDesktop,
  }
}

export default useWindow
