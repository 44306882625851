import cloneDeep from 'lodash/cloneDeep';
import { parseDate } from '../parseDate';
import { getPipelineProcessingTimings } from '../getPipelineProcessingTimings';
import { StorageType } from '../../const/appConstants';
/**
 * This normalizer is supposed to work only after
 * async app configuration is made and configuration
 * of this is made within it
 */
export class PipelineNormalizer {
    constructor() {
        // {
        //   storageType: 'GoogleStorage',
        //   bucketNames: {
        //     persistentBucket: string,
        //     pipelinesBucket: string,
        //   }
        // }
        this.storageConfig = undefined;
        this.stats = [];
    }
    configureStorage(storageConfig) {
        this.storageConfig = storageConfig;
    }
    prepareGoogleStorageOutputUrl(output) {
        const bucketName = output.exposed_as
            ? this.storageConfig.bucketNames.persistentBucket
            : this.storageConfig.bucketNames.pipelinesBucket;
        return output.url.replace('/data', `https://storage.googleapis.com/${bucketName}`);
    }
    normalizePipelineTask(task) {
        const modifiedProps = {};
        /**
         * Prepare outputs according to what file storage is used:
         * file system or cloud storage
         */
        if (this.storageConfig.storageType === StorageType.GOOGLE_STORAGE) {
            const outputs = cloneDeep(task.outputs);
            for (const key in outputs) {
                outputs[key].url = this.prepareGoogleStorageOutputUrl(outputs[key]);
            }
            modifiedProps.outputs = outputs;
        }
        /**
         * Preparing dates
         */
        // Nullable:
        modifiedProps.ts_created = task.ts_created ? parseDate(task.ts_created) : task.ts_created;
        // Optional:
        modifiedProps.ts_updated = task.ts_updated ? parseDate(task.ts_updated) : task.ts_updated;
        // Nullable:
        modifiedProps.ts_start = task.ts_start ? parseDate(task.ts_start) : task.ts_start;
        // Nullable:
        modifiedProps.ts_end = task.ts_end ? parseDate(task.ts_end) : task.ts_end;
        /**
         * Finish normalization
         * and return result
         */
        return {
            ...task,
            ...modifiedProps,
        };
    }
    normalizePipeline(rawPipelineListItem) {
        try {
            const state = rawPipelineListItem.state.map(this.normalizePipelineTask.bind(this));
            const created = parseDate(rawPipelineListItem.created);
            const updated = rawPipelineListItem.updated ? parseDate(rawPipelineListItem.updated) : rawPipelineListItem.updated;
            // Calculate pipeline start / end / length
            const timings = getPipelineProcessingTimings(state, { updated, status: rawPipelineListItem.status });
            // window.stats = this.stats;
            // if (['MINI_PCI', 'MINI_PCI_PRE', 'MINI_PCI_B'].includes(rawPipelineListItem.pipeline_type)) {
            //   // console.log('-->-->-->', rawPipelineListItem.pipeline_type);
            //   this.stats.push({
            //     uuid: rawPipelineListItem.uuid,
            //     type: rawPipelineListItem.pipeline_type,
            //     created: rawPipelineListItem.created,
            //     status: rawPipelineListItem.status,
            //     seconds: timings.pipelineLengthMilliseconds / 1000,
            //   });
            // }
            return {
                ...rawPipelineListItem,
                ...timings,
                state,
                created,
                updated,
            };
        }
        catch (e) {
            console.error('normalizePipeline error:', e);
        }
    }
    normalizePipelineShort(rawPipelineListItem) {
        try {
            const created = parseDate(rawPipelineListItem.create_ts);
            const updated = rawPipelineListItem.update_ts ? parseDate(rawPipelineListItem.update_ts) : rawPipelineListItem.update_ts;
            return {
                ...rawPipelineListItem,
                created,
                updated,
            };
        }
        catch (e) {
            console.error('normalizePipeline error:', e);
        }
    }
    normalizeGetPipelineListResponse(response) {
        return {
            ...response,
            items: response.items.map(this.normalizePipeline.bind(this)),
        };
    }
    normalizeGetPipelineListResponseShort(response) {
        return {
            ...response,
            items: response.items.map(this.normalizePipelineShort.bind(this)),
        };
    }
    normalizeGetPipelineItemResponse(response) {
        return this.normalizePipeline(response);
    }
}
