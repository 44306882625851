import { Button, RadioGroup, Radio, Switch } from '@blueprintjs/core'
import { Classes, Popover2 } from '@blueprintjs/popover2'
import { MAPBOX_OVERLAY, UNITS } from 'const'
import styles from './PipelineItemMapSettings.module.css'

function PipelineItemMapSettings({
  unit,
  onUnitChange,
  isOverlayShow,
  onIsOverlayShowChange,
  overlay,
  onOverlayChange,
}) {
  const handleUnitChange = e => {
    onUnitChange(e.target.value)
  }

  const handleIsOverlayShowChange = e => {
    onIsOverlayShowChange(e.target.checked)
  }

  const handleOverlayChange = e => {
    onOverlayChange(MAPBOX_OVERLAY[e.target.value])
  }

  return (
    <Popover2
      interactionKind="click"
      popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
      placement="left-end"
      content={
        <div className={styles.content}>
          <div className={styles.section}>
            <div className={styles.title}>
              <div className={styles.titleText}>Overlay:</div>
              <Switch checked={isOverlayShow} onChange={handleIsOverlayShowChange} />
            </div>
            <div className="bp4-html-select">
              <select value={overlay.key} onChange={handleOverlayChange}>
                {Object.values(MAPBOX_OVERLAY).map(item => (
                  <option value={item.key} key={item.key}>
                    {item.title}
                  </option>
                ))}
              </select>
              <span className="bp4-icon bp4-icon-double-caret-vertical"></span>
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.title}>Unit:</div>
            <RadioGroup inline onChange={handleUnitChange} selectedValue={unit}>
              {UNITS.map(unit => (
                <Radio label={unit} value={unit} key={unit} />
              ))}
            </RadioGroup>
          </div>
        </div>
      }
      renderTarget={({ isOpen, ref, ...targetProps }) => (
        <div className={styles.settings}>
          <Button {...targetProps} elementRef={ref} placement="top-end" icon="cog" />
        </div>
      )}
    />
  )
}

export default PipelineItemMapSettings
