import { jsx as _jsx } from "react/jsx-runtime";
// import { useNavigate } from 'react-router';
import { DemoUserPciPanel } from './DemoUserPciPanel';
import { useLogin } from '../../../hooks/useLogin';
import { EXTERNAL_PATH } from '../../../const/routingConstants';
export const DemoUserPciPanelContainer = () => {
    // const navigate = useNavigate();
    const { logout } = useLogin();
    const handleClickTryRoadlyPro = async () => {
        logout(); // Do not wait to avoid blinking
        window.location.replace(EXTERNAL_PATH.roadlyProAppStore.path);
    };
    return (_jsx(DemoUserPciPanel, { onClickTryRoadlyPro: handleClickTryRoadlyPro }));
};
