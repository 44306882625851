import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { LoadingStatus } from '../../../const/appConstants';
import { API } from '../../../services/ApiService';
import { ManuallyChangePassword } from './ManuallyChangePassword';
import { useException } from '../../../hooks/useException';
import { ManuallyResetPasswordException } from '../../../utils/exceptions';
export const ManuallyChangePasswordContainer = () => {
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.INITIAL);
    const { exception, setException } = useException(ManuallyResetPasswordException);
    const handleSubmitChangePassword = useCallback(async (params) => {
        setLoadingStatus(LoadingStatus.LOADING);
        try {
            await API.manuallyChangePassword(params);
            setLoadingStatus(LoadingStatus.SUCCESS);
        }
        catch (e) {
            setException(e);
            setLoadingStatus(LoadingStatus.ERROR);
        }
    }, []);
    const handleRestartFlowRequested = useCallback(() => {
        setLoadingStatus(LoadingStatus.INITIAL);
        setException(null);
    }, []);
    return (_jsx(ManuallyChangePassword, { onRestartFlowRequested: handleRestartFlowRequested, loadingStatus: loadingStatus, exception: exception, onSubmitChangePassword: handleSubmitChangePassword }));
};
