import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { PROGRESS, REQUEST_STATUS } from 'const'
import { ConfirmCode } from 'features/ConfirmCode/ConfirmCode/ConfirmCode'
import API from 'utils/api'

export const ConfirmCodeContainer = () => {
  const [searchParams] = useSearchParams()

  const code = searchParams.get('code')
  const email = searchParams.get('email')

  const [status, setStatus] = useState(PROGRESS.WORK)

  useEffect(() => {
    if (!code || !email) {
      setStatus(PROGRESS.ERROR)
      return
    }

    ;(async () => {
      const confirmationResult = await API.confirmCode({ code, email })
      setStatus(confirmationResult.status === REQUEST_STATUS.OK ? PROGRESS.SUCCESS : PROGRESS.ERROR)
    })()
  }, [])

  return <ConfirmCode status={status} />
}
