export function processMask(rawMaskImage, { inputColor, invert }) {
    const [R, G, B] = inputColor || [0, 0, 0];
    const [maskAlpha, nonMaskAlpha] = invert ? [0, 255] : [255, 0];
    const canvas = document.createElement('canvas');
    canvas.width = rawMaskImage.width;
    canvas.height = rawMaskImage.height;
    const context = canvas.getContext('2d');
    if (context === null) {
        throw new Error('Canvas context is null');
    }
    context.drawImage(rawMaskImage, 0, 0);
    const imageData = context.getImageData(0, 0, rawMaskImage.width, rawMaskImage.height);
    const { data } = imageData;
    const imageDataLength = data.length;
    for (let i = 0; i < imageDataLength; i += 4) {
        const value = data[i];
        if (value === 1) {
            data[i] = R;
            data[i + 1] = G;
            data[i + 2] = B;
            data[i + 3] = maskAlpha;
        }
        else {
            data[i] = R;
            data[i + 1] = G;
            data[i + 2] = B;
            data[i + 3] = nonMaskAlpha;
        }
    }
    context.putImageData(imageData, 0, 0);
    return canvas.toDataURL();
}
