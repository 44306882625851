import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormGroup, InputGroup, Button } from '@blueprintjs/core';
import { LoadingStatus } from '../../../../const/appConstants';
import { PASSWORD_MIN_LEN } from '../../../../const/userConstants';
import style from './SubmitRestorePasswordForm.module.css';
// Todo: move to a common place:
const toastProps = {
    position: 'bottom-center',
    closeOnClick: true,
    pauseOnHover: false,
    autoClose: 3000,
};
export const SubmitRestorePasswordForm = ({ code, onSubmit }) => {
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [progress, setProgress] = useState(LoadingStatus.INITIAL);
    const isValid = passwordConfirmation === password && password.length >= PASSWORD_MIN_LEN;
    const getErrMsg = () => {
        if (password.length && password.length < PASSWORD_MIN_LEN) {
            return 'Password too short';
        }
        if (password !== passwordConfirmation) {
            return 'Passwords do not match';
        }
        return '\u00A0';
    };
    const onClick = async () => {
        try {
            setProgress(LoadingStatus.LOADING);
            const isDone = await onSubmit({ code, password });
            if (!isDone) {
                setProgress(LoadingStatus.ERROR);
                const res = toast.error('Link expired or corrupted', toastProps);
            }
            else {
                toast.success('Password changed! Please log in', toastProps);
            }
        }
        catch (error) {
            console.error(error);
            setProgress(LoadingStatus.ERROR);
            toast.error('Something went wrong', toastProps);
        }
    };
    return (_jsxs("div", { className: style.formCt, children: [_jsx(FormGroup, { labelFor: "password", label: "New password:", children: _jsx(InputGroup, { id: "password", placeholder: "password", name: "roadar-password", type: "password", value: password, onChange: (event) => setPassword(event.target.value), disabled: progress === LoadingStatus.LOADING }) }), _jsx(FormGroup, { labelFor: "passwordConfirmation", label: "Confirm password:", children: _jsx(InputGroup, { id: "passwordConfirmation", placeholder: "password", name: "roadar-passwordConfirmation", type: "password", value: passwordConfirmation, onChange: (event) => setPasswordConfirmation(event.target.value), disabled: progress === LoadingStatus.LOADING }) }), _jsx("div", { className: style.error, children: getErrMsg() }), _jsx("div", { className: style.footer, children: _jsx(Button, { onClick: onClick, loading: progress === LoadingStatus.LOADING, disabled: !isValid, children: "Apply" }) })] }));
};
