export var Filter;
(function (Filter) {
    Filter["ALL"] = "all";
    Filter["ACTIVE"] = "active";
    Filter["CANCELED"] = "canceled";
    Filter["FAILED"] = "failed";
    Filter["SUCCESS"] = "success";
})(Filter || (Filter = {}));
export var Sort;
(function (Sort) {
    Sort["ASC"] = "asc";
    Sort["DESC"] = "desc";
})(Sort || (Sort = {}));
