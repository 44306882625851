import { Owner } from './userConstants';
import { Filter, Sort } from './listingConstants';
export var PipelineStepExposition;
(function (PipelineStepExposition) {
    PipelineStepExposition["ASSETS_GEOJSON"] = "ASSETS_GEOJSON";
})(PipelineStepExposition || (PipelineStepExposition = {}));
export var PipelineStatus;
(function (PipelineStatus) {
    PipelineStatus["active"] = "active";
    PipelineStatus["failed"] = "failed";
    PipelineStatus["success"] = "success";
    PipelineStatus["canceled"] = "canceled";
})(PipelineStatus || (PipelineStatus = {}));
export var AssetPipelineTypes;
(function (AssetPipelineTypes) {
    AssetPipelineTypes["LSA_ASSET_LOC"] = "LSA_ASSET_LOC";
    AssetPipelineTypes["SIGNS_ASSET_LOC"] = "SIGNS_ASSET_LOC";
})(AssetPipelineTypes || (AssetPipelineTypes = {}));
export const PipelineListFilters = {
    DEFAULT_LIMIT: 20,
    DEFAULT_SKIP: 0,
    DEFAULT_STATUS: Filter.ALL,
    DEFAULT_OWNER: Owner.ALL,
    DEFAULT_IS_COMPLETE_VIEW: false,
    DEFAULT_SESSION: 'all',
    DEFAULT_ORGANIZATION: 'all',
    DEFAULT_SORT_ORDER: Sort.DESC,
    DEFAULT_SORT_FIELD: 'created',
};
export const PipelineTypes = ['GENERAL', 'SPARSE_CLOUD', 'DENSE_CLOUD', 'CAM_LOC'];
