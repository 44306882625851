import { MODULE_OUTPUT_NAMES, MODULE_TYPE, POINT_CLOUD_TYPE } from 'const'
import { getOutputList } from 'utils/getOutputList'
import { get3dTilesFromPipeline } from 'utils/get3dTilesFromPipeline'

const getXyzFileUrl = pipeline => {
  const output = getOutputList(pipeline)
  const module = Object.values(output).find(item => item.outputName === MODULE_OUTPUT_NAMES.XYZ)
  return module && module.unix_type === MODULE_TYPE.FILE && module.url ? module.url : undefined
}

export const getPointCloud = pipeline => {
  const tiles = get3dTilesFromPipeline(pipeline)
  const xyzFileUrl = getXyzFileUrl(pipeline)

  return new Promise((resolve, reject) => {
    if (tiles) {
      resolve({
        type: POINT_CLOUD_TYPE.TILES,
        tiles: tiles,
        delta: 0,
      })
    } else if (xyzFileUrl) {
      const worker = new Worker(new URL('workers/track.js', import.meta.url))
      worker.postMessage({ url: xyzFileUrl })
      worker.onmessage = ({ data: { points, delta } }) => {
        resolve({
          type: POINT_CLOUD_TYPE.XYZ,
          points,
          delta,
        })
        worker.terminate()
      }
    } else {
      resolve(undefined)
    }
  })
}
