import { useState } from 'react'
import { Card, Elevation, Tab, Tabs } from '@blueprintjs/core'
import useRoles from 'hooks/useRoles'
import { ROLES } from 'const'
import RunPipelineForm, {
  RunChunkedPipelineForm,
  RunCamLocPipelineForm,
  RunMiniPCIPipelineForm,
} from 'components/RunPipelineForm/RunPipelineForm'
import { RunMultiSessionPipelineForm } from 'components/RunPipelineForm/RunMultiSessionPipelineForm'
import style from './RunPipelinePage.module.css'
import API from 'utils/api'
import { useSearchParams } from 'react-router-dom'

const TABS = {
  CUSTOM: 'custom',
  SESSION: 'session',
  CHUNKED: 'chunked',
  CAM_LOC: 'cam_loc',
}

function RunPipelinePage() {
  const [activeTabId, setActiveTabId] = useState(TABS.CHUNKED)

  const onSubmit = payload => API.runPipelineItem({ payload })

  const pointcloudBasedTypeNames = ['Pointcloud', 'LSA', 'SIGNS']

  const onChunkedSessionSubmit = payload => {
    const pipelineTypeName = payload['pipelineTypeName']
    delete payload['pipelineTypeName']
    if (pipelineTypeName === 'LSA') {
      const newPayload = {}
      const appendKeyAndDropOld = (oldKeyName, newKeyName) => {
        if (newPayload[newKeyName] !== undefined) {
          newPayload[newKeyName] = payload.data[oldKeyName]
          delete payload.data[oldKeyName]
        }
      }
      appendKeyAndDropOld('text_prompt', 'textPrompt')
      appendKeyAndDropOld('text_threshold', 'textThreshold')
      appendKeyAndDropOld('box_threshold', 'boxThreshold')
      newPayload['sessionId'] = payload['session_id']
      newPayload['areSizesUsed'] = false
      newPayload['textPromptSizes'] = ''
      newPayload['pointcloudData'] = payload.data

      return API.runAssetsPipeline(newPayload)
    }
    if (pipelineTypeName === 'SIGNS') {
      const sessionId = payload['session_id']
      delete payload['session_id']
      return API.runSignsAssetsPipeline(sessionId, payload)
    }
    return API.runChunkedPipelineItem({ payload })
  }

  const onCamLocSubmit = formData => API.runCamLocPipelineItem(formData)

  const onMiniPCISubmit = formData => API.runMiniPCIPipelineItem(formData)

  const onMultiSessionSubmit = (pipelineTypeName, formData) => {
    if (pipelineTypeName === 'reloc') {
      return API.runRelocPipelineItem(formData)
    } else if (pipelineTypeName === 'tiling') {
      return API.runTilingPipelineItem(formData)
    } else if (pipelineTypeName == 'gauss') {
      return API.runGaussPipelineItem(formData)
    }
    console.error(`Unsupported pipeline type ${pipelineTypeName}`)
  }

  const roles = useRoles()
  const isAdmin = roles && roles.includes(ROLES.ADMIN)

  const [searchParams] = useSearchParams()
  const selectedSession = searchParams.get('session') || null

  return (
    <div className={style.page}>
      <h2 className="bp4-heading">Run pipeline</h2>
      <Tabs id="TabsExample" selectedTabId={activeTabId} onChange={tab => setActiveTabId(tab)}>
        {isAdmin ? (
          <Tab
            id="custom"
            title="Custom"
            panel={
              <Card className={style.content} elevation={Elevation.THREE}>
                <RunPipelineForm onSubmit={onSubmit} />
              </Card>
            }
          />
        ) : null}
        <Tab
          id="chunked"
          title="Pointcloud"
          panel={
            <Card className={style.content} elevation={Elevation.THREE}>
              <RunChunkedPipelineForm
                onSubmit={onChunkedSessionSubmit}
                selectedSession={selectedSession}
                pointcloudBasedTypeNames={pointcloudBasedTypeNames}
              />
            </Card>
          }
        />
        <Tab
          id="cam_loc"
          title="Camera localization"
          panel={
            <Card className={style.content} elevation={Elevation.THREE}>
              <RunCamLocPipelineForm onSubmit={onCamLocSubmit} />
            </Card>
          }
        />
        <Tab
          id="mini_pci"
          title="Mini PCI"
          panel={
            <Card className={style.content} elevation={Elevation.THREE}>
              <RunMiniPCIPipelineForm onSubmit={onMiniPCISubmit} />
            </Card>
          }
        />
        <Tab
          id="multi"
          title="Multi session"
          panel={
            <Card className={style.content} elevation={Elevation.THREE}>
              <RunMultiSessionPipelineForm onSubmit={onMultiSessionSubmit} />
            </Card>
          }
        />
        <Tabs.Expander />
      </Tabs>
    </div>
  )
}

export default RunPipelinePage
