import clsx from 'clsx'
import { PATH } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/routingConstants'

import screenshotFist from './images/1.jpg'
import screenshotTwo from './images/2.jpg'
import screenshotThree from './images/3.jpg'
import instruction1 from './images/instruction1.jpg'
import instruction2 from './images/instruction2.jpg'
import instruction3 from './images/instruction3.jpg'
import miniPci1 from './images/miniPci1.jpg'
import miniPci2 from './images/miniPci2.jpg'

import styles from './UserGuideDocument.module.scss'

export const UserGuideDocument = ({ isWide }) => {
  return (
    <div className={isWide ? styles.wideContainer : styles.narrowContainer}>
      <div className={styles.extraMarginBottom}>
        <h1>Welcome to Roadly!</h1>

        <h3>In this guide you will learn:</h3>
        <ul>
          <li>what the Roadly platform is</li>
          <li>how to install the Roadly PRO app on your smartphone and make your first recording</li>
          <li>perform data processing in the cloud to generate your first point cloud</li>
        </ul>

        <h2>What is Roadly?</h2>
        <p>
          With Roadly you can generate PCI map from your video and see it on top of a web-based map. No technical skill
          is required to start using the platform.
        </p>
      </div>

      <h2>Roadly quick start guide</h2>

      {/*
       * Step 1
       */}

      <div className={styles.extraMarginBottom}>
        <h3>Step 1. Registration</h3>
        <p>
          If you have your invite link, please follow it, then fill in your email and create a password. If you already
          have credentials – proceed to the <a href={PATH.signIn.path}>Sign in</a> page.
        </p>
        <div className={styles.imageContainer}>
          <img src={screenshotFist} alt="registration" className={styles.image} />
        </div>
        <p>You will then be logged in to the Roadly web application.</p>
      </div>

      {/*
       * Step 2
       */}

      <div className={styles.extraMarginBottom}>
        <h3>Step 2. Download and install the Roadly PRO iPhone app</h3>
        <p>
          Right now Roadly supports iPhone 11 or later smartphones (Android is on our roadmap 👌). You can install the
          Roadly PRO beta from{' '}
          <a href="https://testflight.apple.com/join/sozi0K9g" target="_blank" rel="noreferrer" className="text-href">
            here
          </a>
          . You will need to install the Apple{' '}
          <a
            href="https://itunes.apple.com/us/app/testflight/id899247664?mt=8"
            target="_blank"
            rel="noreferrer"
            className="text-href"
          >
            TestFlight
          </a>{' '}
          app in the first place, if you have not done so before.
        </p>
        <div className={styles.imageContainer}>
          <img src={screenshotTwo} alt="registration" className={styles.image} />
        </div>
        <p>
          Please allow the Roadly PRO app to access your location and camera, otherwise it won't be able to perform the
          recordings.
        </p>
        <p>When launching for the first time, please, log in using your email and password from the previous step.</p>
        <div className={styles.imageContainer}>
          <img src={screenshotThree} alt="registration" className={styles.image} />
        </div>
        <p>
          <i>
            Please, note that the Roadly PRO app doesn't record any personal information except an email address that is
            necessary for the platform authentication.
          </i>
        </p>
      </div>

      {/*
       * Step 3
       */}

      <div className={styles.extraMarginBottom}>
        <h3>Step 3. Making your first recording</h3>
        <p>
          Before making your first recording, please, install your smartphone under your windshield like a dashcam. Our
          positioning algorithm relies on GPS and reasonable 🌤 lighting conditions (just don't record at night or
          indoors and it will be ok).
        </p>

        <ul className={styles.customList}>
          <li>❗️ Make sure the camera is stable and the view is not blocked by anything.</li>
          <li>❗️ Make sure the camera is set horizontally.</li>
        </ul>

        <p>
          For now, you don't need to change any default settings. To start recording proceed through the following
          actions:
        </p>

        <div className={styles.imageContainer}>
          <div className={styles.instructionGrid}>
            <div className={styles.instructionRow}>
              <div className={clsx(styles.instructionCell, styles.instructionCellText)}>Press New record button</div>
              <div className={clsx(styles.instructionCell, styles.instructionCellImage)}>
                <img src={instruction1} alt="registration" className={styles.instructionImage} />
              </div>
            </div>
            <div className={styles.instructionRow}>
              <div className={clsx(styles.instructionCell, styles.instructionCellText)}>
                Choose "Drive mode" and start Record button
              </div>
              <div className={clsx(styles.instructionCell, styles.instructionCellImage)}>
                <img src={instruction2} alt="registration" className={styles.instructionImage} />
              </div>
            </div>
            <div className={styles.instructionRow}>
              <div className={clsx(styles.instructionCell, styles.instructionCellText)}>
                When you are done with the recording, press Finish button
              </div>
              <div className={clsx(styles.instructionCell, styles.instructionCellImage)}>
                <img src={instruction3} alt="registration" className={styles.instructionImage} />
              </div>
            </div>
          </div>
        </div>

        <p>Please note that the optimal duration for the session is 3-5 miles</p>
        <p>
          <i>
            👍 Well done! You're finished with your first recording. Now it is time to prepare and process the data to
            see the results. Don't worry, you won't need any tech skills for that.
          </i>
        </p>
      </div>

      {/**
       * Step 4
       */}

      <div className={styles.extraMarginBottom}>
        <h3>Step 4. Upload the data to the cloud</h3>
        <p>
          Please find a place with good WIFI connection to upload the data to the cloud (or you may be the lucky one
          with a fast and unlimited cell internet).
        </p>
        <p>
          While in the Roadly PRO app, open the menu on the top right corner, you should see the list of completed
          recordings.
        </p>

        <div className={styles.imageContainer}>
          <img src={miniPci1} className={styles.image} />
        </div>

        <p>
          Press this button to upload to the cloud all recordings you've done. Don't close the app until the upload
          process is complete. We have experimental floating window (PiP) mode that enables background upload. Please
          check it in settings.
        </p>
      </div>

      {/**
       * Step 5
       */}

      <div className={styles.extraMarginBottom}>
        <h3>Step 5. Processing</h3>
        <p>
          After you upload the video please inform us and we will process the video to calculate PCI. In future (after
          the free pilot project) your videos will be processed instantly after upload.
        </p>
      </div>

      {/**
       * Step 5
       */}

      <div className={styles.extraMarginBottom}>
        <h3>Step 6. See the results</h3>
        <p>After the successful processing, you will see the results by following the provided link.</p>

        <div className={styles.imageContainer}>
          <img src={miniPci2} className={styles.image} />
        </div>

        <p>You can move the map with your mouse or touchpad, hold Ctrl (Command on Mac) and drag to tilt the camera.</p>
        <p>🔧 For more advanced users we have a detailed end user guide for the Roadly PRO app.</p>
        <p>
          ☝️ If you have any issues or questions to the team, please, do not hesitate to contact us via{' '}
          <a href="mailto:support@road.ly">support@road.ly</a>
        </p>
      </div>
    </div>
  )
}
