import { pciSeverityNamesSortedArray } from '../../const/pciConstants';
import { getSeverityIndexByPci } from '../pciUtils';
export const normalizePciFeature = (feature) => ({
    ...feature,
    properties: {
        ...feature.properties,
        stats: {
            ...feature.properties.stats,
            pciSeverityName: pciSeverityNamesSortedArray[getSeverityIndexByPci(feature.properties.stats.pci)],
        },
    },
});
export function normalizePciAreaResponse({ features, ...other }) {
    return {
        ...other,
        features: features.map(normalizePciFeature),
    };
}
