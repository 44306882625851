import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FinalAssetsQaVideoPlayerContainer } from '../../features/FinalAssetsQa/FinalAssetsQaVideoPlayer.container';
const feature1 = {
    type: 'Feature',
    geometry: {
        type: 'Point',
        coordinates: [
            30.649581,
            36.881448,
            9.637024,
        ],
        // @ts-ignore
        bbox: null,
    },
    properties: {
        image_name: 'pipeline-c93cddf8-3f7d-4002-a7dc-9b9461f50b4a/uuid450/asset_localization_results/frame002523_class212_id80.jpg',
        main_frame_number: 2523,
        video_url: 'pipeline-c93cddf8-3f7d-4002-a7dc-9b9461f50b4a/uuid450/asset_localization_results/masks.mp4',
        class_id: 71858,
        class_name: 'utility_pole',
        plane_q: [
            -0.4805207217882064,
            -0.8768305618141101,
            -0.016370760547180452,
            -153.14631828946364,
        ],
        rgb_tiles_dir: 'pipeline-c93cddf8-3f7d-4002-a7dc-9b9461f50b4a/uuid950/image-colors-tiles',
        sem_tiles_dir: 'pipeline-c93cddf8-3f7d-4002-a7dc-9b9461f50b4a/uuid850/semantic-tiles',
        icon_url: '',
        is_approved: null,
        data_source_type: 'LSA_ASSET_LOC',
        asset_uuid: '001cc071-363e-4ee3-bfa5-2bde505f70ba',
        asset_version: 1,
        original_geometry: null,
    },
    id: '001cc071-363e-4ee3-bfa5-2bde505f70ba',
    bbox: null,
};
const feature2 = JSON.parse(JSON.stringify(feature1));
feature2.properties.main_frame_number = 0;
export const VideoTestPage = () => {
    return (_jsxs(_Fragment, { children: [_jsx(FinalAssetsQaVideoPlayerContainer, { feature: feature1 }), _jsx(FinalAssetsQaVideoPlayerContainer, { feature: feature2 })] }));
};
