import { useEffect, useRef, useState } from 'react'
import JSONEditor from 'jsoneditor'

function JsonEditorComponent({ text, onChangeText }) {
  const _ref = useRef()
  const [editor, setEditor] = useState()

  useEffect(() => {
    if (editor && text) {
      editor.updateText(text)
    }
  }, [text, editor])

  useEffect(() => {
    const options = {
      mode: 'code',
      modes: ['tree', 'code'],
      onChangeText,
    }
    const newEditor = new JSONEditor(_ref.current, options)
    setEditor(newEditor)

    return () => {
      newEditor.destroy()
    }
  }, []) //eslint-disable-line

  return <div ref={_ref} style={{ height: '60vh' }} />
}

export default JsonEditorComponent
