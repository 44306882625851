import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { LoadingStatus } from '../../../const/appConstants';
import { FinalAssetsQaViewer } from './FinalAssetsQaViewer';
import { fcFromFeatures } from '../../../utils/geoJson.utils';
import { API } from '../../../services/ApiService';
import { LoadingOverlay } from '../../../components';
import { enrichFinalAssetsWithIcons } from '../../RoadLyMap/layers/assetsRegistryLayer/enrichFinalAssetsWithIcons';
const EMPTY_FEATURE_COLLECTION = fcFromFeatures([]);
export const FinalAssetsQaViewerContainer = () => {
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.INITIAL);
    const [finalAssetsFeatureCollection, setFinalAssetsFeatureCollection] = useState(EMPTY_FEATURE_COLLECTION);
    useEffect(() => {
        const effect = async () => {
            setLoadingStatus(LoadingStatus.LOADING);
            const getAssetsRegistryResult = await API.getFinalAssetsForQa();
            enrichFinalAssetsWithIcons(getAssetsRegistryResult);
            setFinalAssetsFeatureCollection(getAssetsRegistryResult);
            setLoadingStatus(LoadingStatus.SUCCESS);
        };
        effect();
    }, []);
    const handleUpdateAsset = async (updatedFeature) => {
        await API.updateFinalAsset(updatedFeature);
    };
    if (loadingStatus !== LoadingStatus.SUCCESS) {
        return _jsx(LoadingOverlay, {});
    }
    return (_jsx(FinalAssetsQaViewer, { loadingStatus: loadingStatus, finalAssetsFeatureCollection: finalAssetsFeatureCollection, onUpdateAsset: handleUpdateAsset }));
};
