import React, { useContext, useEffect, useRef, useState } from 'react'
import DeckGLContext from './deckGLContext'

import { MiniSessionViewerContainer } from '@roadar-pipeline-viewer/roadly-typescript/dist/features/SessionsViewer'

export default function DeckGLMapContainer() {
  const [state, dispatch] = useContext(DeckGLContext)
  const deckRef = useRef(null)
  const [staticMapRef, setStaticMapRef] = useState(null)
  const [loadedStaticMap, setLoadedStaticMap] = useState(false)
  const [renderFinished, setRenderFinished] = useState(false)

  useEffect(() => {
    if (state.currentSession === null && state.sessionsQueue.length > 0) {
      dispatch({ type: 'SET_CURRENT_SESSION' })
    }
  }, [state.currentSession, state.sessionsQueue.length])

  useEffect(() => {
    if (state.currentSession !== null) {
      setLoadedStaticMap(false)
      setRenderFinished(false)
    }
  }, [state.currentSession])

  useEffect(() => {
    if (loadedStaticMap && renderFinished && staticMapRef) {
      const staticMapCanvas = staticMapRef.getMap().getCanvas()
      const layersCanvas = deckRef.current.deck.canvas
      const newCanvas = document.createElement('canvas')
      newCanvas.width = Math.max(staticMapCanvas.width, layersCanvas.width)
      newCanvas.height = Math.max(layersCanvas.height, staticMapCanvas.height)
      const context = newCanvas.getContext('2d')
      context.drawImage(staticMapCanvas, 0, 0)
      context.drawImage(layersCanvas, 0, 0)
      const dataUrl = newCanvas.toDataURL()
      dispatch({
        type: 'ADD_RENDERED_SESSION',
        payload: { id: state.currentSession.id, dataUrl },
      })
      dispatch({ type: 'DEQUEUE_SESSION' })
    }
  }, [loadedStaticMap, renderFinished, staticMapRef])

  const onLoadStaticMap = () => {
    setLoadedStaticMap(true)
  }

  const onAfterRender = () => {
    setRenderFinished(true)
  }

  return (
    <div style={{ width: '400px', height: '300px', position: 'absolute', opacity: 0.001, zIndex: -1 }}>
      {state.currentSession && (
        <MiniSessionViewerContainer
          session={state.currentSession}
          onAfterRender={onAfterRender}
          deckRef={deckRef}
          staticMapRef={setStaticMapRef}
          onLoadStaticMap={onLoadStaticMap}
        />
      )}
    </div>
  )
}
