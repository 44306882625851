import { MAPBOX_ACCESS_TOKEN } from './mapBoxAccessToken';
export var MapboxOverlayType;
(function (MapboxOverlayType) {
    MapboxOverlayType["FLAT"] = "flat";
    MapboxOverlayType["TERRAIN"] = "terrain";
})(MapboxOverlayType || (MapboxOverlayType = {}));
export var MapboxOverlayKey;
(function (MapboxOverlayKey) {
    MapboxOverlayKey["CUSTOM_DARK"] = "custom-dark";
    MapboxOverlayKey["STREETS"] = "streets-v11";
    MapboxOverlayKey["LIGHT"] = "light-v10";
    MapboxOverlayKey["DARK"] = "dark-v10";
    MapboxOverlayKey["OUTDOORS"] = "outdoors-v11";
    MapboxOverlayKey["NAVIGATION_DAY"] = "navigation-day";
    MapboxOverlayKey["NAVIGATION_NIGHT"] = "navigation-night";
    MapboxOverlayKey["SATELLITE"] = "satellite-v9";
    MapboxOverlayKey["SATELLITE_STREETS"] = "satellite-streets";
    MapboxOverlayKey["TERRAIN_OSM"] = "satellite-osm";
    // TERRAIN_SATELLITE = 'satellite-streets',
    // Is it a mistake that TERRAIN_SATELLITE and SATELLITE_STREETS are the same?
    MapboxOverlayKey["TERRAIN_SATELLITE"] = "terrain_satellite";
})(MapboxOverlayKey || (MapboxOverlayKey = {}));
export const MAPBOX_OVERLAY = {
    [MapboxOverlayKey.STREETS]: {
        key: MapboxOverlayKey.STREETS,
        type: MapboxOverlayType.FLAT,
        title: 'Street',
        style: 'mapbox://styles/mapbox/streets-v11',
    },
    [MapboxOverlayKey.LIGHT]: {
        key: MapboxOverlayKey.LIGHT,
        type: MapboxOverlayType.FLAT,
        title: 'Light',
        style: 'mapbox://styles/mapbox/light-v10',
    },
    [MapboxOverlayKey.DARK]: {
        key: MapboxOverlayKey.DARK,
        type: MapboxOverlayType.FLAT,
        title: 'Dark',
        style: 'mapbox://styles/mapbox/dark-v10',
    },
    [MapboxOverlayKey.CUSTOM_DARK]: {
        key: MapboxOverlayKey.CUSTOM_DARK,
        type: MapboxOverlayType.FLAT,
        title: 'Road.ly dark',
        // Todo: attach link from company's account
        style: 'mapbox://styles/andreyselin/cldu293b300ax01qqlswrde47',
    },
    [MapboxOverlayKey.OUTDOORS]: {
        key: MapboxOverlayKey.OUTDOORS,
        type: MapboxOverlayType.FLAT,
        title: 'Outdoors',
        style: 'mapbox://styles/mapbox/outdoors-v11',
    },
    [MapboxOverlayKey.NAVIGATION_DAY]: {
        key: MapboxOverlayKey.NAVIGATION_DAY,
        type: MapboxOverlayType.FLAT,
        title: 'Navigation day',
        style: 'mapbox://styles/mapbox/navigation-day-v1',
    },
    [MapboxOverlayKey.NAVIGATION_NIGHT]: {
        key: MapboxOverlayKey.NAVIGATION_NIGHT,
        type: MapboxOverlayType.FLAT,
        title: 'Navigation night',
        style: 'mapbox://styles/mapbox/navigation-night-v1',
    },
    [MapboxOverlayKey.SATELLITE]: {
        key: MapboxOverlayKey.SATELLITE,
        type: MapboxOverlayType.FLAT,
        title: 'Satellite',
        style: 'mapbox://styles/mapbox/satellite-v9',
    },
    [MapboxOverlayKey.SATELLITE_STREETS]: {
        key: MapboxOverlayKey.SATELLITE_STREETS,
        type: MapboxOverlayType.FLAT,
        title: 'Satellite streets',
        style: 'mapbox://styles/mapbox/satellite-streets-v11',
    },
    [MapboxOverlayKey.TERRAIN_OSM]: {
        key: MapboxOverlayKey.TERRAIN_OSM,
        type: MapboxOverlayType.TERRAIN,
        title: 'Terrain OSM (3d)',
        style: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
    },
    [MapboxOverlayKey.TERRAIN_SATELLITE]: {
        key: MapboxOverlayKey.TERRAIN_SATELLITE,
        type: MapboxOverlayType.TERRAIN,
        title: 'Terrain satellite (3d)',
        style: `https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.png?access_token=${MAPBOX_ACCESS_TOKEN}`,
    },
};
