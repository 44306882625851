import { Button, Alignment, FormGroup } from '@blueprintjs/core'
import { Select2 } from '@blueprintjs/select'
import { MenuItem2 } from '@blueprintjs/popover2'
import style from './FilterByOrganizations.module.scss'
import { OWNER } from '../../const'

export const FilterByOrganizations = ({ items, isLoading, value, onItemSelect, onItemPredicate }) => {
  const onItemRender = ({ name, id }, { handleClick, handleFocus, modifiers }) => (
    <MenuItem2
      text={name}
      label={name?.length >= 80 ? name.slice(0, 80) + '...' : name}
      roleStructure="menuitem"
      active={modifiers.active}
      key={id}
      onClick={handleClick}
      onFocus={handleFocus}
    />
  )

  return (
    <FormGroup label="Organization" labelFor="organization">
      <Select2
        fill
        disabled={isLoading}
        id="organization"
        items={items}
        popoverContentProps={{
          className: style.selectorPopoverContent,
        }}
        activeItem={value}
        onItemSelect={onItemSelect}
        noResults={'No results'}
        itemPredicate={onItemPredicate}
        itemRenderer={onItemRender}
      >
        <Button className={style.select} loading={isLoading} alignText={Alignment.LEFT} rightIcon={'chevron-down'}>
          {value ? value.name : 'Select organization'}
        </Button>
      </Select2>
    </FormGroup>
  )
}
