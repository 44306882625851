import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { GeoJsonLayer, ScatterplotLayer } from '@deck.gl/layers/typed';
import { Deck2gisLayer } from '@2gis/deck2gis-layer';
import { PCI_PALETTE, pciFeatureProcessingStatuses } from '../../../const/pciConstants';
import { getSeverityIndexByPci } from '../../../utils/pciUtils';
import { fcFromFeatures } from '../../../utils/geoJson.utils';
// const getSampleUnitColor = (sampleUnit: PciSampleUnitFeature) => {
//   if (sampleUnit.properties.status === pciFeatureProcessingStatuses.invalid) {
//     return [50, 50, 50];
//   }
//   return PCI_PALETTE[getSeverityIndexByPci(sampleUnit.properties.stats.pci)];
// };
// const getViewpointColor = (viewpoint: PciViewpointFeature) => PCI_PALETTE[getSeverityIndexByPci(viewpoint.properties.stats.pci)];
const getFeatureColor = (feature) => {
    if (feature.properties.status === pciFeatureProcessingStatuses.invalid) {
        return [205, 205, 205];
    }
    if (feature.properties.status === pciFeatureProcessingStatuses.hidden) {
        return [120, 120, 120];
    }
    return PCI_PALETTE[getSeverityIndexByPci(feature.properties.stats.pci)];
};
const getViewpointCoordinates = (viewpoint) => viewpoint.geometry.coordinates;
const sampleUnitsStaticProps = {
    id: 'mini-pci-sample-units-layer',
    pickable: true,
    lineWidthMinPixels: 2,
    lineWidthMaxPixels: 5,
    getLineColor: getFeatureColor,
    miterLimit: 1,
};
const viewpointsStaticProps = {
    id: 'mini-pci-viewpoints-layer',
    pickable: true,
    stroked: false,
    filled: true,
    radiusMinPixels: 3,
    radiusMaxPixels: 7,
    getPosition: getViewpointCoordinates,
    getColor: getFeatureColor,
};
const coverageStaticProps = {
    id: 'mini-pci-coverage-layer',
    pickable: true,
    lineWidthMinPixels: 4,
    lineWidthMaxPixels: 6,
    getLineColor: [30, 30, 30],
};
export const getMiniPciIndexLayerAsReactNode = ({ viewpointsData, sampleUnitsData, coverageData, showBadPciAbove }) => {
    const finalViewpointsData = useMemo(() => {
        if (!viewpointsData) {
            return viewpointsData;
        }
        if (showBadPciAbove) {
            const featuresArrayToSort = Array.from(viewpointsData.features);
            featuresArrayToSort.sort((a, b) => b.properties.stats.pci - a.properties.stats.pci);
            return fcFromFeatures(featuresArrayToSort);
        }
        return viewpointsData;
    }, [viewpointsData, showBadPciAbove]);
    return (_jsxs(_Fragment, { children: [sampleUnitsData && (
            // @ts-ignore Todo
            _jsx(GeoJsonLayer, { ...sampleUnitsStaticProps, data: sampleUnitsData.features })), finalViewpointsData && (
            // @ts-ignore Todo
            _jsx(ScatterplotLayer, { ...viewpointsStaticProps, data: finalViewpointsData.features })), coverageData && (
            // @ts-ignore Todo
            _jsx(GeoJsonLayer, { ...coverageStaticProps, data: coverageData.features }))] }));
};
export const applyMiniPciLayerTo2GisMap = ({ deck, map }, { viewpointsData, sampleUnitsData, coverageData, showBadPciAbove, }) => {
    /* Todo: use prioritizing here as well */
    const sampleUnitsLayer = new Deck2gisLayer({
        /* @ts-ignore Todo: find out way to expect exact deck type with 2gis map */
        deck,
        type: GeoJsonLayer,
        ...sampleUnitsStaticProps,
        data: sampleUnitsData.features,
    });
    const viewpointsLayer = new Deck2gisLayer({
        /* @ts-ignore Todo: find out way to expect exact deck type with 2gis map */
        deck,
        type: ScatterplotLayer,
        ...viewpointsStaticProps,
        data: viewpointsData.features,
    });
    const coverageLayer = new Deck2gisLayer({
        /* @ts-ignore Todo: find out way to expect exact deck type with 2gis map */
        deck,
        type: GeoJsonLayer,
        ...coverageStaticProps,
        data: coverageData.features,
    });
    /* Rendering */
    const rerender = () => {
        try {
            map.removeLayer(sampleUnitsLayer.id);
            map.removeLayer(viewpointsLayer.id);
            map.removeLayer(coverageLayer.id);
        }
        catch (e) {
            console.warn('Removing layers error caught', e);
        }
        map.addLayer(sampleUnitsLayer);
        map.addLayer(viewpointsLayer);
        map.addLayer(coverageLayer);
    };
    rerender();
    /*
    // @ts-ignore
    // Todo: find way to avoid monkey patching
    if (map.isStyleLoadFired) {
      rerender();
    }
  
    map.on('styleload', () => {
      // @ts-ignore
      map.isStyleLoadFired = true;
      rerender();
    });
    */
};
