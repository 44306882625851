import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog } from '@blueprintjs/core';
import { PciViewerContainer } from '../PciViewer';
import styles from './PciViewerModal.module.scss';
export const PciViewerModal = ({ frameSegmentId, onNavigate, navigationStepSize, onClose, frameSegmentIdsSequence, isFrameSegmentIdsSequenceLoaded, outerPciViewerQaProps, }) => {
    return (_jsxs(Dialog, { isOpen: true, className: styles.pciViewerModal, portalClassName: styles.portal, children: [_jsx(Button, { icon: "cross", className: styles.closeButton, onClick: onClose }), _jsx(PciViewerContainer, { frameSegmentId,
                onNavigate,
                navigationStepSize,
                frameSegmentIdsSequence,
                outerPciViewerQaProps,
                isFrameSegmentIdsSequenceLoaded })] }));
};
