import { Exception, ExceptionType, SuccessfulResult } from '../../exceptions';
export class RequestResetPasswordNormalizer {
    normalizeResponse(response) {
        const exceptionPath = 'RequestResetPasswordNormalizer.normalizeResponse';
        switch (response.status) {
            case 200:
                return new SuccessfulResult(null);
            case 404:
                throw new Exception({
                    exceptionType: ExceptionType.NOT_FOUND,
                    exceptionPath,
                    formErrorDetails: [{ loc: 'Email', msg: 'Not found' }],
                });
            case 422:
                throw new Exception({
                    exceptionType: ExceptionType.BAD_REQUEST,
                    exceptionPath,
                    formErrorDetails: [{ loc: 'Request', msg: 'Invalid request' }],
                });
            default:
                throw new Exception({
                    exceptionType: ExceptionType.OTHER,
                    exceptionPath,
                    formErrorDetails: [{ loc: 'Server', msg: 'Unknown error' }],
                });
        }
    }
}
export const requestResetPasswordNormalizer = new RequestResetPasswordNormalizer();
