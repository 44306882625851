import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import videojs from 'video.js';
import { useDownloader } from '../../hooks/useDownloader';
const defaultOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    controlBar: {
        fullscreenToggle: false,
    },
    playbackRates: [0.5, 1, 1.5, 2],
};
const newErrorMessage = 'Video format is not supported. Try to open this player in Safari or Firefox.';
const oldErrorMessage = 'The media could not be loaded, either because the server or network failed or because the format is not supported.';
videojs.addLanguage('en', { [oldErrorMessage]: newErrorMessage });
export const VideoPlayer = ({ url, playerRef, onTimeUpdated, className, initialSeconds }) => {
    const videoRef = useRef(null);
    const { getDownloadLink } = useDownloader();
    useEffect(() => {
        (async () => {
            const videoElement = videoRef.current;
            const src = await getDownloadLink({ url });
            if (videoElement && !playerRef.current) {
                const options = {
                    ...defaultOptions,
                    sources: [{ src, type: 'video/mp4' }],
                };
                const player = videojs(videoElement, options);
                player.on('timeupdate', () => onTimeUpdated(player.currentTime()));
                player.on('loadedmetadata', () => {
                    if (typeof initialSeconds === 'number') {
                        player.currentTime(initialSeconds);
                    }
                });
                // Todo:
                // @ts-ignore
                // eslint-disable-next-line no-param-reassign
                playerRef.current = player;
            }
        })();
    }, [videoRef, url, playerRef, onTimeUpdated, getDownloadLink]);
    useEffect(() => {
        const player = playerRef.current;
        return () => {
            if (player) {
                // @ts-ignore todo
                player.dispose();
                // Todo:
                // @ts-ignore
                // eslint-disable-next-line no-param-reassign
                playerRef.current = null;
            }
        };
    }, [playerRef]);
    return (_jsx("div", { "data-vjs-player": true, children: _jsx("video", { ref: videoRef, className: clsx('video-js', 'vjs-big-play-centered', className) }) }));
};
