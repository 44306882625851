import { jsx as _jsx } from "react/jsx-runtime";
import { FloatingContainer } from '../../../components/FloatingContainer';
import { RoadLyMapPopup } from '../RoadLyMapPopup';
import styles from './ClusterFeaturePopup.module.scss';
export const ClusterFeaturePopup = ({ activeCluster, onClickRoadLyMapClusterSubFeature, }) => {
    const handleClick = (feature) => {
        if (typeof onClickRoadLyMapClusterSubFeature !== 'function') {
            console.warn('onClickRoadLyMapClusterSubFeature is not passed');
            return;
        }
        onClickRoadLyMapClusterSubFeature === null || onClickRoadLyMapClusterSubFeature === void 0 ? void 0 : onClickRoadLyMapClusterSubFeature(feature);
    };
    return (_jsx(RoadLyMapPopup, { coordinates: activeCluster.feature.geometry.coordinates, drawPopup: () => (_jsx(FloatingContainer, { className: styles.popup, children: _jsx("div", { className: styles.clusterFeaturesContainer, children: activeCluster.subFeatures.map((feature) => (_jsx("img", { alt: feature.properties.class_name, src: feature.properties.icon_url, className: styles.image, onClick: () => handleClick(feature) }, feature.properties.asset_uuid))) }) })) }));
};
