export var REQUEST_STATUS;
(function (REQUEST_STATUS) {
    REQUEST_STATUS["OK"] = "ok";
    REQUEST_STATUS["DUPLICATED"] = "duplicated";
    REQUEST_STATUS["NOT_VALID"] = "notValid";
    REQUEST_STATUS["NOT_FOUND"] = "notFound";
    REQUEST_STATUS["INTERNAL_ERROR"] = "internalError";
    REQUEST_STATUS["NETWORK_ERROR"] = "networkError";
    REQUEST_STATUS["UNKNOWN_ERROR"] = "unknownError";
})(REQUEST_STATUS || (REQUEST_STATUS = {}));
export var LoadingStatus;
(function (LoadingStatus) {
    LoadingStatus["INITIAL"] = "INITIAL";
    LoadingStatus["LOADING"] = "LOADING";
    LoadingStatus["SUCCESS"] = "SUCCESS";
    LoadingStatus["ERROR"] = "ERROR";
})(LoadingStatus || (LoadingStatus = {}));
export var StorageType;
(function (StorageType) {
    StorageType["GOOGLE_STORAGE"] = "GCP";
    StorageType["FILE_SYSTEM"] = "FileSystem";
})(StorageType || (StorageType = {}));
export var Progress;
(function (Progress) {
    Progress["IDLE"] = "IDLE";
    Progress["WORK"] = "WORK";
    Progress["SUCCESS"] = "SUCCESS";
    Progress["ERROR"] = "ERROR";
})(Progress || (Progress = {}));
export const DESKTOP_BREAKPOINT = 768;
export const appBrands = {
    ROADLY: 'ROADLY',
    URBI: 'URBI',
};
