export function getOutputList(pipeline) {
    return pipeline.state.reduce((memo, state) => {
        const urls = Object.values(state.outputs).reduce((memo2, item) => {
            const [moduleId, outputName] = item.url.split('/').slice(-2);
            const key = `${moduleId}_${outputName}`;
            memo2[key] = { moduleName: state.module, moduleId, outputName, ...item };
            return memo2;
        }, {});
        // @ts-ignore todo
        return { ...memo, ...urls };
    }, {});
}
