import { useState } from 'react'
import { Checkbox, Button, Tag, Intent, Collapse, FormGroup, Alignment, InputGroup, FileInput } from '@blueprintjs/core'
import { useNavigate } from 'react-router-dom'
import { ROLES, stringifiedDefaultMonoYamlAsJson } from 'const'
import JsonEditor from 'components/JsonEditor/JsonEditor'
import style from './RunPipelineForm.module.css'
import { usePipelineForm } from 'hooks/usePipelineForm'
import useProfile from 'hooks/useProfile'
import { stringToBase64 } from 'utils/utils'
import useRoles from 'hooks/useRoles'

import { SessionSelect, SessionMultiSelect } from './SessionSelect'
import { ReconstructionSelect } from './ReconstructionSelect'
import { CamLocPipelineSelect } from './CamLocPipelineSelect'
import { APIControlledSelect } from './APIControlledSelect'
import { TripleCheckbox } from './TripleCheckbox'
import { SimpleSelect } from './SimpleSelect'
import cx from 'classnames'

import { HandleSubmitResponse } from './util'

function RunPipelineForm({ onSubmit, json }) {
  const { profile } = useProfile()
  const roles = useRoles()
  const navigate = useNavigate()

  const isAdmin = roles && roles.includes(ROLES.ADMIN)

  const { folder } = profile

  const initialFormData = {
    session: null,
    folder: folder,
    path: '',
  }

  const {
    //form
    formData,
    onInput,

    //yaml
    onChangeYaml,
    isYamlValid,
    yamlText,

    //payload
    onChangePayload,
    payloadJson,
    isJsonValid,
  } = usePipelineForm({ json, initialFormData: initialFormData })

  const [loading, setLoading] = useState(false)
  const [isEditorOpen, setIsEditorOpen] = useState(false)
  const [isYamlEditorOpen, setIsYamlEditorOpen] = useState(false)
  const [errors, setErrors] = useState({})
  const [requestErrors, setRequestErrors] = useState([])
  const formOptions = ['session_id']

  const isFormValid = () => {
    return isAdmin || formData.session || formData.path.length > 0
  }

  const onInputPath = path => {
    onInput({
      key: 'path',
      value: path,
    })
  }

  const onRunPipeline = () => {
    setLoading(true)
    setErrors({})
    setRequestErrors([])

    onSubmit(payloadJson).then(res => {
      HandleSubmitResponse(res, formOptions, navigate, setErrors, setRequestErrors, setLoading)
    })
  }

  return (
    <div>
      <div className={style.pathForm}>
        <div className={style.formItem}>
          <FormGroup label="Path:" labelFor="fullPath">
            <InputGroup
              fill
              id="fullPath"
              type="text"
              value={formData.path}
              onInput={e => onInputPath(e.target.value)}
            />
          </FormGroup>
        </div>
      </div>

      <div className={style.formItem}>
        <Button
          small
          minimal
          alignText={Alignment.LEFT}
          rightIcon={isEditorOpen ? 'chevron-right' : 'chevron-down'}
          onClick={() => setIsEditorOpen(!isEditorOpen)}
        >
          <h3 className={style.buttonText}>Modules settings</h3>
        </Button>
        <Collapse isOpen={isEditorOpen} keepChildrenMounted>
          <div className={style.collapse}>
            <JsonEditor text={payloadJson} onChangeText={onChangePayload} />
          </div>
        </Collapse>
      </div>
      {!isJsonValid && (
        <div className={style.formItem}>
          <Tag large intent={Intent.DANGER} style={{ width: '100%', textAlign: 'center' }}>
            JSON validation error. Please, fix JSON errors.
          </Tag>
        </div>
      )}
      <div className={style.formItem}>
        <Button
          small
          minimal
          alignText={Alignment.LEFT}
          rightIcon={isYamlEditorOpen ? 'chevron-right' : 'chevron-down'}
          onClick={() => setIsYamlEditorOpen(!isYamlEditorOpen)}
        >
          <h3 className={style.buttonText}>DSOPP settings</h3>
        </Button>

        <Collapse isOpen={isYamlEditorOpen} keepChildrenMounted>
          <div className={style.collapse}>
            <JsonEditor text={yamlText} onChangeText={onChangeYaml} />
          </div>
        </Collapse>
      </div>

      {!isYamlValid && (
        <div className={style.formItem}>
          <Tag large intent={Intent.DANGER} style={{ width: '100%', textAlign: 'center' }}>
            DSOPP settings validation error. Please, fix DSOPP settings errors.
          </Tag>
        </div>
      )}

      {requestErrors.map(({ loc, msg }) => (
        <Tag key={msg} multiline fill large intent={Intent.DANGER} style={{ width: '100%', textAlign: 'center' }}>
          {`${loc}: ${msg}`}
        </Tag>
      ))}

      <div className={style.formItem}>
        <Button
          intent={Intent.PRIMARY}
          disabled={!isFormValid() || loading || !isJsonValid || !isYamlValid}
          loading={loading}
          onClick={onRunPipeline}
          text="Run pipeline"
        />
      </div>
    </div>
  )
}

export function RunCamLocPipelineForm({ onSubmit }) {
  const [imageFile, setImageFile] = useState(null)
  const [kmlFile, setKMLFile] = useState(null)
  const [reconstruction, setReconstruction] = useState(null)
  const [camLocPipelineUUID, setCamLocPipelineUUID] = useState(null)
  const [useGpsBasedSelection, setUseGpsBasedSelection] = useState(false)
  const [skipGpsForSelection, setSkipGpsForSelection] = useState(false)
  const onImageFileChange = evt => setImageFile(evt.target.files[0])
  const onKMLFileChange = evt => setKMLFile(evt.target.files[0])

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [requestErrors, setRequestErrors] = useState([])
  const formOptions = ['file']
  const navigate = useNavigate()

  const onRunPipeline = () => {
    setLoading(true)
    setErrors({})
    setRequestErrors([])

    onSubmit({
      imageFile,
      kmlFile,
      reconstruction,
      useGpsBasedSelection,
      skipGpsForSelection,
      referencePipelineUUID: camLocPipelineUUID,
    }).then(res => {
      HandleSubmitResponse(res, formOptions, navigate, setErrors, setRequestErrors, setLoading)
    })
  }

  return (
    <div>
      <div className={style.pathForm}>
        <div className={style.formItem}>
          <FormGroup label="Video file or photo:">
            <FileInput
              text={imageFile ? imageFile.name : 'jpeg image or video file'}
              buttonText="Upload"
              onChange={onImageFileChange}
              inputProps={{ accept: 'video/mp4, video/quicktime, video/x-msvideo, image/jpeg' }}
            />
          </FormGroup>
          <FormGroup label="KML file with camera coordinates (optional):">
            <FileInput
              text={kmlFile ? kmlFile.name : 'kml file'}
              buttonText="Upload"
              onChange={onKMLFileChange}
              inputProps={{ accept: '.kml' }}
            />
          </FormGroup>
          <FormGroup label="Reference pipeline (select only to use short processing):">
            <CamLocPipelineSelect uuid={camLocPipelineUUID} onItemSelect={el => setCamLocPipelineUUID(el.uuid)} />
          </FormGroup>
          <FormGroup label="Reconstruction (autodetect will lead to reconstruction detection step):">
            <ReconstructionSelect reconstructionName={reconstruction} onItemSelect={setReconstruction} />
          </FormGroup>
          <FormGroup label="">
            <Checkbox
              checked={useGpsBasedSelection}
              onChange={({ target: { checked } }) => setUseGpsBasedSelection(checked)}
              disabled={reconstruction || !kmlFile}
            >
              Use gps data only for reconstruction autodetect
            </Checkbox>
          </FormGroup>
          <FormGroup label="">
            <Checkbox
              checked={skipGpsForSelection}
              onChange={({ target: { checked } }) => setSkipGpsForSelection(checked)}
              disabled={reconstruction || !kmlFile}
            >
              Not no use provided KML file for reconstruction selection
            </Checkbox>
          </FormGroup>
          {errors.file && (
            <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
              {errors.file.message}
            </Tag>
          )}
        </div>
      </div>

      {requestErrors.map(({ loc, msg }) => (
        <Tag key={msg} multiline fill large intent={Intent.DANGER} style={{ width: '100%', textAlign: 'center' }}>
          {`${loc}: ${msg}`}
        </Tag>
      ))}

      <div className={style.formItem}>
        <Button
          intent={Intent.PRIMARY}
          disabled={!imageFile || loading}
          loading={loading}
          onClick={onRunPipeline}
          text="Run pipeline"
        />
      </div>
    </div>
  )
}

export function RunMiniPCIPipelineForm({ onSubmit }) {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [requestErrors, setRequestErrors] = useState([])
  const [selectedSessionId, setSelectedSessionId] = useState(null)
  const [selectedRoadGraph, setSelectedRoadGraph] = useState(null)
  const formOptions = ['uri']
  const navigate = useNavigate()

  const onRunPipeline = () => {
    setLoading(true)
    setErrors({})
    setRequestErrors([])

    onSubmit({ payload: { sessionId: selectedSessionId, roadGraph: selectedRoadGraph } }).then(res => {
      HandleSubmitResponse(res, formOptions, navigate, setErrors, setRequestErrors, setLoading)
    })
  }

  const onSessionSelect = session => setSelectedSessionId(session.id)

  return (
    <div>
      <div className={style.pathForm}>
        <div className={style.formItem}>
          <FormGroup label="Session:" labelFor="session">
            <SessionSelect sessionId={selectedSessionId} onItemSelect={onSessionSelect} />
            {errors.session_id && (
              <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                {errors.session_id.message}
              </Tag>
            )}
          </FormGroup>
          <FormGroup label="Road graph:">
            <APIControlledSelect
              apiMethodName="getMiniPCIRoadGraphs"
              selectedItem={selectedRoadGraph}
              onItemSelect={setSelectedRoadGraph}
            />
            {errors.road_graph && (
              <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                {errors.road_graph.message}
              </Tag>
            )}
          </FormGroup>
        </div>
      </div>

      {requestErrors.map(({ loc, msg }) => (
        <Tag key={msg} multiline fill large intent={Intent.DANGER} style={{ width: '100%', textAlign: 'center' }}>
          {`${loc}: ${msg}`}
        </Tag>
      ))}

      <div className={style.formItem}>
        <Button
          intent={Intent.PRIMARY}
          disabled={!selectedSessionId || loading}
          loading={loading}
          onClick={onRunPipeline}
          text="Run pipeline"
        />
      </div>
    </div>
  )
}

const DEFAULT_CHUNKED_VALUE = {
  FRAMES_PER_CHUNK: 2000,
  OVERLAP: 500,
  MINIMUM_LEFTOVER: 999,
}

export function RunChunkedPipelineForm({ onSubmit, selectedSession, json, pointcloudBasedTypeNames }) {
  const [isDense, setIsDense] = useState(null)
  const [skipVio, setSkipVio] = useState(null)
  const [slamTag, setSlamTag] = useState(null)
  const [preferRelocalized, setPreferRelocalized] = useState(null)
  const [withGaussian, setWithGaussian] = useState(null)
  const [withSelfReloc, setWithSelfReloc] = useState(null)

  const [sessionIds, setSessionIds] = useState(selectedSession ? [selectedSession] : [])
  const [isYamlEditorOpen, setIsYamlEditorOpen] = useState(false)
  const [isAdvancesSettingsOpen, setIsAdvancesSettingsOpen] = useState(false)
  const [slamAlg, setSlamAlg] = useState(null)

  const [pipelineTypeName, setPipelineTypeName] = useState(pointcloudBasedTypeNames[0])
  const [isPipelineTypeSwitchOpen, setIsPipelineTypeSwitchOpen] = useState(false)
  const [textPrompt, setTextPrompt] = useState('')
  const [textThreshold, setTextThreshold] = useState(null)
  const [boxThreshold, setBoxThreshold] = useState(null)

  const [name, setName] = useState(null)
  const [framesPerChunk, setFramesPerChunk] = useState(DEFAULT_CHUNKED_VALUE.FRAMES_PER_CHUNK)
  const [overlap, setOverlap] = useState(DEFAULT_CHUNKED_VALUE.OVERLAP)
  const [minimumLeftover, setMinimumLeftover] = useState(DEFAULT_CHUNKED_VALUE.MINIMUM_LEFTOVER)
  const navigate = useNavigate()

  const roles = useRoles()
  const isSuperAdmin = roles && roles.includes(ROLES.SUPERADMIN)

  const {
    //yaml
    onChangeYaml,
    isYamlValid,
    yamlText,

    //payload
    isJsonValid,
  } = usePipelineForm({ json })

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [requestErrors, setRequestErrors] = useState([])
  const formOptions = ['frames_per_chunk', 'overlap', 'minimum_leftover', 'session_id']

  const isFormValid = () => sessionIds.length > 0 && (pipelineTypeName !== 'LSA' || !!textPrompt)

  const MAX_SELECTED_SESSIONS = 10
  const onSessionItemSelect = session => {
    if (sessionIds.includes(session.id)) return
    if (sessionIds.length >= MAX_SELECTED_SESSIONS) {
      alert(`Maximum amount of selected sessions is ${MAX_SELECTED_SESSIONS}!`)
      return
    }
    setSessionIds([...sessionIds, session.id])
  }
  const onSessionItemRemove = session => setSessionIds(sessionIds.filter(id => id !== session.id))

  const filterNotNull = obj => Object.fromEntries(Object.entries(obj).filter(en => en[1] !== null))

  const onRunChunkedPipeline = () => {
    setLoading(true)
    setErrors({})
    setRequestErrors([])

    const submitParams = {
      session_id: sessionIds.length === 1 ? sessionIds[0] : sessionIds, // to show pipeline uuid in popup for single session
      pipelineTypeName: pipelineTypeName,
      data: filterNotNull({
        frames_per_chunk: framesPerChunk,
        overlap: overlap,
        minimum_leftover: minimumLeftover,
        name: name,
        is_dense: isDense,
        skip_vio: skipVio,
        slam_tag: slamTag,
        slam_alg: slamAlg,
        with_gaussian: withGaussian,
        with_self_reloc: withSelfReloc,
        prefer_relocalized: preferRelocalized,
        ...(pipelineTypeName === 'LSA'
          ? {
              text_prompt: textPrompt,
              text_threshold: textThreshold,
              box_threshold: boxThreshold,
            }
          : {}),
      }),
    }

    // Unify json strings for checking
    const stringifiedJson = JSON.stringify(JSON.parse(yamlText))

    if (stringifiedJson !== stringifiedDefaultMonoYamlAsJson) {
      submitParams.data.mono_yaml = stringToBase64(yamlText)
    }

    onSubmit(submitParams).then(res => {
      HandleSubmitResponse(res, formOptions, navigate, setErrors, setRequestErrors, setLoading)
    })
  }

  return (
    <div>
      <div className={style.pathForm}>
        <div className={style.formItem}>
          <FormGroup
            className={cx({
              [style.compactFormGroup]: true,
              [style.sessionSelect]: true,
            })}
          >
            <SessionMultiSelect
              sessionIds={sessionIds}
              onItemSelect={onSessionItemSelect}
              onRemove={onSessionItemRemove}
              onReset={() => setSessionIds([])}
            />
            {errors.session_id && (
              <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                {errors.session_id.message}
              </Tag>
            )}
          </FormGroup>
        </div>
      </div>
      <div className={style.formItem}>
        <TripleCheckbox value={isDense} setter={setIsDense} label="With densification" />
      </div>

      {isSuperAdmin ? (
        <div className={style.pathForm}>
          <div className={style.formItem}>
            <Button
              small
              minimal
              className={style.headerButton}
              alignText={Alignment.LEFT}
              rightIcon={isPipelineTypeSwitchOpen ? 'chevron-right' : 'chevron-down'}
              onClick={() => setIsPipelineTypeSwitchOpen(!isPipelineTypeSwitchOpen)}
            >
              <h3 className={style.buttonText}>Change pipeline type</h3>
            </Button>

            <Collapse isOpen={isPipelineTypeSwitchOpen} keepChildrenMounted>
              <div className={style.formItem}>
                <div className={style.collapse}>
                  <FormGroup className={style.compactFormGroup} label="Pipeline type:">
                    <SimpleSelect
                      items={pointcloudBasedTypeNames}
                      selectedItem={pipelineTypeName}
                      onItemSelect={setPipelineTypeName}
                    />
                  </FormGroup>

                  {pipelineTypeName === 'LSA' ? (
                    <>
                      <div className={style.pathForm}>
                        <div className={style.formItem}>
                          <FormGroup className={style.compactFormGroup} label="Text prompt:" labelFor="textPrompt">
                            <InputGroup
                              fill
                              id="textPrompt"
                              className={cx({ [style.inputShort]: true, [style.inputWide]: true })}
                              value={textPrompt}
                              onInput={e => setTextPrompt(e.target.value)}
                            />
                            {errors.text_prompt && (
                              <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                                {errors.text_prompt.message}
                              </Tag>
                            )}
                          </FormGroup>
                        </div>
                      </div>
                      <div className={style.pathForm}>
                        <div className={style.formItem}>
                          <FormGroup
                            className={style.compactFormGroup}
                            label="Text threshold:"
                            labelFor="textThreshold"
                          >
                            <InputGroup
                              fill
                              type="number"
                              id="textPrompt"
                              className={style.inputShort}
                              value={textThreshold !== null ? textThreshold : ''}
                              onInput={e => setTextThreshold(e.target.value)}
                            />
                            <Button
                              className={style.tripleCheckoxReset}
                              onClick={() => setTextThreshold(null)}
                              icon="reset"
                              small={true}
                            />
                            {errors.text_threshold && (
                              <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                                {errors.text_threshold.message}
                              </Tag>
                            )}
                          </FormGroup>
                        </div>
                      </div>
                      <div className={style.pathForm}>
                        <div className={style.formItem}>
                          <FormGroup className={style.compactFormGroup} label="Box threshold:" labelFor="boxThreshold">
                            <InputGroup
                              fill
                              type="number"
                              id="textPrompt"
                              className={style.inputShort}
                              value={boxThreshold !== null ? boxThreshold : ''}
                              onInput={e => setBoxThreshold(e.target.value)}
                            />
                            <Button
                              className={style.tripleCheckoxReset}
                              onClick={() => setBoxThreshold(null)}
                              icon="reset"
                              small={true}
                            />
                            {errors.box_threshold && (
                              <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                                {errors.box_threshold.message}
                              </Tag>
                            )}
                          </FormGroup>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      ) : null}

      {isSuperAdmin ? (
        <div>
          <div className={style.pathForm}>
            <Button
              small
              minimal
              className={style.headerButton}
              alignText={Alignment.LEFT}
              rightIcon={isAdvancesSettingsOpen ? 'chevron-right' : 'chevron-down'}
              onClick={() => setIsAdvancesSettingsOpen(!isAdvancesSettingsOpen)}
            >
              <h3 className={style.buttonText}>Advanced settings</h3>
            </Button>
          </div>
          <div>
            <Collapse isOpen={isAdvancesSettingsOpen} keepChildrenMounted>
              <div className={style.collapse}>
                <p className={style.warning}>
                  If you are changing something here - you are responsible for parameters consistency.
                </p>

                <div className={style.pathForm}>
                  <div className={style.formItem}>
                    <FormGroup className={style.compactFormGroup} label="Pipeline name:" labelFor="name">
                      <InputGroup
                        fill
                        id="name"
                        className={cx({ [style.inputShort]: true, [style.inputWide]: true })}
                        value={name !== null ? name : ''}
                        onInput={e => setName(e.target.value)}
                      />
                      {errors.name && (
                        <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                          {errors.name.message}
                        </Tag>
                      )}
                    </FormGroup>
                  </div>

                  <FormGroup className={style.compactFormGroup} label="SLAM algorithm:">
                    <APIControlledSelect
                      apiMethodName="getSlamChoiches"
                      selectedItem={slamAlg}
                      itemsTextMapping={{ colmap: 'sfm' }}
                      onItemSelect={setSlamAlg}
                    />
                    {errors.slam_alg && (
                      <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                        {errors.slam_alg.message}
                      </Tag>
                    )}
                  </FormGroup>

                  <div className={cx({ [style.paramDependent]: true, [style.inactive]: slamAlg === 'colmap' })}>
                    <div className={style.formItem}>
                      <FormGroup className={style.compactFormGroup} label="Slam tag:" labelFor="slamTag">
                        <InputGroup
                          fill
                          id="slamTag"
                          className={style.inputShort}
                          value={slamTag === null ? '' : slamTag}
                          onInput={e => setSlamTag(e.target.value)}
                        />
                        <Button
                          className={style.tripleCheckoxReset}
                          onClick={() => setSlamTag(null)}
                          icon="reset"
                          small={true}
                        />
                        {errors.slamTag && (
                          <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                            {errors.slamTag.message}
                          </Tag>
                        )}
                      </FormGroup>
                    </div>

                    <div className={style.pathForm}>
                      <div className={style.formItem}>
                        <FormGroup className={style.compactFormGroup} label="Frames per chunk:" labelFor="fullPath">
                          <InputGroup
                            fill
                            id="frames_per_chunk"
                            type="number"
                            className={style.inputShort}
                            value={framesPerChunk}
                            onInput={e => setFramesPerChunk(e.target.value)}
                          />
                          {errors.frames_per_chunk && (
                            <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                              {errors.frames_per_chunk.message}
                            </Tag>
                          )}
                        </FormGroup>
                      </div>
                    </div>
                    <div className={style.pathForm}>
                      <div className={style.formItem}>
                        <FormGroup className={style.compactFormGroup} label="Overlap:" labelFor="fullPath">
                          <InputGroup
                            fill
                            id="overlap"
                            type="number"
                            className={style.inputShort}
                            value={overlap}
                            onInput={e => setOverlap(e.target.value)}
                          />
                          {errors.overlap && (
                            <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                              {errors.overlap.message}
                            </Tag>
                          )}
                        </FormGroup>
                      </div>
                    </div>
                    <div className={style.pathForm}>
                      <div className={style.formItem}>
                        <FormGroup className={style.compactFormGroup} label="Minimum leftover:" labelFor="fullPath">
                          <InputGroup
                            fill
                            id="minimum_leftover"
                            type="number"
                            className={style.inputShort}
                            value={minimumLeftover}
                            onInput={e => setMinimumLeftover(e.target.value)}
                          />
                          {errors.minimum_leftover && (
                            <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                              {errors.minimum_leftover.message}
                            </Tag>
                          )}
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={style.formItem}>
                  <Button
                    small
                    minimal
                    className={style.headerButton}
                    alignText={Alignment.LEFT}
                    rightIcon={isYamlEditorOpen ? 'chevron-right' : 'chevron-down'}
                    onClick={() => setIsYamlEditorOpen(!isYamlEditorOpen)}
                  >
                    <h3 className={style.buttonText}>Mono YAML editor</h3>
                  </Button>

                  <Collapse isOpen={isYamlEditorOpen} keepChildrenMounted>
                    <div className={style.collapse}>
                      <JsonEditor text={yamlText} onChangeText={onChangeYaml} />
                    </div>
                  </Collapse>
                </div>
                <TripleCheckbox value={skipVio} setter={setSkipVio} label="Skip VIO" />
                <TripleCheckbox value={preferRelocalized} setter={setPreferRelocalized} label="Prefer relocalized" />
                <TripleCheckbox value={withSelfReloc} setter={setWithSelfReloc} label="With self relocalization" />
                <TripleCheckbox value={withGaussian} setter={setWithGaussian} label="With gaussian" />
              </div>
            </Collapse>
          </div>
        </div>
      ) : null}

      {!isYamlValid && (
        <div className={style.formItem}>
          <Tag large intent={Intent.DANGER} style={{ width: '100%', textAlign: 'center' }}>
            DSOPP settings validation error. Please, fix DSOPP settings errors.
          </Tag>
        </div>
      )}

      {requestErrors.map(({ loc, msg }) => (
        <Tag key={msg} multiline fill large intent={Intent.DANGER} style={{ width: '100%', textAlign: 'center' }}>
          {`${loc}: ${msg}`}
        </Tag>
      ))}
      <div className={style.formItem}>
        <Button
          intent={Intent.PRIMARY}
          disabled={!isFormValid() || loading || !isJsonValid || !isYamlValid}
          loading={loading}
          onClick={onRunChunkedPipeline}
          text="Run chunked pipeline"
        />
      </div>
    </div>
  )
}

export default RunPipelineForm
