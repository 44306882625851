import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { LoadingStatus } from '../../../const/appConstants';
import { getFinalAssetPipelineId } from '../../../utils/assets.utilities/getFinalAssetPipelineId';
import { API } from '../../../services/ApiService';
import { LoadingOverlay } from '../../../components';
import { VideoPlayer } from '../../../components/VideoPlayer';
import { getGoogleStorageUrl } from '../../../utils';
import { asyncConfigManager } from '../../../utils/asyncConfigManager';
const anticipatingSeconds = 2;
export const FinalAssetsQaVideoPlayerContainer = ({ feature }) => {
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.INITIAL);
    const [initialSeconds, setInitialSeconds] = useState(0);
    const playerRef = useRef(undefined);
    // @ts-ignore Todo ts error
    const bucketName = asyncConfigManager.config.bucketNames.persistentBucket;
    const url = getGoogleStorageUrl(`${bucketName}/${feature.properties.video_url}`);
    useEffect(() => {
        const effect = async () => {
            const pipelineId = getFinalAssetPipelineId(feature);
            let fps;
            try {
                const pipeline = await API.getPipelineItem({ uuid: pipelineId });
                fps = pipeline.session_data.video_metadata.fps;
            }
            catch (e) {
                console.error(e);
                console.warn('Pipeline has not been loaded for calculating fps');
                fps = 60;
                toast.warn('Attention! Failed to load pipeline, FPS = 60 used');
            }
            const seconds = Math.floor(feature.properties.main_frame_number / fps) - anticipatingSeconds;
            setInitialSeconds(seconds < 0 ? 0 : seconds);
            setLoadingStatus(LoadingStatus.SUCCESS);
        };
        effect();
    }, [feature]);
    if (loadingStatus !== LoadingStatus.SUCCESS) {
        return _jsx(LoadingOverlay, {});
    }
    return (_jsx(VideoPlayer, { initialSeconds,
        url,
        playerRef, onTimeUpdated: () => { } }));
};
