import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { RoadLyMap } from '../RoadLyMap';
import styles from './MapPicker.module.scss';
import { StoredViewportsListContainer } from '../../components';
import { WORLD_VIEWPORT_CONFIG } from '../../const/mapConstants';
import { StickyContainer } from '../../components/StickyContainer/StickyContainer';
export const MapPickerFeature = () => {
    // const userViewportConfig: RoadLyMapViewportConfig = {
    //   longitude: 0,
    //   latitude: 0,
    //   zoom: 1,
    // };
    const [lastActualViewport, setLastActualViewport] = useState(null);
    const userViewportConfig = lastActualViewport || WORLD_VIEWPORT_CONFIG;
    const handleSelectViewport = (newViewport) => {
        setLastActualViewport({
            ...lastActualViewport,
            ...newViewport,
        });
    };
    const handleClickMap = (pickingInfo) => {
        console.log('pickingInfo', pickingInfo.coordinate);
    };
    return (_jsxs("div", { className: styles.mapPickerContainer, children: [_jsx(StickyContainer, { position: "topRight", stickContent: "topRight", isPadded: true, children: _jsx(StoredViewportsListContainer, { activateViewport: handleSelectViewport }) }), _jsx(RoadLyMap, { onClickMap: handleClickMap, userViewportConfig: userViewportConfig })] }));
};
