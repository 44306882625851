import { getGoogleStorageUrlOrKeepIfIsUrl } from '../cloudStorageUtils';
export class PciFrameSegmentNormalizer {
    configure({ pciBucketName }) {
        this.pciBucketName = pciBucketName;
    }
    normalize(frameSegment) {
        const { images: { front, bev }, ...others } = frameSegment;
        const images = {
            front: {
                img: getGoogleStorageUrlOrKeepIfIsUrl(front.img, this.pciBucketName),
                // mask: getGoogleStorageUrl(`${this.pciBucketName}/${front.mask}`),
                roi: getGoogleStorageUrlOrKeepIfIsUrl(front.roi, this.pciBucketName),
            },
            bev: {
                img: getGoogleStorageUrlOrKeepIfIsUrl(bev.img, this.pciBucketName),
                // mask: getGoogleStorageUrl(`${this.pciBucketName}/${bev.mask}`),
                roi: getGoogleStorageUrlOrKeepIfIsUrl(bev.roi, this.pciBucketName),
            },
        };
        return {
            ...others,
            images,
        };
    }
}
