import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import styles from './AssetsViewerPanel.module.scss';
import { AssetsViewerPanelSessionContent } from './AssetsViewerPanelSessionContent';
import { AssetsViewerPanelPipelineContent } from './AssetsViewerPanelPipelineContent';
export const AssetsViewerPanel = ({ activeSession, assetsPipelinePayloadWithMapProps, onClickOnPipelineListItem, onCloseSession, onClosePipeline, className, onReloadDataRequested, }) => {
    if (assetsPipelinePayloadWithMapProps) {
        return (_jsx("div", { className: clsx(styles.wrapper, className), children: _jsx(AssetsViewerPanelPipelineContent, { assetsPipelinePayloadWithMapProps, onClosePipeline }) }));
    }
    if (activeSession) {
        return (_jsx("div", { className: clsx(styles.wrapper, className), children: _jsx(AssetsViewerPanelSessionContent, { onCloseSession, onClickOnPipelineListItem, session: activeSession, onPipelineSubmitted: onReloadDataRequested }) }));
    }
    return (_jsx("div", { className: clsx(styles.wrapper, className), children: "No session or pipeline is selected" }));
};
