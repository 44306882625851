import { useState } from 'react'

import { PROGRESS, REQUEST_STATUS } from 'const'
import { SignUpForm } from 'features/auth/SignUp/SignUpForm/SignUpForm'
import API from 'utils/api'

export const SignUpFormContainer = ({ onSuccess }) => {
  const [status, setStatus] = useState(PROGRESS.IDLE)
  const [requestErrors, setRequestErrors] = useState([])

  const handleSubmit = async params => {
    setStatus(PROGRESS.WORK)
    const signUpResult = await API.signUp(params)

    if (signUpResult.status === REQUEST_STATUS.OK) {
      setStatus(PROGRESS.SUCCESS)
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
      return
    }

    setStatus(PROGRESS.ERROR)
    setRequestErrors(signUpResult.details)
  }

  return (
    <SignUpForm
      isLoading={status === PROGRESS.WORK}
      onSubmit={handleSubmit}
      requestErrors={requestErrors}
      status={status}
    />
  )
}
