import { ImageViewType } from '../../const/pciConstants';
import { FrameSegmentImagesPreloader } from './FrameSegmentImagesPreloader';
import { FrameSegmentPreloader } from './FrameSegmentPreloader';
export class AggregatedFrameSegmentPreloader {
    constructor() {
        this.preLoadersMap = {
            frameSegmentPreloader: new FrameSegmentPreloader(),
            frameSegmentImagesPreLoadersMap: {
                [ImageViewType.front]: new FrameSegmentImagesPreloader(),
                [ImageViewType.bev]: new FrameSegmentImagesPreloader(),
            },
        };
    }
    async load({ id, imageViewType, forceReloadFrameSegment = false }) {
        const frameSegmentResponseBody = await this.preLoadersMap.frameSegmentPreloader
            .get({ id }, forceReloadFrameSegment);
        const frameSegmentImageUrlStructure = frameSegmentResponseBody.images[imageViewType];
        const frameSegmentImagesPreloadingResult = await this.preLoadersMap.frameSegmentImagesPreLoadersMap[imageViewType].get({ frameSegmentImageUrlStructure });
        return { frameSegmentResponseBody, frameSegmentImagesPreloadingResult };
    }
}
