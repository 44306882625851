export const DIALOGS_TYPES = {
  WELCOME: 'WELCOME',
}

export const DESKTOP_BREAKPOINT = 768

export const PROGRESS = {
  IDLE: 'IDLE',
  WORK: 'WORK',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
}

export const REQUEST_STATUS = {
  OK: 'ok',
  DUPLICATED: 'duplicated',
  NOT_VALID: 'notValid',
  NOT_FOUND: 'notFound',
  INTERNAL_ERROR: 'internalError',
  NETWORK_ERROR: 'networkError',
  UNKNOWN_ERROR: 'unknownError',
}

export const PASS_MIN_LEN = 8
