import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Divider } from '@blueprintjs/core/lib/esm/components';
import { RoadLyMap } from '../../RoadLyMap';
import { defaultFinalAssetsQaConfig } from '../../RoadLyMap/layers/assetsRegistryQaLayer/assetsRegistryQaLayer.constants';
import { findInArrayOrFail } from '../../../utils/array.utilities/findInArrayOrFail';
import { bringFeatureCollectionToTheGround } from '../../../utils/bringFeatureCollectionToTheGround';
import { FinalAssetQaControls } from '../FinalAssetQaControls';
import { getFinalAssetUrl } from '../../../utils/finalAssets.utilities/getFinalAssetUrl';
import { FinalAssetQaPictureViewer } from '../FinalAssetQaPictureViewer/FinalAssetQaPictureViewer';
import { MapboxOverlayKey } from '../../../const/mapConstants';
import { FinalAssetQaSettings } from '../FinalAssetQaSettings/FinalAssetQaSettings';
import { LoadingOverlay, StoredViewportsListContainer } from '../../../components';
import { useStoredViewports } from '../../../components/StoredViewportsList/useStoredViewports';
import { FloatingContainer } from '../../../components/FloatingContainer';
import { FinalAssetsQaVisibilityFilters } from '../FinalAssetsQaVisibilityFilters';
import styles from './FinalAssetsQaViewer.module.scss';
import { useFinalAssetsQaVisibilityFilters } from '../useFinalAssetsQaVisibilityFilters';
import { AssetsClassFilters } from '../../AssetsViewer/AssetsClassFilters';
import { LoadingStatus } from '../../../const/appConstants';
export const FinalAssetsQaViewer = ({ loadingStatus, onUpdateAsset, finalAssetsFeatureCollection, }) => {
    const initialViewportConfig = {
        latitude: 34.001687,
        longitude: -118.250839,
        zoom: 20,
    };
    const { setLastChangedViewport, getCurrentViewport, handleSelectViewport, actualViewportConfig, } = useStoredViewports(initialViewportConfig);
    const [isEditable, setIsEditable] = useState(initialViewportConfig.zoom > 17);
    const handleNavigationEnd = (viewState) => {
        setIsEditable(viewState.zoom > 17);
        setLastChangedViewport(viewState);
    };
    const [qaConfig, setQaConfig] = useState(defaultFinalAssetsQaConfig);
    const finalAssetsFeatureCollectionOnTheGround = useMemo(() => bringFeatureCollectionToTheGround(finalAssetsFeatureCollection), [finalAssetsFeatureCollection]);
    // Decided to keep features in additional state layer in order
    // to keep all methods to work with it also here
    const [finalAssets, setFinalAssets] = useState(finalAssetsFeatureCollectionOnTheGround);
    const [dragMode, setDragMode] = useState('pan');
    const dragPan = dragMode === 'pan';
    const [controlsParams, setControlsParams] = useState(null);
    const { visibleFinalAssetsFeatureCollection, handleSwitchVisibilityByQaStatus, finalAssetsQaVisibilityFilters, assetsVisibilityFiltersByClassNameProps, } = useFinalAssetsQaVisibilityFilters({ finalAssetsFeatureCollection: finalAssets });
    /**
     * @param newUuid string for picking new feature null for picking empty space
     */
    const isSwitchingFromUnsavedAsset = (newUuid) => {
        if (!qaConfig.activeFeature) {
            return false;
        }
        const isAnotherUuid = newUuid !== qaConfig.activeFeature.uuid;
        const isSwitchingFromUnsaved = isAnotherUuid && qaConfig.activeFeature.isChanged;
        if (isSwitchingFromUnsaved) {
            toast('Finish editing current asset please', { type: 'warning' });
        }
        return isSwitchingFromUnsaved;
    };
    const changeFeature = (uuid, decision, newCoordinates) => {
        const feature = findInArrayOrFail(finalAssets.features, (aFeature) => aFeature.properties.asset_uuid === uuid);
        // Modifications
        const finalNewCoordinates = newCoordinates
            ? [...newCoordinates, feature.geometry.coordinates[2]]
            : feature.geometry.coordinates;
        const newIsApproved = decision === null
            ? feature.properties.is_approved
            : decision === 'approve';
        const updatedFeature = {
            ...feature,
            geometry: {
                ...feature.geometry,
                coordinates: finalNewCoordinates,
            },
            properties: {
                ...feature.properties,
                is_approved: newIsApproved,
            },
        };
        // Update state
        setFinalAssets({
            ...finalAssets,
            features: [
                ...finalAssets.features.filter((aFeature) => aFeature !== feature),
                updatedFeature,
            ],
        });
        // Take original feature to take original (not brought to the ground) height
        const originalFeature = findInArrayOrFail(finalAssetsFeatureCollection.features, (aFeature) => aFeature.properties.asset_uuid === uuid);
        const featureWithOriginalHeight = {
            ...updatedFeature,
            geometry: {
                ...updatedFeature.geometry,
                coordinates: [
                    updatedFeature.geometry.coordinates[0],
                    updatedFeature.geometry.coordinates[1],
                    originalFeature.geometry.coordinates[2],
                ],
            },
        };
        return featureWithOriginalHeight;
    };
    const activateFeature = (theFeature) => {
        setQaConfig({
            ...qaConfig,
            activeFeature: {
                isChanged: false,
                assetType: theFeature.properties.class_name,
                // Maybe use it in normalizers?
                imageUrl: getFinalAssetUrl(theFeature.properties.image_name),
                feature: theFeature,
                uuid: theFeature.properties.asset_uuid,
                version: theFeature.properties.asset_version,
                geometry: theFeature.geometry,
                originalGeometry: theFeature.properties.original_geometry,
            },
        });
    };
    const deactivateFeature = () => {
        setQaConfig({
            ...qaConfig,
            activeFeature: null,
        });
    };
    const setControlsParamsWithInfo = (info) => {
        setControlsParams({
            left: info.x,
            top: info.y,
        });
    };
    const onDragStart = (info) => {
        if (!isEditable) {
            setControlsParams(null);
            deactivateFeature();
            return;
        }
        if (info.object) {
            if (qaConfig.activeFeature === null) {
                setDragMode('move');
                setControlsParams(null);
                activateFeature(info.object);
                return;
            }
            if (isSwitchingFromUnsavedAsset(info.object.properties.asset_uuid)) {
                setDragMode('none');
                return;
            }
            // Activate new feature if old one is not changed
            setDragMode('move');
            setControlsParams(null);
            activateFeature(info.object);
            return;
        }
        setDragMode('pan');
        // setControlsParams(null);
        // deactivateFeature();
    };
    const onDrag = (info) => {
        if (dragMode !== 'move') {
            return;
        }
        if (qaConfig.activeFeature === null) {
            return;
        }
        if (!info.coordinate) {
            console.warn('No coordinates in picking info!');
            return;
        }
        setQaConfig({
            ...qaConfig,
            activeFeature: {
                ...qaConfig.activeFeature,
                isChanged: true,
            },
        });
        changeFeature(qaConfig.activeFeature.uuid, null, [info.coordinate[0], info.coordinate[1]]);
    };
    const onDragEnd = (info) => {
        if (dragMode === 'move') {
            // setDragPan(true);
            setControlsParamsWithInfo(info);
        }
    };
    const dragEventsConfig = qaConfig.activeFeature
        ? { dragPan, onDragStart, onDrag, onDragEnd }
        : { dragPan };
    const onClickMap = (info) => {
        if (!isEditable) {
            setControlsParams(null);
            deactivateFeature();
            return;
        }
        if (info.object) {
            // First click on object: activate
            if (qaConfig.activeFeature === null) {
                activateFeature(info.object);
                setControlsParamsWithInfo(info);
                return;
            }
            if (isSwitchingFromUnsavedAsset(info.object.properties.asset_uuid)) {
                return;
            }
            // Activate new feature if old one is not changed
            activateFeature(info.object);
            setControlsParamsWithInfo(info);
            return;
        }
        // Do not allow to switch off asset if there are changes
        if (isSwitchingFromUnsavedAsset(null)) {
            return;
        }
        // Clear selection
        setControlsParams(null);
        deactivateFeature();
    };
    const handleAssetQaDecision = async (decision) => {
        console.log('handleAssetQaDecision', qaConfig.activeFeature);
        if (!qaConfig.activeFeature) {
            throw new Error('No active feature');
        }
        // Todo: find better way to get updated feature
        const updatedFeature = changeFeature(qaConfig.activeFeature.uuid, decision, null);
        setControlsParams(null);
        deactivateFeature();
        await onUpdateAsset(updatedFeature);
        toast.success('Feature updated');
    };
    const [overlayKey, setOverlayKey] = useState(MapboxOverlayKey.CUSTOM_DARK);
    const handleSetMapOverlayKey = (newKey) => {
        setOverlayKey(newKey);
    };
    const [isBigPicture, setIsBigPicture] = useState(true);
    const handleToggleBigPicture = () => setIsBigPicture(!isBigPicture);
    const roadLyMapPopupProps = qaConfig.activeFeature
        ? {
            drawPopup: () => (_jsx(FinalAssetQaControls, { onDecision: handleAssetQaDecision })),
            coordinates: qaConfig.activeFeature.feature.geometry.coordinates,
        }
        : undefined;
    if (loadingStatus !== LoadingStatus.SUCCESS) {
        return _jsx(LoadingOverlay, {});
    }
    return (_jsxs("div", { children: [_jsx(StoredViewportsListContainer, { activateViewport: handleSelectViewport }), _jsxs(FloatingContainer, { className: styles.visibilityFiltersContainer, children: [_jsx(FinalAssetsQaVisibilityFilters, { onClickVisibilityFilter: handleSwitchVisibilityByQaStatus, finalAssetsQaVisibilityFilters: finalAssetsQaVisibilityFilters }), _jsx(Divider, {}), _jsx(AssetsClassFilters, { ...assetsVisibilityFiltersByClassNameProps })] }), _jsx(FinalAssetQaSettings, { isEditable: isEditable, isBigPicture: isBigPicture, onToggleBigPicture: handleToggleBigPicture, onSetMapOverlayKey: handleSetMapOverlayKey }), qaConfig.activeFeature
                ? (_jsx(FinalAssetQaPictureViewer, { isBigPicture: isBigPicture, activeFeature: qaConfig.activeFeature }))
                : null, _jsx(RoadLyMap, { roadLyMapPopupProps: roadLyMapPopupProps, overlayKey: overlayKey, onClickMap: onClickMap, dragEventsConfig: dragEventsConfig, onNavigationEnd: handleNavigationEnd, 
                // onMouseMoveOverMap={onMouseMoveOverMap}
                userViewportConfig: actualViewportConfig, finalAssetsQaConfig: {
                    ...qaConfig,
                    finalAssets: visibleFinalAssetsFeatureCollection,
                } })] }));
};
