import { useEffect, useState } from 'react'
import { Button, Tag, Intent, Collapse, Checkbox } from '@blueprintjs/core'
import { useNavigate } from 'react-router-dom'
import { PATH } from '@roadar-pipeline-viewer/roadly-typescript/dist/const/routingConstants'
import { ROLES } from 'const'
import JsonEditor from 'components/JsonEditor/JsonEditor'
import style from './RerunPipelineForm.module.css'
import { ContentFor } from 'components/ContentFor/ContentFor'
import { usePipelineForm } from 'hooks/usePipelineForm'

function RerunPipelineForm({ onSubmit, initialYaml, json }) {
  const navigate = useNavigate()
  const {
    //yaml
    onChangeYaml,
    isYamlValid,
    yamlText,
    addYamlToPayload,
    removeYamlFromPayload,
    //payload
    onChangePayload,
    payloadJson,
    isJsonValid,
  } = usePipelineForm({ initialYaml, json })

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [isEditorOpen, setIsEditorOpen] = useState(true)
  const [isYamlEdit, setYamlEdit] = useState(Boolean(initialYaml))

  useEffect(() => {
    if (isYamlEdit) {
      addYamlToPayload()
    } else {
      if (typeof initialYaml === 'undefined') {
        // If no yaml was found in configs, remove yamls (as it initially was)
        removeYamlFromPayload()
      } else {
        // Otherwise add initial yaml values, which were
        // found in configs (all values are considered same)
        addYamlToPayload(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isYamlEdit])

  const onRerunPipeline = () => {
    setLoading(true)
    setError(null)

    onSubmit(payloadJson)
      .then(() => {
        setLoading(false)
        navigate(PATH.pipelinesList.path)
      })
      .catch(() => {
        setLoading(false)
        setError('Something went wrong. Please, try again later.')
      })
  }

  return (
    <div>
      <div className={style.formItem}>
        <Button
          fill
          outlined
          active={isEditorOpen}
          rightIcon={isEditorOpen ? 'chevron-up' : 'chevron-down'}
          onClick={() => setIsEditorOpen(!isEditorOpen)}
          text="Edit Payload JSON"
        />
      </div>
      <div className={style.formItem}>
        <Collapse isOpen={isEditorOpen} keepChildrenMounted>
          <JsonEditor text={payloadJson} onChangeText={onChangePayload} />
        </Collapse>
      </div>
      {!isJsonValid && (
        <div className={style.formItem}>
          <Tag large intent={Intent.DANGER} style={{ width: '100%', textAlign: 'center' }}>
            JSON validation error. Please, fix JSON errors.
          </Tag>
        </div>
      )}
      <ContentFor role={ROLES.ADMIN}>
        <div className={style.formItem}>
          <Checkbox checked={isYamlEdit} onChange={({ target: { checked } }) => setYamlEdit(checked)}>
            Edit mono.yaml
          </Checkbox>
        </div>
        {isYamlEdit && (
          <div className={style.formItem}>
            <JsonEditor text={yamlText} onChangeText={onChangeYaml} />
          </div>
        )}
        {!isYamlValid && (
          <div className={style.formItem}>
            <Tag large intent={Intent.DANGER} style={{ width: '100%', textAlign: 'center' }}>
              Mono YAML validation error. Please, fix Mono YAML errors.
            </Tag>
          </div>
        )}
      </ContentFor>
      {error && (
        <div className={style.formItem}>
          <Tag large intent={Intent.DANGER} style={{ width: '100%', textAlign: 'center' }}>
            {error}
          </Tag>
        </div>
      )}
      <div className={style.formItem}>
        <Button
          fill
          large
          disabled={loading || !isJsonValid || !isYamlValid}
          loading={loading}
          onClick={onRerunPipeline}
          text="Rerun pipeline"
        />
      </div>
    </div>
  )
}

export default RerunPipelineForm
