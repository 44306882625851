class StaticServerClient {
    constructor() {
        this.apiRootPath = 'https://roadly.bratemoj.com';
    }
    // apiRootPath = 'http://localhost:3001';
    async fetch(url) {
        return fetch(`${this.apiRootPath}${url}`);
    }
}
export const staticServerClient = new StaticServerClient();
