import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import styles from './FrameSegmentPositionOnSequence.module.scss';
export const FrameSegmentPositionOnSequence = ({ frameSegmentId, pciQaSessionStats, }) => {
    const { indexOfFrameSegmentId, positionAsPercent } = useMemo(() => {
        const frameSegmentIdsSequence = pciQaSessionStats.map((el) => el.id);
        const index = frameSegmentIdsSequence.indexOf(frameSegmentId);
        return {
            indexOfFrameSegmentId: index,
            positionAsPercent: Number((index / frameSegmentIdsSequence.length).toFixed(3)) * 100,
        };
    }, [frameSegmentId, pciQaSessionStats]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { children: ["Frame ", indexOfFrameSegmentId + 1, " / ", pciQaSessionStats.length, " (starting from 1)"] }), _jsx("div", { className: styles.positionBar, children: _jsxs("div", { className: styles.positionJitter, style: { left: `${positionAsPercent}%` }, children: [positionAsPercent.toFixed(2), "%"] }) })] }));
};
