import { Tile3DLayer } from '@deck.gl/geo-layers'
import { PointCloudLayer } from '@deck.gl/layers'
import { COORDINATE_SYSTEM } from '@deck.gl/core'

import vs from './point-cloud-layer-vertex.glsl'
import fs from './point-cloud-layer-fragment.glsl'

var a = 1
function tmpGetPosition(d) {
  if (a++ < 10) {
    console.log(d)
  }
  return d
}

const DEFAULT_PALETTES = Array(60).fill(0)
class PalettePointCloudLayer extends PointCloudLayer {
  static layerName = 'PalettePointCloudLayer'

  getShaders() {
    return Object.assign({}, super.getShaders(), { vs, fs })
  }

  draw({ uniforms }) {
    const { palette } = this.props

    super.draw({
      uniforms: {
        ...uniforms,
        palette: new Float32Array(palette || DEFAULT_PALETTES),
      },
    })
  }
}

class PaletteTile3DLayer extends Tile3DLayer {
  static layerName = 'PaletteTile3DLayer'
  _getSubLayer(tileHeader, oldLayer) {
    if (!tileHeader.content) {
      return null
    }

    return this._makePointCloudLayer(tileHeader, oldLayer)
  }

  _makePointCloudLayer(tileHeader, oldLayer) {
    const { attributes, pointCount, constantRGBA, cartographicOrigin, modelMatrix } = tileHeader.content
    const { positions, normals, colors } = attributes

    if (!positions) {
      return null
    }
    const data = (oldLayer && oldLayer.props.data) || {
      header: {
        vertexCount: pointCount,
      },
      attributes: {
        POSITION: positions,
        NORMAL: normals,
        COLOR_0: colors,
      },
    }

    const { pointSize, getPointColor, palette } = this.props
    const SubLayerClass = this.getSubLayerClass('palettepointcloud', PalettePointCloudLayer)
    return new SubLayerClass(
      {
        pointSize,
      },
      this.getSubLayerProps({
        id: 'palettepointcloud',
      }),
      {
        id: `${this.id}-palettepointcloud-${tileHeader.id}`,
        tile: tileHeader,
        data,
        coordinateSystem: COORDINATE_SYSTEM.METER_OFFSETS,
        coordinateOrigin: cartographicOrigin,
        modelMatrix,
        getColor: constantRGBA || getPointColor,
        getPosition: tmpGetPosition,
        palette,
        _offset: 0,
      }
    )
  }
}

export default PaletteTile3DLayer
