import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { RoadLyMapContext } from '../../../../contexts/roadLyMapContext';
import { RoadLyClusterLayer } from './RoadLyClusterLayer';
export const getRoadLyClusterLayer = ({ featureCollection, }) => {
    const [roadLyMapContext, setRoadLyMapContext] = useContext(RoadLyMapContext);
    const handlePickCluster = (activeCluster) => {
        setRoadLyMapContext({
            ...roadLyMapContext,
            activeCluster,
        });
    };
    return (
    /* @ts-expect-error Todo: */
    _jsx(RoadLyClusterLayer, { featureCollection,
        onPickCluster: handlePickCluster,
        viewState: roadLyMapContext.viewState }));
};
