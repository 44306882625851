import { jsx as _jsx } from "react/jsx-runtime";
import { isStableEnv } from '../../utils/isStableEnv';
import { useProfile } from '../../hooks/useProfile';
import { AppSidebar } from './AppSidebar';
import { stableOrganizationIds } from '../../const/stableOrganizationIds';
export const AppSidebarContainer = ({ className }) => {
    const { profile } = useProfile();
    const buttonVisibilityMap = isStableEnv() && profile.organization_id === stableOrganizationIds.waltham
        ? {
            pciRegistry: true,
            assetsPipelines: false,
            assetsRegistry: false,
        }
        : {
            pciRegistry: true,
            assetsPipelines: true,
            assetsRegistry: true,
        };
    return _jsx(AppSidebar, { className, buttonVisibilityMap });
};
