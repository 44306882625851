import { useCallback, useContext } from 'react';
import { AppContext } from '../contexts/appContext';
import { prepareStorageUrl } from '../utils/download/prepareStorageUrl';
import { downloadProgrammatically } from '../utils/download/downloadProgrammatically';
export const useDownloader = () => {
    const { appState: { isCloudStorageContour } } = useContext(AppContext);
    const getDownloadLink = useCallback(async ({ url, filename = 'unnamed' }) => prepareStorageUrl({ url, filename, isCloudStorageContour }), [isCloudStorageContour]);
    const handleDownload = useCallback(async ({ event, url, filename = 'unnamed' }) => {
        event.preventDefault();
        const finalUrl = await getDownloadLink({ url, filename });
        downloadProgrammatically({ url: finalUrl, filename });
    }, [getDownloadLink]);
    return { getDownloadLink, handleDownload };
};
