import { jsx as _jsx } from "react/jsx-runtime";
import { LOCAL_STORAGE_KEYS } from '../../const/localStorageConstants';
import { appBrands } from '../../const/appConstants';
import { UrbiLogoWithLabel } from './UrbiLogo';
import { RoadlyLogoWithLabel } from './RoadlyLogo';
export const PreferredLogoWithLabel = ({ className }) => {
    const brand = localStorage.getItem(LOCAL_STORAGE_KEYS.APP_BRAND);
    return (brand === appBrands.URBI
        ? _jsx(UrbiLogoWithLabel, { className: className })
        : _jsx(RoadlyLogoWithLabel, { className: className }));
};
