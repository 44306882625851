import { FlyToInterpolator } from '@deck.gl/core/typed';
import { MAPBOX_ACCESS_TOKEN } from './mapBoxAccessToken';
/**
 * Deep viewport config including props,
 * not available for changing by user
 */
export const COMPLETE_VIEWPORT_CONFIG = {
    transitionDuration: 400,
    transitionInterpolator: new FlyToInterpolator(),
    latitude: 52.35389348750071,
    longitude: 4.934368124949788,
    zoom: 15.9,
    pitch: 0,
    bearing: 0,
    maxPitch: 85,
    maxZoom: 28,
};
// Lowercase because it is mapbox type expectation
export var UnitsSystem;
(function (UnitsSystem) {
    UnitsSystem["metric"] = "metric";
    UnitsSystem["imperial"] = "imperial";
})(UnitsSystem || (UnitsSystem = {}));
export const ELEVATION_DATA = `https://api.mapbox.com/v4/mapbox.terrain-rgb/{z}/{x}/{y}.png?access_token=${MAPBOX_ACCESS_TOKEN}`;
export const ELEVATION_DECODER = {
    rScaler: 6553.6,
    gScaler: 25.6,
    bScaler: 0.1,
    offset: -10000,
};
export const MAP_PADDING = {
    MOBILE: 100,
    DESKTOP: 200,
};
/**
 * Viewport props, available on user level
 */
export const DEFAULT_USER_VIEWPORT_CONFIG = {
    latitude: 52.35389348750071,
    longitude: 4.934368124949788,
    zoom: 15.9,
};
export const WORLD_VIEWPORT_CONFIG = {
    latitude: 0,
    longitude: 0,
    zoom: 1,
};
export const DEMO_VIEWPORT_CONFIG = {
    latitude: 47.64041259544334,
    longitude: -122.13649639084363,
    zoom: 15,
};
export const WALTHAM_VIEWPORT_CONFIG = {
    latitude: 42.3867326,
    // longitude: -71.2251787,
    longitude: -71.2176232,
    zoom: 13,
};
