import { ToastContainer } from 'react-toastify'
import { usePipelineList } from '../../hooks/usePipelineList'
import PipelineList from 'features/PipelineList/PipelineList'

export function PipelineListPage() {
  const {
    progress,
    items,
    limit,
    skip,
    sort_field,
    sort_order,
    owner,
    session,
    status,
    total,
    modules,
    types,
    is_complete_view,
    pipeline_name,
    organization_id,
    updatePipelineList,
    updateItemPart,
  } = usePipelineList()
  return (
    <>
      <PipelineList
        progress={progress}
        items={items}
        total={total}
        limit={limit}
        skip={skip}
        sort_field={sort_field}
        sort_order={sort_order}
        status={status}
        is_complete_view={is_complete_view}
        types={types}
        owner={owner}
        session={session}
        modules={modules}
        organization_id={organization_id}
        pipeline_name={pipeline_name}
        updatePipelineList={updatePipelineList}
        updateItemPart={updateItemPart}
      />
      <ToastContainer />
    </>
  )
}
