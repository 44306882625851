import { Filter, Sort } from '../../const/listingConstants';
import { Owner } from '../../const/userConstants';
export function parse(searchParams, fieldName, defaultValue, parser) {
    const field = searchParams.get(fieldName);
    if (field === null)
        return defaultValue;
    return parser(field);
}
export function parseNumber(field) {
    return Number(field);
}
export function parseString(field) {
    return field;
}
export function parseBoolean(field) {
    return field === 'true';
}
export function parseSort(field) {
    return Sort[field.toUpperCase()];
}
export function parseFilter(field) {
    return Filter[field.toUpperCase()];
}
export function parseOwner(field) {
    return Owner[field.toUpperCase()];
}
