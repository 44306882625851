import { jsx as _jsx } from "react/jsx-runtime";
import { LOCAL_STORAGE_KEYS } from '../../const/localStorageConstants';
import { appBrands } from '../../const/appConstants';
import { UrbiSignLogo } from './UrbiLogo';
import { RoadLySignLogo } from './RoadlyLogo';
export const PreferredSignLogo = ({ className }) => {
    const brand = localStorage.getItem(LOCAL_STORAGE_KEYS.APP_BRAND);
    return (brand === appBrands.URBI
        ? _jsx(UrbiSignLogo, { className: className })
        : _jsx(RoadLySignLogo, { className: className }));
};
