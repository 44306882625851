import { useState } from 'react';
import { DEFAULT_USER_VIEWPORT_CONFIG } from '../../const/mapConstants';
export const useStoredViewports = (initialViewportConfig) => {
    // This is just to store viewport state after user changed it:
    const [lastChangedViewport, setLastChangedViewport] = useState(null);
    // User config is last actual config changed by navigation or
    // if there has been no navigation made yet - it is equal to initial config:
    const [actualViewportConfig, setActualViewportConfig] = useState(lastChangedViewport || initialViewportConfig);
    //
    const handleSelectViewport = (newViewport) => {
        setActualViewportConfig({
            ...actualViewportConfig,
            ...newViewport,
        });
        setLastChangedViewport({
            ...lastChangedViewport,
            ...newViewport,
        });
    };
    // Todo: figure out if DEFAULT_USER_VIEWPORT_CONFIG is correct
    const getCurrentViewport = () => lastChangedViewport || DEFAULT_USER_VIEWPORT_CONFIG;
    return {
        setLastChangedViewport,
        handleSelectViewport,
        getCurrentViewport,
        initialViewportConfig,
        actualViewportConfig,
    };
};
