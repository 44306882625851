import { OWNER, SESSIONS_LIST_FILTERS } from 'const'
import { useNavigate, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import API from 'utils/api'
import { getQueryString } from 'utils/utils'
import { FilterByOrganizations } from '.'

const commonPartOrganizationList = [
  {
    id: 0,
    name: 'all',
    description: null,
    owner: {
      id: 0,
      email: OWNER.ALL,
    },
  },
]

export const FilterByOrganizationsContainer = ({ organization_id, searchParams }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [items, setItems] = useState(commonPartOrganizationList)
  const [isLoading, setIsLoading] = useState(true)
  const [value, setValue] = useState()

  useEffect(() => {
    setIsLoading(true)
    API.getOrganizations()
      .then(all_organizations => {
        let organizationSelected = undefined
        if (!Array.isArray(all_organizations)) {
          organizationSelected = [...commonPartOrganizationList].find(org => org.id === organization_id)
        } else {
          setItems([...commonPartOrganizationList, ...all_organizations])
          organizationSelected = [...commonPartOrganizationList, ...all_organizations].find(
            org => org.id === organization_id
          )
        }
        if (organizationSelected === undefined) {
          organizationSelected = commonPartOrganizationList[0]
        }
        setValue(organizationSelected)
        setIsLoading(false)
      })
      .catch(() => {
        const organizationSelected = [...commonPartOrganizationList].find(org => org.id === organization_id)
        setValue(organizationSelected)
        setIsLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onItemPredicate = (query, item) => {
    return item.name?.toLowerCase().includes(query.toLowerCase())
  }

  const onItemSelect = organizationInput => {
    setValue(organizationInput)
    const searchParamsWithSkip = { ...searchParams, skip: SESSIONS_LIST_FILTERS.DEFAULT_SKIP }
    const query = getQueryString(searchParamsWithSkip, { organization_id: organizationInput.id })
    navigate({
      ...location,
      search: `?${query}`,
    })
  }

  return (
    <FilterByOrganizations
      items={items}
      isLoading={isLoading}
      value={value}
      onItemSelect={onItemSelect}
      onItemPredicate={onItemPredicate}
    />
  )
}
