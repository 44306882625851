import { Filter } from '../../const/listingConstants';
import { addOperatorPciSearchParams, addSuperAdminPciSearchParams } from './api.helpers';
const processSearchParamsToQuery = (searchParams) => {
    const params = Object.entries(searchParams)
        .filter(([_key, value]) => !!value)
        .filter(([key, value]) => value !== Filter.ALL)
        .filter(([key, value]) => {
        if (Array.isArray(value)) {
            if (!value.length)
                return false;
        }
        return true;
    });
    const paramsWithoutArrayParams = params
        .reduce((memo, [key, value]) => {
        if (!Array.isArray(value))
            return { ...memo, [key]: value };
        return { ...memo };
    }, {});
    const urlSearchParams = new URLSearchParams(paramsWithoutArrayParams);
    // Todo
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const index in params) {
        const [key, value] = params[index];
        if (Array.isArray(value)) {
            // Todo
            // eslint-disable-next-line guard-for-in,no-restricted-syntax
            for (const valueParam in value) {
                urlSearchParams.append(key, value[valueParam]);
            }
        }
    }
    return urlSearchParams.toString();
};
export const handles = {
    pipelineList: (searchParams) => {
        const query = processSearchParamsToQuery(searchParams);
        return `/api/get_pipelines?${query}`;
    },
    pipelineListShort: (searchParams) => {
        const query = processSearchParamsToQuery(searchParams);
        return `/api/get_pipelines_short?${query}`;
    },
    sessionList: (searchParams) => {
        const query = processSearchParamsToQuery(searchParams);
        return `/api/get_sessions/full?${query}`;
    },
    updatePipelineName: (uuid) => {
        return `/api/pipeline/set_name/${uuid}`;
    },
    pipelineItem: ({ uuid }) => {
        return `/api/get_pipelines/${uuid}`;
    },
    pipelineItemAction: ({ action, uuid }) => {
        return `/api/pipeline/${action}/${uuid}`;
    },
    runPipelineItem: () => {
        return '/api/submit';
    },
    runChunkedPipelineItem: () => {
        return '/api/submit_chunked_session';
    },
    runMiniPCIPipelineItem: () => {
        return '/api/submit_mini_pci';
    },
    runRelocPipelineItem: () => {
        return '/api/reloc/submit';
    },
    runTilingPipelineItem: () => {
        return '/api/tiling/submit';
    },
    runGaussPipelineItem: () => {
        return '/api/gauss/submit';
    },
    runCamLocPipelineItem: (params) => {
        const query = new URLSearchParams(params).toString();
        return `/api/cam_loc/submit?${query}`;
    },
    // Todo: clarify type of id
    changePipelineItemPublished: (id) => {
        return `/api/pipeline/set_published/${id}`;
    },
    rerunPipelineItem: ({ pipeline_uuid }) => {
        return `/api/pipeline/rerun/${pipeline_uuid}`;
    },
    signUp: () => {
        return '/api/user/create';
    },
    confirmCode: (params) => {
        const query = new URLSearchParams(params).toString();
        return `/api/user/confirm-code?${query}`;
    },
    login: () => {
        return '/api/login';
    },
    acceptInvite: () => {
        return '/api/accept_invite';
    },
    logout: () => {
        return '/api/logout';
    },
    whoami: () => {
        return '/api/whoami';
    },
    getPayloadJson: () => {
        return '/api/payload.json';
    },
    createInvite: () => {
        return '/api/create_invite';
    },
    getInvites: (searchParams) => {
        const params = Object.entries(searchParams).reduce((memo, [key, value]) => ({ ...memo, [key]: value }), {});
        // Todo: remove as Record<...> and fic types
        const query = new URLSearchParams(params).toString();
        return `/api/get_invites?${query}`;
    },
    getSessions: (organization_id) => {
        return organization_id === 'all' || typeof organization_id === 'undefined'
            ? '/api/get_sessions?limit=10000'
            : `/api/get_sessions?limit=10000&organization_id=${organization_id}`;
    },
    getExtendedSessions: (filters) => {
        const finalFilters = Object.keys(filters).reduce((acc, k) => {
            if (filters[k] === null || filters[k] === undefined)
                return acc;
            acc[k] = String(filters[k]);
            return acc;
        }, {});
        const query = new URLSearchParams(finalFilters).toString();
        return `/api/get_extended_sessions?${query}`;
    },
    getModules: () => {
        return '/api/constants/modules';
    },
    getEmails: (organization_id) => {
        return organization_id === 'all' || typeof organization_id === 'undefined'
            ? '/api/constants/emails'
            : `/api/constants/emails?organization_id=${organization_id}`;
    },
    getOrganizations: () => {
        return '/api/constants/organizations';
    },
    getMiniPCIRoadGraphs: () => {
        return '/api/minipci/get_roadgraph_types';
    },
    getSlamChoiches: () => {
        return '/api/pointcloud/get_slam_choices';
    },
    getReconstructions: () => {
        return '/api/cam_loc/get_reconstructions';
    },
    getPipelineTypes: () => {
        return '/api/constants/pipeline_types';
    },
    requestResetPassword: () => {
        return '/api/password/send_restore_link';
    },
    manuallyChangePassword: () => {
        return '/api/change_password';
    },
    restorePassword2Stage: () => {
        return '/api/password/restore';
    },
    getWholePciRegistry: ({ pipelineId, asSuperAdmin }) => {
        const searchParams = new URLSearchParams();
        if (typeof pipelineId === 'string') {
            searchParams.append('pipelineId', pipelineId);
        }
        if (asSuperAdmin) {
            addSuperAdminPciSearchParams(searchParams);
        }
        else {
            addOperatorPciSearchParams(searchParams);
        }
        const pipelineUrlSubstring = searchParams.size ? `&${searchParams.toString()}` : '';
        return `/pci-api/v1/area/all/30?layers=viewpoints.uniform&layers=segments.sections${pipelineUrlSubstring}`;
    },
    getPciRegistryArea: ({ pipelineId, asSuperAdmin }) => {
        const searchParams = new URLSearchParams();
        if (typeof pipelineId === 'string') {
            searchParams.append('pipelineId', pipelineId);
        }
        if (asSuperAdmin) {
            addSuperAdminPciSearchParams(searchParams);
        }
        else {
            addOperatorPciSearchParams(searchParams);
        }
        const pipelineUrlSubstring = searchParams.size ? `&${searchParams.toString()}` : '';
        return `/pci-api/v1/area/30?layers=viewpoints.uniform&layers=segments.sections${pipelineUrlSubstring}`;
    },
    getMiniPciFrameSegment: (logicalId) => {
        return `/pci-api/v1/item/frame_segment/${logicalId}`;
    },
    /*
     * PCI QA SESSION STUFF
     */
    getPciQaSessionFrameSegment: (frameSegmentId) => {
        return `/pci-api/v1/qa-session/frame-segment/${frameSegmentId}`;
    },
    getPciQaSessionStats: (pipelineId) => {
        return `/pci-api/v1/qa-session/frame-segments/${pipelineId}`;
    },
    patchPciQaSessionStats: ({ qaStatusId, status }) => {
        return `/pci-api/v1/qa-session/frame-segment/${qaStatusId}?status=${status}`;
    },
    exportPciQaSession: (pipelineId) => {
        return `/pci-api/v1/qa-session/export/${pipelineId}`;
    },
    changeFrameSegmentStatus: (frameSegmentId, 
    // @ts-ignore Todo:
    { status, description }) => {
        let url = `pci-api/v1/frame_segment/${frameSegmentId}?status=${status}`;
        if (typeof description === 'string') {
            url = `${url}&description=${description}`;
        }
        return url;
    },
    /* - */
    getSignedUrl: ({ bucketName, objectPath }) => {
        return `/api/buckets/signed_url?bucket_name=${bucketName}&blob_path=${encodeURIComponent(objectPath)}`;
    },
    getAppConfiguration: () => {
        return '/api/configuration';
    },
    runAssetsPipeline: () => {
        return '/api/lsa_asset_loc/submit';
    },
    runSignsAssetsPipeline: () => {
        return '/api/signs_asset_loc/submit';
    },
    getOrganizationSessionsDistance: () => {
        return '/api/organization/sessions/distance';
    },
    simpleExportPciData: (organizationId) => {
        return `/pci-api/v1/stats/section/user?owner_ids=${organizationId}`;
    },
    getFinalAssets: () => {
        return '/assets-registry-api/public/get-final-assets';
    },
    getFinalAssetsForQa: () => {
        return '/assets-registry-api/public/get-final-assets?skip_approved_filter=true';
        // return '/assets-registry-api/public/get-final-assets?approved_filter=true';
    },
    updateFinalAsset: ({ properties: { asset_uuid, asset_version, is_approved } }) => {
        const queryParams = `asset_uuid=${asset_uuid}&asset_version=${asset_version}&is_approved=${is_approved}`;
        return `/assets-registry-api/public/final-assets/update?${queryParams}`;
    },
    getRawToFinalAssetsMapping({ pipelineId }) {
        return `/api/pipeline/get_asset_icons_mapping/${pipelineId}`;
    },
};
