import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { LoadingStatus } from '../../../../const/appConstants';
import { API } from '../../../../services/ApiService';
import { RequestRestorePasswordForm } from './RequestRestorePasswordForm';
import { useException } from '../../../../hooks/useException';
import { RequestRestorePasswordException } from '../../../../utils/exceptions';
export const RequestRestorePasswordFormContainer = ({ onSuccess }) => {
    const [progressStatus, setProgressStatus] = useState(LoadingStatus.INITIAL);
    const { exception, setException } = useException(RequestRestorePasswordException);
    const handleSubmit = async (params) => {
        setProgressStatus(LoadingStatus.LOADING);
        try {
            await API.requestResetPassword(params);
            setProgressStatus(LoadingStatus.SUCCESS);
            if (typeof onSuccess === 'function') {
                onSuccess();
            }
        }
        catch (e) {
            setException(e);
        }
    };
    return (_jsx(RequestRestorePasswordForm, { onSubmit: handleSubmit, progressStatus: progressStatus, exception: exception }));
};
