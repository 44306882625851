import { ExceptionType, ManuallyResetPasswordException, SuccessfulResult } from '../../exceptions';
import { capitalizeString } from '../../miscUtils';
class ManuallyChangePasswordNormalizer {
    constructor() {
        this.serverParamKeysMap = {
            password: 'password',
            password_new: 'newPassword',
        };
    }
    /*
     * Think of making this normalizer global, for now
     * it looks like it should be specific for each case
     */
    normalizeFormErrorDetail(detailsListItem) {
        return {
            // Slice for removing unnecessary body root level
            loc: detailsListItem.loc.map((el) => this.serverParamKeysMap[el]).slice(1).join('.'),
            msg: capitalizeString(detailsListItem.msg),
        };
    }
    async normalizeResponse(rawResponse) {
        if (rawResponse.status === 200) {
            return new SuccessfulResult(null);
        }
        /* Substitute server response which is string in this case */
        if (rawResponse.status === 400) {
            throw new ManuallyResetPasswordException({
                exceptionType: ExceptionType.BAD_REQUEST,
                formErrorDetails: [
                    {
                        loc: 'password',
                        msg: 'Current password is incorrect',
                    },
                ],
            });
        }
        const data = await rawResponse.json();
        throw new ManuallyResetPasswordException({
            exceptionType: ExceptionType.OTHER,
            formErrorDetails: data.detail.map((el) => this.normalizeFormErrorDetail(el)),
        });
    }
}
export const manuallyChangePasswordNormalizer = new ManuallyChangePasswordNormalizer();
