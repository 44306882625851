import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, FormGroup, Tag, InputGroup, Intent } from '@blueprintjs/core'
import cx from 'classnames'
import style from './RunPipelineForm.module.css'

import { SessionMultiSelect } from './SessionSelect'
import { TripleCheckbox } from './TripleCheckbox'
import { SimpleSelect } from './SimpleSelect'
import { HandleSubmitResponse } from './util'

export function RunMultiSessionPipelineForm({ onSubmit }) {
  const [sessionIds, setSessionIds] = useState([])
  const pipelineTypeNames = ['reloc', 'tiling', 'gauss']
  const [preferRelocalized, setPreferRelocalized] = useState(null)
  const [pipelineTypeName, setPipelineTypeName] = useState(pipelineTypeNames[0])
  const [name, setName] = useState(null)
  const [gaussData, _setGaussData] = useState({})

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [requestErrors, setRequestErrors] = useState([])
  // gauss params
  const [minFrames, setMinFrames] = useState(null)
  const [maxFrames, setMaxFrames] = useState(null)
  const [iterations, setIterations] = useState(null)
  const runSectionNames = ['run_pro', 'run_ges', 'run_classic']
  const [runSection, setRunSection] = useState(runSectionNames[0])
  const [smoothCameras, setSmoothCameras] = useState(null)
  // \\ gauss params
  const formOptions = ['file']
  const navigate = useNavigate()

  const setGaussData = (value, name, setter) => {
    gaussData[name] = value
    _setGaussData({...gaussData})
    setter(value)
  }

  const onRunPipeline = () => {
    setLoading(true)
    setErrors({})
    setRequestErrors([])

    onSubmit(pipelineTypeName, {
      session_ids: sessionIds,
      ...(name !== null ? { name } : {}),
      ...(preferRelocalized !== null && pipelineTypeName !== 'reloc' ? { prefer_relocalized: preferRelocalized } : {}),
      ...(pipelineTypeName === 'gauss' ? gaussData : {}),
    }).then(res => {
      HandleSubmitResponse(res, formOptions, navigate, setErrors, setRequestErrors, setLoading)
    })
  }

  const MAX_SELECTED_SESSIONS = 100
  const onSessionItemSelect = session => {
    if (sessionIds.includes(session.id)) return
    if (sessionIds.length >= MAX_SELECTED_SESSIONS) {
      alert(`Maximum amount of selected sessions is ${MAX_SELECTED_SESSIONS}!`)
      return
    }
    setSessionIds([...sessionIds, session.id])
  }
  const onSessionItemRemove = session => setSessionIds(sessionIds.filter(id => id !== session.id))

  return (
    <>
      <div className={style.pathFormWide}>
        <div className={style.formItem}>
          <FormGroup
            className={cx({
              [style.compactFormGroup]: true,
              [style.sessionSelect]: true,
            })}
          >
            <SessionMultiSelect
              sessionIds={sessionIds}
              onItemSelect={onSessionItemSelect}
              onRemove={onSessionItemRemove}
              onReset={() => setSessionIds([])}
            />
            {errors.session_ids && (
              <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                {errors.session_ids.message}
              </Tag>
            )}
          </FormGroup>
        </div>
      </div>
      <div className={style.pathForm}>
        <div className={style.formItem}>
          <FormGroup className={style.compactFormGroup} label="Pipeline type:">
            <SimpleSelect
              itemsTextMapping={{ reloc: 'relocalization' }}
              items={pipelineTypeNames}
              selectedItem={pipelineTypeName}
              onItemSelect={setPipelineTypeName}
            />
          </FormGroup>
        </div>

        {pipelineTypeName === 'gauss' ? (
          <div className={style.gaussOptions}>
            <div className={style.pathForm}>
              <div className={style.formItem}>
                <FormGroup className={style.compactFormGroup} label="Min frames in area:">
                  <InputGroup
                    fill
                    type="number"
                    className={style.inputShort}
                    value={minFrames === null ? '' : minFrames}
                    onInput={e => setGaussData(e.target.value, 'min_frames', setMinFrames)}
                  />
                  {errors.min_frames && (
                    <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                      {errors.min_frames.message}
                    </Tag>
                  )}
                </FormGroup>
              </div>
              <div className={style.formItem}>
                <FormGroup className={style.compactFormGroup} label="Max frames in area:">
                  <InputGroup
                    fill
                    type="number"
                    className={style.inputShort}
                    value={maxFrames === null ? '' : maxFrames}
                    onInput={e => setGaussData(e.target.value, 'min_frames', setMaxFrames)}
                  />
                  {errors.min_frames && (
                    <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                      {errors.max_frames.message}
                    </Tag>
                  )}
                </FormGroup>
              </div>
              <div className={style.formItem}>
                <FormGroup className={style.compactFormGroup} label="Iterations:">
                  <InputGroup
                    fill
                    type="number"
                    className={style.inputShort}
                    value={iterations === null ? '' : iterations}
                    onInput={e => setGaussData(e.target.value, 'iterations', setIterations)}
                  />
                  {errors.min_frames && (
                    <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                      {errors.iterations.message}
                    </Tag>
                  )}
                </FormGroup>
              </div>
              <div className={style.formItem}>
                <TripleCheckbox
                  value={smoothCameras}
                  setter={val => setGaussData(val, 'smooth_cameras', setSmoothCameras)}
                  label="Use smoothed cameras"
                />
                {errors.smooth_cameras && (
                  <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                    {errors.smooth_cameras.message}
                  </Tag>
                )}
              </div>
              <div className={style.formItem}>
                <FormGroup className={style.compactFormGroup} label="Run section:">
                  <SimpleSelect
                    items={runSectionNames}
                    selectedItem={runSection}
                    onItemSelect={val => setGaussData(val, 'run_section', setRunSection)}
                  />
                  {errors.run_section && (
                    <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                      {errors.run_section.message}
                    </Tag>
                  )}
                </FormGroup>
              </div>
            </div>
          </div>
        ) : null}

        <div className={style.formItem}>
          <FormGroup className={style.compactFormGroup} label="Pipeline name:" labelFor="name">
            <InputGroup
              fill
              id="name"
              className={cx({ [style.inputShort]: true, [style.inputWide]: true })}
              value={name !== null ? name : ''}
              onInput={e => setName(e.target.value)}
            />
            {errors.name && (
              <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                {errors.name.message}
              </Tag>
            )}
          </FormGroup>
        </div>

        <div className={cx({ [style.paramDependent]: true, [style.inactive]: pipelineTypeName === 'reloc' })}>
          <div className={style.formItem}>
            <TripleCheckbox value={preferRelocalized} setter={setPreferRelocalized} label="Prefer relocalized" />
            {errors.prefer_relocalized && (
              <Tag multiline fill large intent={Intent.DANGER} className={style.error}>
                {errors.prefer_relocalized.message}
              </Tag>
            )}
          </div>
          {pipelineTypeName == 'gauss' ? (
            <Tag multiline fill={false} large>
              For Gaussian in case of several tracks they must be relocalized
            </Tag>
          ) : null}
        </div>

        <div className={style.formItem}>
          <Button
            intent={Intent.PRIMARY}
            disabled={loading || sessionIds.length == 0}
            loading={loading}
            onClick={onRunPipeline}
            text="Run pipeline"
          />
        </div>
        {requestErrors.map(({ loc, msg }) => (
          <Tag key={msg} multiline fill large intent={Intent.DANGER} style={{ width: '100%', textAlign: 'center' }}>
            {`${loc}: ${msg}`}
          </Tag>
        ))}
      </div>
    </>
  )
}
