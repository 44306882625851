const getIndexOf = (array, value) => {
    const index = array.indexOf(value);
    if (index > -1) {
        return index;
    }
    array.push(value);
    return array.length - 1;
};
// Todo:
//  Refactor this. It has to be much more simple after removing colored points
//  It does only color and width for session linestring
export const getAssetGeoJsonRenderingConfig = () => {
    const assetGeoJsonRenderingConfig = {
        getPointRadius: 7,
        lineWidthMinPixels: 8,
        getFillColor: (info) => {
            const { properties } = info;
            if (properties.sessionId) {
                return [50, 255, 50, 255];
            }
            // return assetsRenderingPropsMap?.[properties.sign].color || [0, 0, 0, 255];
            return [0, 0, 0, 255];
        },
    };
    return assetGeoJsonRenderingConfig;
};
/*
// Todo: remove?
export function makeAssetsRenderingPropsMap(
  features: Feature<any, any>[],
): AssetsRenderingPropsMap {
  const result: AssetsRenderingPropsMap = {};
  const signs: string[] = [];

  for (let i = 0; i < features.length; i += 1) {
    const { properties: { sign } } = features[i];

    if (!result[sign]) {
      const newIndex = getIndexOf(signs, sign);
      const color = randomFeatureColors[newIndex] || randomFeatureColors[randomFeatureColors.length - 1];
      result[sign] = { sign, count: 0, color };
    }

    result[sign].count += 1;
  }

  return result;
}
*/
