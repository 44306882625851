import { localeDateManager } from '../localeUtils';
import { parseDate } from '../parseDate';
// Old, worse written
// export function formatDate(date: string | Date): string {
//   return date
//     ? localeDateManager.formatDate(typeof date === 'string'
//       ? parseDate(`${date}`)
//       : date) // Todo: handle rendering as
//     : '';
// }
export function formatDate(date, showAsLocalDate = false) {
    if (!date) {
        return '[wrong date]';
    }
    if (typeof date === 'string') {
        return localeDateManager.formatDate(parseDate(`${date}`, showAsLocalDate));
    }
    // Date cases
    if (showAsLocalDate) {
        return localeDateManager.formatDate(parseDate(`${date}`, showAsLocalDate));
    }
    return localeDateManager.formatDate(date);
}
