import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { asyncConfigManager } from '../../../utils/asyncConfigManager';
import { extractBucketAndPathFromGcsUrl } from '../../../utils/download/extractBucketAndPathFromGcsUrl';
import { API } from '../../../services/ApiService';
import { AssetFrameViewer } from './AssetFrameViewer';
export const AssetFrameViewerContainer = ({ pipelineId, filename, sign }) => {
    const [url, setUrl] = useState(null);
    useEffect(() => {
        (async () => {
            // @ts-ignore Todo
            const { persistentBucket } = asyncConfigManager.config.bucketNames;
            const rawUrl = `https://storage.googleapis.com/${persistentBucket}/pipeline-${pipelineId}/uuid450/asset_localization_results/${filename}`;
            const params = extractBucketAndPathFromGcsUrl(rawUrl);
            const signedUrl = await API.getSignedUrl(params);
            setUrl(signedUrl);
        })();
    }, [pipelineId, filename, sign]);
    return _jsx(AssetFrameViewer, { url, sign });
};
