import { getTrackColor } from 'components/Map/mapUtils'

export default function TrackProps({ properties }) {
  const color = getTrackColor(properties)
  const style = {
    borderBottom: `3px solid rgba(${color.join(',')})`,
    lineHeight: '2em',
  }
  return (
    <div>
      <h4 className="map-sidebar-title" style={style}>
        Id {properties.id}
      </h4>
      <p className="text bp4-text-small bp4-text-muted">
        <b>Folder name:</b> {properties.folder_name}
      </p>
      <p className="text bp4-text-small bp4-text-muted">
        <b>Folder prefix:</b> {properties.folder_prefix}
      </p>
      <p className="text bp4-text-small bp4-text-muted">
        <b>User's email:</b> {properties.user.email}
      </p>
      <p className="text bp4-text-small bp4-text-muted">
        <b>OS:</b> {properties.os}
      </p>
      <p className="text bp4-text-small bp4-text-muted">
        <b>Device:</b> {properties.device}
      </p>
      <p className="text bp4-text-small bp4-text-muted">
        <b>Start time (UTC):</b> {properties.start_time}
      </p>
      <p className="text bp4-text-small bp4-text-muted">
        <b>End time (UTC):</b> {properties.end_time}
      </p>
      <p></p>
      <h5>Cameras data</h5>
      {properties.cameras.map(camera => (
        <div key={camera.camera_name}>
          <p className="text bp4-text-small bp4-text-muted">
            <b>Camera name:</b> {camera.camera_name}
          </p>
          <p className="text bp4-text-small bp4-text-muted">
            <b>FPS:</b> {camera.fps}
          </p>
          <p className="text bp4-text-small bp4-text-muted">
            <b>Frame count:</b> {camera.frame_count}
          </p>
          <p className="text bp4-text-small bp4-text-muted">
            <b>Resolution:</b> {camera.width}x{camera.height}
          </p>
          <p></p>
        </div>
      ))}
      {properties.pipelines.length > 0 ? <h5>Pipelines</h5> : null}
      {properties.pipelines.map(pipeline => (
        <div key={pipeline.uuid}>
          <p className="text bp4-text-small bp4-text-muted">
            <a target="_blank" href={`/pipeline/${pipeline.uuid}`}>
              {pipeline.uuid}
            </a>
          </p>
          <p className="text bp4-text-small bp4-text-muted">
            <b>Status:</b> {pipeline.status}
          </p>
          <p className="text bp4-text-small bp4-text-muted">
            <b>Created:</b> {pipeline.created}
          </p>
          <p className="text bp4-text-small bp4-text-muted">
            <b>Updated:</b> {pipeline.updated}
          </p>
          <p></p>
        </div>
      ))}
    </div>
  )
}
